import { Button, Dialog, Slide, Box, CircularProgress, Typography, TextField } from '@mui/material'
import { forwardRef, useEffect, useState } from "react"
import { useStyles } from './EditProducerCuit.styles'
import { cuitValidator } from 'utils/validators'

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

export default function EditProducerCuit({submit, isOpen, close}) {
  const [CuitValue, setCuitValue] = useState('')
  const styles = useStyles()

  const handleSubmit = () => {
    const cuitIsValid = cuitValidator(CuitValue)
    if(!cuitIsValid)
      return alert('Cuit inválido');
    submit(parseInt(CuitValue))
  }

  useEffect(() => {
    if(!isOpen){
      setCuitValue('')
    }
  }, [isOpen])

  return (
    <Dialog
      open={isOpen} 
      onClose={close}
      transition={Transition}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault()
          handleSubmit()
        }}
      >
        <Box className={styles.container}>
          <TextField
            label={`Antes de continuar, ingrese el cuit del productor: `}
            variant='outlined'
            placeholder='(solo números)'
            value={CuitValue}
            fullWidth
            type="number"
            autoFocus
            className={styles.input}
            onChange={(e) => {
              const {value} = e.target
              setCuitValue(value)
            }}
          />
          <Button type='submit' disabled={!CuitValue}>
            Continuar
          </Button>
        </Box>
      </form>
    </Dialog>
  )
}