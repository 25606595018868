import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: '1040px',
      margin: 'auto',
      marginBottom: '170px',
    },
  },
  firstColumn: {
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      maxWidth: '680px',
      marginTop: '-20px',
    },
  },
  textGrid: {
    padding: '20px, 0px, 20px, 0px',
    borderBottom: '0.6px solid #bbbbbb',
  },
  text: {
    fontWeight: '400',
    fontSize: '13px',
    lineHeight: '16px',
    color: theme.palette.primary.almostBlack,
  },
  textInput: {
    '& input': {
      fontSize: '12px',
    },
    '& label': {
      fontSize: '12px',
    },
  },
  title: {
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '22px',
    color: theme.palette.primary.almostBlack,
  },
  subtitle: {
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '15px',
    color: theme.palette.primary.almostBlack,
    marginBottom: '9px',
  },
  subtitleInfo: {
    fontWeight: '300',
    fontSize: '12px',
    lineHeight: '15px',
    color: theme.palette.primary.almostBlack,
    marginBottom: '20px',
  },
  grid: {
    margin: '15px',
    width: '100%',
  },
  shorterTextField: {
    width: '60%',
  },
  textFieldInput: {
    fontSize: '12px',
    padding: '14px',
  },
  userInfo: {
    padding: '20px',
    margin: '15px',
    boxShadow: '0px 2px 14px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    width: '82vw',
    [theme.breakpoints.up('sm')]: {
      margin: '20px',
      marginLeft: 0,
      width: 'auto'
    },
  },
  card: {
    padding: '20px',
    margin: '15px',
    boxShadow: '0px 2px 14px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    border: '2px solid transparent',
    '&:active, &:hover': {
      borderColor: theme.palette.primary.typographyGreen,
    },
    [theme.breakpoints.up('sm')]: {
      margin: '20px',
      marginLeft: 0,
    },
  },
  cardInsideContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    verticalAlign: 'center',
  },
  addressTitleActive: {
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '15px',
    color: theme.palette.primary.almostBlack,
  },
  addressDescriptionActive: {
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '15px',
    color: theme.palette.primary.almostBlack,
    marginTop: '4px',
  },
}))
