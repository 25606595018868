import React, { useEffect, useState } from 'react'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { useMediaQuery, Box, Skeleton } from '@mui/material'
import { useStyles } from './Home.styles'
import ProductsSection from '../../components/ProductsSection'
import CategoriesSection from '../../components/CategoriesSection/index'
import FAQSection from '../../components/FAQSection/index'
import SearchBox from '../../components/SearchBox'
import { useDispatch, useSelector } from 'react-redux'
import { clearAllFilters } from 'stores/searchStore/actionsSearch'
import { Helmet } from 'react-helmet'
import {
  getUserPromotionsAndOffers,
  updateUserInfo,
} from 'stores/userStore/actionsUser'
import NewClientPopup2 from 'components/NewClientsPopup/NewClientPopup2'
import NewsSection from 'components/NewsSection/index'
import getProductsSections from './services/getProductsSections'
import CarouselComponent from 'components/Carousel/CarouselComponent'
import YouTubeVideo from 'components/YouTubeVideo/YouTubeVideo'
import ConsultarProducto from 'components/ConsultarProducto/ConsultarProducto'

const Home = ({
  consultaModal = false
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const desktop = useMediaQuery((theme) => theme.breakpoints.up('md'), {
    noSsr: true,
  })
  
  const { userInfo, signedIn } = useSelector(({ userAuth }) => userAuth)
  const desktopBanners = useSelector(({ app }) => app.banners?.home?.desk)
  const mobileBanners = useSelector(({ app }) => app.banners?.home?.mobile)
  const initFinished = useSelector(({ app }) => app.initFinished)
  
  const [productsSections, setProductsSections] = useState([])
  const [newPopupOpen, setNewPopupOpen] = useState(false)
  const [popupAlreadyShowed, setPopupAlreadyShowed] = useState(false)
  const [consultaModalIsOpen, setConsultaModalIsOpen] = useState(consultaModal)

  useEffect(() => {
    if (!userInfo?.codigoUsuario) return

    getProductsSections(userInfo?.codigoUsuario).then(setProductsSections)
  }, [userInfo?.codigoUsuario])

  useEffect(() => {
    if (!userInfo.user) return
    dispatch(getUserPromotionsAndOffers(userInfo.user))
    dispatch(updateUserInfo(userInfo.user))
  }, [userInfo.user])

  useEffect(() => {
    dispatch(clearAllFilters())
    if (!signedIn && !popupAlreadyShowed) {
      setPopupAlreadyShowed(true)
      return setNewPopupOpen(true)
    }
  }, [])

  const filterBanners = (banners) => {
    if (!banners) return []
    return banners.filter((banner) =>
      signedIn ? true : !banner.showForLoggedIn
    )
  }

  const filteredDesktopBanners = filterBanners(desktopBanners)
  const filteredMobileBanners = filterBanners(mobileBanners)

  return (
    <>
      <Helmet>
        <title>VILLANUEVA</title>
        <meta
          name='description'
          content='Empresa distribuidora de insumos agropecuarios y ganaderos. Caravanas Oficiales. Sitio oficial VILLANUEVA. ¡Encontrá todos los productos de identificación animal, salud animal, rural, y muchos más!'
        />
        <meta
          name='keywords'
          content='villanueva,villa nueva,caravanas oficiales,villanuevafull,villa nueva s.a,villa nueva sa,insumos agropecuarios,agropecuario,ganadería,ganadero'
        />
      </Helmet>
      {/* <NewClientPopup2
        isOpen={newPopupOpen}
        onClose={() => {
          setNewPopupOpen(false)
        }}
      /> */}
      <ConsultarProducto
        onClose={() => setConsultaModalIsOpen(false)}
        open={consultaModalIsOpen}
      />
      <Box className={classes.container}>
        {!desktop && (
          <Box className={classes.searchGrid}>
            <SearchBox />
          </Box>
        )}
        {initFinished &&
        (desktopBanners?.length > 0 || mobileBanners.length > 0) ? (
          <CarouselComponent
            bannersToShow={
              desktop ? filteredDesktopBanners : filteredMobileBanners
            }
            desktop={desktop}
          />
        ) : (
          <Skeleton
            variant='rectangular'
            animation='wave'
            width={'100%'}
            height={'75vh'}
          />
        )}
        <YouTubeVideo />
        <Box className={classes.innerContainer}>
          <CategoriesSection />
          {productsSections.length > 0 && (
            <Box className={classes.mainGrid}>
              {productsSections.map((section, index) => (
                <ProductsSection
                  key={section.id}
                  titleSection={section.label}
                  data={section.products}
                  redirectTo={section.redirectTo}
                  carouselIndex={index}
                />
              ))}
            </Box>
          )}
        </Box>

        <Box className={classes.innerContainer}>
          <Box className={classes.mainGrid}>
            {process.env.REACT_APP_ENVIRONMENT === 'development' && (
              <NewsSection />
            )}
          </Box>
        </Box>
        <FAQSection />
      </Box>
    </>
  )
}

export default Home
