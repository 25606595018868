import { ApiConnection } from 'config';

export function cuitValidator(cuit) {
  if (cuit.length !== 11) {
    return false;
  }

  const [checkDigit, ...rest] = cuit
    .split('')
    .map(Number)
    .reverse();

  const total = rest.reduce(
    (acc, cur, index) => acc + cur * (2 + (index % 6)),
    0,
  );

  const mod11 = 11 - (total % 11);

  if (mod11 === 11) {
    return checkDigit === 0;
  }

  if (mod11 === 10) {
    return false;
  }

  return checkDigit === mod11;
}

const DEFAULT_RENSPA_VALIDATOR_MESSAGE = 'Formato inválido'

export async function renspaValidator(renspa, cuit) {
  if (renspa.length !== 17) return { isValid: false, reason: DEFAULT_RENSPA_VALIDATOR_MESSAGE }

  const { data } = await ApiConnection.get(
    `producer/validate-renspa?renspa=${renspa}&cuit=${cuit}`
  )

  const { reason, isValid } = data.response

  return { isValid, reason: reason || DEFAULT_RENSPA_VALIDATOR_MESSAGE }
}
