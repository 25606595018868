import axiosRetry from "axios-retry";
import axios from 'axios';

// Create a new instance of Axios
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_URL_BACKEND_REMOTO}`,
});

// // Apply retry logic to the instance
// axiosRetry(axiosInstance, {
//   retries: 3, // Set the number of retries
//   retryDelay: axiosRetry.exponentialDelay, // Use exponential backoff strategy
//   shouldResetTimeout: true, // Reset the timeout on retries
// });

// Export the instance
export default axiosInstance;
