import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: '45px',
    backgroundColor: theme.palette.primary.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: '18px',
    color: theme.palette.primary.almostBlack,
    fontWeight: 600,
    marginBottom: '30px',
  },
  tagName: {
    fontWeight: 600,
    fontSize: '12px',
    color: theme.palette.primary.almostBlack,
    marginBottom: '5px',
  },
  italic: {
    fontWeight: 300,
    fontStyle: 'italic',
  },
  textField: {
    marginBottom: '14px',
    fontWeight: 300,
    fontSize: '12px',
    borderRadius: '50%',
    '& .MuiInputBase-root': {
      fontSize: '12px',
    },
    '& .MuiFormHelperText-root': {
      color: 'red',
    },
  },
  agregar: {
    textAlign: 'center',
    margin: '10px',
    display: 'block',
    fontWeight: 600,
    fontSize: '12px',
    color: theme.palette.primary.moreBlue,
  },
  formRow: {
    display: 'flex',
    marginBottom: '20px',
    marginTop: '30px',
  },
  halfRow: {
    width: '50%',
    marginRight: '30px',
  },
  buttonBuy: {
    fontSize: '17px',
    fontWeight: 500,
    padding: '14px',
    backgroundColor: theme.palette.primary.main,
    textTransform: 'capitalize',
  },
  buttonBack: {
    fontSize: '17px',
    fontWeight: 500,
    padding: '14px',
    color: theme.palette.primary.main,
    textTransform: 'capitalize',
  },
}))
