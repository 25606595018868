import { Chip } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

const FilterChips = ({ filterName, filterValue, handleDeleteFilter }) => {
  return (
    <Chip
      deleteIcon={<CloseIcon fontSize='small' />}
      sx={{
        margingTop: '15px',
        fontSize: '12px',
        borderRadius: '4px',
        fontStyle: 'normal',
        fontWeight: 400,
        color: (theme) => theme.palette.primary.almostBlack,
        backgroundColor: (theme) => theme.palette.primary.chipBackground,
      }}
      label={filterValue}
      onDelete={() => handleDeleteFilter(filterName)}
    />
  )
}
export default FilterChips
