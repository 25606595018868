import { Typography, TextField } from '@mui/material'
import { useStyles } from './ClientDetails.styles'

const ClientDetails = ({
  selectedProducer,
  setSelectedProducer,
  errors,
  setErrors
}) => {
  const classes = useStyles()
  const {
    razonSocial,
    digitoVerificador,
    cuit,
    cuig,
    contacto,
    direccion,
    localidad,
    provincia,
    nombre,
    renspa,
  } = selectedProducer

  return (
    <div className={classes.container}>
      <div className={classes.detailContainer}>
        <Typography className={classes.detailTitle}>Razón Social</Typography>
        <Typography className={classes.detailContent}>
          {razonSocial ?? 'no especificado'}
        </Typography>
      </div>

      <div className={classes.detailContainer} style={{ width: '40%' }}>
        <Typography className={classes.detailTitle}>CUIT</Typography>
        <Typography className={classes.detailContent}>{cuit}</Typography>
      </div>

      <div className={`${classes.detailContainer}`}>
        <Typography className={classes.detailTitle}>
          Nombre del establecimiento
        </Typography>
        <Typography className={classes.detailContent}>
          {nombre ?? 'no especificado'}
        </Typography>
      </div>

      <div className={`${classes.detailContainer}`} style={{ width: '41%' }}>
        {/* <Typography className={classes.detailTitle}>
          CV
        </Typography> */}
        <Typography className={classes.detailContent}>
          <CustomRequiredTextField
            variant='filled'
            placeholder='CV *'
            label='CV'
            required
            name='digitoVerificador'
            errorText={errors?.digitoVerificador?.message}
            className={classes.textField}
            onFocus={(e) => e.target.value && setErrors({...errors, digitoVerificador: null})}
            InputProps={{
              value: digitoVerificador,
              onChange: (({target: {value, name}}) => {
                if(errors.digitoVerificador && value){
                  setErrors({...errors, digitoVerificador: null})
                }
                setSelectedProducer({ ...selectedProducer, [name]: value.replace(/[^\d]/g, '') })
              }),
            }}
            defaultValue={digitoVerificador}
          />
        </Typography>
      </div>

      <div className={classes.detailContainer}>
        <Typography className={classes.detailTitle}>RENSPA</Typography>
        <Typography className={classes.detailContent}>
          {renspa ?? 'no especificado'}
        </Typography>
      </div>

      <div className={classes.detailContainer} style={{ width: '40%' }}>
        <Typography className={classes.detailTitle}>CUIG</Typography>
        <Typography className={classes.detailContent}>
          {cuig ?? 'no especificado'}
        </Typography>
      </div>

      <div className={classes.detailContainer}>
        <Typography className={classes.detailTitle}>Ubicación</Typography>
        <Typography className={classes.detailContent}>
          {`${direccion ?? ''} ${localidad ?? ''} ${provincia ?? ''}`}
        </Typography>
      </div>

      <div className={classes.detailContainer} style={{ width: '40%' }}>
        <Typography className={classes.detailTitle}>Teléfono</Typography>
        <Typography className={classes.detailContent}>
          {contacto?.telefonoMovil ??
            contacto?.telefonoFijo ??
            'no especificado'}
        </Typography>
      </div>

      {/* <div className={classes.editLinkContainer}>
        <BorderColorIcon sx={{ fontSize: 20, marginRight: '6px' }} />
        <Typography to='#' disabled style={{ color: '#53A2FF' }}>
          Editar perfil
        </Typography>
      </div> */}
    </div>
  )
}

export default ClientDetails


const CustomRequiredTextField = ({
  label,
  register,
  field = '',
  errorText = '',
  extraValidation = {},
  placeholder = '',
  ...rest
}) => {
  return (
    <TextField
      variant='outlined'
      label={label}
      sx={{
        '& label': {
          fontSize: '14px',
        },
        '& .MuiInputBase-root': {
          fontSize: '14px',
        },
        '& .MuiFormHelperText-root': {
          color: 'red',
        },
      }}
      required
      size='small'
      helperText={errorText}
      placeholder={placeholder}
      {...(register ? register(field, {
        required: `${label} es obligatorio`,
        ...extraValidation,
      }) : {})}
      {...rest}
    />
  )
}