import { Button, Dialog, Slide, Box, CircularProgress, Typography } from '@mui/material'
import { Fragment, forwardRef } from "react"
import { useStyles } from "./PopupPromotionProducts.styles"
import { useNavigate } from "react-router-dom"
import { buildUrl } from "utils/filters"

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const PopupPromotionProducts = ({
  isOpen = false,
  close = () => {},
  productParticipeInfo,
  continueAction,
  firstActionDisabled
}) => {
  const classes = useStyles()
  const navigate = useNavigate()
  
  return (
    <Dialog
      open={isOpen} 
      onClose={close}
      transition={Transition}
    >
      <Box className={classes.container}>
        <Typography className={classes.title}>
          Sumá mas productos y aprovechá las promociones del mes:
        </Typography>
        <Box className={classes.infoContainer}>
          {productParticipeInfo.map(({products, promotion, quantityToPromo, redirectToCategory}) => (
            <Fragment key={promotion}>
              <ul>
                {products.map(({nombre, imagen, sku}) => 
                  <li style={{margin: 0, borderBottom: '1px solid #dddddd'}} key={nombre}>
                    <Button 
                      type="button"
                      onClick={() => 
                        redirectToCategory 
                          ? navigate('/catalogo/'+buildUrl(redirectToCategory))
                          : navigate('/producto/'+sku)
                      }
                      sx={{display: 'flex', width: '100%', marginBottom: 0, justifyContent: 'flex-start', minHeight: 50}}
                    >
                      {imagen[0] && (
                        <img width={50} src={imagen[0]} />
                      )}
                      <Typography style={{ marginLeft: imagen[0] ? 10 : 60, color: '#444444'}}>
                        {nombre}
                      </Typography>
                    </Button>
                  </li>
                )}
              </ul>
              <Typography style={{ textAlign: 'left', color: '#4AA259', fontWeight: 'bold' }}>
                {products.length > 1 ? 'Promociones disponibles' : 'Promoción disponible'}: {promotion.join(', ')}
              </Typography>
              <Typography style={{ textAlign: 'left', color: 'red', fontWeight: 'bold' }}>
                Agregá {quantityToPromo} artículo{quantityToPromo > 1 ? 's' : ''} y accedé a esta promoción
              </Typography>
              <br/>
              <br/>
            </Fragment>
          ))}
        </Box>
        <Button
          onClick={continueAction}
          variant='contained'
          disabled={firstActionDisabled}
          fullWidth={true}
          className={classes.buttonContinue}
        >
          {firstActionDisabled ? <CircularProgress/> : 'Proceder al pago'}
        </Button>
        <Button
          onClick={close}
          variant='text'
          fullWidth={true}
          className={classes.buttonKeepBuying}
        >
          Seguir comprando
        </Button>
      </Box>
    </Dialog>
  )
}

export default PopupPromotionProducts