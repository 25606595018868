import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: '1040px',
      margin: 'auto',
    },
  },
  title: {
    margin: '20px 8px 5px',
    [theme.breakpoints.up('sm')]: {
      margin: '20px 0px',
    },
  },
  orderList: {
    margin: '14px',
    padding: '16px 12px 44px',
    backgroundColor: 'white',
    boxShadow: '0px 2px 14px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    marginBottom: '72px',
  },
  tableHeadersRow: {
    paddingBottom: '23px',
    maxWidth: '963px',
    paddingRight: '24px',
  },
  borderTop: {
    borderTop: `1px solid ${theme.palette.primary.borderGrey}`,
  },
  accordionContainer: {
    boxShadow: 'none',
    borderTop: `1px solid ${theme.palette.primary.borderGrey}`,
    '& .MuiAccordionSummary-root': {
      padding: 0,
    },
    '& .MuiAccordionDetails-root': {
      padding: '30px 22px',
    },
  },
  detailsHeadersRow: {
    paddingBottom: '5px',
    borderBottom: `1px solid ${theme.palette.primary.borderGrey}`,
    marginBottom: '10px',
  },
  detailHeader: {
    color: '#2D2D2D',
    fontWeight: 500,
    fontSize: '11px',
  },
  detailRow: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '5px',
  },
  detailText: {
    fontSize: '11px',
  },
}))

export const tableColumns = ['20%', '40%', '40%']

export const detailsWidths = ['12.5%', '50%', '25%', '12.5%']

export const getStatusColor = (status) => {
  switch (status) {
    case 'Cancelado':
      return '#EE7054'
    case 'Pendiente':
      return '#EDB039'
    case 'Despachado':
      return '#558B57'
  }
}
