import LeftButton from 'components/LeftButton'
import ProductCard from 'components/ProductsSection/Product'
import RightButton from 'components/RightButton'
import React, { useState } from 'react'
import TinySlider from 'tiny-slider-react'
import sinImagen from '../../assets/sinImagen.jpg'
import {Box, useMediaQuery} from '@mui/material'
import { isLastOpportunityCategory } from 'pages/ProductPage/utils/helpers'

const stylesDesk = {
  width: '1040px',
  margin: 'auto',
  color: 'transparent',
  userSelect: 'none'
}

const stylesMobile = {
  ...stylesDesk,
  width: '100%',
  marginBottom: '30px',
}

const settings = {
  slideBy: 1,
  rewind: true,
  mouseDrag: true,
  nav: false,
  controlsPosition: 'bottom',
  navPosition: 'bottom',
  autoWidth: false,
}

const controlContainerStyles = {
  position: 'absolute',
  width: '1080px',
  marginLeft: '-20px',
  marginTop: '175px',
  display: 'flex',
  justifyContent: 'space-between',
  zIndex: 100,
}

const controlStyle = {
  backgroundColor: 'white',
  boxShadow: '2px 2px 5px rgba(30,30,30,0.1)',
  '&:hover': {
    backgroundColor: 'rgb(230,230,230)',
    transition: '0.25s',
  },
}

const showItem = (displayIndex, itemIndex) => {
  if (displayIndex === itemIndex) return true
  if (itemIndex === displayIndex + 1) return true
  if (itemIndex === displayIndex + 2) return true
  if (itemIndex === displayIndex + 3) return true
  return false
}

function ProductSlider({ data, signedIn, carouselIndex }) {
  const [displayIndex, setDisplayIndex] = useState(1)
  const desktop = useMediaQuery((theme) => theme.breakpoints.up('sm'), { noSsr: true })

  return (
    <div id='sliderContainer' style={desktop ? stylesDesk : stylesMobile}>
      {data?.length && (
        <>
          <div
            id={`control-container-${carouselIndex}`}
            style={controlContainerStyles}
          >
            {desktop && (
              <>
                <LeftButton
                  sx={{
                    ...controlStyle,
                  }}
                  size='large'
                />
                <RightButton sx={controlStyle} size='large' />
              </>
            )}
          </div>
          <TinySlider
            settings={{
              ...settings,
              container: '#sliderContainer',
              controls: desktop === true,
              items: desktop ? 4 : 2,
              autoWidth: desktop === true,
              controlsContainer: `#control-container-${carouselIndex}`,
            }}
            onIndexChanged={(info) => setDisplayIndex(info?.displayIndex)}
            style={{
              position: 'relative',
            }}
          >
            {data?.map((element, index) => (
              <Box
                className='slide-wrapper'
                sx={{
                  width: '265px',
                  display: 'inline-block',
                  '&:last-child': {
                    width: '245px',
                  },
                }}
                key={index}
              >
                <ProductCard
                  {...element}
                  sinImagen={sinImagen}
                  showPrice={signedIn}
                  style={{
                    height: showItem(displayIndex, index + 1) ? '300px' : '0',
                    color: showItem(displayIndex, index + 1)
                      ? 'black'
                      : 'transparent',
                    marginRight: '20px',
                  }}
                  isLastOpportunity={isLastOpportunityCategory(element?.menuCategorias)}
                >
                  {element?.nombre}
                </ProductCard>
              </Box>
            ))}
          </TinySlider>
        </>
      )}
    </div>
  )
}

export default ProductSlider
