import React, { useRef } from 'react'
import {
  Grid,
  Box,
  useMediaQuery,
  Tooltip,
  IconButton,
  CircularProgress,
} from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download'
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined'
import { Carousel } from 'react-responsive-carousel'

const ProductImageSection = ({
  productToShow,
  sinImagen,
  selectedImage,
  setSelectedImage,
  downloadingImg,
  handleDownloadImg,
  handleShare,
  showDiscountLabel
}) => {
  const currentImage = useRef(0)
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('sm'))

  return (
    <Grid
      container
      sx={{
        position: 'relative',
      }}
    >
      {isDesktop && (
        <Grid
          item
          sx={{
            width: '70px',
            marginRight: '10px',
            display: 'block',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: showDiscountLabel ? '35px' : '0px' }}>
            {productToShow?.imagen?.map((img, index) => (
              <Box
                key={index}
                component='img'
                src={img}
                sx={{
                  cursor: 'pointer',
                  width: '50px',
                  height: '50px',
                  boxShadow: '0px 0px 5px #C4C4C4',
                  marginBottom: '12px',
                  borderRadius: '4px',
                  objectFit: 'contain',
                  '&:hover': {
                    boxShadow: '0px 0px 8px 1px rgba(0,0,0,0.11)',
                  },
                }}
                onClick={() => {
                  setSelectedImage(index)
                }}
              />
            ))}
          </Box>
        </Grid>
      )}
      <Grid
        item
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
          }}
        >
          <Tooltip
            title={downloadingImg ? 'Descargando imagen' : 'Descargar imagen'}
            sx={{ height: '40px' }}
          >
            <IconButton>
              {downloadingImg ? (
                <CircularProgress
                  style={{
                    width: 25,
                    height: 25,
                    color: '#53A2FF',
                  }}
                />
              ) : (
                <DownloadIcon
                  sx={(theme) => ({
                    color: theme.palette.primary.moreBlue,
                    width: '100%',
                  })}
                  onClick={handleDownloadImg}
                />
              )}
            </IconButton>
          </Tooltip>
          <Tooltip title='Copiar enlace para compartir' sx={{ height: '40px' }}>
            <IconButton>
              <ShareOutlinedIcon
                sx={(theme) => ({
                  color: theme.palette.primary.moreBlue,
                  width: '100%',
                })}
                onClick={handleShare}
              />
            </IconButton>
          </Tooltip>
        </Box>
        {productToShow?.imagen?.length ? (
          <Box
            sx={{
              width: '100%',
              maxWidth: isDesktop ? '600px' : '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Carousel
              showArrows={true}
              showThumbs={false}
              dynamicHeight
              onChange={(index) => {
                setSelectedImage(index)
              }}
              showIndicators={false}
              selectedItem={selectedImage}
            >
              {productToShow?.imagen?.map((img, index) => (
                <Box
                  key={index}
                  component='img'
                  src={img}
                  sx={{
                    width: isDesktop ? '500px' : '100%',
                    height: isDesktop ? '500px' : 'auto',
                    maxWidth: isDesktop ? '500px' : '100%',
                    maxHeight: isDesktop ? '500px' : '400px',
                    objectFit: 'contain',
                  }}
                  alt={`${
                    productToShow?.nombreEC || productToShow?.nombre
                  } ${index}`}
                />
              ))}
            </Carousel>
          </Box>
        ) : (
          <Box
            component='img'
            src={sinImagen}
            sx={{
              width: '100%',
              maxWidth: '600px',
              height: 'auto',
              objectFit: 'contain',
            }}
            alt={`El Producto ${
              productToShow?.nombreEC || productToShow?.nombre
            } no tiene imagen`}
          />
        )}
      </Grid>
    </Grid>
  )
}

export default ProductImageSection
