import React, { useState } from 'react'
import useStyles from './ChangesAndReturns.styles'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Helmet } from 'react-helmet'
import { useDispatch } from 'react-redux'
import { sendForm } from './services/sendForm';
import { setSnackbar } from 'stores/snackbarStore'

function ChangesAndReturns() {
  const [typeSelect, setTypeSelect] = useState('return')
  const navigate = useNavigate()
  const classes = useStyles()
  const dispatch = useDispatch()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      receiptNumber: '',
      orderNumber: '',
      message: '',
    },
  })

  const onSubmitForm =  async (data) => {
    try{
      await sendForm({ ...data, type: typeSelect })
      navigate('/devoluciones/enviado')
    }
    catch{
      dispatch(setSnackbar(true, 'error', 'Ocurrio un error, intente nuevamente mas tarde.'))
    }
  }

  const handleTypeSelect = (e, newType) => {
    if (newType) setTypeSelect(newType)
  }

  return (
    <>
      <Helmet>
        <title>Cambios y devoluciones | Villanueva</title>
        <meta
          name='description'
          content='Completa los datos para solicitar un cambio o devolución'
        />
        <meta
          name='keywords'
          content='cambio, devolución, cambios, devoluciones, solicitar, solicitar cambio, solicitar devolución'
        />
      </Helmet>
      <Grid sx={{ backgroundColor: 'white' }}>
        <Grid className={classes.container}>
          <Grid container sx={{ paddingBottom: '70px' }}>
            <Grid item xs={12} sm={6} className={classes.titleColumn}>
              <Grid className={classes.titleBox}>
                <Typography className={classes.title}>
                  Cambios y devoluciones
                </Typography>
                <Typography variant='p'>
                  Todos los cambios y devoluciones aquí consignados son los
                  referidos a las compras de productos que se realizaron a
                  través del sitio web www.villanueva.com.ar
                </Typography>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6} className={classes.formColumn}>
              <Typography className={classes.formLabel}>Motivo</Typography>
              <ToggleButtonGroup
                color='primary'
                value={typeSelect}
                exclusive
                onChange={handleTypeSelect}
                className={classes.toggleButtonGroup}
              >
                <ToggleButton value='change' className={classes.toggleButton}>
                  Cambio
                </ToggleButton>
                <ToggleButton value='return' className={classes.toggleButton}>
                  Devolución
                </ToggleButton>
              </ToggleButtonGroup>
              <form onSubmit={handleSubmit(onSubmitForm)}>
                <Typography className={classes.formLabel}>
                  Número de Factura
                </Typography>
                <TextField
                  {...register('receiptNumber')}
                  placeholder='12 dígitos, sin guiones'
                  className={classes.textField}
                  fullWidth
                  helperText={errors?.receiptNumber?.message ?? ''}
                />
                <Typography className={classes.formLabel}>
                  Número de pedido
                </Typography>
                <TextField
                  {...register('orderNumber', {
                    required: 'El número de pedido es obligatorio',
                  })}
                  placeholder='Ej: 203300'
                  className={`${classes.textField} ${classes.shorterField}`}
                  fullWidth
                  helperText={errors?.orderNumber?.message ?? ''}
                />
                <Typography className={classes.formLabel}>
                  Producto y cantidad a devolver/ cambiar
                </Typography>
                <TextField
                  {...register('message', {
                    required: 'El mensaje es obligatorio',
                  })}
                  placeholder='Escribir mensaje'
                  className={classes.textField}
                  fullWidth
                  multiline
                  rows={4}
                />
                <Button
                  type='submit'
                  variant='primary'
                  className={classes.submitButton}
                >
                  Enviar
                </Button>
              </form>
            </Grid>
          </Grid>

          <Grid className={classes.disclaimerContainer}>
            <Typography className={classes.disclaimer}>
              <span style={{ fontWeight: 600 }}>
                Consideraciones para cambios y devoluciones:{' '}
              </span>
              Los plazos para realizar el cambio o devolución deberán ser dentro
              de los diez (10) días corridos contando desde la fecha en que
              usted recibió el producto con su factura correspondiente. Podrá
              solicitar el cambio o devolución en cualquier día y horario de
              atención al público. Para el cambio o devolución el producto
              deberá permanecer nuevo y sin uso. Debe no haber sufrido un
              deterioro esencial. Para iniciar el cambio o devolución deberá
              tener a mano la factura correspondiente. Los cambios o
              devoluciones quedarán sujetos a la disponibilidad de stock. <br />{' '}
              Los siguientes productos no admitirán cambios y devoluciones:{' '}
              <br />
              - Todos los productos de la marca Allflex. <br /> - Productos que
              tienen una personalización y una logística de distribución
              especial. <br />
              Recuerde que por cualquier inquietud se encuentra disponible su
              asesor comercial o también puede contactarse con su sucursal
              correspondiente, ingrese aquí para contacto con sucursal. Para
              iniciar el cambio o devolución deberá ingresar los datos en el
              siguiente formulario que detallamos a continuación.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default ChangesAndReturns
