import { ApiConnection } from 'config'


export const cartHeaderFixedData = {
         objType: '17', // fijo
         docCur: 'ARS', // fijo
         groupNum: '3', // medio de pago (fijo)
         uBitUniNego: 'UN02', // unidad de negocio
         uBitTipoOperacion: 'OVD', // fijo
         uBitOrigenPedido: '8', // fijo
         uBitMotivoBonifPiePedido: 'Generico', //
         discPrcnt: '5.0',
       }

export const createHeaderCart = async (cartHeader) => {
  try {
    const {
      data,
    } = await ApiConnection.post(
      `cartheader`,
      { ...cartHeaderFixedData, ...cartHeader }
    )
    return { ...data }
  } catch (error) {
    throw new Error(error.message)
  }
}
