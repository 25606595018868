import React from 'react'
import { Typography, Button, Grid } from '@mui/material'
import { useStyles } from './ResetPasswordSent.styles'
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead'
import { Helmet } from 'react-helmet'
import { useNavigate, useLocation } from 'react-router-dom'

function ResetPasswordSent() {
  const classes = useStyles()
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <>
      <Helmet>
        <title>¿Has olvidado tu contraseña? | Villanueva</title>
        <meta
          name='description'
          content='Introduce la dirección de correo electrónico que utilices para recibir un mensaje de correo con una nueva contraseña'
        />
        <meta
          name='keywords'
          content='contraseña, has olvidado, e-mail, nuevo, regeneración'
        />
      </Helmet>
      <div className={classes.container}>
        <Grid className={classes.innerContainer}>
          <Grid className={classes.iconContainer}>
            <MarkEmailReadIcon className={classes.emailIcon} />
          </Grid>
          <Typography className={classes.title}>
            Recuperar contraseña
          </Typography>
          <Typography paragraph className={classes.subtitleInfo}>
            Te acabamos de enviar un email con las instrucciones a <br/><h4 style={{fontSize: 16}}>{location.state.email}</h4>
            Si necesitás modificar el mail, escribinos por whatsapp al +54 9 3534 25-0771
          </Typography>
          <Grid sx={{ width: { sm: '325px' } }}>
            <Button
              fullWidth
              onClick={() => navigate(`/`)}
              variant='primary'
              className={classes.buttonBuy}
            >
              Regresar
            </Button>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default ResetPasswordSent
