import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useStyles } from './CartPage.styles'
import { Grid, Box, Typography, useMediaQuery, Button } from '@mui/material'
import CartItem from './CartItem'
import emptyCart from '../../assets/emptyCart.png'
import Title from '../../components/Title'
import {
  addItemToCart,
  changeActiveOrderAndFetchDetails,
  clearCart,
  deleteSavedCart,
  editItemFromCart,
  fetchSavedCarts,
  getCartItems,
  removeItemFromCart,
} from '../../stores/cartStore/actionsCart'
import CheckoutSummary from 'components/CheckoutSummary/CheckoutSummary'
import {
  getAllProductDiscount,
  promotionsCartChanges,
  shouldProductHaveSecondaryDiscount,
} from 'pages/ProductPage/utils/getProductDiscounts'
import { Helmet } from 'react-helmet'
import { setSnackbar } from 'stores/snackbarStore'
import { getUserPromotionsAndOffers } from 'stores/userStore/actionsUser'
import groupBy from 'utils/groupBy'
import {
  getCartBannersAction,
  setSectionIsLoading,
} from 'stores/appStore/actionsApp'
import FeedbackModal from 'components/FeedbackModal/FeedbackModal'
import SavedCartsModal from './SavedCartsModal'
import DeleteConfirmationModal from './DeleteConfirmationModal'

const lastCuponUpdate = {
  quantity: 0,
  cupon: null,
}

let changingCartItemQuantity = []

const CartScreen = () => {
  const navigate = useNavigate()
  const classes = useStyles()
  const dispatch = useDispatch()
  const cart = useSelector(({ cart }) => cart)
  const deskBanner = useSelector(({ app }) => app.banners?.cart?.desk)
  const mobileBanner = useSelector(({ app }) => app.banners?.cart?.mobile)
  const sectionLoading = useSelector(({ app }) => app.loaders?.cart?.isLoading)
  const [discountsInfo, setDiscountsInfo] = useState({})
  const firstRender = useRef(false)
  const [loading, setLoading] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [modalContent, setModalContent] = useState({})
  const [showSavedCartsModal, setShowSavedCartsModal] = useState(false)
  const [cartToDelete, setCartToDelete] = useState(null)
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)

  const pr4PromotionsApplied = useRef({})
  const lastPr4PromotionsLength = useRef(0)

  const isLoading = sectionLoading || loading

  // eslint-disable-next-line camelcase
  const {
    product,
    product_manejo,
    product_official,
    savedCarts,
    idOrder,
  } = cart

  product.sort((a, b) => Number(a.sku) - Number(b.sku))
  product_manejo.sort((a, b) => Number(a.sku) - Number(b.sku))
  product_official.sort((a, b) => Number(a.sku) - Number(b.sku))

  const allProducts = [...product, ...product_manejo, ...product_official]
  const totalItemsQuantity = allProducts.reduce((acc, pr) => {
    acc += pr.cantidad
    return acc
  }, 0)

  const { userInfo, currentBranch } = useSelector(({ userAuth }) => userAuth)
  const desktop = useMediaQuery((theme) => theme.breakpoints.up('sm'), {
    noSsr: true,
  })
  const banner = desktop ? deskBanner?.[0] : mobileBanner?.[0]

  const goToCheckout = () => {
    const allProducts = [
      ...product,
      ...product_manejo,
      ...product_official,
    ].filter(Boolean)
    const productWithoutQuantity = allProducts.find((p) => p.cantidad < 1)

    if (productWithoutQuantity) {
      return dispatch(
        setSnackbar(
          true,
          'error',
          `La cantidad del producto no puede ser menor a 1 (SKU ${productWithoutQuantity.sku})`
        )
      )
    }

    navigate('/finalizar-compra')
  }

  const goToHome = () => navigate('/')

  const handleSaveCart = async () => {
    try {
      await dispatch(clearCart())
      setModalContent({
        title: 'Carrito guardado con éxito!',
        content:
          'El carrito se ha guardado correctamente. Puede recuperarlo en cualquier momento desde la sección de carrito.',
      })
      setModalOpen(true)
    } catch (error) {
      console.error('Error al guardar el carrito', error)
      setModalContent({
        title: 'Error al guardar el carrito',
        content:
          'Ocurrió un error al guardar el carrito. Por favor, intente nuevamente.',
      })
      setModalOpen(true)
    }
  }

  const handleRemoveItem = (item) => {
    dispatch(removeItemFromCart({ [item?.tipo ?? 'product']: item }))
  }

  const handleOpenSavedCartsModal = () => setShowSavedCartsModal(true)
  const handleCloseSavedCartsModal = () => setShowSavedCartsModal(false)

  const handleSelectOrder = (orderId) => {
    dispatch(changeActiveOrderAndFetchDetails(orderId))
    handleCloseSavedCartsModal()
  }

  const handleDeleteOrder = () => {
    if (cartToDelete) {
      dispatch(deleteSavedCart(cartToDelete))
      setDeleteModalOpen(false)
      setCartToDelete(null)
    }
  }

  const handleOpenDeleteModal = (orderId) => {
    setCartToDelete(orderId)
    setDeleteModalOpen(true)
  }

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false)
    setCartToDelete(null)
  }

  useEffect(() => {
    if (totalItemsQuantity < 1) return
    const productDiscounts = {}
    const promotionChanges = promotionsCartChanges(
      allProducts,
      userInfo?.promociones
    )
    const pr4 = promotionChanges.filter(({ promotionCode }) =>
      promotionCode.includes('PR4')
    )

    pr4.forEach(({ productsToApplyPromotion, updateDiscount }, index) => {
      if (
        changingCartItemQuantity.length > 0 &&
        !productsToApplyPromotion.some(({ sku }) =>
          changingCartItemQuantity.includes(sku)
        ) &&
        firstRender.current === true
      )
        return
      if (
        pr4PromotionsApplied.current[index] === updateDiscount &&
        lastPr4PromotionsLength.current === pr4.length
      )
        return

      productsToApplyPromotion.forEach((product) => {
        if(Number(product.descuentos) > Number(updateDiscount)) return;
        dispatch(setSectionIsLoading(['cart', true]))
        dispatch(
          editItemFromCart(
            {
              ...product,
              descuentos: updateDiscount,
            },
            {
              idUsuario: userInfo.codigoUsuario,
              idOrder: cart.idOrder,
            }
          )
        )
        pr4PromotionsApplied.current[index] = updateDiscount
        lastPr4PromotionsLength.current = pr4.length
      })
    })

    allProducts.forEach((product) => {
      const allProductDiscounts = getAllProductDiscount(
        { ...product, cantidad: 1 },
        cart,
        userInfo?.ofertas,
        userInfo?.promociones,
        null,
        currentBranch?.cod
      )

      const discount = allProductDiscounts?.secondaryDiscount
        ? allProductDiscounts?.secondaryDiscount
        : allProductDiscounts?.mainDiscount

      const promotion = promotionChanges.find(
        ({ applyBannerTo, applyBannerToId, cupon, promotionCode }) =>
          (applyBannerToId === product.id || applyBannerTo === product.sku) &&
          (cart.cuponSelected && promotionCode.includes('PR3')
            ? cart.cuponSelected === cupon
            : true)
      )
      productDiscounts[product.sku] = { ...discount, promotion }
    })
    setDiscountsInfo(productDiscounts)
    firstRender.current = true
  }, [userInfo?.ofertas, userInfo?.promociones, totalItemsQuantity])

  const getCart = () => {
    dispatch(
      getCartItems(
        userInfo?.codigoUsuario,
        cart.idOrder ?? localStorage.getItem('idOrder') ?? userInfo?.openedCart
      )
    )
  }

  useEffect(() => {
    dispatch(getUserPromotionsAndOffers(userInfo?.user))
    dispatch(getCartBannersAction())
    getCart()
    return () => {
      changingCartItemQuantity = []
    }
  }, [])

  useEffect(() => {
    if (firstRender.current === true && !isLoading) {
      getCart()
    }
  }, [isLoading])

  useEffect(() => {
    dispatch(fetchSavedCarts(userInfo?.codigoUsuario))
  }, [dispatch, cart?.idOrder, cart?.product, userInfo?.codigoUsuario])

  function makeChanges(productsToApplyPromotion, changes, promotion = {}) {
    const { cantidad, promotionCode, discountRate, cupon } = promotion
    Object.values(productsToApplyPromotion).forEach((product) => {
      changes.toRemove.push(...product.flat())
    })
    Object.values(productsToApplyPromotion)
      .reduce((prev, curr) => {
        prev.push(...curr.flat())
        return prev
      }, [])
      .reduce((prev, curr) => {
        if (prev.length === 0) {
          return [...prev, curr]
        } else {
          prev[0].cantidad += curr.cantidad
          prev[0].codigoOferta = null
          if (Number(curr.descuentos) < Number(prev[0].descuentos)) {
            prev[0].descuentos = curr.descuentos
          }
          return prev
        }
      }, [])
      .forEach((product) => {
        const itemsToAdd = []
        if (cart.cuponSelected && cupon === cart.cuponSelected) {
          const affectedQuantity =
            Math.floor(product.cantidad / cantidad) * cantidad
          itemsToAdd.push({
            ...product,
            cantidad: affectedQuantity,
            descuentos: discountRate,
            codigoOferta: promotionCode,
          })
          if (product.cantidad - affectedQuantity > 0) {
            itemsToAdd.push({
              ...product,
              id: undefined,
              cantidad: product.cantidad - affectedQuantity,
            })
          }
        } else {
          itemsToAdd.push({
            ...product,
            id: undefined,
            descuentos: null,
            codigoOferta: null,
          })
        }
        changes.toAdd = changes.toAdd.concat(itemsToAdd)
      })
  }

  useEffect(() => {
    if (!cart) return

    if (!cart.cuponSelected && !lastCuponUpdate.cupon) return

    if (
      lastCuponUpdate.quantity === totalItemsQuantity &&
      lastCuponUpdate.cupon === cart.cuponSelected
    )
      return

    const cartChanges = promotionsCartChanges(allProducts, userInfo.promociones)

    const { cuponSelected } = cart
    const isPR3Cupon = cartChanges.find(
      ({ cupon, promotionCode }) =>
        cupon === cuponSelected && promotionCode?.includes('PR3')
    )

    const changes = {
      toAdd: [],
      toRemove: [],
    }

    if (cartChanges.length === 0) {
      const productsToApplyPromotion = groupBy(allProducts, 'sku')
      Object.values(productsToApplyPromotion).forEach((value) => {
        makeChanges(groupBy(value, 'sku'), changes)
      })
    } else {
      cartChanges
        .filter(({ promotionCode }) => !promotionCode?.includes('PR16'))
        .filter(({ cupon }) =>
          cuponSelected && isPR3Cupon
            ? cupon === cuponSelected
            : cupon === lastCuponUpdate.cupon
        )
        .forEach((pr) => {
          let productsToApplyPromotion = allProducts.filter(({ sku }) =>
            pr.sku.includes(sku)
          )
          productsToApplyPromotion = groupBy(productsToApplyPromotion, 'sku')
          makeChanges(productsToApplyPromotion, changes, pr)
        })
    }

    changes.toRemove.forEach((product) => {
      if(product)
        dispatch(removeItemFromCart({ product }))
    })

    changes.toAdd.forEach((product) => {
      dispatch(
        addItemToCart(
          {
            product: {
              ...product,
            },
          },
          {
            idUsuario: userInfo?.codigoUsuario,
            idOrder: cart.idOrder,
          }
        )
      )
    })
    lastCuponUpdate.quantity = totalItemsQuantity
    lastCuponUpdate.cupon = cart.cuponSelected
    if (cart.cuponSelected) {
      return dispatch(setSnackbar(true, 'success', `Cupón aplicado!`))
    }
  }, [cart.cuponSelected, cart.applyCupon])

  const filteredSavedCarts = Object.entries(savedCarts).filter(
    ([savedOrderId]) => savedOrderId !== idOrder
  )

  const actualCart = Object.entries(savedCarts).find(
    ([savedOrderId]) => savedOrderId === idOrder
  )

  const actualCartCreatedBySeller =
    actualCart && actualCart[1].createdBySellerName
      ? actualCart[1].createdBySellerName
      : 'Cliente'

  return (
    <>
      <Helmet>
        <title>Carrito de compras | Villanueva</title>
        <meta name='description' content='Carrito de compras' />
        <meta name='keywords' content='Carrito de compras' />
      </Helmet>
      {banner && (
        <Box sx={{ width: '100vw' }}>
          <img
            style={{ width: '100vw' }}
            src={`${process.env.REACT_APP_URL_BACKEND_REMOTO}/files/${banner.file.ruta}`}
          />
        </Box>
      )}
      <Box className={classes.container}>
        <Title
          text={`Carrito de compras`}
          customStyles={desktop ? { margin: '30px 0px' } : {}}
        />
        <Typography
          sx={{
            marginBottom: '10px',
            marginLeft: '16px',
            fontSize: '0.75rem',
            fontStyle: 'italic',
          }}
        >
          Creado por: {actualCartCreatedBySeller}
        </Typography>
        {savedCarts && Object.keys(filteredSavedCarts).length > 0 && (
          <Button
            variant='contained'
            color='primary'
            onClick={handleOpenSavedCartsModal}
            sx={{
              marginLeft: {
                xs: '16px',
                sm: '0',
              },
              marginBottom: '16px',
              fontSize: {
                xs: '0.75rem',
                sm: '0.875rem',
              },
              padding: {
                xs: '6px 16px',
                sm: '8px 22px',
              },
            }}
          >
            Ver carritos guardados
          </Button>
        )}
        <Grid container className={classes.innerContainer}>
          {!product?.length &&
          !product_manejo?.length &&
          !product_official?.length ? (
            <Grid container className={classes.gridEmpty}>
              <Grid container className={classes.gridAligner}>
                <Grid
                  container
                  justifyContent='center'
                  align='center'
                  className={classes.emptyCart}
                >
                  <img
                    src={emptyCart}
                    alt='Carrito vacío'
                    style={{ borderRadius: 50, marginBottom: '35px' }}
                  />
                  <Typography className={classes.titleEmpty}>
                    Tu carrito está vacio
                  </Typography>
                  <Typography className={classes.subtitleInfoEmpty}>
                    Probá buscando algún producto que necesites y podrás sumarlo
                    a tu compra.
                  </Typography>
                </Grid>

                <Button
                  variant='primary'
                  sx={(theme) => ({
                    margin: '55px 0px',
                    [theme.breakpoints.up('sm')]: {
                      fontSize: '14px',
                    },
                  })}
                  onClick={goToHome}
                >
                  Volver al inicio
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Grid container className={classes.products}>
              {product?.map((item) => {
                return (
                  <CartItem
                    key={item.id + item.codigoOferta}
                    item={item}
                    showQuantityInput={
                      !shouldProductHaveSecondaryDiscount(
                        item?.codigoOferta || ''
                      )
                    }
                    setLoading={setLoading}
                    onChange={(item) => changingCartItemQuantity.push(item.sku)}
                    onRemove={handleRemoveItem}
                    discountInfo={discountsInfo[item.sku] || null}
                  />
                )
              })}
              {product_manejo?.length ? (
                <>
                  <Typography sx={{ marginTop: '25px' }}>
                    Caravanas De Manejo
                  </Typography>
                  {product_manejo?.map((item) => {
                    return (
                      <CartItem
                        key={item.id + item.codigoOferta}
                        item={item}
                        onChange={(item) =>
                          changingCartItemQuantity.push(item.sku)
                        }
                        onRemove={handleRemoveItem}
                      />
                    )
                  })}
                </>
              ) : null}
              {product_official?.length ? (
                <>
                  <Typography sx={{ marginTop: '25px' }}>
                    Caravanas Oficiales
                  </Typography>
                  {product_official?.map((item) => {
                    return (
                      <CartItem
                        key={item.id + item.codigoOferta}
                        item={item}
                        onChange={(item) =>
                          changingCartItemQuantity.push(item.sku)
                        }
                        onRemove={handleRemoveItem}
                      />
                    )
                  })}
                </>
              ) : null}
            </Grid>
          )}

          {product?.length ||
          product_manejo?.length ||
          product_official?.length ? (
            <CheckoutSummary
              firstAction={goToCheckout}
              secondAction={goToHome}
              firstText={'Proceder al pago'}
              firstActionDisabled={isLoading}
              secondText={'Seguir comprando'}
              thirdText={'Guardar carrito'}
              thirdAction={handleSaveCart}
            />
          ) : null}
        </Grid>
      </Box>
      <FeedbackModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        title={modalContent.title}
        content={modalContent.content}
      />
      <SavedCartsModal
        open={showSavedCartsModal}
        onClose={handleCloseSavedCartsModal}
        savedCarts={Object.fromEntries(filteredSavedCarts)}
        onSelectOrder={handleSelectOrder}
        onDeleteOrder={handleOpenDeleteModal}
      />
      <DeleteConfirmationModal
        open={isDeleteModalOpen}
        onClose={handleCloseDeleteModal}
        onConfirm={handleDeleteOrder}
      />
    </>
  )
}

export default CartScreen
