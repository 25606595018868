import { Card,Box, Grid, useMediaQuery } from '@mui/material'
import { useStylesProducts } from './styles'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const BannerCard = ({file, redirectTo}) => {
  const desktop = useMediaQuery((theme) => theme.breakpoints.up('sm'), { noSsr: true })
  const userSignin = useSelector((state) => state.userAuth)
  const { signedIn } = userSignin
  const navigate = useNavigate()
  const styles = useStylesProducts({signedIn})
  
  return(
    <Grid
      item
      sx={{
        '&.MuiGrid-root': {
          width: desktop ? '242px !important' : '47%',
          marginBottom: '30px',
          paddingBottom: '-30px',
        },
      }}
    >
      <Card
        classes={{
          root: styles?.card,
        }}
        sx={{
          position: 'relative',
          padding: '0px !important',
          '&:hover': {
            boxShadow: '0px 5px 13px rgba(0, 0, 0, 0.2)',
          },
          cursor: redirectTo ? 'pointer' : 'auto'
        }}
        onClick={() => {
          if(redirectTo) {
            navigate(redirectTo)
          }
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display:'flex',
            alignItems: 'center'
          }}
        >
          <img 
            style={{width: '100%'}}
            src={`${process.env.REACT_APP_URL_BACKEND_REMOTO}/files/${file.ruta}`}
          />
        </Box>
      </Card>
    </Grid>
  ) 
}

export default BannerCard