import React, { useEffect, useState } from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AccordionDetails from '@mui/material/AccordionDetails'
import { makeStyles } from '@mui/styles'
import ListItemComponent from './ListItemComponent'
import { useLocation } from 'react-router-dom'
import { buildUrl } from 'utils/filters'
import { useSelector } from 'react-redux'

const useStyles = makeStyles(() => ({
  content: {
    margin: '0px !important',
    '&expanded': {
      maxHeight: '48px',
    },
  },
  expanded: {
    margin: '0px 0px !important',
  },
  root: {
    paddingBottom: '0px',
  },
  rootAccordion: {
    paddingBottom: '0px',
    '&expanded': {
      margin: '0px',
    },
  },
  expandIconWrapper: {
    '&expanded': {
      alignItems: 'center',
    },
  },
}))

export default function AccordionComponent({
  index,
  label,
  icon,
  childs,
  active,
  linkTo,
  onPanelChange,
  accordionExpanded,
  closeDrawer,
  handleSelect,
}) {
  const [subAccordionExpanded, setSubAccordionExpanded] = useState(false)
  const { initFinished } = useSelector((state) => state.app)
  const { pathname } = useLocation()
  const classes = useStyles()

  const handleChange = (panel) => (_event, isExpanded) => {
    setSubAccordionExpanded(isExpanded ? panel : false)
  }

  useEffect(()=> {
    if(pathname.includes('catalogue'))
      setSubAccordionExpanded(false)
  }, [pathname])

  return (
    <Accordion
      sx={{
        boxShadow: 'none',
        '&:before': {
          display: 'none',
        },
        marginTop: index ? '' : '5px',
      }}
      classes={{
        root: classes.rootAccordion,
        expanded: classes.expanded,
      }}
      expanded={accordionExpanded === `${label}-content`}
      onChange={onPanelChange(`${label}-content`)}
    >
      <AccordionSummary
        style={{
          padding: '0px',
          border: '0px',
        }}
        classes={{
          content: classes.content,
          expandIconWrapper: classes.expandIconWrapper,
        }}
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${label}-content`}
        id={`${label}-header`}
      >
        <ListItemComponent active={active} label={label} index={index}>
          {/* {React.createElement(icon, { active: active })} */}
        </ListItemComponent>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          paddingTop: '0px',
        }}
        classes={{
          root: classes.root,
        }}
      >
        {childs?.map((child, index) => (
          child.childs?.length > 0 ?
            <AccordionComponent
              key={child.id}
              label={child.label}
              icon={null}
              index={index}
              linkTo={'catalogue'}
              childs={child.childs}
              onPanelChange={handleChange}
              accordionExpanded={subAccordionExpanded}
              handleSelect={(item) => {
                // setMenuOpen(false)
                handleSelect(item)
              }}
            />
            :
            <ListItemComponent
              closeDrawer={closeDrawer}
              key={index}
              index={index}
              label={child?.label}
              linkTo={initFinished ? `/catalogo/${buildUrl(child?.code)}` : '/'}
              onClick={() => handleSelect(child)}
            >
              {child?.icon}
            </ListItemComponent>
        ))}
      </AccordionDetails>
    </Accordion>
  )
}
