import { constantsCart } from './constantsCart'

const {
  CART_INIT,
  CART_LOAD,
  CART_EMPTY,
  CART_REMOVE_ITEM,
  CART_ADD_ITEM,
  CART_EDIT_ITEM,
  CART_MINUS_ITEM,
  CART_PLUS_ITEM,
  CART_ADD_CUPON,
  CART_ADD_CUPON_NEW,
  CART_GET_SAVED_ORDERS_REQUEST,
  CART_GET_SAVED_ORDERS_SUCCESS,
  CART_GET_SAVED_ORDERS_FAIL,
  CART_DELETE_SAVED_ORDER_REQUEST,
  CART_DELETE_SAVED_ORDER_SUCCESS,
  CART_DELETE_SAVED_ORDER_FAIL,
} = constantsCart

const initialState = {
  savedCarts: {},
  product: [],
  product_manejo: [],
  product_official: [],
  idOrder: null,
  cuponDiscount: null,
  cuponSelected: localStorage.getItem('cuponSelected') || '',
  loading: false,
  error: null,
  // this is only for refresh
  applyCupon: false
}

export const cartReducer = (state = initialState, action) => {
  const { type, payload } = action
  let productType
  if (payload?.product) productType = 'product'
  if (payload?.product_manejo) productType = 'product_manejo'
  if (payload?.product_official) productType = 'product_official'
  switch (type) {
    case CART_INIT:
      return { ...initialState, idOrder: payload }
    case CART_LOAD:
      return {
        ...state,
        ...payload
      }
    case CART_ADD_ITEM: {
      if (productType && payload[productType]?.cantidad) {
        if (productType !== 'product') {
          return {
            ...state,
            [productType]: [...state?.[productType], payload[productType]],
          }
        } else {
          const products = [...state?.[productType]]?.map((product) => {
            if (
              product.idProducto === payload[productType].idProducto &&
              Boolean(product.codigoOferta) ===
                Boolean(payload[productType].codigoOferta)
            )
              return payload[productType]
            else return product
          })
          const productExist = products.find(
            (product) =>
              product.idProducto === payload[productType].idProducto &&
              Boolean(product.codigoOferta) ===
                Boolean(payload[productType].codigoOferta)
          )

          return {
            ...state,
            [productType]: !productExist
              ? [...products, payload[productType]]
              : products,
          }
        }
      } else return state
    }
    case CART_MINUS_ITEM: {
      if (productType) {
        const clonedProducts = [...state[productType]]
        const product = clonedProducts?.map((product) => {
          const isCurrentProduct =
            productType === 'product'
              ? product.idProducto === payload[productType].idProducto &&
                Boolean(product.codigoOferta) ===
                  Boolean(payload[productType].codigoOferta)
              : product.id === payload[productType].id

          if (isCurrentProduct) return payload[productType]
          else return product
        })
        return {
          ...state,
          [productType]: product,
        }
      }
      return state
    }
    case CART_PLUS_ITEM: {
      if (productType) {
        const clonedProducts = [...state[productType]]
        const product = clonedProducts.map((product) => {
          const isCurrentProduct =
            productType === 'product'
              ? product.idProducto === payload[productType].idProducto &&
                Boolean(product.codigoOferta) ===
                  Boolean(payload[productType].codigoOferta)
              : product.id === payload[productType].id
          if (isCurrentProduct) return payload[productType]
          else return product
        })

        return {
          ...state,
          [productType]: product,
        }
      }
      return state
    }
    case CART_EDIT_ITEM: {
      if (productType) {
        const clonedProducts = [...state[productType]]
        const editedProduct = clonedProducts?.map((product) => {
          const isCurrentProduct =
            productType === 'product'
              ? product.idProducto === payload[productType].idProducto &&
                Boolean(product.codigoOferta) ===
                  Boolean(payload[productType].codigoOferta)
              : product.id === payload[productType].id

          if (isCurrentProduct) {
            return {
              ...payload?.[productType],
              cantidad: Number(payload?.[productType]?.cantidad),
            }
          } else return product
        })
        return {
          ...state,
          [productType]: editedProduct,
        }
      }
      return state
    }
    case CART_REMOVE_ITEM: {
      if (productType) {
        const newProducts = state[productType].filter((prod) => {
          const isCurrentProduct =
            productType === 'product'
              ? prod.idProducto === payload[productType].idProducto &&
                Boolean(prod.codigoOferta) ===
                  Boolean(payload[productType].codigoOferta)
              : prod.id === payload[productType].id
          return !isCurrentProduct
        })
        return {
          ...state,
          [productType]: newProducts,
        }
      }
      return state
    }
    case CART_EMPTY:
      return initialState
    case 'CART_SET_ID_ORDER':
      return {
        ...state,
        idOrder: action.payload,
      }
    case CART_ADD_CUPON:
      return {
        ...state,
        cuponDiscount: action.payload,
      }
    case CART_ADD_CUPON_NEW:
      if(state.cuponSelected === action.payload){
        return {
          ...state,
          applyCupon: !state.applyCupon
        }
      }
      return {
        ...state,
        cuponSelected: action.payload
      }
    case CART_GET_SAVED_ORDERS_REQUEST:
      return { ...state, loadingSavedOrders: true };
    case CART_GET_SAVED_ORDERS_SUCCESS:
      return { ...state, savedCarts: action.payload, loading: false };
    case CART_GET_SAVED_ORDERS_FAIL:
      return { ...state, loading: false, error: action.payload };
    case CART_DELETE_SAVED_ORDER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CART_DELETE_SAVED_ORDER_SUCCESS:{
      const { [action.payload]: _, ...remainingCarts } = state.savedCarts;
      return {
        ...state,
        savedCarts: remainingCarts,
        loading: false,
      };
    }
    case CART_DELETE_SAVED_ORDER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state
  }
}
