import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('sm')]: {
      margin: 'auto',
      maxWidth: '1040px',
    },
  },
  innerContainer: {
    [theme.breakpoints.up('sm')]: {
      paddingBottom: '76px',
    },
  },
  gridEmpty: {
    height: '100%',
    padding: '66px 45px 0px 45px',
    backgroundColor: theme.palette.primary.white,
    [theme.breakpoints.up('sm')]: {
      paddingTop: '76px',
      paddingBottom: '55px',
    },
  },
  gridAligner: {
    alignItems: 'space-around',

    [theme.breakpoints.up('sm')]: {
      maxWidth: '296px',
      margin: 'auto',
    },
  },
  emptyCart: {
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      '& img': {
        width: '110px',
      },
    },
  },
  itemActionsContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between'
  },
  products: {
    flexDirection: 'column',
    marginBottom: '25px',
    marginTop: '-20px',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '680px',
    },
  },
  card: {
    position: 'relative',
    margin: '20px 15px 0px',
    borderRadius: '8px',
    boxShadow: '0px 2px 14px rgba(0, 0, 0, 0.1)',
    [theme.breakpoints.up('sm')]: {
      margin: '20px 20px 0px 0px',
    },
  },
  image: {
    width: '61px',
    height: '61px',
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      height: '94px',
    },
  },
  textGrid: {
    padding: '20px, 0px, 20px, 0px',
    borderBottom: '0.6px solid #bbbbbb',
  },
  textDetalle: {
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '17px',
    color: theme.palette.primary.almostBlack,
  },
  textMid: {
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '17px',
    color: theme.palette.primary.almostBlack,
  },
  textNumber: {
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '18.5px',
    color: theme.palette.primary.almostBlack,
  },
  textColor: {
    fontWeight: '700',
    fontSize: '20px',
    lineHeight: '23px',
    color: theme.palette.primary.typographyGreen,
  },
  textSucursal: {
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '17px',
    color: theme.palette.primary.typographyGreen,
  },
  textTotal: {
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '19px',
    color: theme.palette.primary.almostBlack,
  },
  borderMargin: {
    borderBottom: '0.6px solid #BBBBBB',
    paddingTop: '20px',
  },
  borderMarginEnd: {
    paddingTop: '20px',
  },
  buttonsGrid: {
    marginTop: '55px',
  },
  buttonBuy: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '6px',
    height: '56px',
    fontWeight: '500',
    fontSize: '18px',
    textTransform: 'unset',
    lineHeight: '22px',
  },
  buttonKeepBuying: {
    borderRadius: '6px',
    color: theme.palette.primary.main,
    fontWeight: '400',
    fontSize: '18px',
    textTransform: 'unset',
    lineHeight: '22px',
    height: '56px',
    marginBottom: '20px',
  },
  text: {
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '15px',
    color: theme.palette.primary.almostBlack,
  },
  textDescription: {
    fontWeight: '300',
    fontSize: '12px',
    lineHeight: '15px',
    color: theme.palette.primary.almostBlack,
  },
  precio: {
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '21px',
    color: theme.palette.primary.almostBlack,
    [theme.breakpoints.up('sm')]: {
      marginBottom: '4px',
    },
  },
  precioLista: {
    fontSize: '10px',
    color: 'grey',
    [theme.breakpoints.up('sm')]: {
      marginBottom: '4px',
    },
  },
  root: {
    border: '1px dashed #D0D0D0',
  },
  focused: {
    border: '1px dashed #D0D0D0',
  },
  notchedOutline: {
    border: '0px',
  },

  // empty
  titleEmpty: {
    fontWeight: '600',
    fontSize: '24px',
    lineHeight: '32px',
    marginBottom: '25px',
    color: theme.palette.primary.almostBlack,
    [theme.breakpoints.up('sm')]: {
      marginBottom: '12px',
    },
  },
  subtitleInfoEmpty: {
    fontWeight: '300',
    fontSize: '12px',
    lineHeight: '15px',
    color: theme.palette.primary.black,
    marginBottom: '5px',
  },
  discountPrice: {
    fontSize: '12px',
    lineHeight: '15px',
    marginBottom: '1px',
    color: '#B6B6B6',
    [theme.breakpoints.up('sm')]: {
      fontSize: '14px',
    },
  },
  offertBox: {
    maxWidth: '65%',
    fontSize: '9px',
    fontWeight: 600,
    lineHeight: '10.75px',
    letterSpacing: '0em',
    whiteSpace: 'nowrap',
    backgroundColor: '#4AA259',
    borderRadius: '4px',
    padding: '2px 3.75px 2px 3px',
    color: 'white',
    textAlign: 'center',
    marginBottom: '2px',
  },
  promotionBanner: {
    display:'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#4AA259',
    height: '40px',
    width: '100%',
    color: 'white',
    [theme.breakpoints.up('sm')]: {
      height: '30px',
    },
  }
}))
