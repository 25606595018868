import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: '1040px',
      margin: 'auto',
    },
  },
  productContainer: {
    padding: '16px',
    backgroundColor: theme.palette.primary.white,
    borderRadius: '12px',
    position: 'relative',

    [theme.breakpoints.up('sm')]: {
      borderRadius: '8px',
    },
  },

  firstContainer: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      marginBottom: '30px',
    },
  },

  productImageBox: {
    position: 'relative',
    width: '617px',
    height: '500px',
    marginBottom: '15px',
    marginRight: '30px',
  },

  imageContainer: {
    width: '427px',
    alignItems: 'center',
    marginRight: '5px',
  },

  full: {
    width: '100%',
    height: '100%',
  },
  thumbsContainer: {
    width: '50px',
    marginRight: '50px',
  },
  thumbnail: {
    cursor: 'pointer',
    width: '50px',
    height: '50px',
    boxShadow: '0px 0px 5px #C4C4C4',
    marginBottom: '12px',
    borderRadius: '4px',
    '& img': {
      width: '100%',
      objectFit: 'cover',
    },
    '&:hover': {
      transition: '0.3s',
      boxShadow: '0px 0px 8px 1px rgba(0,0,0,0.11)',
    },
  },

  shareButton: {
    boxSizing: 'border-box',
    position: 'absolute',
    right: 0,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    padding: '7px',
    borderRadius: '50%',
    backgroundColor: '#EDEDED',
    width: '34px',
    height: '34px',
    marginTop: '14px',
  },

  productOptionsBox: {
    [theme.breakpoints.up('sm')]: {
      padding: '24px 16px',
      maxWidth: '355px',
    },
  },

  discountNumber: {
    fontSize: '20px',
    lineHeight: '119.5%',
    marginBottom: '1px',
    color: theme.palette.primary.productName,
    [theme.breakpoints.up('sm')]: {
      fontSize: '18px',
      fontWeight: 300,
    },
  },
  priceNumber: {
    fontSize: '36px',
    lineHeight: '115.5%',
    whiteSpace: 'nowrap',
    marginBottom: '0px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '28px',
    },
  },
  percentage: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: '600',
    marginLeft: '30px',
    letterSpacing: '0em',
    whiteSpace: 'nowrap',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '5px',
    padding: '4px 8px',
    color: 'white',
    textAlign: 'center',
    marginBottom: '0px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '12px',
      fontWeight: 500,
    },
  },
  unitPrice: {
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '15px',
    color: '#9E9E9E',
    marginTop: '8px',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginTop: '13px',
      marginBottom: '26px',
    },
  },
  cantidadBox: {
    marginTop: '26px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    paddingBottom: '15px',
    [theme.breakpoints.up('sm')]: {
      paddingBottom: 0,
    },
  },
  sizeBox: {
    marginTop: '26px',
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    paddingBottom: '15px',
  },
  sizeText: {
    fontSize: '14px',
    lineHeight: '17px',
    color: theme.palette.primary.almostBlack,
    marginRight: '22px',
    height: '100%',
    marginBottom: '20px',
  },
  productInfoContainer: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: '617px',
    },
  },

  productInfoTextTitle: {
    marginBottom: '24px',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '20px',
    color: theme.palette.primary.almostBlack,
  },
  clickButton: {
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '15px',
    marginBottom: '12px',
    color: theme.palette.primary.moreBlue,
    textDecoration: 'underline',
    cursor: 'pointer',
    '& a': {
      color: theme.palette.primary.moreBlue,
    },
  },
  description: {
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '26px',
    marginBottom: '12px',
    color: theme.palette.primary.almostBlack,
    [theme.breakpoints.up('sm')]: {
      marginBottom: '0px',
    },
  },
  sku: {
    marginTop: '8px',
    fontFamily: 'Inter',
    fontWeight: '300',
    fontSize: '12px',
    marginLeft: 'auto',
    color: theme.palette.primary.almostBlack,
    [theme.breakpoints.up('sm')]: {
      marginBottom: '17px',
    },
  },
  skuTitle: {
    fontFamily: 'Inter',
    fontWeight: '300',
    fontSize: '12px',
    color: theme.palette.primary.almostBlack,
    [theme.breakpoints.up('sm')]: {
      marginBottom: '17px',
    },
  },
  cantidadText: {
    fontSize: '14px',
    lineHeight: '17px',
    color: theme.palette.primary.almostBlack,
    marginRight: '22px',
    height: '100%',
    marginBottom: '0px',
  },
  // imageCarousel: {
  //   width: '100%',
  //   height: 'auto',
  // },
  // productImage: {
  //   width: '100%',
  //   height: 'auto',
  //   objectFit: 'contain',
  // },
}))
