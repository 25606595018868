import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  textGrid: {
    padding: '20px, 0px, 20px, 0px',
    borderBottom: '0.6px solid #bbbbbb',
  },
  text: {
    fontWeight: '400',
    fontSize: '13px',
    lineHeight: '16px',
    color: theme.palette.primary.almostBlack,
  },
  title: {
    fontWeight: '600',
    fontSize: '24px',
    lineHeight: '32px',
    marginBottom: '25px',
    color: theme.palette.primary.typographyGreen,
  },
  titleError: {
    fontWeight: '600',
    fontSize: '24px',
    lineHeight: '32px',
    color: theme.palette.error.main,
    marginBottom: '25px',
  },
  subtitle: {
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '15px',
    color: theme.palette.primary.almostBlack,
    marginBottom: '9px',
  },
  subtitleInfo: {
    fontWeight: '300',
    fontSize: '14px',
    lineHeight: '20px',
    color: theme.palette.primary.almostBlack,
    marginBottom: '5px',
    maxWidth: '272px',
  },
  grid: {
    height: '100%',
    padding: '147px 45px 0px 45px',
    backgroundColor: theme.palette.primary.white,
    [theme.breakpoints.up('sm')]: {
      maxWidth: '332px',
      backgroundColor: 'transparent',
      margin: 'auto',
      padding: 0,
      paddingTop: '120px',
    },
  },
  gridAligner: {
    alignItems: 'space-around',
  },
}))
