import React, { useState } from 'react'
import useStyles from './WorkWithUs.styles'
import { Grid, Typography, TextField, Button } from '@mui/material'
import { useForm } from 'react-hook-form'
import FileInput from 'components/DragDropFile'
import { FileUploader } from 'react-drag-drop-files'
import { emailRegex } from 'utils/regesx'
import { Helmet } from 'react-helmet'

function WorkWithUs() {
  const [resume, setResume] = useState(null)

  const classes = useStyles()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      fullName: null,
      phone: null,
      email: null,
      message: null,
    },
  })

  const onSubmitForm = (data) => {
    // TODO: post to back end
    console.log('Submitted:', { ...data, resume: resume })
  }

  return (
    <>
      <Helmet>
        <title>Trabaja con nosotros | Villanueva</title>
        <meta
          name='description'
          content='Complete el formulario para ponerte en contacto con nosotros'
        />
        <meta
          name='keywords'
          content='sobre nosotros, nosotros, contacto, trabaja con nosotros, trabaja con villanueva'
        />
      </Helmet>
      <Grid sx={{ backgroundColor: 'white' }}>
        <Grid className={classes.container}>
          <Grid container sx={{ paddingBottom: '70px' }}>
            <Grid item xs={12} sm={6} className={classes.titleColumn}>
              <Grid className={classes.titleBox}>
                <Typography className={classes.title}>
                  Trabajá con <br />
                  nosotros
                </Typography>
                <Typography variant='p'>
                  Completa este formulario, y a la brevedad nos estaremos
                  contactando
                </Typography>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6} className={classes.formColumn}>
              <form onSubmit={handleSubmit(onSubmitForm)}>
                <Typography className={classes.formLabel}>
                  Nombre y Apellido*
                </Typography>
                <TextField
                  {...register('fullName', {
                    required: 'El nombre y apellido es obligatorio',
                  })}
                  placeholder='Nombre y Apellido'
                  className={classes.textField}
                  fullWidth
                  helperText={errors?.fullName?.message ?? ''}
                />

                <Typography className={classes.formLabel}>Teléfono*</Typography>
                <TextField
                  {...register('phone', {
                    required: 'El teléfono es obligatorio',
                  })}
                  placeholder='351-XXXXXX'
                  className={`${classes.textField} ${classes.shorterField}`}
                  fullWidth
                  helperText={errors?.phone?.message ?? ''}
                />

                <Typography className={classes.formLabel}>Email</Typography>
                <TextField
                  {...register('email', {
                    required: 'El email es obligatorio',
                    pattern: {
                      value: emailRegex,
                      message: 'Formato de email inválido',
                    },
                  })}
                  placeholder='tucorreo@tudominio.com'
                  className={classes.textField}
                  fullWidth
                  helperText={errors?.email?.message ?? ''}
                />

                <FileUploader
                  handleChange={(file) => setResume(file)}
                  multiple={false}
                  name='resume'
                  types={['JPEG', 'PNG', 'GIF', 'PDF', 'JPG', 'DOCX']}
                  maxSize={10}
                  hoverTitle={'Suelta aqui'}
                >
                  <FileInput fileName={resume?.name} prompt='Subir CV (PDF)' />
                </FileUploader>

                <Typography className={classes.formLabel}>Mensaje</Typography>
                <TextField
                  {...register('message')}
                  placeholder='Escribir mensaje'
                  className={classes.textField}
                  fullWidth
                  multiline
                  minRows={5}
                />

                <Button
                  type='submit'
                  variant='primary'
                  className={classes.submitButton}
                >
                  Enviar
                </Button>
              </form>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default WorkWithUs
