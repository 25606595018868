import React, { useEffect, useState } from 'react'
import {
  useMediaQuery,
  IconButton,
  Typography,
  Grid,
  Card,
  Box
} from '@mui/material'
import sinImagen from '../../assets/sinImagen.jpg'
import DeleteIcon from '../../assets/delete.svg'
import AddMinusQuantity from '../../components/AddMinusQuantity'
import { useStyles } from './CartPage.styles'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  minusCartItem,
  plusCartItem,
  editItemFromCart,
  getCartItems,
} from '../../stores/cartStore/actionsCart'
import { formatNumber, numberWithDots } from '../../utils/formatNumber'
import checkStock from './services/checkStock'
import { setSnackbar } from 'stores/snackbarStore'
import { noStockMessage } from 'utils/noStockMessage'
import { fixAmount } from 'pages/ProductPage/utils/helpers'
import DiscountLabel from 'components/DiscountLabel/index'
import { getProducts } from 'stores/productStore/services/getProducts'
import EditIcon from '@mui/icons-material/Edit'
import EditDiscountModal from '../../components/EditDiscModal/EditDiscountModal'
import { debounce } from 'utils/debounce'
import useProductPrice from 'customHooks/useProductPrice'

const calculatePercentage = (price, disc) =>
  Number(price) - (Number(price) * Number(disc)) / 100

const CartItem = ({ item, showQuantityInput = true, onRemove, discountInfo, setLoading, onChange }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const desktop = useMediaQuery((theme) => theme.breakpoints.up('sm'))

  // REDUX SELECTORS
  const { userInfo } = useSelector((state) => state.userAuth)
  const currentBranch = useSelector((state) => state.userAuth.currentBranch)
  const cart = useSelector((state) => state.cart)
  const series =
    item?.tipo === 'product_manejo' ? parseFloat(item?.series || 1) : 1
  
  const { priceToShow, priceApplyPromotions } = useProductPrice(item)
  const precioIndividual = priceToShow || item?.precioDeLista
  const subtotal = precioIndividual * item?.cantidad * series
  const itemPrecioDescuento = calculatePercentage(
    priceToShow,
    priceApplyPromotions ? (item.descuentos || 0) : 0
  )
  const precioConDescuento = itemPrecioDescuento * item?.cantidad * series

  // STATE
  const [currentStock, setCurrentStock] = useState(0)
  const [itemQuantity, setItemQuantity] = useState(0)
  const [firstRender, setFirstRender] = useState(true)
  const [promotionBanner, setPromotion] = useState('')
  const [editDiscountModalIsOpen, setEditDiscModal] = useState(false)

  useEffect(async () => {
    if(discountInfo?.promotion?.skuDeRegalo && userInfo?.codigoUsuario) {
      let product, message
      const { quantity, skuDeRegalo, applyBannerToId, cupon } = discountInfo?.promotion
      if(applyBannerToId && applyBannerToId !== item.id) return;
      if(cupon && cart?.cuponSelected !== cupon) return;
      
      if(skuDeRegalo === item.sku) {
        product = item
        message = 'Estas llevando este producto de regalo'
        if(quantity === 1 && item.cantidad > 1){
          message = `${quantity} de las ${item.cantidad} unidades la llevás de REGALO`
        }
        if(quantity > 1){
          message = `${quantity} de las ${item.cantidad} unidades las llevás de REGALO`
        }
      } else {
        const { response } = await getProducts({page: 1, items: 1, cardCode: userInfo.codigoUsuario, finderParams: { sku: skuDeRegalo, bo: true}})
        product = response?.products ? response?.products[0] : {}
        message = `Estas llevando ${quantity} "${product.nombre}" de regalo`
      }
      if(quantity > 0)
        setPromotion(message)
      else
        setPromotion('')
    } else {
      setPromotion('')
    }
  }, [discountInfo?.promotion, userInfo?.codigoUsuario])

  // USE EFFECT
  useEffect(async () => {
    if (currentBranch.cod && item.tipo === 'product') {
      try {
        const { response } = await checkStock({
          sku: item.sku,
          branchOffice: currentBranch.cod,
        })
        if (response) setCurrentStock(response)

        if (
          item.cantidad > currentStock &&
          item.tipo === 'product' &&
          !firstRender
        ) {
          dispatch(setSnackbar(true, 'warning', noStockMessage))
        }
      } catch (error) {
        console.error(error)
        console.error(error.message)
      }
      setFirstRender(false)
    }
  }, [item.cantidad, currentBranch])

  const removeFromCartHandler = async (item) => {
    onRemove(item)
  }

  const plusItemHandler = (item) => {
    dispatch(
      plusCartItem(item, {
        idUsuario: userInfo.codigoUsuario,
        idOrder: cart.idOrder,
      })
    )
    onChange?.(item)
  }

  const minusItemHandler = (item) => {
    if (item.cantidad > 1) {
      dispatch(
        minusCartItem(item, {
          idUsuario: userInfo.codigoUsuario,
          idOrder: cart.idOrder,
        })
      )
      onChange?.(item)
    }
  }

  const editItemHandler = (item) => {
    dispatch(
      editItemFromCart(item, {
        idUsuario: userInfo.codigoUsuario,
        idOrder: cart.idOrder,
      })
    )
  }

  useEffect(() => {
    setItemQuantity(item?.cantidad)
  }, [item.cantidad])

  return (
    <>
      <Card className={classes.card} key={item.id}>
        <Box padding='14px'>
        {discountInfo && (
          <DiscountLabel
            discountType={
              item?.combos ? 'combo' : discountInfo?.discountType
            }
            size={10}
          />
        )}
        <Grid container sx={{position: 'relative'}}>
          <Grid
            item
            xs={3}
            md={2}
            component={Link}
            to={`/producto/${item.parent || item.sku}`}
            sx={(theme) => ({
              [theme.breakpoints.up('sm')]: {
                paddingRight: '10px',
                alignItems: 'center',
              },
            })}
          >
            <img
              src={item?.imagen?.[0] ?? sinImagen}
              alt={item.nombreEC}
              className={classes.image}
            />
          </Grid>
          <Grid
            item
            xs={5}
            md={7}
            sx={(theme) => ({
              display: 'flex',
              [theme.breakpoints.up('sm')]: {
                alignItems: 'center',
                padding: '0 12px',
              },
            })}
          >
            <Grid item md={8}>
              <Typography
                className={classes.text}
                style={{
                  marginBottom: '5px',
                }}
              >
                {item?.nombreEC ?? item?.nombre}
              </Typography>
              <Typography
                className={classes.textDescription}
                style={{
                  marginBottom: item?.tipo === 'product' ? '30px' : '9px',
                }}
              >
                SKU: {item.sku}
              </Typography>
              {item?.tipo === 'product_official' && (
                <>
                  <Typography
                    paragraph
                    className={classes.textDescription}
                    style={{
                      marginBottom: '5px',
                    }}
                  >
                    Productor:{' '}
                    {item?.productor?.razonSocial ?? item?.productor?.nombre}
                  </Typography>
                  {item?.productor?.cuig && (
                    <Typography
                      paragraph
                      className={classes.textDescription}
                      style={{
                        marginBottom: '5px',
                      }}
                    >
                      CUIG: {item.productor.cuig}
                    </Typography>
                  )}
                  <div style={{display: desktop ? 'flex' : 'block'}}>
                    {item?.productor?.renspa && (
                      <Typography
                        paragraph
                        className={classes.textDescription}
                        style={{
                          marginRight: '10px'
                        }}
                      >
                        Renspa: {item.productor.renspa}
                      </Typography>
                    )}
                    {item?.productor?.digitoVerificador && (
                      <Typography
                        paragraph
                        className={classes.textDescription}
                      >
                        CV: {item.productor.digitoVerificador}
                      </Typography>
                    )}
                  </div>
                </>
              )}
              {
                // Numeracion Desde Hasta
                item.tipo === 'product_manejo' && (
                  <>
                    <Typography
                      paragraph
                      className={classes.textDescription}
                      style={{
                        marginBottom: '5px',
                      }}
                    >
                      Numeración: {item.numeracionDesde} a {item.numeracionHasta}
                    </Typography>

                    {item.series && (
                      <Typography
                        paragraph
                        className={classes.textDescription}
                        style={{
                          marginBottom: '5px',
                        }}
                      >
                        Series: {item.series}
                      </Typography>
                    )}
                  </>
                )
              }
              {item.textoTarjeta && (
                <Typography
                  paragraph
                  className={classes.textDescription}
                  style={{
                    marginBottom: '5px',
                  }}
                >
                  Texto impreso: {item.textoTarjeta}
                </Typography>
              )}
            </Grid>
            <Grid item md={4}>
              <>
                {item.tipo === 'product' && showQuantityInput ? (
                  <>
                    {desktop && (
                      <AddMinusQuantity
                        cartQuantity={itemQuantity}
                        plusItem={() => plusItemHandler(item)}
                        minusItem={() => minusItemHandler(item)}
                        onChangeHandler={(e) => {
                          const { value } = e.target
                          setItemQuantity(value)
                          setLoading(true)
                          const debounceCb = debounce((prev) => {
                            const itemQuantity = Number(prev < 1 ? 1 : prev)
                            setItemQuantity(itemQuantity)
                            editItemHandler({ ...item, cantidad: itemQuantity })
                            setLoading(false)
                          }, 2000)
                          debounceCb(value)
                        }}
                        width={'129px'}
                        height={'45px'}
                      />
                    )}
                  </>
                ) : (
                  desktop &&
                  <Typography sx={{ textAlign: 'center' }}>
                    {numberWithDots(item.cantidad * (item.series || 1))} u.
                  </Typography>
                )}
                {item.tipo === 'product' && item.cantidad > currentStock && (
                  <Typography
                    sx={{
                      fontSize: '12px',
                      color: '#f54c36',
                      paddingTop: '6px',
                    }}
                  >
                    Sin stock solicitado
                  </Typography>
                )}
              </>
            </Grid>
          </Grid>
          <Grid
            item
            xs={4}
            md={3}
            justifyContent={desktop ? 'space-around' : 'space-between'}
            alignItems={desktop ? 'center' : 'flex-end'}
            sx={(theme) => ({
              display: 'flex',
              flexDirection: 'column',
              [theme.breakpoints.up('sm')]: {
                flexDirection: 'row',
              },
            })}
          >
            {desktop && (
              <IconButton
                aria-label='delete'
                size='large'
                onClick={() => removeFromCartHandler(item)}
              >
                <img src={DeleteIcon} alt='Eliminar' />
              </IconButton>
            )}
            <Grid sx={{ minWidth: '80px' }}>
              {parseFloat(item?.descuentos) ? (
                <>
                  {item.cantidad !== 1 && (
                    <Typography paragraph className={classes.precioLista}>
                      {formatNumber(precioIndividual.toString())} c/u
                    </Typography>
                  )}
                  {priceApplyPromotions && (
                    <Typography
                      paragraph
                      style={{position: 'relative'}}
                      classes={{ paragraph: classes.offertBox }}
                    >
                      {fixAmount(item?.descuentos || 0)}% OFF
                      {localStorage.getItem('permisos') === '2' && (
                        <Box style={{
                          position: 'absolute',
                          top: -8, 
                          right: -28
                        }}>
                          <IconButton
                            aria-label='delete'
                            style={{width: '28px', height: '28px'}}
                            onClick={() => setEditDiscModal(true)}
                          >
                            <EditIcon 
                              style={{width: '18px'}}
                            />
                          </IconButton>
                        </Box>
                      )}
                    </Typography>
                  )}

                  {priceApplyPromotions && (
                    <Typography
                      paragraph
                      component='strike'
                      classes={{ paragraph: classes.discountPrice }}
                    >
                      {formatNumber(subtotal.toString())}
                    </Typography>
                  )}
                  {!priceApplyPromotions && (
                    <Typography
                      paragraph
                      component='strike'
                      classes={{ paragraph: classes.discountPrice }}
                    >
                      {formatNumber(item.precioDeLista)}
                    </Typography>
                  )}
                  <Typography paragraph className={classes.precio}>
                    {formatNumber(precioConDescuento.toString())}
                  </Typography>
                </>
              ) : (
                <>
                {localStorage.getItem('permisos') === '2' && (
                  <Typography
                    paragraph
                    style={{position: 'relative'}}
                    classes={{ paragraph: classes.offertBox }}
                  >
                    {fixAmount(item?.descuentos || 0)}% OFF
                      <Box style={{
                        position: 'absolute',
                        top: -8, 
                        right: -28
                      }}>
                        <IconButton
                          aria-label='delete'
                          style={{width: '28px', height: '28px'}}
                          onClick={() => setEditDiscModal(true)}
                        >
                          <EditIcon 
                            style={{width: '18px'}}
                          />
                        </IconButton>
                      </Box>
                    </Typography>
                  )}
                  {item.cantidad !== 1 && (
                    <Typography paragraph className={classes.precioLista}>
                      {formatNumber(precioIndividual.toString())} c/u
                    </Typography>
                  )}
                  <Typography paragraph className={classes.precio}>
                    {formatNumber(subtotal.toString())}
                  </Typography>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
        {!desktop && (
          <Box className={classes.itemActionsContainer}>
            <IconButton
              aria-label='delete'
              size='large'
              onClick={() => removeFromCartHandler(item)}
            >
              <img src={DeleteIcon} alt='Eliminar' />
            </IconButton>
            {item.tipo === 'product' && showQuantityInput ? (
              <AddMinusQuantity
                cartQuantity={itemQuantity}
                plusItem={() => plusItemHandler(item)}
                minusItem={() => minusItemHandler(item)}
                onChangeHandler={(e) => {
                  const { value } = e.target
                  setItemQuantity(value)
                  const debounceCb = debounce((prev) => {
                    const itemQuantity = Number(prev < 1 ? 1 : prev)
                    setItemQuantity(itemQuantity)
                    editItemHandler({ ...item, cantidad: itemQuantity })
                    setLoading(false)
                  }, 2000)
                  debounceCb(value)
                }}
                width={'129px'}
                height={'45px'}
              />
            ) : (
              <Typography sx={{ textAlign: 'center' }}>
                {numberWithDots(item.cantidad * (item.series || 1))} u.
              </Typography>
            )}
          </Box>
        )}
          
        </Box>
        {discountInfo?.promotion && promotionBanner && (
          <Box className={classes.promotionBanner}>
            <Typography style={{ fontSize: desktop ? '16px' : '14px', textAlign: 'center'}}>
              {promotionBanner}
            </Typography>
          </Box>
        )}
      </Card>
      <EditDiscountModal 
        isOpen={editDiscountModalIsOpen}
        close={() => setEditDiscModal(false)}
        submit={(newDiscount) => {
          editItemHandler({
            ...item,
            sellerDiscount: newDiscount
          })
          dispatch(getCartItems(userInfo.codigoUsuario, cart.idOrder))
          setEditDiscModal(false)
        }}
      />
    </>
  )
}

export default CartItem
