import React from 'react'
import { FormControl, InputAdornment, OutlinedInput } from '@mui/material'
import { Add, Remove } from '@mui/icons-material'

const AddMinusQuantity = ({
  cartQuantity,
  width,
  height,
  plusItem,
  minusItem,
  onChangeHandler,
  extraStyles,
}) => {
  return (
    <FormControl variant='outlined'>
      <OutlinedInput
        sx={{
          width: width,
          borderRadius: '8px',
          height: height,
        }}
        inputProps={{ style: { textAlign: 'center', ...extraStyles } }}
        id='input-with-icon-adornment'
        value={cartQuantity ?? 1}
        onChange={onChangeHandler}
        onPaste={(e) => {
          const value = e?.clipboardData?.getData('Text')
          if (isNaN(Number(value))) return e.preventDefault()
        }}
        onKeyDown={(e) =>
          e?.code?.includes('Key') && !e?.ctrlKey && e.preventDefault()
        }
        startAdornment={
          <InputAdornment
            position='start'
            sx={{ cursor: 'pointer !important' }}
          >
            <Remove
              onClick={() => minusItem()}
              style={{
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: '500',
                fontSize: '14px',
                lineHeight: '17px',
                textAlign: 'center',
                color: '#444444',
                cursor: 'pointer !important',
                zIndex: 5,
              }}
            />
          </InputAdornment>
        }
        endAdornment={
          <InputAdornment position='end' sx={{ cursor: 'pointer !important' }}>
            <Add
              onClick={() => plusItem()}
              style={{
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: '500',
                fontSize: '14px',
                lineHeight: '17px',
                textAlign: 'center',
                color: '#444444',
                cursor: 'pointer !important',
                zIndex: 5,
              }}
            />
          </InputAdornment>
        }
      />
    </FormControl>
  )
}

export default AddMinusQuantity
