import { ApiConnection } from 'config'

const getAllSucursales = async () => {
  const res = await ApiConnection.get(
    `branchoffice`
  )
  return res?.data?.response
}

export default getAllSucursales
