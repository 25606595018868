import React from 'react'
import { Box, Stack } from '@mui/material'
import { Link } from 'react-router-dom'
import AvatarComponent from '../AvatarComponent/index'
import { useStyles } from './userInfoStyles'
import { useSelector } from 'react-redux'
import sinAvatar from '../../assets/sinAvatar.svg'

export default function UserInfoComponent({ closeDrawer }) {
  const userAuth = useSelector((state) => state.userAuth)
  const signedIn = userAuth.signedIn
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      {signedIn ? (
        <>
          <Stack className={classes.stack}>
            <Box className={classes.stackBox}>
              <AvatarComponent imgURL={userAuth.userInfo?.avatar} size={71.9} />
            </Box>
          </Stack>
          <Box sx={{ marginLeft: '13px' }}>
            <p className={classes.userName}>
              {userAuth.userInfo?.contacto?.correoElectronico ??
                userAuth.userInfo?.user}
            </p>
            <Link to='/resumen' onClick={closeDrawer} className={classes.link}>
              Mi Cuenta
            </Link>
          </Box>
        </>
      ) : (
        <IniciarSesion closeDrawer={closeDrawer} />
      )}
    </Box>
  )
}

const IniciarSesion = ({ closeDrawer }) => {
  const classes = useStyles()

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div className={classes.avatarContainer}>
        <div className={classes.avatarInnerContainer}>
          <img
            src={sinAvatar}
            alt='Avatar por defecto del usuario'
            style={{ display: 'block', margin: 'auto' }}
          />
        </div>
      </div>
      <Box sx={{ marginLeft: '13px' }}>
        <Link
          to='/resumen'
          onClick={closeDrawer}
          className={classes.signInLink}
        >
          Iniciar Sesión
        </Link>
      </Box>
    </div>
  )
}
