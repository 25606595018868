import { forwardRef } from 'react'
import { Dialog, Slide } from "@mui/material";
import SiteInMaintensePopup2 from '../../assets/SiteInMaintensePopup2.png'

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

export default function NewClientPopup2 ({ isOpen = false }) {

  return (
    <Dialog
      open={isOpen}
      onClose={() => {}}
      TransitionComponent={Transition}
    >
      <div style={{ display: 'flex', alignItems:'center', overflow: 'hidden', maxWidth: 400 }}>
        <img src={SiteInMaintensePopup2} style={{width: '100%'}}/>
      </div>
    </Dialog>
  )
}