import { ApiConnection } from 'config'


const getSucursales = async () => {
  const { data } = await ApiConnection.get(
    `branchoffice`
  )
  return data.response
};
export default getSucursales

