import { ApiConnection } from 'config'


export const searchProducts = async (nameOrSku) => {
  const userId = localStorage.getItem('sellerId')
  try {
    const { data } = await ApiConnection.get(
      `product/finder/searchbox`,
      {
        params: {
          nameOrSku,
          userId
        }
      }
    )
    return data.response
  } catch (error) {
    throw new Error(error.message)
  }
}
