export const isProductInCart = (cart, value, quantity = 0, types = ['sku']) => {
  const products = [
    ...(cart?.product || []),
    ...(cart?.product_manejo || []),
    ...(cart?.product_official || []),
  ]
  const productsFilter = products.filter(
    (item) =>
      types?.some((type) => value && item[type] === value) &&
      !item?.codigoOferta?.startsWith('PR')
  )

  let totalQuantity = 0
  productsFilter.forEach((item) => (totalQuantity += item.cantidad))

  if (productsFilter?.length)
    return totalQuantity >= quantity ? productsFilter : false

  return false
}

export const isProductCategoryInCart = (cart, value, quantity) =>
  isProductInCart(cart, value, quantity, ['subLinea', 'linea', 'rubro'])

export const getHighDiscount = (list) => {
  if (list) {
    const keys = Object.keys(list)
    const listSort = keys.sort(
      (a, b) => (list[b]?.descuento || 0) - (list[a]?.descuento || 0)
    )

    return list[listSort[0]]?.descuento
      ? list[listSort[0]]
      : { descuento: 0, codigo: null }
  }

  return { descuento: 0, codigo: null }
}

export const isLastOpportunityCategory = (menuCategorias) => {
  return menuCategorias.some((categoria) => categoria.codigo === 'PROM-UO');
};


export const fixAmount = (number, toFixed = 2) =>
  parseFloat(parseFloat(number).toFixed(toFixed))

export const formatPrice = (price) =>
  parseFloat(parseFloat(price).toFixed(2)).toString()

export const applyDiscount = (price, discount) =>
  discount ? price * (1 - discount / 100) : price

// the client needed put the category with code 110 at the last of categories list
export const setLastItem = (itemList, code) => {
  if(itemList?.length === 0) return []
  const index = itemList.findIndex((el) => el.codigo === code)
  const temp = [...itemList]
  temp.splice(index, 1)
  return [...temp, itemList[index]]
}