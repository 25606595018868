import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import store from './store'
import { useDispatch } from 'react-redux'
import { changeLoginState } from './stores/userStore/actionsUser'
import Home from 'pages/Home/Home'
import { getInitialData } from 'stores/appStore/actionsApp'

const useAuth = () => {
  const user = store.getState().userAuth
  return user && user.signedIn
}

function ProtectedRoutes() {
  const isAuth = useAuth()
  const dispatch = useDispatch()
  if (!isAuth) dispatch(changeLoginState(false))

  useEffect(() => {
    dispatch(getInitialData())
  }, [])
  // TODO: Enviar a la pagina que estaba tratando de ir en vez de Home
  return isAuth ? <Outlet /> : <Home />
}

export default ProtectedRoutes
