import { ApiConnection } from 'config'

export const removeItem = async (id) => {
  try {
    const { data } = await ApiConnection.delete(
      `cart`,
      {
        data: { id },
      }
    )
    return { ...data }
  } catch (error) {
    console.error(error)
    throw error
  }
}
