import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    height: '100vh',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
      height: 'auto',
    },
  },
  closeIcon: {
    position: 'absolute',
    right: '20px',
    top: '20px',
    cursor: 'pointer',
  },
  content: {
    margin: '0px 36px',
    [theme.breakpoints.up('sm')]: {
      margin: '52px',
      marginBottom: '64px',
    },
  },
  title: { fontSize: '20px', marginBottom: '10px', padding: 0 },
  description: {
    fontSize: '14px',
    marginBottom: '25px',
    color: theme.palette.primary.almostBlack,
  },
  formLabel: {
    fontSize: '12px',
    fontWeight: 600,
    marginBottom: '4px',
  },
  textFieldInput: {
    fontSize: '12px',
    padding: '14px',
  },
  textField: {
    marginBottom: '16px',
    '& .MuiFormHelperText-root': {
      color: 'red',
    },
  },
  textMultilineInput: {
    fontSize: '12px',
  },
  shorterTextField: {
    width: '60%',
  },
  actionButton: {
    marginTop: '20px',
  },
}))

export default useStyles
