import { useStyles } from 'components/SelectClient/SelectClient.styles'
import React, { useEffect, useState } from 'react'
import Title from 'components/Title'
import {
  Grid,
  Typography,
  Button,
  TextField,
  InputAdornment,
  Slide,
  FormGroup,
  useMediaQuery,
  CircularProgress,
  Dialog,
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { openResetPasswordModal } from 'stores/userStore/actionsUser'
import CustomCloseIcon from 'components/CloseIcon'
import { FormProvider, useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { setSnackbar } from 'stores/snackbarStore'
import { resetPasswordService } from './services/resetPasswordService'
import {
  VisibilityOff,
  Visibility,
} from '../../../node_modules/@mui/icons-material/index'
import { passwordRegex } from './helpers/resetPassword'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

// TODO Add a new funtion for show password
const ResetPassword = () => {
  const INITIAL_STATE = {
    cardCode: '',
    oldPassword: '',
    newPassword: '',
  }

  // HOOKS
  const classes = useStyles()
  const desktop = useMediaQuery((theme) => theme.breakpoints.up('sm'))
  const dispatch = useDispatch()
  const openModal = useSelector(
    (store) => store.userAuth.openResetPasswordModal
  )
  const userInfo = useSelector((store) => store.userAuth.userInfo)
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const methods = useForm({ defaultValues: INITIAL_STATE, mode: 'onSubmit' })
  const [newPasswordShown, setNewPasswordShown] = useState(false)
  const [oldPasswordShown, setOldPasswordShown] = useState(false)

  const handleClickShowOldPassword = () => {
    setOldPasswordShown(!oldPasswordShown)
  }

  const handleClickShowNewPassword = () => {
    setNewPasswordShown(!newPasswordShown)
  }

  const handleMouseDownOldPassword = (event) => {
    event.preventDefault()
  }

  const handleMouseDownNewPassword = (event) => {
    event.preventDefault()
  }

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    reset: resetForm,
  } = methods

  useEffect(() => {
    setValue('cardCode', userInfo.codigoUsuario)
  }, [userInfo.codigoUsuario])

  const handleClose = () => {
    dispatch(openResetPasswordModal(false))
  }

  const onSubmit = async (data) => {
    try {
      setIsButtonLoading(true)
      const { response, message } = await resetPasswordService(data)
      if (response) {
        dispatch(setSnackbar(true, 'success', '¡Contraseña Restablecida!'))
        resetForm()
        handleClose()
      } else
        dispatch(
          setSnackbar(
            true,
            'error',
            message ||
              'Error al restablecer contraseña, Asegurate de que la contraseña actual coincida.'
          )
        )
    } catch (error) {
      dispatch(
        setSnackbar(
          true,
          'error',
          'Error al restablecer contraseña, Asegurate de que la contraseña actual coincida.'
        )
      )
    } finally {
      setIsButtonLoading(false)
    }
  }

  return (
    <Dialog
      open={openModal}
      TransitionComponent={Transition}
      fullScreen={!desktop}
      onClose={handleClose}
    >
      <Grid className={classes.innerContainer}>
        <CustomCloseIcon handleClose={handleClose} />
        <Title
          text='Restablecer contraseña'
          customStyles={{ marginBottom: '3px' }}
        />
        <FormProvider {...methods} initialState={INITIAL_STATE}>
          <form style={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
            <Typography className={classes.tagName}>Contraseña</Typography>
            <TextField
              variant='outlined'
              className={classes.inputField}
              fullWidth
              required
              type={oldPasswordShown ? 'text' : 'password'}
              id='oldPassword'
              name='olPassword'
              {...register('oldPassword', {
                required: true,
                pattern: {
                  value: passwordRegex,
                },
              })}
              error={!!errors.oldPassword}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position='end'
                    aria-label='toggle password visibility'
                    onClick={handleClickShowOldPassword}
                    onMouseDown={handleMouseDownOldPassword}
                  >
                    {oldPasswordShown ? <VisibilityOff /> : <Visibility />}
                  </InputAdornment>
                ),
              }}
            />

            <Typography
              variant='inherit'
              color='red'
              sx={{ paddingTop: 1, fontSize: 14 }}
            >
              {errors.oldPassword?.type === 'required' &&
                'La contraseña es obligatoria'}
              {errors.oldPassword?.type === 'pattern' && (
                <span>
                  La contraseña tiene que contar con: <br />
                  - letras mayúsculas, minúsculas <br />
                  - al menos 1 número y 1 caracter especial <br />
                  <br />
                </span>
              )}
            </Typography>
            <Typography className={classes.tagName}>
              Nueva contraseña
            </Typography>
            <TextField
              variant='outlined'
              className={classes.inputField}
              fullWidth
              required
              type={newPasswordShown ? 'text' : 'password'}
              id='newPassword'
              name='newPassword'
              {...register('newPassword', {
                required: true,
                pattern: {
                  value: passwordRegex,
                },
              })}
              error={!!errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position='end'
                    aria-label='toggle password visibility'
                    onClick={handleClickShowNewPassword}
                    onMouseDown={handleMouseDownNewPassword}
                  >
                    {newPasswordShown ? <VisibilityOff /> : <Visibility />}
                  </InputAdornment>
                ),
              }}
            />
            <Typography
              variant='inherit'
              color='red'
              sx={{ paddingTop: 1, fontSize: 14 }}
            >
              {errors.newPassword?.type === 'required' &&
                'La contraseña es obligatoria'}
              {errors.newPassword?.type === 'pattern' && (
                <span>
                  La contraseña tiene que contar con: <br />
                  - letras mayúsculas, minúsculas <br />
                  - al menos 1 número y 1 caracter especial <br />
                  <br />
                </span>
              )}
            </Typography>
            <Grid container alignItems='center'>
              <Grid item xs={7}>
                <FormGroup></FormGroup>
              </Grid>
              <Grid item xs={5}>
                <Typography className={classes.forgot}>
                  <Link
                    to='/recuperar-acceso'
                    onClick={() => dispatch(openResetPasswordModal(false))}
                  >
                    Olvidé mi contraseña
                  </Link>
                </Typography>
              </Grid>
            </Grid>
            <Button
              type='submit'
              variant='contained'
              fullWidth
              className={classes.buttonBuy}
              disabled={isButtonLoading}
            >
              {isButtonLoading ? (
                <CircularProgress disableShrink />
              ) : (
                'Restablecer contraseña'
              )}
            </Button>
          </form>
        </FormProvider>
      </Grid>
    </Dialog>
  )
}

export default ResetPassword
