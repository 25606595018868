import React from 'react'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import CancelIcon from '@mui/icons-material/Cancel'
import PendingActionsIcon from '@mui/icons-material/PendingActions'

function StatusIcon({ statusString }) {
  switch (statusString) {
    case 'Despachado':
      return <CheckCircleOutlineIcon sx={{ color: '#00984A' }} />
    case 'Pendiente':
      return <PendingActionsIcon sx={{ color: '#EDB039' }} />
    case 'Cancelado':
      return <CancelIcon sx={{ color: '#E45151' }} />
    default:
      return <div></div>
  }
}

export default StatusIcon
