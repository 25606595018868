import { ApiConnection } from 'config'


export const sendForm = async (data) => {
  try {
    const res = await ApiConnection.post(
      `users/form`, { ...data }
    )
    return res?.data?.response
  } catch (e) {
    console.error(e.message)
  }
}
