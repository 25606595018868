import { makeStyles } from '@mui/styles'

export const usePopOverSucursalesStyles = makeStyles((theme) => ({
  textStyles: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px !important',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '14px !important',
    },
  },
  branchOfficeText: {
    cursor: 'pointer',
    color: '#23FF8E',
  },
  popOverContainer: {
    '& .MuiPopover-paper': {
      paddingInline: '14px',
    },
  },
  brancOfficesPopOver: {
    padding: '10px 14px !important',
    width: '133px !important',
    textAlign: 'right !important',
    cursor: 'pointer',
  },
}))
