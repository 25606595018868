import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.primary.almostWhite,
    display: 'flex',
    flexWrap: 'wrap',
    padding: '18px',
    borderRadius: '12px',
    justifyContent: 'space-between',
    margin: '30px 0px',
  },
  detailContainer: {
    width: '50%',
    marginBottom: '20px',
    color: theme.palette.primary.almostBlack,
  },
  detailTitle: {
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '24px',
  },
  detailContent: {
    fontSize: '12px',
    fontWeight: 300,
    lineHeight: '24px',
  },
  editLinkContainer: {
    fontSize: '12px',
    width: '100%',
    color: theme.palette.primary.moreBlue,
    // display: 'block',
    marginTop: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '16px',
  },
  fullWidth: {
    width: '100%',
  },
  width30: {
    width: '30%',
  },
}))
