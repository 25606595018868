import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.primary.white,
    minHeight: '90vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    padding: '30px 15px',
    [theme.breakpoints.up('sm')]: {
      backgroundColor: 'transparent',
      minHeight: '83vh',
      justifyContent: 'space-between',
      padding: '150px 0px',
    },
  },
  button: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: '300px',
    },
  },
  notFoundContainer: {
    textAlign: 'center',
  },
  fourOfour: {
    marginTop: '20px',
    fontSize: '5em',
    color: '#B0D7C0',
    fontWeight: 600,
  },
  caption: {
    color: theme.palette.primary.typographyGrey,
  },
}))
