import { ApiConnection } from 'config'


const getAllClients = async (sellerId, branchOffice) => {
  try {
    const { data } = await ApiConnection.get(
      `user-seller/all/${sellerId}`,
    )
    return data
  } catch (error) {
    console.error(error.message)
  }
}

export default getAllClients
