export const questions = [
  {
    question: '¿Cómo puedo ser cliente VILLANUEVA?',
    answer: `VILLANUEVA es una empresa distribuidora y no comercializa de manera particular, si deseas hacer una compra pequeña escribinos a encontacto@villanueva.com.ar indicándonos tu nombre, dirección, ciudad y provincia para que podamos derivarte a alguno de nuestros clientes tu zona. 
    Por el contrario, en caso que tengas una veterinaria o comercio, por favor envianos tu mensaje a través de nuestro formulario de CONTACTO para que evaluemos la información y poder darte de alta como cliente.`,
  },
  {
    question: '¿Cómo comprar si soy cliente VILLANUEVA?',
    answer: `• Para comprar por nuestra plataforma, primero deberás estar dado de alta como cliente.
      •	Ingresá con tu usuario y contraseña (El usuario es el cuit de tu empresa, y la contraseña es la que te fue proporcionada vía mail al correo que tenés registrado). En caso de tener inconvenientes para ingresar, escribinos vía mail a encontacto@villanueva.com.ar 
      •	Elegí los productos que querés comprar agregándolos al carrito.
      •	Cuando termines de seleccionar los productos, clickeá en el carrito de compras para ver el listado completo y hacé clic en SELECCIONAR ENVÍO.
      •	Elegí en el buscador el transporte que llevará tu pedido o si deseas retirarlo en alguna de nuestras sucursales.
      •	Procede al pago del pedido mediante el saldo de tu cuenta y finaliza el mismo.
      •	Te llegará un mail con el detalle de tu compra. Una vez aprobado, la sucursal pasará a procesarlo.
      •	Podrás ver el estado de tus pedidos dentro de tu cuenta, haciendo clic en HISTORIAL DE PEDIDOS.`,
  },
  {
    question: '¿Cómo pagar los productos que compro?',
    answer: `Al darse de alta como cliente en VILLANUEVA se te genera automáticamente una cuenta corriente, cuyo saldo podés abonar a través de cheques, transferencia bancaria, mercado pago o cuenta recaudadora.
    Para más información consultá con tu vendedor o sucursal asignada.`,
  },
  {
    question: '¿Cómo consultar el saldo de mi cuenta corriente?',
    answer:
      'Para consultar el saldo de tu cuenta corriente deberás iniciar sesión con tu usuario y contraseña e ir a la sección Resumen de cuenta corriente para consultar el estado de la misma. Ingresando podrás ver las facturas pendientes y abonar el saldo.',
  },
  {
    question: '¿Cómo ver el historial de pedidos?',
    answer: `Para ver el historial de compras, deberás ingresar a tu cuenta, en la sección Historial de pedidos, donde podrás ver todos los que hayas realizado previamente y el estado en el que se encuentra cada uno.    `,
  },
]
