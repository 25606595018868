import { ApiConnection } from 'config'

export const getAvailableBalance = async (cardCode) => {
  try {
    const { data } = await ApiConnection.get(
      `users/available-balance?cardCode=${cardCode}`
    )
    return data?.response
  } catch (error) {
    console.error(error)
  }
}
