import React from 'react'
import { Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

const Title = ({ text, customStyles }) => {
  const classes = useStyles()

  return (
    <Grid className={classes.grid} style={customStyles}>
      <Typography className={classes.title}>{text}</Typography>
    </Grid>
  )
}

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: '600',
    fontSize: '18px',
    color: theme.palette.primary.almostBlack,
  },
  grid: {
    margin: '20px',
    [theme.breakpoints.up('sm')]: {
      marginLeft: 0,
    },
  },
}))

export default Title
