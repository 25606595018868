import React, { useState } from 'react'
import {
  Grid,
  Typography,
  Button,
  TextField,
  InputAdornment,
  Slide,
  FormControlLabel,
  FormGroup,
  Checkbox,
  useMediaQuery,
  CircularProgress,
} from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import { useStyles } from './SigninModal.styles'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import {
  Person,
  VisibilityOff,
  Visibility,
} from '../../../node_modules/@mui/icons-material/index'
import {
  login,
  changeLoginState,
  openSelectClientModal,
} from '../../stores/userStore/actionsUser'
import { setSnackbar } from 'stores/snackbarStore'
import store from '../../store'
import { Link } from 'react-router-dom'
import CustomCloseIcon from 'components/CloseIcon'
import { Helmet } from 'react-helmet'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const SigninModal = () => {
  const classes = useStyles()
  const { showModalLogin } = useSelector((state) => state.userAuth)
  const dispatch = useDispatch()
  const desktop = useMediaQuery((theme) => theme.breakpoints.up('sm'))

  const [passwordShown, setPasswordShown] = useState(false)
  const [loading, setLoading] = useState(false)

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      username: '',
      password: '',
    },
  })
  const onSubmit = async (data) => {
    setLoading(true)
    await dispatch(login(data.username, data.password))
    setLoading(false)
    const signedIn = store.getState().userAuth.signedIn
    const userInfo = store.getState().userAuth.userInfo
    if (signedIn) {
      if (userInfo?.permisos === '2') {
        dispatch(openSelectClientModal(true))
      }
      dispatch(changeLoginState(showModalLogin))
    } else {
      dispatch(
        setSnackbar(true, 'error', 'Error. Revise su usuario y contraseña.')
      )
    }
  }

  const handleClose = () => {
    dispatch(changeLoginState(showModalLogin))
  }

  const handleClickShowPassword = () => {
    setPasswordShown(!passwordShown)
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const handleResetPassword = () => {
    dispatch(changeLoginState(showModalLogin))
  }

  return (
    <>
      {showModalLogin && (
        <Helmet>
          <title>Iniciar sesión | Villanueva</title>
          <meta name='description' content='Iniciar sesión en Villanueva' />
          <meta
            name='keywords'
            content='inciar sesion, login, signin, ingresar, ingresar a villanueva'
          />
        </Helmet>
      )}
      <Dialog
        open={showModalLogin}
        TransitionComponent={Transition}
        fullScreen={!desktop}
        onClose={handleClose}
        maxWidth={'md'}
      >
        <DialogTitle>
          <CustomCloseIcon handleClose={handleClose} />
        </DialogTitle>
        <Grid className={classes.grid}>
          <Grid className={classes.container}>
            <Typography className={classes.title}>
              Te damos la bienvenida a{' '}
              <span
                style={{
                  color: '#4AA259',
                }}
              >
                Villanueva
              </span>
            </Typography>
            <Typography paragraph className={classes.subtitleInfo}>
              Iniciá sesión para visualizar los precios y realizar un pedido.
            </Typography>
            <form style={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
              <Typography className={classes.tagName}>Usuario</Typography>
              <TextField
                variant='outlined'
                fullWidth
                required
                id='username'
                name='username'
                {...register('username', {
                  required: true
                })}
                error={!!errors.username}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <Person />
                    </InputAdornment>
                  ),
                }}
                className={classes.inputField}
              />
              <Typography variant='inherit' color='red' sx={{ paddingTop: 1 }}>
                {errors.username?.type === 'required' &&
                  'La usuario es obligatorio'}
                {errors.username?.type === 'pattern' &&
                  'Formato de e-mail inválido'}
              </Typography>
              <Typography className={classes.tagName}>Contraseña</Typography>
              <TextField
                variant='outlined'
                className={classes.inputField}
                fullWidth
                required
                type={passwordShown ? 'text' : 'password'}
                id='password'
                name='password'
                {...register('password', { required: true })}
                error={!!errors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position='end'
                      aria-label='toggle password visibility'
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {passwordShown ? <VisibilityOff /> : <Visibility />}
                    </InputAdornment>
                  ),
                }}
              />
              <Typography variant='inherit' color='red' sx={{ paddingTop: 1 }}>
                {errors.password?.type === 'minLength' &&
                  'La contraseña debe tener al menos 10 caracteres'}
                {errors.password?.type === 'required' &&
                  'La contraseña es obligatoria'}
              </Typography>
              <Grid container alignItems='center'>
                <Grid item xs={7}>
                  <FormGroup>
                    <FormControlLabel
                      sx={(theme) => ({
                        '& span': {
                          fontSize: '12px',
                          color: '#444444',
                        },
                      })}
                      control={<Checkbox defaultChecked />}
                      {...register('remember')}
                      size='small'
                      label='Recordarme'
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={5}>
                  <Typography className={classes.forgot}>
                    <Link
                      to='/recuperar-acceso'
                      onClick={() => handleResetPassword()}
                    >
                      Olvidé mi contraseña
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
              <Button
                type='submit'
                variant='contained'
                fullWidth
                className={classes.buttonBuy}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress disableShrink />
                ) : (
                  'Iniciar Sesión'
                )}
              </Button>
            </form>
          </Grid>
          <Grid container>
            <Grid container className={classes.bottomInfo}>
              <Grid item xs={12}>
                <Typography paragraph className={classes.question}>
                  ¿Querés ser cliente?
                </Typography>
                <Link
                  to={'/registro'}
                  onClick={handleClose}
                  className={classes.alta}
                >
                  Solicitar alta
                </Link>
                <Typography paragraph className={classes.disclaimer}>
                  * El alta de cliente esta condicionado a un previo analisis
                  crediticio y finianciero, en el lapso de la proximas horas se
                  le enviará una confirmación por correo.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </>
  )
}

export default SigninModal
