import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '30vh',
    backgroundColor: theme.palette.primary.white,
    padding: '30px 14px',
    [theme.breakpoints.up('sm')]: {
      padding: 0,
      minHeight: 0,
      backgroundColor: 'transparent',
    },
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '30vh',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '1040px',
      margin: 'auto',
      paddingTop: '32px',
      paddingBottom: '90px',
      backgroundColor: 'transparent',
      minHeight: 0,
    },
  },
  content: {
    backgroundColor: theme.palette.primary.white,
    marginTop: '20px',
    [theme.breakpoints.up('sm')]: {
      borderRadius: '8px',
      boxShadow: '0px 2px 14px rgba(0,0,0,0.1)',
      padding: '22px 20px 62px',
    },
  },
}))
