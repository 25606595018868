import React from 'react'
import { useSelector } from 'react-redux'
import ProductCard from './Product'
import Caravana from '../../assets/sinImagen.jpg'
import { Link } from 'react-router-dom'
import { Typography, Box, Grid } from '@mui/material'
import { useStylesProducts } from './styles'
import ProductSlider from 'components/ProductSlider/ProductSlider'
import BannerCard from './BannerCard'
import { isLastOpportunityCategory } from 'pages/ProductPage/utils/helpers'

export default function ProductsSection({
  titleSection,
  redirectTo,
  banners,
  data,
  catalogo,
  carouselIndex = data?.[0]?.id,
}) {
  const classes = useStylesProducts(catalogo)
  const userSignin = useSelector((state) => state.userAuth)
  const { signedIn } = userSignin

  const getCardsWithBanners = () => {
    const cards = []
    for (let index = 0; index < data.length; index++) {
      if (
        (index === 2 && banners?.[0]) ||
        (index !== 0 && index % 10 === 0 && banners?.[0])
      )
        cards.push({ isBanner: true, ...banners[0] })

      cards.push(data[index])
    }
    return cards
  }

  return (
    <Box>
      {titleSection && (
        <span className={classes.titleContainer}>
          <Typography className={classes.titleSection}>
            {titleSection}
          </Typography>
          {redirectTo && (
            <Link className={classes.seeMore} to={redirectTo}>
              Ver más
            </Link>
          )}
        </span>
      )}

      {catalogo ? (
        <Grid
          container
          direction='row'
          alignItems='start'
          gap={{ xs: '12px', md: '20px' }}
          sx={() => ({
            flexWrap: 'wrap',
            paddingBottom: '12px',
          })}
        >
          {(banners ? getCardsWithBanners() : data)?.map((row) =>
            row.isBanner ? (
              <BannerCard
                key={row.id}
                file={row.file}
                redirectTo={row.redirectTo}
              />
            ) : (
              <ProductCard
                key={row.id}
                {...row}
                sinImagen={Caravana}
                showPrice={signedIn}
                catalogo={catalogo}
                isLastOpportunity={isLastOpportunityCategory(
                  row?.menuCategorias
                )}
              />
            )
          )}
        </Grid>
      ) : (
        <ProductSlider
          data={data}
          signedIn={signedIn}
          carouselIndex={carouselIndex}
        />
      )}
    </Box>
  )
}
