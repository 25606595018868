/* eslint-disable eqeqeq */
import { useStyles } from './FilterComponent.styles'
import { Box, Grid, Typography, Stack } from '@mui/material'
import { findChildsOfCategory, formatCharacteristics } from './helpers/filterList';
import FilterElement from './FilterElement'
import FilterChips from './FilterChips/index'
import { useSelector } from 'react-redux'
import { useNavigate,  useParams } from 'react-router-dom'
import { useContext, useEffect, useMemo, useState } from 'react';
import { findCurrentCategoryWithNormalizedTree } from 'pages/CatalogPage - refactor/utils';
import { CatalogueContext } from 'pages/CatalogPage - refactor/CatalogPage';

export default function FilterDesktopComponent({
  handleChange,
  accordionExpanded,
}) {
  const classes = useStyles()
  const navigate = useNavigate()
  const {"*": categoriesURL} = useParams()
  const { allCategories } = useSelector(({app}) => app)
  const { categoriesURL: { treeWithoutCurrentCategoryURL, currentCategoryURL } } = useContext(CatalogueContext)
  const [childCategories, setChildCategories] = useState(null)
  const { characteristics, results } = useSelector(({ searchResults }) => searchResults)

  useEffect(() => {
    if(allCategories?.length === 0 || !categoriesURL) return setChildCategories(null)
    const currentCategory = findCurrentCategoryWithNormalizedTree(categoriesURL)
    const childs = findChildsOfCategory(currentCategory, allCategories)
    // si tiene sub categorias las renderizo, sino muestro filtros
    if(childs?.length > 0) {
      setChildCategories(childs)
      // const filtrableChilds = childs.filter(child => child.filtrable);
      // setChildCategories(filtrableChilds)
    } else {
      setChildCategories(null)
    }
  }, [categoriesURL, allCategories])
  
  const characteristicsFormatted = useMemo(() => {
    if(results.length === 0 || childCategories) return []
    const {caracteristicas, caracteristicasValores} = characteristics

    if (!Array.isArray(caracteristicas)) return []
    
    const filtrableCharacteristics = caracteristicas.filter(c => c.filtrable)
    return formatCharacteristics({caracteristicas: filtrableCharacteristics, caracteristicasValores})
    // return characteristics? formatCharacteristics(characteristics) : []
  }, [characteristics, childCategories, results.length, currentCategoryURL])

  const currentFilters = useMemo(() => {
    const filters = []
    if(allCategories.length === 0) return filters

    const currentCategory = findCurrentCategoryWithNormalizedTree(categoriesURL)
    if(currentCategory)
      filters.push({
        label: currentCategory.nombre, 
        onDelete: () => navigate(`/catalogo/${treeWithoutCurrentCategoryURL}`)
      })
    // ..demas filtros con label y onDelete
    return filters
  }, [treeWithoutCurrentCategoryURL, categoriesURL, allCategories])

  return (
    <Box className={classes.mainBox}>
      <Grid sx={{ padding: '0px 20px 20px 0px' }}>
        <Typography
          className={classes.filterTitle}
          sx={{ paddingBottom: '15px' }}
        >
          Filtros:
        </Typography>
        <Stack
          direction={{ xs: 'column', lg: 'row' }}
          flexWrap='wrap'
          gap={{ xs: '10px' }}
        >
          {currentFilters.map(({onDelete, label}) => {
              return (
                <FilterChips
                  key={label}
                  handleDeleteFilter={onDelete}
                  filterValue={label}
                />
              )
            }
          )}
        </Stack>
      </Grid>
      <FilterElement
        data={childCategories || characteristicsFormatted}
        accordionExpanded={accordionExpanded}
        handleChange={handleChange}
      />
    </Box>
  )
}
