import { SEARCH_FAIL, SEARCH_SUCCESS, SEARCH_REQUEST, CATEGORY_CHARACTERISTICS, SEARCH_SUCCESS_2, SEARCH_QUERY } from './constantsSearch'

const initialState = {
  results: [],
  loading: false,
  formatedFilters: {},
  formatedFrontFilters: {},
  additionalFilters: {},
  categoryCharacteristics: {},
  query: '',
  totalItems: 0,
  remainingItems: 0,
  remainingPages: 0,
  nextPage: 0,
}

export const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_REQUEST:
      return { ...state, loading: true }
    case SEARCH_FAIL:
      return { ...state, loading: false, results: [] }
    case 'SET_FILTER': {
      return { ...state, additionalFilters: action.payload }
    }
    case 'DELETE_FILTER': {
      const { formatedFilters, formatedFrontFilters } = state
      delete formatedFilters[action.payload]
      delete formatedFrontFilters[action.payload]
      delete formatedFilters.caracteristicas
      delete formatedFrontFilters.caracteristicas
      if (
        action.payload === 'precioDesde' ||
        action.payload === 'precioHasta'
      ) {
        delete formatedFilters.precioHasta
        delete formatedFrontFilters.precioHasta
        delete formatedFilters.precioDesde
        delete formatedFrontFilters.precioDesde
      }
      return {
        ...state,
        formatedFilters: { ...formatedFilters },
        formatedFrontFilters: { ...formatedFrontFilters },
      }
    }
    case SEARCH_QUERY: {
      const { response } = action.payload
      return {
        ...state,
        loading: false,
        results: response.products,
        categories: response.categories,
        characteristics: response.characteristicsGroup,
        totalItems: response?.totalItems ?? 0,
        remainingItems: response?.itemsRestantes ?? 0,
        remainingPages: response?.paginasRestantes ?? 0,
        nextPage: response?.nextPage ?? 0,
      }
    }
    case SEARCH_SUCCESS: {
      const {
        response,
        query,
        formatedFilters,
        formatedFrontFilters,
      } = action.payload
      return {
        ...state,
        loading: false,
        results: response.products,
        categories: response.categories,
        characteristics: response.characteristicsGroup,
        query,
        formatedFilters: { ...state.formatedFilters, ...formatedFilters },
        formatedFrontFilters: {
          ...state.formatedFrontFilters,
          ...formatedFrontFilters,
        },
        totalItems: response?.totalItems ?? 0,
        remainingItems: response?.itemsRestantes ?? 0,
        remainingPages: response?.paginasRestantes ?? 0,
        nextPage: response?.nextPage ?? 0,
      }
    }
    case CATEGORY_CHARACTERISTICS: {
      return {
        ...state,
        categoryCharacteristics: action.payload
      }
    }
    case 'CLEAR_ALL_FILTERS':
      return initialState
    default:
      return state
  }
}
