import { ApiConnection } from 'config'


export const registerClient = async (formData) => {
  try {
    const { data } = await ApiConnection.post(
      `users/register`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
    return data?.response?.password
  } catch (error) {
    console.error(error)
  }
}
