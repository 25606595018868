/* eslint-disable no-constant-condition */
/* eslint-disable eqeqeq */
import { Box, Typography, FormGroup, FormControlLabel, Checkbox } from '@mui/material'
import { useContext} from 'react'
import { useSelector } from 'react-redux'
import { useStyles } from './FilterComponent.styles'
import AccordionElement from './AccordionElement'
import { formatTitle } from './helpers/filterList'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { CatalogueContext } from 'pages/CatalogPage - refactor/CatalogPage'
import { buildUrl } from 'utils/filters'


const FilterElement = ({
  data = [],
  handleChange,
  accordionExpanded,
}) => {
  const classes = useStyles()
  const { loading } = useSelector(({ searchResults }) => searchResults)
  const navigate = useNavigate()
  const { updateCatalogParams } = useContext(CatalogueContext)
  const [searchParams] = useSearchParams()

  const handleSelectedCheckbox = (e, characteristicCode) => {
    const {checked} = e.target
    let caracteristicas = searchParams.get('caracteristicas') || ''
    
    if(checked) {
      caracteristicas = caracteristicas.split('-')
      caracteristicas.push(characteristicCode)
      caracteristicas = caracteristicas.filter(Boolean).join('-')
      updateCatalogParams({ caracteristicas })
    } else {
      caracteristicas = caracteristicas.split('-').filter((filterCode) => filterCode !== characteristicCode).join('-')
      updateCatalogParams({ caracteristicas })
    }
  }

  const handleSelectedFilter = (categoryCode) => {
    navigate(`/catalogo/${buildUrl(categoryCode).replace(/%/g, '%25')}`)
  }

  return (
    <Box>
      {data
      .reduce((prev, curr) => prev.find(({label}) => label === curr?.label) ? prev : [...prev, curr] ,[])
      .sort((a,b) => a?.label?.localeCompare(b?.label))
      .map(({ label, value, type, code, values }, index) => {
        return type == 'categoria' ? (
          <Typography
            key={index}
            role='button'
            hidden={loading}
            className={classes.filterLabel}
            onClick={() => handleSelectedFilter(code)}
            sx={{ 
              fontSize: '11px', 
              paddingTop: '10px', 
              paddingBottom: '10px', 
              borderBottom: '1px solid lightgrey', 
              marginBottom: '0px !important',
              borderTop: index === 0 ? '1px solid lightgrey' : ''
            }}
          >
            {label}
          </Typography>
        ) : (
          <AccordionElement
            key={index}
            label={label}
            handleChange={handleChange}
            accordionExpanded={accordionExpanded}
          >
            {values
              .sort((a, b) => a?.label?.localeCompare(b?.label))
              .map((characteristicValue, index) => (
                <FormGroup key={index}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={searchParams.get('caracteristicas')?.includes(`${value}/${characteristicValue.codigo}`)}
                        onChange={(e) =>
                          handleSelectedCheckbox(e, `${value}/${characteristicValue.codigo}`)
                        }
                      />
                    }
                    label={formatTitle(characteristicValue.nombre)}
                  />
                </FormGroup>
              ))}
          </AccordionElement>
        )
      })}
    </Box>
  )
}
export default FilterElement
