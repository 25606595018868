import React, { useState } from 'react'
import {
  Grid,
  Typography,
  TextField,
  FormControlLabel,
  Switch,
  Button,
  CircularProgress,
  useMediaQuery
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useForm, Controller } from 'react-hook-form'
import { setSnackbar } from 'stores/snackbarStore'
import { useDispatch } from 'react-redux'
import postProducer from '../services/postProducer'
import { CUIGRegex } from 'utils/regesx'
import { cuitValidator, renspaValidator } from 'utils/validators'

const INITIAL_STATE = {
  nombre: null,
  razonSocial: null,
  cuit: null,
  renspa: null,
  cuig: null,
  domicilio: {
    calle: null,
    altura: null,
    codigoPostal: null,
    localidad: null,
    piso: null,
    provincia: null,
    pais: null,
  },
  contacto: {
    correoElectronico: null,
    telefonoFijo: null,
    telefonoMovil: null,
  },
}

function AddProducer({ hideForm = () => {}, setProducer = () => {} }) {
  const classes = useStyles()
  const desktop = useMediaQuery((theme) => theme.breakpoints.up('sm'))

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    setError,
    clearErrors,
  } = useForm({
    defaultValues: INITIAL_STATE,
  })
  const dispatch = useDispatch()
  // STATE
  const [showAddressForm, setShowAddressForm] = useState(false)
  const [showContactForm, setShowContactForm] = useState(false)
  const [addingProducer, setAddingProducer] = useState(false)

  const renspaIsValid = async (renspa, cuit) => {
    const result = await renspaValidator(renspa, cuit)
    if (result.isValid === false)
      setError('renspa', { type: 'format', message: result.reason })
    return result.isValid
  }

  const cuitIsValid = (cuit) => {
    const result = cuitValidator(cuit)
    if (result === false)
      setError('cuit', { type: 'format', message: 'Cuit inválido' })
    return result
  }

  const cuigIsValid = (cuig = '') => {
    const isValidFormat = CUIGRegex.test(cuig)
    if(!isValidFormat) {
      setError('cuig', { type: 'format', message: 'Formato inválido' })
      return false
    } 
    return true
  }

  const extraValidations = async (data) => {
    if (!cuitIsValid(data.cuit)) return false
    if (!cuigIsValid(data.cuig)) return false
    if (!(await renspaIsValid(data.renspa, data.cuit))) return false
    return true
  }

  // EVENTS
  const onSubmit = async (data) => {
    try {
      const extraValidationWasSuccessful = await extraValidations(data)
      if (!extraValidationWasSuccessful) return;
      
      setAddingProducer(true)
      const res = await postProducer(data)
      if (res) {
        dispatch(setSnackbar(true, 'success', 'Productor agregado con exito!'))
        setProducer(res)
        hideForm()
      } else {
        dispatch(
          setSnackbar(
            true,
            'error',
            'Error al agregar el productor. Intente nuevamente.'
          )
        )
      }
    } catch (error) {
      const message = typeof error?.response?.data?.response === 'string' && error.response.data.response
      console.log({error})
      dispatch(
        setSnackbar(true, 'error', message || 'Error de conexion. Intente nuevamente.')
      )
    } finally {
      setAddingProducer(false)
    }
  }

  return (
    <Grid className={classes.container}>
      <Typography variant='h2' className={classes.title}>
        Agregar nuevo productor
      </Typography>

      <Grid className={classes.formRow}>
        <CustomRequiredTextField
          label='Nombre establecimiento'
          register={register}
          field='nombre'
          errorText={errors?.nombre?.message}
        />
      </Grid>
      <Grid className={classes.formRow}>
        <CustomRequiredTextField
          label='Razón social'
          register={register}
          field='razonSocial'
          errorText={errors?.razonSocial?.message}
        />
      </Grid>
      <Grid className={classes.formRow}>
        <CustomRequiredTextField
          label='CUIT (sin guiones)'
          register={register}
          field='cuit'
          errorText={errors?.cuit?.message}
          onBlur={(e) => e.target.value && cuitIsValid(e.target.value)}
          onFocus={(e) => e.target.value && clearErrors('cuit')}
        />
        <Controller
          control={control}
          name={'cuig'}
          render= {({ field: { onChange, ...rest }, formState: { errors } }) => (
            <CustomRequiredTextField
              label='CUIG'
              register={register}
              field='cuig'
              errorText={errors?.cuig?.message}
              inputProps={{ maxLength: 5 }}
              onChange={(e) => onChange(e.target.value.toUpperCase())}
              {...rest}
            />
          )}
        />
        
        <Controller
          control={control}
          name={'renspa'}
          render={({ field: { onChange, ...rest }, formState: { errors } }) => (
            <CustomRequiredTextField
              label='Renspa'
              register={register}
              field='renspa'
              onChange={(e) => {
                if (e.target.value.length > 17) return
                const [inputNumericPart, alsoAlphabeticPart] = e.target.value.split('/')
                let input = inputNumericPart.replace(/\D/g, '')

                if(alsoAlphabeticPart)
                  input += alsoAlphabeticPart.replace(/\W/g, '').toUpperCase()

                const typed = e.nativeEvent.inputType
                if (typed === 'deleteContentBackward') {
                  return onChange(e.target.value)
                }
                const groups = input.match(
                  /^(\d{2})?(\d{3})?(\d{1})?(\d{0,5})?(\w{0,2})?$/
                ) // format the input

                let formatted = ''
                if (groups[1]) {
                  formatted += `${groups[1]}`
                }
                if (groups[2]) {
                  formatted += `.${groups[2]}`
                }
                if (groups[3]) {
                  formatted += `.${groups[3]}`
                }
                if (groups[4]) {
                  formatted += `.${groups[4]}`
                }
                if (groups[5]) {
                  formatted += `/${groups[5]}`
                }
                onChange(formatted)
              }}
              {...rest}
              errorText={errors?.renspa?.message}
              placeholder='Ej: 01.023.0.00123/00'
            />
          )}
        />
      </Grid>

      <Grid className={classes.formRow}>
        <FormControlLabel
          control={<Switch />}
          label='Agregar dirección'
          className={classes.switchFormControl}
          checked={showAddressForm}
          onChange={(e) => setShowAddressForm(e.target.checked)}
        />
        <Controller 
          control={control}
          name={'digitoVerificador'}
          render={({ field: { onChange, value, ...rest }, formState: { errors } }) => (
            <CustomRequiredTextField
              {...rest}
              label='CV'
              field='digitoVerificador'
              type='text'
              register={register}
              errorText={errors?.digitoVerificador?.message}
              value={value}
              onChange={e => {
                const inputOnlyNumber = e.target.value.replace(/[^\d]/g, '')
                onChange(inputOnlyNumber)
              }}
            />
          )}
        />
      </Grid>
      {showAddressForm && (
        <>
          <Grid className={classes.formRow}>
            <CustomTextField
              label='Calle'
              register={register}
              field='domicilio.calle'
            />
            <CustomTextField
              label='Altura'
              register={register}
              field='domicilio.altura'
            />
          </Grid>

          <Grid className={classes.formRow}>
            <CustomTextField
              label='Piso/depto'
              register={register}
              field='domicilio.piso'
            />
            <CustomTextField
              label='Código postal'
              register={register}
              field='domicilio.codigoPostal'
            />
          </Grid>

          <Grid className={classes.formRow}>
            <CustomTextField
              label='País'
              register={register}
              field='domicilio.pais'
            />
            <CustomTextField
              label='Provincia'
              register={register}
              field='domicilio.provincia'
            />
            <CustomTextField
              label='Localidad'
              register={register}
              field='domicilio.localidad'
            />
          </Grid>
        </>
      )}

      <Grid className={classes.formRow}>
        <FormControlLabel
          control={<Switch />}
          label='Agregar información de contacto'
          className={classes.switchFormControl}
          checked={showContactForm}
          onChange={(e) => setShowContactForm(e.target.checked)}
        />
      </Grid>
      {showContactForm && (
        <>
          <Grid className={classes.formRow}>
            <CustomTextField
              label='Correo electrónico'
              register={register}
              field='contacto.correoElectronico'
            />
          </Grid>

          <Grid className={classes.formRow}>
            <CustomTextField
              label='Teléfono fijo'
              register={register}
              field='contacto.telefonoFijo'
            />
            <CustomTextField
              label='Teléfono móvil'
              register={register}
              field='contacto.telefonoMovil'
            />
          </Grid>
        </>
      )}

      <Grid className={classes.buttonsContainer}>
        <Button
          variant='secondary'
          onClick={handleSubmit(onSubmit)}
          className={classes.submitButton}
          disabled={addingProducer}
        >
          {addingProducer ? (
            <CircularProgress disableShrink size={22} />
          ) : (
            'Agregar productor'
          )}
        </Button>
        {!desktop && (
          <Button
              onClick={hideForm}
              variant='text'
              fullWidth
              className={classes.buttonBack}
            >
            Volver
          </Button>
        )}
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '12px',
    paddingBottom: '24px',
    color: theme.palette.primary.almostBlack,
  },
  title: {
    fontSize: '16px',
    marginBottom: '12px',
  },
  formRow: {
    display: 'flex',
    flexWrap: 'nowrap',
    gap: '20px',
  },
  switchFormControl: {
    marginBottom: '14px',
    minWidth: '50%',
    '& .MuiFormControlLabel-label': {
      fontSize: '14px',
      color: '#444',
    },
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems:'center'
  },
  submitButton: {
    width: '50%',
    margin: '12px auto',
  },
}))

const CustomRequiredTextField = ({
  label,
  register,
  field = '',
  errorText = '',
  extraValidation = {},
  placeholder = '',
  ...rest
}) => {
  return (
    <TextField
      variant='outlined'
      label={label}
      sx={{
        marginBottom: '14px',
        '& label': {
          fontSize: '14px',
        },
        '& .MuiInputBase-root': {
          fontSize: '14px',
        },
        '& .MuiFormHelperText-root': {
          color: 'red',
        },
      }}
      fullWidth
      required
      size='small'
      helperText={errorText}
      placeholder={placeholder}
      {...(register ? register(field, {
        required: `${label} es obligatorio`,
        ...extraValidation,
      }) : {})}
      {...rest}
    />
  )
}

const CustomTextField = ({ label, register = () => {}, field = '' }) => {
  return (
    <TextField
      variant='outlined'
      label={label}
      sx={{
        marginBottom: '14px',
        '& label': {
          fontSize: '14px',
        },
        '& .MuiInputBase-root': {
          fontSize: '14px',
        },
      }}
      fullWidth
      size='small'
      {...register(field)}
    />
  )
}

export default AddProducer
