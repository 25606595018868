export const contact = [
  {
    title: 'Sucursales',
    url: '/sucursales',
  },
  {
    title: 'Solicitar Alta de Cliente',
    url: '/registro',
  },
  {
    title: 'Boton de arrepentimiento',
    url: '/devoluciones',
  },
]
