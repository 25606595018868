import React from 'react'
import { Link } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import { Box, Typography, useMediaQuery } from '@mui/material'
import insta from '../../assets/insta.png'
import fb from '../../assets/fb.png'
import youtube from '../../assets/youtube.png'
import logoVillanueva from '../../assets/VNSALogoFondoBlanco.png'
import dataFiscal from '../../assets/dataFiscal.png'
import { useStyles } from './Footer.styles'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'
import { quickAccess } from './FooterLinks/quickAccess'
import { ourBrands } from './FooterLinks/ourBrands'
import { contact } from './FooterLinks/contact'

const instagramURL = 'https://www.instagram.com/villanueva.arg'
const facebookURL = 'https://www.facebook.com/VillaNuevaArg'
const youtubeURL = 'https://www.youtube.com/user/ContactoVNSA'

export default function Footer() {
  const classes = useStyles()
  const desktop = useMediaQuery((theme) => theme.breakpoints.up('sm'))

  return (
    <Grid className={classes.container}>
      {!desktop ? (
        <Box className={classes.innerContainer}>
          <Grid item xs={12}>
            <ul>
              <li className={classes.titleList}>Accesos Rápidos</li>

              {quickAccess.map((item, index) => (
                <Link key={index} style={{ color: 'white' }} to={item?.url}>
                  <li className={classes.itemList}>{item.title}</li>
                </Link>
              ))}
            </ul>
          </Grid>
          <Grid item xs={12}>
            <ul style={{ marginTop: '8px' }}>
              <li className={classes.titleList}>Nuestras Marcas</li>
              {ourBrands.map((item, index) => (
                <a key={index} style={{ color: 'white' }} href={item?.url} target='_blank' rel="noreferrer">
                  <li className={classes.itemList}>{item.title}</li>
                </a>
              ))}
            </ul>
          </Grid>
          <Grid item xs={12}>
            <ul style={{ marginTop: '8px' }}>
              <li className={classes.titleList}>Contacto</li>
              {contact.map((item, index) => (
                <Link key={index} style={{ color: 'white' }} to={item?.url}>
                  <li className={classes.itemList}>{item.title}</li>
                </Link>
              ))}
            </ul>
          </Grid>
          <Grid item xs={12}>
            <p className={classes.titleList}>Seguinos en</p>
            <Grid
              container
              spacing={'18px'}
              direction='row'
              alignItems='center'
            >
              <a href={instagramURL} target='_blank' rel="noreferrer" style={{ margin: '13px 0px 0px 13px' }}>
                <img src={insta} alt='Instagram Villanueva' />
              </a>
              <a href={facebookURL} target='_blank' rel="noreferrer" style={{ margin: '13px 0px 0px 13px' }}>
                <img src={fb} alt='Facebook Villanueva' />
              </a>
              <a href={youtubeURL} target='_blank' rel="noreferrer" style={{ margin: '13px 0px 0px 13px' }}>
                  <img
                    style={{ width: '43px' }}
                    src={youtube}
                    alt='Youtube Villanueva'
                  />
                </a>
            </Grid>
            <img
              style={{ marginTop: '51px', width: '200px' }}
              src={logoVillanueva}
              alt='Logo Villanueva'
            />
            <p className={classes.itemList}>
              Av. Presidente Perón 1775 <br />
              C.P. X5900HXE Villa María, Córdoba - Argentina
            </p>
          </Grid>
          <Grid item xs={12} style={{ marginTop: '18px' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              {/* <span
                // href='tel:+543534527967'
                style={{
                  fontSize: '14px',
                  fontWeight: '700',
                  color: 'white',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <LocalPhoneIcon
                  style={{ marginRight: '13px', width: '18px', height: '18px' }}
                />{' '}
                0353 – 4527967{' '}
              </span> */}
              <span
              // href='tel:+54908105550018'
              style={{
                fontSize: '14px',
                fontWeight: '700',
                color: 'white',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <div>
                <div style={{ marginBottom: '5px'}}>
                  Casa central: 
                </div>
                <div style={{ display: 'flex', alignItems: 'center'}}>
                  <LocalPhoneIcon
                    style={{ marginRight: '13px', width: '18px', height: '18px' }}
                  />{' '}
                  0810 - 555 - 0018
                </div>
              </div>
            </span>
              <img
                src={dataFiscal}
                style={{ marginRight: '10px' }}
                alt='Data Fiscal'
              />
            </Box>
          </Grid>
        </Box>
      ) : (
        <Box className={classes.innerContainer}>
          <Grid item xs={12} sx={{ minWidth: '276px' }}>
            <img
              style={{ marginTop: '51px', width: '200px' }}
              src={logoVillanueva}
              alt='Logo Villanueva'
            />
            <p className={classes.itemList} style={{ marginBottom: '28px' }}>
              Av. Presidente Perón 1775 <br />
              C.P. X5900HXE Villa María, Córdoba - Arg.
            </p>
            {/* <span
              // href='tel:+543534527967'
              style={{
                fontSize: '14px',
                fontWeight: '700',
                color: 'white',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <LocalPhoneIcon
                style={{ marginRight: '13px', width: '18px', height: '18px' }}
              />{' '}
              0353 – 4527967{' '}
            </span> */}
            <span
              // href='tel:+54908105550018'
              style={{
                fontSize: '14px',
                fontWeight: '700',
                color: 'white',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <div>
                <div style={{ marginBottom: '5px'}}>
                  Casa central: 
                </div>
                <div style={{ display: 'flex', alignItems: 'center'}}>
                  <LocalPhoneIcon
                    style={{ marginRight: '13px', width: '18px', height: '18px' }}
                  />{' '}
                  0810 - 555 - 0018
                </div>
              </div>
            </span>
          </Grid>
          <Grid item xs={12} sx={{ marginRight: '20px' }}>
            <ul>
              <li className={classes.titleList}>Accesos Rápidos</li>
              {quickAccess.map((item, index) => (
                <Link key={index} style={{ color: 'white' }} to={item?.url}>
                  <li className={classes.itemList}>{item.title}</li>
                </Link>
              ))}
            </ul>
          </Grid>
          <Grid item xs={12} sx={{ marginRight: '20px' }}>
            <ul style={{ marginTop: '8px' }}>
              <li className={classes.titleList}>Nuestras Marcas</li>
              {ourBrands.map((item, index) => (
                <a target='_blank' key={index} style={{ color: 'white' }} href={item?.url} rel="noreferrer" >
                  <li className={classes.itemList}>{item.title}</li>
                </a>
              ))}
            </ul>
          </Grid>
          <Grid item xs={12}>
            <ul style={{ marginTop: '8px' }}>
              <li className={classes.titleList}>Contacto</li>
              {contact.map((item, index) => (
                <Link key={index} style={{ color: 'white' }} to={item?.url}>
                  <li className={classes.itemList}>{item.title}</li>
                </Link>
              ))}
            </ul>
            <Box>
              <p className={classes.titleList} style={{ marginTop: '70px' }}>
                Seguinos en
              </p>
              <Grid
                container
                spacing={'13px'}
                direction='row'
                alignItems='center'
              >
                <a href={instagramURL} target='_blank' rel="noreferrer" style={{ margin: '13px 0px 0px 13px' }}>
                  <img
                    style={{ width: '30px' }}
                    src={insta}
                    alt='Instagram Villanueva'
                  />
                </a>
                <a href={facebookURL} target='_blank' rel="noreferrer" style={{ margin: '13px 0px 0px 13px' }}>
                  <img
                    style={{ width: '30px' }}
                    src={fb}
                    alt='Facebook Villanueva'
                  />
                </a>
                <a href={youtubeURL} target='_blank' rel="noreferrer" style={{ margin: '13px 0px 0px 13px' }}>
                  <img
                    style={{ width: '30px' }}
                    src={youtube}
                    alt='Youtube Villanueva'
                  />
                </a>
              </Grid>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <img
                src={dataFiscal}
                alt='Data Fiscal'
                style={{
                  marginTop: '26px',
                  width: '25px',
                }}
              />
            </Box>
          </Grid>
        </Box>
      )}

      <Typography className={classes.copyright}>
        Todos los derechos reservados © {new Date().getFullYear()} Villanueva
        S.A // The Fuzzy Fish
      </Typography>
    </Grid>
  )
}
