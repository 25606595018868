import { ApiConnection } from 'config'


const getProducer = async (id) => {
  const { data } = await ApiConnection.get(
    `producer/id`,
    {
      params: { id },
    }
  )
  return data.response
}

export default getProducer
