import { useRef, useEffect, useState } from 'react'
import InputBase from '@mui/material/InputBase'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import { useNavigate, useLocation } from 'react-router-dom'
import { searchProducts } from './services/searchProducts'
import LoadingSpinner from 'components/LoadingSpinner'
import { requestQueue } from 'utils/requestsQueue'
import { debounce } from 'utils/debounce'

function SearchBoxAlt() {
  const searchInput = useRef(null)
  const resultsBoxRef = useRef(null)
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [showResults, setShowResults] = useState(false)
  const [results, setResults] = useState([])
  const [loadingResults, setLoadingResults] = useState(false)

  const submitHandler = (e) => {
    e.preventDefault()
    setResults([])
    if (!searchInput.current.value) return

    navigate(`/catalogo?nameOrSku=${searchInput.current.value}`)
  }

  const handleQueryChange = () => {
    const query = searchInput.current?.value?.trim()
    if (!query || query.length < 3) {
      setLoadingResults(false)
      return setResults([])
    }
    requestQueue.addToQueue(async () => {
      setLoadingResults(true)
      setShowResults(true)

      try {
        const res = await searchProducts(query)
        setResults(res)
      } catch (error) {
        console.error(error)
      } finally {
        setLoadingResults(false)
      }
    })
  }

  const handleOnClick = (product) => (e) => {
    e.preventDefault()
    setResults([])
    navigate(`/producto/${product.sku}`)
  }

  const handleBlur = (e) => {
    if (
      resultsBoxRef.current &&
      resultsBoxRef.current.contains(e.relatedTarget)
    ) {
      return
    }

    setTimeout(() => setShowResults(false), 300)
  }

  useEffect(() => {
    if (!pathname.match(/catalogue/)) searchInput.current.value = ''
  }, [pathname])

  return (
    <Grid sx={{ width: '50%', maxWidth: '497px', position: 'relative' }}>
      <Paper
        component='form'
        onSubmit={submitHandler}
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          borderRadius: '8px',
          boxShadow: 'none',
          backgroundColor: theme.palette.primary.almostWhite,
          height: '48px',
          margin: '0 5px',
          boxSizing: 'border-box',
        })}
      >
        <IconButton type='submit' sx={{ p: '14px' }} aria-label='search'>
          <SearchIcon sx={{ fontSize: '16px' }} />
        </IconButton>
        <InputBase
          sx={{ flex: 1, fontSize: '14px' }}
          placeholder='Buscar productos'
          inputProps={{
            ref: searchInput,
            'aria-label': 'Buscar productos',
            style: { border: '0px' },
          }}
          onBlur={handleBlur}
          onClick={() => setShowResults(true)}
          onChange={(e) => {
            setLoadingResults(true)
            debounce(handleQueryChange)(e)
          }}
          onKeyPress={(e) => e.key === 'Enter' && setShowResults(false)}
        />
      </Paper>
      {showResults && (
        <Grid
          ref={resultsBoxRef}
          sx={{
            position: 'absolute',
            backgroundColor: 'white',
            boxShadow: '3px 3px 15px -3px rgba(0,0,0,0.25)',
            width: '98%',
            marginLeft: '5px',
            maxHeight: '300px',
            overflow: 'auto',
            color: '#444',
            zIndex: 900,
          }}
        >
          {loadingResults ? (
            <LoadingSpinner />
          ) : (
            searchInput?.current?.value &&
            results.filter(Boolean).map((product) => (
              <Button
                key={product.id}
                variant='secondary'
                sx={{
                  fontSize: '14px',
                  backgroundColor: 'white',
                  display: 'block',
                  color: '#444',
                  padding: '12px 8px',
                  textAlign: 'left',
                  borderRadius: 0,
                }}
                onClick={handleOnClick(product)}
              >
                {product.nombreEC || product.nombre}
              </Button>
            ))
          )}
        </Grid>
      )}
    </Grid>
  )
}

export default SearchBoxAlt
