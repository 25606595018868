import {
  USER_REGISTER_FAIL,
  USER_SIGNIN_FAIL,
  USER_SIGNIN_REQUEST,
  USER_SIGNIN_SUCCESS,
  USER_SIGNOUT,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAIL,
  USER_MODAL_LOGIN_STATE,
  USER_CURRENT_BRANCH,
  UPDATE_BRANCH_LIST,
  USER_RESET_PASSWORD_MODAL_STATE,
  USER_PROMOTIONS_SUCCESS,
  UPDATE_BALANCE,
  UPDATE_USER_OPENED_CART,
} from './constantsUser'


// TODO: implementar redux persist para este reducer
const initialState = {
  signedIn: localStorage.getItem('signedIn')
    ? JSON.parse(localStorage.getItem('signedIn'))
    : false,
  loading: false,
  showModalLogin: false,
  showModalResetPassword: false,
  openSelectClientModal: false,
  openResetPasswordModal: false,
  userInfo: localStorage.getItem('userInfo')
    ? JSON.parse(localStorage.getItem('userInfo'))
    : {},
  currentBranch: (localStorage.getItem('currentBranch') !== null && localStorage.getItem('currentBranch') !== 'undefined')
    ? JSON.parse(localStorage.getItem('currentBranch'))
    : {},
}

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_REGISTER_FAIL:
      return { ...state, loading: false, error: action.payload }
    case USER_REGISTER_REQUEST:
      return { ...state, loading: true }
    case USER_REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        userInfo: action.payload,
        signedIn: true,
      }
    case USER_SIGNIN_REQUEST:
      return { ...state, loading: true }
    case USER_SIGNIN_SUCCESS:
      return {
        ...state,
        loading: false,
        userInfo: action.payload,
        signedIn: true,
      }
    case USER_SIGNIN_FAIL:
      return { ...state, loading: false }
    case USER_SIGNOUT:
      return { ...state, signedIn: false, userInfo: {} }
    case USER_MODAL_LOGIN_STATE:
      return { ...state, showModalLogin: action.payload }
    case USER_RESET_PASSWORD_MODAL_STATE:
      return { ...state, showModalResetPassword: action.payload }
    case USER_CURRENT_BRANCH:
      return { ...state, currentBranch: action.payload }
    case UPDATE_BRANCH_LIST:
      return {
        ...state,
        userInfo: { ...state.userInfo, sucursales: action.payload },
      }
    case UPDATE_BALANCE: {
      return {
        ...state, 
        userInfo: {
          ...state.userInfo, 
          saldoDisponible: action.payload.saldoDisponible,
          deuda: action.payload.deuda,
          isEnabled: action.payload.isEnabled
        }
      }
    }
    case 'USER_SELECT_CLIENT_MODAL':
      return {
        ...state,
        openSelectClientModal: action.payload,
      }
    case 'USER_RESET_PASSWORD_MODAL':
      return {
        ...state,
        openResetPasswordModal: action.payload,
      }
    case 'USER_SUBTRACT_BALANCE':
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          saldoDisponible: state.userInfo.saldoDisponible - action.payload,
        },
      }
    case USER_PROMOTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        userInfo: { ...state.userInfo, ...action.payload },
      }
    case USER_DETAILS_REQUEST:
      return { ...state, loading: true }
    case USER_DETAILS_SUCCESS:
      return { ...state, loading: false, userInfo: {...state.userInfo, ...action.payload} }
    case USER_DETAILS_FAIL:
      return { ...state, loading: false }
    case UPDATE_USER_OPENED_CART:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          openedCart: action.payload,
        },
      }
    default:
      return state
  }
}