import React from 'react'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'

const buttonStyles = {
  color: '#444444',
  borderColor: '#D4D4D4',
  fontFamily: 'Yantramanav',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '13px',
  borderRadius: '8px',
  padding: '4px',
  margin: '5px',
  '&: hover': {
    backgroundColor: '#E8F5EA',
    color: '#4AA259',
  },
}

const SizeComponent = ({ options, onClick, selected }) => {
  return (
    <Stack direction='row' sx={{ flexWrap: 'wrap' }}>
      {options.map((option, index) => (
        <Button
          key={index}
          variant='outlined'
          sx={{
            ...buttonStyles,
            backgroundColor: selected === option.codigo ? '#E8F5EA' : '#FFFFFF',
            color: selected === option.codigo ? '#4AA259' : '#444444',
          }}
          onClick={() => onClick(option)}
        >
          {option.nombre}
        </Button>
      ))}
    </Stack>
  )
}

export default SizeComponent
