import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  image: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '16px',
    paddingBottom: '8px',
    [theme.breakpoints.up('sm')]: {
      padding: 0,
    },
  },
  mainGrid: {
    marginTop: '50px',
    marginBottom: '-40px',
    [theme.breakpoints.up('sm')]: {
      marginBottom: '0px',
    },
  },
  categoryIcon: {
    height: '36px',
    minWidth: '36px',
    [theme.breakpoints.up('sm')]: {
      height: '43px',
      minWidth: '32px',
    },
  },
  categoryName: {
    wordWrap: 'initial',
    fontSize: '10px',
    fontWeight: 600,
    lineHeight: '14px',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      fontSize: '12px',
      margin: 0,
      marginTop: '18.67px',
    },
  },
  title: {
    fontWeight: 600,
    fontStyle: 'normal',
    fontSize: '18px',
    lineHeight: '20px',
    color: theme.palette.primary.almostBlack,
    [theme.breakpoints.up('sm')]: {
      fontSize: '20px',
    },
  },
  stackGrid: {
    overflowX: 'scroll',
    paddingBottom: '70px',
    paddingLeft: '14px',
    '&::-webkit-scrollbar': { width: 0 },
    [theme.breakpoints.up('sm')]: {
      padding: '0px',
      paddingBottom: '70px',
      marginBottom: '-70px',
    },
  },
  card: {
    minWidth: '71px',
    maxWidth: '71px',
    height: '110px',
    borderRadius: '8px',
    display: 'flex',
    backgroundColor: '#FFFFFF',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: '5px',
    boxShadow:
      '0px 1.93796px 3.87593px rgba(14, 31, 53, 0.04), 0px 5.81389px 11.6278px rgba(14, 31, 53, 0.04), 0px 11.6278px 19.3796px rgba(14, 31, 53, 0.05)',

    '&:hover': {
      transition: '0.2s',
      boxShadow: '2px 2px 8px 5px rgba(0,0,0,0.08)',
    },
    [theme.breakpoints.up('sm')]: {
      marginRight: '20px !important',
      marginLeft: '0px !important',
      minWidth: '86px',
      minHeight: '135px',
      padding: '20px 9px',
      boxSizing: 'border-box',
    },
  },

  catMasPopulares: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '22px',
    marginLeft: '14px',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-start',
      marginBottom: '24px',
      marginLeft: '5px',
    },
  },
  catMasPopularesLink: {
    marginRight: '10px',
    fontSize: '12px',
    color: theme.palette.primary.lightBlue,
    [theme.breakpoints.up('sm')]: {
      fontSize: '14px',
      marginLeft: '20px',
    },
  },
}))
