import React from 'react'

import { Grid, Skeleton } from '@mui/material'
import { usePageContent } from 'pages/PagePreview/usePageContent'
import { useStyles } from './TermsPage.styles'

const TermsPage = () => {
  const classes = useStyles()

  const { content, loading } = usePageContent('terms')

  return (
    <Grid className={classes.container}>
      <Grid item xs={12} className={classes.mainGrid}>
        {content && (
          <>
            {loading ? (
              <Skeleton
                variant='rectangular'
                width='100%'
                height={500}
                sx={{
                  borderRadius: '50px',
                  margin: 'auto',
                  width: '80%',
                }}
              />
            ) : (
              <div
                dangerouslySetInnerHTML={{ __html: content.beforeCarousel }}
              />
            )}
          </>
        )}
      </Grid>
    </Grid>
  )
}

export default TermsPage
