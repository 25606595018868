export const ourBrands = [
  // {
  //   title: 'VILLANUEVA Ganadera',
  //   url: '/nosotros',
  // },
  {
    title: 'VILLANUEVA Tech',
    url: 'https://villanuevatech.ar',
  },
  {
    title: 'Punto Agro',
    url: 'https://puntoagro.com.ar/empresa',
  },
  {
    title: 'Allflex',
    url: 'https://allflex.villanueva.com.ar',
  },
  {
    title: 'Máxima',
    url: 'https://maxima.villanueva.com.ar',
  },
  {
    title: 'Arriero',
    url: 'https://arriero.villanueva.com.ar',
  },
  {
    title: 'Pickup',
    url: 'https://pickup.villanueva.com.ar',
  },
  {
    title: 'Lider',
    url: 'https://lider.villanueva.com.ar',
  },
]
