import { normalizeUrlString } from "./formatString"
import store from "store"

export const getCurrentCategory = () => {
  const breadcrumb = JSON.parse(localStorage.getItem('breadcumbs')) || []

  const temp = [...breadcrumb]
    .filter((it) => it.type === 'categoria')
    .sort((a,b) => a.nivel - b.nivel)

  return temp[temp.length - 1]
}

export const getChildCategories = (categories = [], parentCategory) => {
  if(parentCategory?.childs?.length > 0) 
    return parentCategory.childs

  const childCategoriesIds = categories?.find((c) => c.id === parentCategory?.id)?.child || []
  return categories
    ?.filter((c) => childCategoriesIds.includes(c.id))
    .reduce((prev, curr) => 
      !(prev.find((it) => it.id === curr.id)) 
        ? [...prev, curr]
        : prev
    , [])
}

export const buildCategoryTree = (currentCategory) => {
  const categories = store.getState().app.allCategories
  
  const item = categories.find(
    (it) => [it.codigo, it.code, it.value, it.label, it.nombre].includes(currentCategory)
  )

  if(item?.nivel === 2) return [item]

  const parent = categories.find((cat) => cat.child?.includes(item.id))

  if(item?.nivel === 3) {
    return [parent, item].filter(Boolean)
  }

  const root = categories.find((cat) => cat.child?.includes(parent.id))

  return [root, parent, item].filter(Boolean)
}

export const buildBreadcumbs = (currentCategory) => {
  return buildCategoryTree(currentCategory)
}

export const buildUrl = (currentCategoryCode) => {
  const tree = buildCategoryTree(currentCategoryCode)

  return tree
    .sort((a,b) => a.nivel - b.nivel)
    .map((cat) => normalizeUrlString(cat.nombre))
    .join('/')
}
