import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  container: {},
  table: {
    padding: '10px',
    backgroundColor: 'white',
    boxShadow: '0px 2px 14px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    marginBottom: '72px',
  },
  tableHeadersRow: {
    paddingBottom: '23px',
    maxWidth: '963px',
  },
  accordionContainer: {
    boxShadow: 'none',
    borderTop: `1px solid ${theme.palette.primary.borderGrey}`,
    '& .MuiAccordionSummary-root': {
      padding: 0,
    },
    '& .MuiAccordionDetails-root': {
      padding: '30px 22px',
    },
  },
  borderTop: {
    borderTop: `1px solid ${theme.palette.primary.borderGrey}`,
  },
  detailsHeadersRow: {
    paddingBottom: '8px',
    borderBottom: `1px solid ${theme.palette.primary.borderGrey}`,
    marginBottom: '14px',
  },
  detailRow: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '9px',
  },
}))

export const tableColumns = ['20%', '29%', '30%', '21%']

export const columnWidths = {
  cantidad: '6%',
  producto: '23%',
  transporte: '14%',
  despachado: '10%',
  precioDeLista: '12%',
  estado: '8%',
  detalle: '4%',
  descuento: '9%',
  precio: '13%',
}

export const getStatusColor = (status) => {
  switch (status) {
    case 'Cancelado':
      return '#EE7054'
    case 'Pendiente':
      return '#EDB039'
    case 'Despachado':
      return '#558B57'
  }
}
