import React from 'react'
import Avatar from '@mui/material/Avatar'

function stringToColor(string) {
  let hash = 0
  let i
  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }
  let color = '#'
  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }
  /* eslint-enable no-bitwise */

  return color
}

function stringAvatar(name) {
  const splitedName = name.split(' ')
  const firstName = splitedName.shift()?.[0]
  const lastName = splitedName.pop()?.[0]
  return `${firstName}${lastName}`
}

const AvatarComponent = ({
  userName = 'Tim Neutkens',
  imgURL,
  size = 84,
  stylesOverride = {},
}) => {
  return (
    <Avatar
      sx={{
        bgcolor: stringToColor(userName),
        width: size,
        height: size,
        ...stylesOverride,
      }}
      alt={userName}
      src={imgURL}
    >
      {!imgURL ? stringAvatar(userName) : null}
    </Avatar>
  )
}

export default AvatarComponent
