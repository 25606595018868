import React from 'react'
import Box from '@mui/material/Box'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

const buttonStyles = {
  borderRadius: '50%',
  backgroundColor: 'white',
  opacity: 0.35,
  color: 'black',
  boxSizing: 'border-box',
  width: 50,
  height: 50,
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&:hover': {
    transition: '0.3s',
    opacity: 0.5,
  },
}

const leftStyles = {
  ...buttonStyles,
  zIndex: 900,
  left: '26px',
}

const rightStyles = {
  ...buttonStyles,
  right: '26px',
}

function CarrouselControl({ direction, onClick, nextItem, previousItem }) {
  return (
    <>
      {previousItem && direction === 'left' ? (
        <Box onClick={onClick} sx={leftStyles}>
          <ChevronLeftIcon color='inherit' />
        </Box>
      ) : nextItem && direction === 'right' ? (
        <Box onClick={onClick} sx={rightStyles}>
          <ChevronRightIcon color='inherit' />
        </Box>
      ) : (
        <></>
      )}
    </>
  )
}

export default CarrouselControl
