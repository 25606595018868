import { ApiConnection } from 'config'

export const plusItem = async (product, order) => {
  try {
    const { data } = await ApiConnection.post(
      `cart`,
      {
        ...order,
        [product.tipo ?? 'product']: {
          ...product,
          cantidad: product.cantidad + 1,
        },
      }
    )
    return { ...data }
  } catch (error) {
    console.log(error)
  }
}
