import React from 'react'
import { IconButton } from '@mui/material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'

function LeftButton({ disabled, sx, size, color, handleFunc }) {
  return (
    <IconButton
      onClick={handleFunc}
      disabled={disabled}
      sx={sx}
      size={size}
      color={color}
    >
      <ChevronLeftIcon />
    </IconButton>
  )
}

export default LeftButton
