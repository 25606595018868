import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  card: {
    position: 'relative',
    borderRadius: '25px',
    overflow: 'hidden',
  },
  cardMedia: {
    filter: 'brightness(40%)',
    height: 0,
    paddingTop: '56.25%',
  },
  cardContent: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.common.white,
    textShadow: '2px 2px 4px rgba(0,0,0,0.6)',
    padding: 0,
  },
  typography: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    textAlign: 'center',
    padding: theme.spacing(2),
  },
  iconButton: {
    position: 'absolute',
    bottom: theme.spacing(3),
    right: theme.spacing(1),
    color: theme.palette.common.white,
  },
}))
