import { SEARCH_FAIL, SEARCH_SUCCESS, SEARCH_REQUEST, SEARCH_QUERY } from './constantsSearch'
import { searchQueryService } from './services/searchQuery'
import { setSnackbar } from '../snackbarStore'
import { getUserPromotionsAndOffers } from 'stores/userStore/actionsUser'
import { requestQueue } from 'utils/requestsQueue'

const filterDictionary = {
  'Ordenar Por': 'orderBy',
  Categorías: 'linea',
  'Tipo de Caravanas': 'subLinea',
  'Formato de Caravanas': 'formatoCaravanas',
  'Año de Resolución': 'anioResolucion',
  Sucursal: 'sucursal',
  Precio: 'precio',
  Talles: 'talles',
  Sku: 'sku',
}

let lastQuery = ''
let lastQueryTimeout;

const removeFilterOfBreadcumb = (breadcumbs, filterToRemove) =>
  breadcumbs.filter((b) => ![b.nombre, b.title].includes(filterToRemove))
const sortBreadcumbByLevel = (breadcumbs) =>
  [...breadcumbs].sort((a, b) => a.nivel - b.nivel)

export const searchQuery = (queryObj, cardCode, page = 1, items = 21) => async (
  dispatch,
  getState
) => {
  const { codigoUsuario, user } = getState()?.userAuth?.userInfo
  const additionalFilters = getState()?.searchResults?.additionalFilters
  dispatch(getUserPromotionsAndOffers(user))

  const formatedFilters = Object.entries(queryObj?.query).reduce(
    (accu, [key, param]) => {
      const value = typeof param === 'string' ? param : param?.value
      if (value) {
        return {
          ...accu,
          [filterDictionary[key] ?? key]: value,
        }
      } else return accu
    },
    {}
  )
  const formatedFrontFilters = Object.entries(queryObj.query).reduce(
    (accu, [key, param]) => {
      const value = typeof param === 'string' ? param : param?.value
      const label = typeof param === 'string' ? param : param?.label
      if (value) {
        return {
          ...accu,
          [filterDictionary[key] ?? key]: label,
        }
      } else return accu
    },
    {}
  )
  const categoriaNombre = formatedFilters.categoriaNombre
  delete formatedFilters.categoriaNombre
  delete formatedFrontFilters.categoriaNombre
  // const browserSearchParams = window.location.search;

  // if(browserSearchParams.includes('ofertas')) {
  //   formatedFilters.categoria = '300'
  //   formatedFilters.isShowcharacteristics = true
  // }

  const queryObject = {
    ...queryObj?.currentFormatedFilters,
    ...formatedFilters,
    ...(additionalFilters || {}),
    page,
    items,
  }

  if (queryObject.orderBy === 'Recomendados') delete queryObject.orderBy
  const query = new URLSearchParams(queryObject).toString()

  if(lastQuery === query) return;
  dispatch({ type: SEARCH_REQUEST })
  lastQuery = query

  const search = async () => {
    try {
      const res = await searchQueryService(query, cardCode || codigoUsuario)
      const { response } = res.data
      if (categoriaNombre) {
        // delete formatedFilters.categoria
        formatedFrontFilters.categoria = categoriaNombre
      }
      dispatch({
        type: SEARCH_SUCCESS,
        payload: {
          response,
          query,
          formatedFilters,
          formatedFrontFilters,
        },
      })

      if(lastQueryTimeout === undefined)
        lastQueryTimeout = setTimeout(() => { 
          lastQuery = '' 
          if(lastQueryTimeout !== undefined) 
            clearTimeout(lastQueryTimeout)
            lastQueryTimeout = undefined
        }, 3000) // borro la ultima query hecha despues de 3 segundos
    } catch (e) {
      dispatch({ type: SEARCH_FAIL })
      dispatch(setSnackbar(true, 'error', e.message))
      console.error(e.message)
    }
  }
  requestQueue.addToQueue(() => search())
}

const omitMarketingParams = (queryStr) => {
  queryStr = queryStr.replace(/%2F%3Futm_/g, '&utm_').replace(/%3D/g, '=')
  queryStr = queryStr.replace(/%2F%3Ffbclid/g, '&fbclid').replace(/%3D/g, '=')
  queryStr = queryStr.replace(/%2F%3Fembtrk/g, '&embtrk').replace(/%3D/g, '=')
  const urlParams = new URLSearchParams(queryStr);
  const params = Object.fromEntries(urlParams); 
  Object.keys(params).forEach((key) => {
    if(key.includes('utm_'))
      urlParams.delete(key)
    if(key.includes('fbclid'))
      urlParams.delete(key)
    if(key.includes('embtrk'))
      urlParams.delete(key)
  })
  return urlParams.toString()
}

export const handleSearchQuery = (queryObj, queryStr) => (dispatch, getState) => {
  dispatch({ type: SEARCH_REQUEST })
  const { codigoUsuario } = getState()?.userAuth?.userInfo

  async function search(){
    try {
      let query = `${new URLSearchParams(queryObj).toString()}`

      if(queryStr) query += `&${omitMarketingParams(queryStr)}`

      if(!query.includes('items')) query += '&items=21'
    
      if(!query.includes('page')) query += '&page=1'

      const { data } = await searchQueryService(
        query,
        codigoUsuario,
      )
      dispatch({
        type: SEARCH_QUERY,
        payload: {
          response: data.response,
        },
      })
    } catch (error) {
      dispatch({ type: SEARCH_FAIL })
      dispatch(setSnackbar(true, 'error', error.message))
      console.error(error.message)
    } finally {
      // dispatch({ type: SEARCH_REQUEST })
    }
  }

  requestQueue.addToQueue(() => search())
}

export const handleDeleteFilter = (filterName, filterValue) => (dispatch) => {
  let breadcumbs = JSON.parse(localStorage.getItem('breadcumbs'));

  breadcumbs = removeFilterOfBreadcumb(breadcumbs, filterValue)
  breadcumbs = sortBreadcumbByLevel(breadcumbs)

  localStorage.setItem('breadcumbs', JSON.stringify(breadcumbs))

  const currentLevel = breadcumbs[breadcumbs.length - 1]?.nivel || 0
  localStorage.setItem('currentLevel', currentLevel)

  dispatch({ type: 'DELETE_FILTER', payload: filterName })
}
export const clearAllFilters = () => async (dispatch) => {
  dispatch({ type: 'CLEAR_ALL_FILTERS' })
}
