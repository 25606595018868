import React, { useState } from 'react'
import { Box, Grid, useMediaQuery } from '@mui/material'
import { useStyles } from './CuentaCorrientePaymentPage.styles'
import PaymentMethodButton from './PaymentMethodButton'
import logoMP from '../../assets/logoMP.png'
import TitleWithBackArrow from 'components/TitleWithBackArrow'
import WireTransferPage from './PaymentMethods/WireTransfer/WireTransferPage'
import ChequePage from './PaymentMethods/Cheque/ChequePage'
import payWithMP from 'pages/PaymentMethodPage/services/payWithMP'
import LoadingSpinner from 'components/LoadingSpinner'
import { useDispatch, useSelector } from 'react-redux'
import { setSnackbar } from 'stores/snackbarStore'
import { useSearchParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'

function TabPanel(props) {
  const { children, value, index } = props

  return (
    <Box hidden={value !== index}>
      {value === index && <Box>{children}</Box>}
    </Box>
  )
}

function CuentaCorrientePaymentPage() {
  const classes = useStyles()
  const desktop = useMediaQuery((theme) => theme.breakpoints.up('sm'))
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const saldo = parseFloat(searchParams.get('saldo'))
  const userInfo = useSelector((store) => store.userAuth.userInfo)
  const cart = useSelector((store) => store.cart)

  const [panel, setPanel] = useState(2)
  const [loading, setLoading] = useState(false)

  const goToPayWithMP = async () => {
    setLoading(true)
    try {
      const res = await payWithMP(
        saldo,
        userInfo.codigoUsuario,
        cart.idOrder,
        true
      )
      if (res) {
        window.location.assign(res)
      } else {
        dispatch(
          setSnackbar(true, 'error', 'Error de conexión. Intente nuevamente.')
        )
      }
    } catch (error) {
      dispatch(
        setSnackbar(true, 'error', 'Error de conexión. Intente nuevamente.')
      )
    }
    setLoading(false)
  }

  const goBack = () => setPanel(2)
  return (
    <>
      <Helmet>
        <title>Abonar saldo - Cuenta corriente | Villanueva</title>
        <meta
          name='description'
          content='Abonar saldo de la cuenta corriente'
        />
        <meta
          name='keywords'
          content='abonar, saldo, cuenta, corriente, Villanueva'
        />
      </Helmet>
      <Box className={classes.container}>
        {(panel === 2 || desktop) && (
          <TitleWithBackArrow customStylesContainer={{ marginBottom: '30px' }}>
            Medios de pago
          </TitleWithBackArrow>
        )}
        <Grid container sx={{ flexWrap: { md: 'nowrap' } }}>
          <Box className={classes.paymentMethodsContainer}>
            <TabPanel value={panel} index={desktop ? panel : 2}>
              <PaymentMethodButton
                selected={panel === 0}
                onClick={() => setPanel(0)}
              >
                {'Transferencia bancaria'}
              </PaymentMethodButton>
              <PaymentMethodButton
                icon={logoMP}
                iconAlt='Logo Mercadopago'
                onClick={goToPayWithMP}
              >
                {'Tarjeta de crédito o debito'}
              </PaymentMethodButton>
            </TabPanel>
            {loading && <LoadingSpinner />}
          </Box>
          <Box className={classes.paymentDetails}>
            <TabPanel value={panel} index={0}>
              <WireTransferPage onClick={goBack} desktop={desktop} />
            </TabPanel>
            <TabPanel value={panel} index={1}>
              <ChequePage onClick={goBack} desktop={desktop} />
            </TabPanel>
          </Box>
        </Grid>
      </Box>
    </>
  )
}

export default CuentaCorrientePaymentPage
