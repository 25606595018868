import { ApiConnection } from 'config'


export const searchQueryService = async (query, cardCode) => {
  const userId = localStorage.getItem('sellerId')

  try {
    const data = ApiConnection.get(
      `product/finder?${query}&cardCode=${cardCode}&userId=${userId}`
    )
    return data
  } catch (e) {
    throw new Error('Error executing search')
  }
}
