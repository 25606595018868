import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import imageSuccess from '../../assets/imageSuccess.png'
import error from '../../assets/error.png'
import { Box, Grid, Typography } from '@mui/material'
import { useStyles } from './PlaceOrderPage.styles'
import BottomActions from '../../components/BottomActions'
import { Helmet } from 'react-helmet'

const PlaceOrderPage = () => {
  const navigate = useNavigate()
  const classes = useStyles()
  const cart = useSelector((state) => state.cart)
  const [searchParams] = useSearchParams()
  const status = JSON.parse(searchParams.get('status'))
  const idOrder = searchParams.get('idOrder')

  const goToHome = () => {
    navigate('/')
  }

  return (
    <>
      <Helmet>
        <title>Pago exitoso | Villanueva</title>
        <meta name='description' content='Pago exitoso' />
        <meta name='keywords' content='pago, exitoso, Villanueva' />
      </Helmet>
      <Box className={classes.grid}>
        {status ? (
          <Grid container spacing={2} justifyContent='center' align='center'>
            <img
              src={imageSuccess}
              alt='Exitoso'
              style={{
                borderRadius: 50,
                marginBottom: '35px',
                boxSizing: 'border-box',
              }}
            />
            <Typography className={classes.title}>
              Tu pedido se <br />
              ha realizado con exito!
              <br />
              {idOrder && `Nro. pedido: ${idOrder}`}
            </Typography>
            <Typography paragraph className={classes.subtitleInfo}>
              En pocos minutos vas a recibir la factura en tu correo electrónico
            </Typography>
          </Grid>
        ) : (
          <Grid container spacing={2} justifyContent='center' align='center'>
            <Grid item style={{ marginBottom: '25px' }}>
              <img src={error} alt='Error' />
            </Grid>
            <Grid item>
              <Typography className={classes.titleError}>Error</Typography>
              <Typography paragraph className={classes.subtitleInfo}>
                Desafortunadamente tuvimos <br /> un problema para procesar tu
                pago. <br />
                Vuelve a intentarlo más tarde.
              </Typography>
            </Grid>
          </Grid>
        )}
        <Grid
          container
          spacing={1}
          sx={(theme) => ({
            marginTop: '150px',
            paddingBottom: '50px',
            width: '100%',
            marginLeft: 0,
            [theme.breakpoints.up('sm')]: {
              marginTop: '15px',
              paddingBottom: '100px',
            },
          })}
        >
          {status ? (
            <BottomActions
              cart={cart}
              firstAction={goToHome}
              secondAction={() => {
                navigate('/cuenta-corriente')
              }}
              firstText={'Volver al inicio'}
              secondText={'Ver mi cuenta corriente'}
            />
          ) : (
            <BottomActions
              cart={cart}
              firstAction={goToHome}
              firstText={' Volver al inicio'}
            />
          )}
        </Grid>
      </Box>
    </>
  )
}

export default PlaceOrderPage
