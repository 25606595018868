import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  boxMain: {
    backgroundColor: theme.palette.primary.white,
    [theme.breakpoints.up('sm')]: {
      backgroundColor: 'transparent',
      maxWidth: '1040px',
      margin: 'auto',
      marginTop: '45px',
      marginBottom: '200px',
    },
  },
  avatarBox: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: '320px',
      backgroundColor: theme.palette.primary.white,
      padding: '31px 50px',
      boxSizing: 'border-box',
      borderRadius: '8px',
      boxShadow: '0px 2px 14px rgba(0, 0, 0, 0.1)',
    },
  },
  avatar: {
    borderRadius: '50%',
    border: '2px solid #4A8850',
    padding: '2px',
    marginTop: '30px',
    backgroundClip: 'content-box',
    backgroundColor: theme.palette.primary.grey,
    width: '114px',
    height: '114px',
    margin: '8px',
    '& img': {
      borderRadius: '50%',
    },

    [theme.breakpoints.up('sm')]: {
      width: '69px',
      height: '69px',
      margin: 0,
      marginBottom: '14px',
    },
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "bold",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  modalContainer: {
    overflowY: "auto",
    maxHeight: 600,
    width: "90vw",
    maxWidth: "700px",
    display: "flex",
    justifyContent: "center",
  },

  userInfoBox: {
    // margin: '30px 14px',
    // boxShadow: '0px 2px 14px rgba(0, 0, 0, 0.1)',
    // borderRadius: '8px',
    padding: '0px 17px',

    [theme.breakpoints.up('sm')]: {
      backgroundColor: theme.palette.primary.white,
      padding: '0 30px',
      maxWidth: '700px',
      margin: '0px',
    },
  },
  userInfoItem: {
    alignItems: 'center',
    '& p': {
      fontSize: '11.7px',
      lineHeight: '15px',
    },
  },
  userInfoBold: {
    margin: '24px 0px',
    fontWeight: '500',
    color: '#444444',
  },
  userInfoNormal: {
    fontWeight: '300',
    color: '#999999',
  },
  border: {
    borderBottom: '0.6px solid #BBBBBB',
  },

  boxPrecio: {
    margin: '14px 14px',

    [theme.breakpoints.up('sm')]: {
      maxWidth: '700px',
      backgroundColor: theme.palette.primary.white,
      boxShadow: '0px 2px 14px rgba(0, 0, 0, 0.1)',
      borderRadius: '8px',
      padding: '5px 30px',
      margin: '0px',
      marginBottom: '18px',
    },
  },

  card: {
    padding: '15px',
    marginBottom: '30px',
    borderRadius: 8,
    boxShadow: '0px 2px 14px rgba(0, 0, 0, 0.1)',
  },
  text: {
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '16.94px',
    paddingBottom: '5px',
    color: theme.palette.primary.almostBlack,
    [theme.breakpoints.up('sm')]: {
      padding: '0px',
      marginBottom: '8px',
    },
  },
  textEmail: {
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '16.94px',
    color: theme.palette.primary.grey,
  },
  textDescription: {
    fontWeight: '300',
    fontSize: '14px',
    lineHeight: '16.94px',
    color: '#999999',
  },
  textColor: {
    fontWeight: '700',
    fontSize: '14px',
    lineHeight: '17px',
    color: theme.palette.primary.typographyGreen,
  },
  textMid: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '17px',
    color: theme.palette.primary.almostBlack,
  },
  borderText: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '17px',
    color: '#444444',
    paddingTop: '20px',
    border: '0.6px',
    solid: '#BBBBBB',
  },

  borderMargin: {
    borderBottom: '0.6px solid #BBBBBB',
    paddingTop: '20px',
  },

  signOutBox: {
    paddingTop: '41px',
    paddingBottom: '36px',
    display: 'flex',
    justifyContent: 'center',

    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-end',
      paddingTop: '26px',
      paddingBottom: '66px',
    },
  },

  textCloseSession: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '15px',
    color: theme.palette.primary.typographyRed,
    cursor: 'pointer',
    userSelect: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },

    [theme.breakpoints.up('sm')]: {
      marginRight: '27px',
    },
  },
}))
