import { createStore, compose, applyMiddleware, combineReducers } from 'redux'
import thunk from 'redux-thunk'
import { cartReducer } from './stores/cartStore/reducerCart'
import {
  productDetailsReducer,
  productListReducer,
} from './stores/productStore/reducerProduct'
import { authReducer } from 'stores/userStore/reducerUser'
import { searchReducer } from 'stores/searchStore/reducerSearch'
import snackbarReducers from 'stores/snackbarStore'
import { appReducer } from 'stores/appStore/reducerApp'
import { composeWithDevTools } from 'redux-devtools-extension'

const reducer = combineReducers({
  productList: productListReducer,
  productDetails: productDetailsReducer,
  cart: cartReducer,
  userAuth: authReducer,
  searchResults: searchReducer,
  snackbar: snackbarReducers,
  app: appReducer
})
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(reducer, composeWithDevTools(applyMiddleware(thunk)))

export default store
