export const sucursales = () => [
    { name: 'Villa Maria', cod: 'VM' },
    { name: 'Bahía Blanca', cod: 'BB' },
    { name: 'Buenos Aires', cod: 'CF' },
    { name: 'Resistencia', cod: 'RS' },
    { name: 'General Pico', cod: 'GP' },
    { name: 'Rafaela', cod: 'RF' }
]

export const actividades = () => [
    'Distribuidora',
    'Veterinaria',
    'Agroveterinaria',
    'Ferret/Lonera/Corralon',
    'Profesional Veterinario',
    'Productor',
    'Otros'
]

export const intereses = () => [
    'Caravanas electronicas',
    'Insumos veterinarios o ganaderos',
    'Otros'
]