import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#2D2D2D',
    color: 'white',
    paddingTop: '14px',
  },
  innerContainer: {
    boxSizing: 'border-box',
    paddingLeft: '14px',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '1040px',
      margin: 'auto',
      display: 'flex',
      paddingLeft: '0px',
      justifyContent: 'space-between',
    },
  },

  titleList: {
    cursor: 'default',
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '17px',
    fontStyle: 'normal',
    letterSpacing: '0em',
    marginTop: '40px',
    color: '#FFFFFF',
  },

  itemList: {
    fontSize: '14px',
    fontWeight: '300',
    lineHeight: '17px',
    fontStyle: 'normal',
    letterSpacing: '0em',
    marginTop: '16px',
    color: '#FFFFFF',
  },
  copyright: {
    boxSizing: 'border-box',
    padding: '7px 3px 8px 14px',
    marginBottom: '0px',
    fontSize: '10px',
    fontStyle: 'normal',
    fontWeight: '200',
    lineHeight: '14px',
    letterSpacing: '0em',
    textAlign: 'center',
    width: '100%',
    backgroundColor: '#202020',
    marginTop: '23px',
    [theme.breakpoints.up('sm')]: {
      marginTop: '35px',
    },
  }
}))
