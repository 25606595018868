import { ApiConnection } from 'config'


export const downloadCuentaCorriente = async (cardCode) => {
  const res = await ApiConnection.get(
    `users/state-account`,
    {
      params: { cardCode },
    }
  )
  return res.data.response
}
