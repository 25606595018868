import { ApiConnection } from 'config'

export const getDetail = async (docEntry, objType) => {
  const { data } = await ApiConnection.get(
    `customer/doc`,
    {
      params: { docEntry, objType },
    }
  )
  return { ...data }
}
