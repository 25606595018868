import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexFlow: 'row nowrap',
    margin: '0px 0px 29px 19px',
  },
  stack: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&::before': pictureImg,
  },
  stackBox: {
    position: 'absolute',
    border: '2px solid white',
    borderRadius: '50%',
  },
  userName: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '17px',
    marginBottom: '5px',
    color: '#444444',
    maxWidth: '150px',
    minWidth: '100px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  link: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '12px',
    lineHeight: '15px',
    color: '#444444',
  },
  avatarContainer: {
    borderRadius: '50%',
    padding: '3px',
    border: '3px solid #4A8850',
    height: '74px',
    width: '74px',
  },
  avatarInnerContainer: {
    borderRadius: '50%',
    backgroundColor: '#EDEDED',
    width: '100%',
    height: '100%',
    display: 'flex',
  },
  signInLink: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '15px',
    color: '#444444',
    marginLeft: '8px',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}))

export const pictureImg = {
  width: '78px',
  height: '78px',
  position: 'relative',
  content: "''",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  borderRadius: '50px',
  padding: '3px',
  background: 'linear-gradient(45deg,#4A8850, #9BC778)',
  '-webkit-mask':
    'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
  '-webkit-mask-composite': 'xor',
  'mask-composite': 'exclude',
}
