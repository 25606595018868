import { ApiConnection } from 'config'

const getCategories = async () => {
  try {
    const { data } = await ApiConnection.get(
      `menu-category`
    )
    return data?.response
  } catch (error) {
    throw new Error(error.message)
  }
}

export default getCategories;
