/* eslint-disable eqeqeq */
import { useState, useEffect, createContext} from 'react'
import {
  Typography,
  useMediaQuery,
  Button,
  Stack,
  Box,
  MenuItem,
  Grid,
  Select,
  CircularProgress,
} from '@mui/material'
import ProductsSection from '../../components/ProductsSection'
import { useDispatch, useSelector } from 'react-redux'
import { useStyles } from './CatalogPage.styles'
import SearchBox from '../../components/SearchBox'
import BreadcrumbComponent from '../../components/ProductScreenComponents/Breadcrumb'
import NoResults from 'components/NoResults/NoResults'
import FilterComponent from '../../components/Filter/index'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import {
  handleSearchQuery,
} from 'stores/searchStore/actionsSearch'
import Pagination from 'components/Pagination/Pagination'
import { Helmet } from 'react-helmet'
import { useSearchParams, useNavigate, useParams } from '../../../node_modules/react-router-dom/index'
import { buildCategoriesURLTree, findCurrentCategoryWithNormalizedTree } from './utils'
import { findChildsOfCategory } from 'components/Filter/helpers/filterList'
import { getCatalogoBannersAction } from 'stores/appStore/actionsApp'

const catalogueContextInitialState = {
  updateCatalogParams: () => {},
  toggleDrawer: () => {},
  menuOpen: false,
  categoriesURL: {
    fullTree: '',
    treeWithoutCurrentCategoryURL: '',
    currentCategoryURL: '',
    values: {
      currentCategory: null,
      treeWithoutCurrent: [],
    }
  }
}

export const CatalogueContext = createContext(catalogueContextInitialState)

const CataloguePageRefactor = () => {
  const [catalogueContextValue, setCatalogueContextValue] = useState(catalogueContextInitialState)
  const desktop = useMediaQuery((theme) => theme.breakpoints.up('md'))
  const { signedIn } = useSelector(({ userAuth }) => userAuth)
  const { allCategories, initFinished, banners } = useSelector((store) => store.app)

  const {
    loading,
    results = [],
    formatedFrontFilters,
    formatedFilters,
    totalItems,
  } = useSelector(({ searchResults }) => searchResults)

  const [itemsToShow] = useState(21)
  const [searchParams, setSearchParams] = useSearchParams()
  const { '*': categoriesUrl } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const classes = useStyles()

  const totalPages = Math.ceil(totalItems / itemsToShow)
  const currentPage = searchParams.get('page') || 1
  const orderBy = searchParams.get('orderBy')

  const updateCatalogParams = (newParam) => {
    Object.keys(newParam).forEach((key) => {
      const value = newParam[key]
      if(value) searchParams.set(key, value)
      else searchParams.delete(key)
    })

    setSearchParams(searchParams)
  }

  const toggleDrawer = (isOpen) => (event) => {
    if (event.type === 'keydown' && event?.key?.match(/Tab|Shift/)) return
    setCatalogueContextValue({
      ...catalogueContextValue,
      menuOpen: isOpen
    })
  }

  const resetPagination = () => updateCatalogParams({ page: 1 })

  const handleSelectOrder = (e) => {
    let orderBy = e.target.value
    if(orderBy === 'Recomendados')
      orderBy = ''
    updateCatalogParams({ orderBy })
  }

  const handleNextPage = () => {
    if (totalPages - currentPage > 0) {
      updateCatalogParams({page: Number(currentPage) + 1})
      window.scrollTo(0, 0)
    }
  }

  const handlePrevPage = () => {
    if (currentPage > 1) {
      updateCatalogParams({page: Number(currentPage) - 1})
      window.scrollTo(0, 0)
    }
  }

  const handleGoBack = () => {
    navigate(`/catalogo/${catalogueContextValue.categoriesURL.treeWithoutCurrentCategoryURL}`)
  }

  useEffect(() => {
    if(allCategories?.length === 0) return
    const currentCategoryCode = findCurrentCategoryWithNormalizedTree(categoriesUrl, allCategories)
    const childs = findChildsOfCategory(currentCategoryCode, allCategories)
    const isShowcharacteristics = childs?.length === 0
    dispatch(
      handleSearchQuery(
        { categoria: currentCategoryCode?.codigo, isShowcharacteristics },
        searchParams.toString()
      )
    )
  }, [searchParams.toString(), categoriesUrl, allCategories])

  useEffect(() => {
    if(!categoriesUrl || !initFinished) return
    const categoriesTreeData = buildCategoriesURLTree(categoriesUrl)

    setCatalogueContextValue({
      ...catalogueContextValue, 
      categoriesURL: categoriesTreeData
    })

    const currentCategory = findCurrentCategoryWithNormalizedTree(categoriesUrl)
    dispatch(getCatalogoBannersAction(currentCategory?.codigo))
  }, [categoriesUrl, initFinished])

  return (
    <>
      <Helmet>
        <title>
          {`${
            formatedFrontFilters?.subLinea
              ? formatedFrontFilters?.subLinea + ' |'
              : ''
          }`}{' '}
          Catálogo | Villanueva
        </title>
        <meta
          name='description'
          content='Catálogo de productos de Villanueva'
        />
      </Helmet>
      <CatalogueContext.Provider 
        value={{
          ...catalogueContextValue,
          updateCatalogParams,
          resetPagination,
          toggleDrawer
        }}
      >
        <Box className={classes.mainGrid}>
          <BreadcrumbComponent />
          <Stack direction='row' flexWrap={{ xs: 'wrap', md: 'nowrap' }}>
            {!desktop && <SearchBox />}

            <Box className={classes.filterGrid}>
              <Stack
                direction='row'
                justifyContent='space-between'
                sx={{
                  borderRadius: '8px',
                }}
              >
                {results?.length > 1 && (
                  <Box sx={{ marginBlock: '10px' }}>
                    <Typography className={classes.subtitle}>
                      Mostrando {results?.length} de {totalItems}
                    </Typography>
                    <Typography className={classes.subtitle}>
                      productos encontrados
                    </Typography>
                    <Typography className={classes.subtitle}>
                      página {currentPage} de {totalPages}
                    </Typography>
                  </Box>
                )}
                {!desktop && (
                  <Button
                    startIcon={<FilterAltIcon />}
                    onClick={toggleDrawer(true)}
                    sx={{
                      color: (theme) => theme.palette.primary.lightBlue,
                      borderColor: (theme) => theme.palette.primary.lightBlue,
                      '&:focus, &:hover, &:active, &:link, &:visited': {
                        borderColor: (theme) => theme.palette.primary.lightBlue,
                      },
                      justifyContent: 'flex-end',
                    }}
                    variant='outlined'
                  >
                    Filtrar
                  </Button>
                )}
              </Stack>
              <FilterComponent />
            </Box>
            <Box
              sx={{
                marginTop: { sx: '0px', md: '-27px' },
                width: '100%',
                minWidth: { sx: '100%', lg: '779px' },
              }}
            >
              {/* top banner section */}
              {banners.catalogo_top?.desk?.[0] && (
                <Box sx={{width: '100%', marginTop: '30px'}}>
                  <img 
                    style={{width: '98%', borderRadius: '5px', cursor: banners.catalogo_top?.desk?.[0].redirectTo ? 'pointer' : 'auto'}} 
                    src={`${process.env.REACT_APP_URL_BACKEND_REMOTO}/files/${banners.catalogo_top?.desk?.[0].file.ruta}`}
                    onClick={() => {
                      if(banners.catalogo_top?.desk?.[0].redirectTo) {
                        navigate(banners.catalogo_top?.desk?.[0].redirectTo)
                      }
                    }}
                  />
                </Box>
              )}
              {!initFinished || loading ? (
                <Grid
                  container
                  justifyContent='center'
                  sx={{ marginTop: '15vh' }}
                >
                  <CircularProgress disableShrink />
                </Grid>
              ) : results?.length ? (
                <>
                    <Grid
                      container
                      justifyContent='flex-end'
                      alignItems='center'
                      sx={{
                        padding: '10px 20px',
                        marginTop: '20px',
                      }}
                    >
                      <Typography variant='h3'>Ordenar por</Typography>
                      <Select
                        value={orderBy || 'Recomendados'}
                        variant='standard'
                        sx={{
                          fontSize: '12px',
                          marginLeft: '12px',
                        }}
                        onChange={handleSelectOrder}
                      >
                        <MenuItem value='Recomendados'>Recomedados</MenuItem>
                        <MenuItem value='ASC'>Menor precio</MenuItem>
                        <MenuItem value='DESC'>Mayor precio</MenuItem>
                      </Select>
                    </Grid>
                  <ProductsSection
                    search={true}
                    showPrice={signedIn}
                    loading={loading}
                    data={results}
                    catalogo={true}
                    banners={banners.catalogo_cards?.desk}
                  />
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    nextPage={handleNextPage}
                    prevPage={handlePrevPage}
                  />
                </>
              ) : null}
              {!results?.length && !loading && initFinished && (
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <NoResults nombreBusqueda={formatedFilters?.referenceName} />
                  <button
                    style={{
                      background: '#4AA259',
                      borderRadius: '6px',
                      padding: '19px 141px',
                      border: 'none',
                      color: 'white',
                    }}
                    onClick={handleGoBack}
                  >
                    Volver
                  </button>
                </Box>
              )}
            </Box>
          </Stack>
          {/* <Box className={classes.disclaimerSection}>
            <Typography className={classes.disclaimerTitle}>
              Comprá tus caravanas oficiales en Villanueva
            </Typography>
            <Typography className={classes.disclaimerDescription}>
              Si estás buscando comprar Caravanas oficiales de Senasa, ¡llegaste
              al lugar indicado! En Villanueva tenemos la más amplia variedad de
              agro insumos que te acompañan desde la siembra hasta el cuidado y
              protección de tus cultivos, con productos de las mejores marcas y
              calidad sobretodo. Vas a encontrar todo en protección de cultivos
              como herbicidas, insecticidas, fungicidas y coadyuvantes; semillas
              con las mejores propiedades; tratamiento de semillas, inoculantes y
              hasta silo bolsas para almacenar tus granos o forraje. En nuestro
              sitio encontrar lo que buscás es super sencillo, tenemos filtros que
              se ajustan a tu medida, donde vas a poder hacer de una búsqueda
              general una búsqueda minuciosa, ajustando el camino hasta llegar al
              producto que necesitas.
            </Typography>
          </Box> */}
        </Box>
      </CatalogueContext.Provider>
    </>
  )
}

export default CataloguePageRefactor
