import { ApiConnection } from 'config'

export const userPromotionsService = async (user) => {
  const sellerId = localStorage.getItem('sellerId');
  const res = await ApiConnection.get(
    `users/promotions-offers`,
    { headers: { user, sellerId } }
  )
  return res.data.response
}
