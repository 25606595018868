import React from 'react'
import { Grid, Button, CircularProgress } from '@mui/material'
import { makeStyles } from '@mui/styles'
import SaveIcon from '@mui/icons-material/Save'

const BottomActions = ({
  cart,
  firstAction,
  secondAction,
  thirdAction,
  firstText,
  secondText,
  thirdText,
  customStylesContainer,
  firstActionDisabled,
}) => {
  const classes = useStyles(secondText)

  return (
    <Grid container className={classes.buttonsGrid} sx={customStylesContainer}>
      <Grid
        item
        xs={12}
        // sx={{ marginBottom: `${!secondText && '50px'}` }}
        className={classes.gridItem}
      >
        <Button
          onClick={firstAction}
          variant='contained'
          disabled={cart?.length === 0 || firstActionDisabled}
          fullWidth={true}
          className={classes.buttonBuy}
        >
          {firstActionDisabled ? <CircularProgress /> : firstText}
        </Button>
      </Grid>
      {secondText && (
        <Grid item xs={12} className={classes.gridItem}>
          <Button
            variant='outlined'
            className={classes.buttonKeepBuying}
            fullWidth={true}
            onClick={secondAction}
            disabled={cart?.length === 0}
          >
            {secondText}
          </Button>
        </Grid>
      )}
      {thirdText && (
        <Grid item xs={12} className={classes.gridItem}>
          <Button
            variant='secondary'
            // className={classes.buttonSave}
            fullWidth={true}
            onClick={thirdAction}
          >
            <SaveIcon className={classes.saveIcon} />
            {thirdText}
          </Button>
        </Grid>
      )}
    </Grid>
  )
}

const useStyles = makeStyles((theme) => ({
  buttonsGrid: {
    marginTop: '55px',
    gap: theme.spacing(1),
  },
  gridItem: {
    marginBottom: theme.spacing(1),
  },
  buttonBuy: {
    backgroundColor: '#4AA259',
    borderRadius: '6px',
    height: '56px',
    fontFamily: 'Inter',
    fontWeight: '500',
    fontSize: '18px',
    textTransform: 'unset',
    lineHeight: '22px',
    '&:hover': {
      backgroundColor: '#3a8c49',
    },
  },
  buttonKeepBuying: {
    borderRadius: '6px',
    borderWidth: '2px',
    color: '#4AA259',
    fontFamily: 'Inter',
    fontWeight: '400',
    fontSize: '18px',
    textTransform: 'unset',
    lineHeight: '22px',
    height: '56px',
    '&:hover': {
      backgroundColor: '#f5f5f5',
      borderColor: '#3a8c49',
      color: '#3a8c49',
    },
  },
  buttonSave: {
    borderRadius: '6px',
    borderWidth: '2px',
    borderColor: '#e6ebe7',
    color: '#4AA259',
    fontFamily: 'Inter',
    fontWeight: '400',
    fontSize: '16px',
    textTransform: 'unset',
    lineHeight: '22px',
    height: '56px',
    '&:hover': {
      backgroundColor: '#f5f5f5',
      borderColor: '#c2c7c3',
      color: '#3a8c49',
    },
  },
  saveIcon: {
    marginRight: theme.spacing(1),
  },
}))

export default BottomActions
