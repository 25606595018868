import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  boxPrecio: {
    backgroundColor: theme.palette.primary.white,
    padding: '20px',
    boxSizing: 'border-box',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '360px',
      borderRadius: '8px',
      paddingBottom: 0,
    },
  },
  root: {
    border: '1px dashed #D0D0D0',
  },
  focused: {
    border: '1px dashed #D0D0D0',
  },
  notchedOutline: {
    border: '0px',
  },
  input: {
    border: '0px none',
    '&:hover': {
      border: '0px none',
    },
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '15px',
    color: '#777777',
  },
  inputPlaceholder: {
    opacity: '0.35',
  },
  couponButton: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '15px',
    textAlign: 'center',
    color: '#777777',
    cursor: 'pointer',
  },
  thereAreCouponsAvailableText: {
    color: theme.palette.primary.typographyGreen,
    fontWeight: 'bold'
  },
  textMid: {
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '17px',
    color: theme.palette.primary.almostBlack,
  },
  borderMargin: {
    borderBottom: '0.6px solid #BBBBBB',
    paddingTop: '20px',
  },
  textNumber: {
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '18.5px',
    color: theme.palette.primary.almostBlack,
  },
  textSucursal: {
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '17px',
    color: theme.palette.primary.typographyGreen,
  },
  borderMarginEnd: {
    paddingTop: '20px',
  },
  textTotal: {
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '19px',
    color: theme.palette.primary.almostBlack,
  },
  textColor: {
    fontWeight: '700',
    fontSize: '20px',
    lineHeight: '23px',
    color: theme.palette.primary.typographyGreen,
  },
}))
