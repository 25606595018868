import React, { useState } from 'react'
import {
  Grid,
  Typography,
  Button,
  TextField,
  Radio,
  FormControlLabel,
  FormControl,
  RadioGroup,
  Select,
  MenuItem
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useStyles } from './RegisterPage.styles'
import { useForm, Controller } from 'react-hook-form'
import FileInput from 'components/DragDropFile'
import Vaca from '../../assets/vaca.jpg'
import { emailRegex, numberRegex } from 'utils/regesx'
import { FileUploader } from 'react-drag-drop-files'
import { Helmet } from 'react-helmet'
import { registerClient } from './services/registerClient'
import { useDispatch } from 'react-redux'
import { setSnackbar } from 'stores/snackbarStore'
import { sucursales, actividades, intereses } from './helpers/selectData'
const RegisterPage = () => {

  const navigate = useNavigate()
  const classes = useStyles()
  const dispatch = useDispatch()
  const [comprobante, setComprobante] = useState(null)
  const [constancia, setConstancia] = useState(null)
  const [sucursalValue, setSucursalValue] = useState(sucursales()[0].cod)
  const [actividadValue, setActividadValue] = useState(actividades()[0])
  const [interesValue, setInteresValue] = useState(intereses()[0])

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      cuit: '',
      email: '',
      name: '',
      sucursal: '',
      telefono: '',
      comentario: ''
    },
  })


  const handleSelectSucursal = (e) => {
    setSucursalValue(e.target.value)
  }

  const handleSelectActividad = (e) => {
    setActividadValue(e.target.value)
  }

  const handleSelectInteres = (e) => {
    setInteresValue(e.target.value)
  }

  const onSubmit = async ({ cuit, email, name, telefono, comentario }) => {
    try {
      const formData = new FormData();
      formData.append('cuit', cuit)
      formData.append('email', email)
      formData.append('name', name)
      formData.append('sucursal', sucursalValue)
      formData.append('telefono', telefono)
      formData.append('comprobante', comprobante)
      formData.append('constancia', constancia)
      formData.append('actividad', actividadValue)
      formData.append('interes', interesValue)
      formData.append('comentario', comentario)
      await registerClient(formData);
      navigate('/registro/enviado')
    }
    catch (error) {
      dispatch(setSnackbar(true, 'error', 'Ocurrio un error, intente nuevamente mas tarde.'))
    }
  }

  return (
    <>
      <Helmet>
        <title>Quiero registrarme | Villanueva</title>
        <meta name='description' content='Quiero registrarme' />
      </Helmet>
      <Grid className={classes.grid} container>
        {/* MOBILE */}
        <Grid
          container
          className={classes.innerContainer}
          sx={(theme) => ({
            [theme.breakpoints.up('sm')]: {
              display: 'none',
            },
          })}
        >
          <Typography className={classes.title}>
            Querés ser cliente de Villanueva?
          </Typography>
          <Typography paragraph className={classes.subtitleInfo}>
            Dejanos tus datos y te contactaremos en unos minutos para
            confirmarte y que puedas empezar a comprar
          </Typography>
        </Grid>
        {/* DESKTOP */}
        <Grid
          item
          className={classes.innerContainer}
          sx={(theme) => ({
            [theme.breakpoints.down('sm')]: {
              display: 'none',
            },
            [theme.breakpoints.up('sm')]: {
              marginRight: '150px',
            },
          })}
        >
          <Typography className={classes.title}>
            Querés ser cliente <br />
            de Villanueva?
          </Typography>
          <Typography paragraph className={classes.subtitleInfo}>
            Dejanos tus datos y te contactaremos en <br /> unos minutos para
            confirmarte y que <br />
            puedas empezar a comprar
          </Typography>
        </Grid>
        <Grid item className={classes.innerContainer}>
          <img
            src={Vaca}
            alt='Vaca'
            style={{
              borderRadius: 100,
              width: '200px',
              height: '200px',
              marginTop: '10px',
            }}
          />
        </Grid>
      </Grid>
      <Grid className={classes.gridOfForm} container>
        <Grid item xs={5} className={classes.innerContainerOfForm}>
          <form style={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
            <Grid container justifyContent='space-between'>
              <Grid sx={{ width: '40%' }}>
                <Typography className={classes.tagName}>
                  Nombre y apellido
                </Typography>
                <TextField
                  variant='outlined'
                  fullWidth
                  id='name'
                  placeholder='Nombre y Apellido'
                  name='name'
                  {...register('name', { required: true, minLength: 10 })}
                  error={!!errors.name}
                />
                <Typography
                  variant='inherit'
                  color='red'
                  sx={{ paddingTop: 1 }}
                >
                  {errors.name?.type === 'minLength' &&
                    'El nombre debe tener al menos 10 caracteres'}
                  {errors.name?.type === 'required' &&
                    'La nombre es obligatorio'}
                </Typography>
              </Grid>
              <Grid sx={{ width: '40%' }}>
                <Typography className={classes.tagName}>CUIT</Typography>
                <TextField
                  variant='outlined'
                  fullWidth
                  id='cuit'
                  placeholder='Ej: 20-33004971-6'
                  name='cuit'
                  {...register('cuit', {
                    required: true,
                    minLength: 10,
                    pattern: numberRegex,
                  })}
                  error={!!errors.cuit}
                />
                <Typography
                  variant='inherit'
                  color='red'
                  sx={{ paddingTop: 1 }}
                >
                  {errors.cuit?.type === 'minLength' &&
                    'El CUIT debe tener al menos 10 caracteres'}
                  {errors.cuit?.type === 'required' && 'La CUIT es obligatorio'}
                  {errors.telefono?.type === 'pattern' &&
                    'Deben ser solo números'}
                </Typography>
              </Grid>
            </Grid>
            <Grid container justifyContent='space-between'>
              <Grid sx={{ width: '40%' }}>
                <Typography className={classes.tagName}>E-mail</Typography>
                <TextField
                  variant='outlined'
                  fullWidth
                  id='email'
                  name='email'
                  placeholder='ejemplo@gmail.com'
                  {...register('email', {
                    required: true,
                    minLength: 10,
                    pattern: emailRegex,
                  })}
                  error={!!errors.email}
                />
                <Typography
                  variant='inherit'
                  color='red'
                  sx={{ paddingTop: 1 }}
                >
                  {errors.email?.type === 'minLength' &&
                    'El email debe tener al menos 10 caracteres'}
                  {errors.email?.type === 'required' &&
                    'La email es obligatorio'}
                  {errors.email?.type === 'pattern' &&
                    'Formato de e-mail inválido'}
                </Typography>
              </Grid>
              <Grid sx={{ width: '40%' }}>
                <Typography className={classes.tagName}>Teléfono</Typography>
                <TextField
                  variant='outlined'
                  fullWidth
                  id='telefono'
                  name='telefono'
                  placeholder='Incluir codigo de area'
                  {...register('telefono', {
                    required: true,
                    minLength: 10,
                    pattern: numberRegex,
                  })}
                  error={!!errors.telefono}
                />
                <Typography
                  variant='inherit'
                  color='red'
                  sx={{ paddingTop: 1 }}
                >
                  {errors.telefono?.type === 'minLength' &&
                    'El Teléfono debe tener al menos 10 caracteres'}
                  {errors.telefono?.type === 'required' &&
                    'La Teléfono es obligatoria'}
                  {errors.telefono?.type === 'pattern' &&
                    'Deben ser solo números'}
                </Typography>
              </Grid>
            </Grid>
            <Grid container justifyContent='space-between'>
              <Grid sx={{ width: '40%' }}>
                <Typography className={classes.tagName}>
                  Sucursal más cercana
                </Typography>
                <FormControl>
                  <Select
                    inputProps={{
                      className: classes.textFieldInput,
                    }}
                    value={sucursalValue}
                    className={`${classes.textField} ${classes.shorterTextField}`}
                    onChange={handleSelectSucursal}
                  >
                    {sucursales().map((sucursal, index) => {
                      return (
                        <MenuItem value={sucursal.cod} key={index}>
                          {sucursal.name}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid sx={{ width: '40%' }}>
                <Typography className={classes.tagName}>Actividad</Typography>
                <FormControl>
                  <Select
                    inputProps={{
                      className: classes.textFieldInput,
                    }}
                    value={actividadValue}
                    className={`${classes.textField} ${classes.shorterTextField}`}
                    onChange={handleSelectActividad}
                  >
                    {actividades().map((actividad, index) => {
                      return (
                        <MenuItem value={actividad} key={index}>
                          {actividad}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container justifyContent='space-between'>
              <Grid sx={{ width: '40%' }}>
                <Typography className={classes.tagName}>Comentario</Typography>
                <TextField
                  {...register('comentario')}
                  placeholder='Escribir comentario'
                  className={classes.textField}
                  fullWidth
                  multiline
                  rows={4}
                />
              </Grid>
              <Grid sx={{ width: '40%' }}>
                <Typography className={classes.tagName}>Interes</Typography>
                <FormControl>
                  <Select
                    inputProps={{
                      className: classes.textFieldInput,
                    }}
                    value={interesValue}
                    className={`${classes.textField} ${classes.shorterTextField}`}
                    onChange={handleSelectInteres}
                  >
                    {intereses().map((interes, index) => {
                      return (
                        <MenuItem value={interes} key={index}>
                          {interes}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container justifyContent='space-between'>
              <Grid sx={{ width: '40%' }}>
                <Typography className={classes.tagName}>Comprobante</Typography>
                <FileUploader
                  handleChange={(file) => setComprobante(file)}
                  multiple={false}
                  name='comprobante'
                  types={['JPEG', 'PNG', 'GIF', 'PDF', 'JPG']}
                  maxSize={10}
                  hoverTitle={'Suelta aqui'}
                >
                  <FileInput
                    fileName={comprobante?.name}
                    prompt='Subir comprobante AFIP (PDF o imagen)'
                  />
                </FileUploader>
                <Typography
                  variant='inherit'
                  color='red'
                  sx={{ paddingTop: 1 }}
                >
                  {errors.comprobante?.type === 'required' &&
                    'El comprobante de AFIP es obligatorio'}
                </Typography>
              </Grid>
              <Grid sx={{ width: '40%' }}>
                <Typography className={classes.tagName}>Constancia</Typography>
                <FileUploader
                  handleChange={(file) => setConstancia(file)}
                  multiple={false}
                  name='constancia'
                  types={['JPEG', 'PNG', 'GIF', 'PDF', 'JPG']}
                  maxSize={10}
                  hoverTitle={'Suelta aqui'}
                >
                  <FileInput
                    fileName={constancia?.name}
                    prompt='Subir constancia AFIP (PDF o imagen)'
                  />
                </FileUploader>
                <Typography
                  variant='inherit'
                  color='red'
                  sx={{ paddingTop: 1 }}
                >
                  {errors.constancia?.type === 'required' &&
                    'La constancia de AFIP es obligatoria'}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              direction='row'
              justifyContent='center'
              alignItems='center'
            >
              <Grid>
                <Button
                  type='submit'
                  variant='primary'
                  className={classes.buttonBuy}
                >
                  Solicitar alta de cliente
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </>
  )
}

export default RegisterPage
