import { ApiConnection } from 'config'


const uploadFile = async (formData) => {
  try {
    const res = ApiConnection.post(
      `files`,
      formData
    )
    return res
  } catch (error) {
    console.error(error)
  }
}

export default uploadFile
