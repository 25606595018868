import React, { useState } from 'react'
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Divider,
  Typography,
  Tooltip,
  Grid,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import DeleteIcon from '@mui/icons-material/Delete'
import emptyCart from '../../assets/emptyCart.png'

const SavedCartsModal = ({
  open,
  onClose,
  savedCarts,
  onSelectOrder,
  onDeleteOrder,
}) => {
  const [openTooltip, setOpenTooltip] = useState(null)

  const handleTooltipOpen = (id) => setOpenTooltip(id)
  const handleTooltipClose = () => setOpenTooltip(null)

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' }
    return new Date(dateString).toLocaleDateString(undefined, options)
  }

  const sortCartsByDate = (a, b) => {
    const dateA = new Date(
      Math.max(...a[1].items.map((item) => new Date(item.updatedAt)))
    )
    const dateB = new Date(
      Math.max(...b[1].items.map((item) => new Date(item.updatedAt)))
    )
    return dateB - dateA
  }

  const sortedCarts = Object.entries(savedCarts).sort(sortCartsByDate)
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth='sm'>
      <DialogTitle
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <ShoppingCartIcon sx={{ color: 'primary.main', mr: 1 }} />
          Tus carritos guardados
        </Box>
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {sortedCarts.length === 0 ? (
          <Grid
            container
            justifyContent='center'
            alignItems='center'
            style={{ textAlign: 'center', padding: '20px' }}
          >
            <img
              src={emptyCart}
              alt='No hay carritos guardados'
              style={{
                borderRadius: 50,
                marginBottom: '35px',
                maxWidth: '100px',
              }}
            />
            <Typography variant='h6' gutterBottom>
              No existen carritos guardados
            </Typography>
            <Typography variant='body1'>
              Guardá tu carrito para recuperarlo más tarde.
            </Typography>
          </Grid>
        ) : (
          <List>
            {sortedCarts.map(
              ([orderId, { items, createdBySellerName }], index) => {
                const date = formatDate(
                  Math.max(...items.map((item) => new Date(item.updatedAt)))
                )
                const itemTitles =
                  items
                    .map((item) => item.nombre)
                    .slice(0, 3)
                    .join(', ') +
                  (items.length > 3 ? `, y ${items.length - 3} más` : '')
                const secondaryText = `${items.length} ítems: ${itemTitles}`

                return (
                  <React.Fragment key={orderId}>
                    <Tooltip
                      title='Seleccionar carrito'
                      open={openTooltip === orderId}
                      followCursor
                    >
                      <ListItem
                        button
                        onClick={() => onSelectOrder(orderId)}
                        onMouseEnter={() => handleTooltipOpen(orderId)}
                        onMouseLeave={handleTooltipClose}
                      >
                        <Grid container spacing={0} alignItems='center'>
                          <Grid item xs={12} sm={8}>
                            <ListItemText
                              primary={`Última modificación: ${date}`}
                              secondary={secondaryText}
                              primaryTypographyProps={{
                                variant: 'body2',
                                fontWeight: 'normal',
                                sx: {
                                  fontSize: { xs: '0.8rem', sm: '1rem' },
                                },
                              }}
                              secondaryTypographyProps={{
                                variant: 'body2',
                                sx: {
                                  fontSize: { xs: '0.7rem', sm: '0.875rem' },
                                },
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                              }}
                            >
                              <Typography
                                variant='body2'
                                color='textSecondary'
                                sx={{
                                  fontSize: '0.75rem',
                                  fontStyle: 'italic',
                                }}
                              >
                                Creado por: {createdBySellerName || 'Cliente'}
                              </Typography>
                              <Tooltip
                                title='Eliminar carrito'
                                onOpen={handleTooltipClose}
                                onClose={() => handleTooltipOpen(orderId)}
                              >
                                <IconButton
                                  edge='end'
                                  aria-label='delete'
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    onDeleteOrder(orderId)
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItem>
                    </Tooltip>
                    {index < sortedCarts.length - 1 && (
                      <Divider component='li' />
                    )}
                  </React.Fragment>
                )
              }
            )}
          </List>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default SavedCartsModal
