import { ApiConnection } from 'config'

const getProducerList = async (param, items = 5) => {
  const { data } = await ApiConnection.get(
    `producer/get-by`,
    {
      params: { param, items },
    }
  )
  return data.response
}

export default getProducerList
