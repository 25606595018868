import { useStyles } from './FilterComponent.styles'
import { Drawer, Box, IconButton } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { findChildsOfCategory, formatCharacteristics } from './helpers/filterList'
import FilterElement from './FilterElement'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { CatalogueContext } from 'pages/CatalogPage - refactor/CatalogPage'
import { findCurrentCategoryWithNormalizedTree } from 'pages/CatalogPage - refactor/utils'

const FilterMobileComponent = ({
  accordionExpanded,
  handleChange,
}) => {
  const classes = useStyles()
  const {"*": categoriesURL} = useParams()
  const { allCategories } = useSelector(({app}) => app)
  const [childCategories, setChildCategories] = useState(null)
  const { characteristics, results } = useSelector(({ searchResults }) => searchResults)
  const { 
    categoriesURL: { currentCategoryURL }, 
    toggleDrawer, 
    menuOpen 
  } = useContext(CatalogueContext)

  useEffect(() => {
    if(allCategories?.length === 0 || !categoriesURL) return setChildCategories(null)
    const currentCategory = findCurrentCategoryWithNormalizedTree(categoriesURL)
    const childs = findChildsOfCategory(currentCategory, allCategories)
    // si tiene sub categorias las renderizo, sino muestro filtros
    if(childs?.length > 0) {
      setChildCategories(childs)
    } else {
      setChildCategories(null)
    }
  }, [categoriesURL, allCategories])

  const characteristicsFormatted = useMemo(() => {
    if(results.length === 0 || childCategories) return []
    return characteristics ? formatCharacteristics(characteristics) : []
  }, [characteristics, childCategories, results.length, currentCategoryURL])

  return (
    <Drawer
      anchor='left'
      open={menuOpen}
      sx={{
        '& .MuiDrawer-paper': {
          width: '100%',
          backgroundColor: '#F4F4F4',
        },
      }}
      onClose={toggleDrawer(false)}
    >
      <Box className={classes.mainBox}>
        <IconButton className={classes.closeBox} onClick={toggleDrawer(false)}>
          <ArrowBackIcon
            fontSize='medium'
            sx={{ padding: '10px 10px 10px 0px' }}
          />
          <h2>Filtrar</h2>
        </IconButton>
        <FilterElement
          data={childCategories || characteristicsFormatted}
          accordionExpanded={accordionExpanded}
          handleChange={handleChange}
        />
      </Box>
    </Drawer>
  )
}
export default FilterMobileComponent
