import { makeStyles } from '@mui/styles'

export const useAccordinElementStyles = makeStyles((theme) => ({
  accordionItem: {
    '&.MuiPaper-root': {
      padding: '8px 0px !important',
      '&:first-of-type': {
        // borderRadius: '8px !important',
      },
      '&:nth-of-type(2)': {
        // borderTopRightRadius: '8px !important',
        // borderTopLeftRadius: '8px !important',
      },
      '&:last-of-type': {
        borderBottomRightRadius: '8px !important',
        borderBottomLeftRadius: '8px !important',
      },
    },
    '&.Mui-expanded': {
      '&:before': {
        opacity: '1 !important',
      },
    },

    [theme.breakpoints.up('md')]: {
      boxShadow: 'none',
      marginTop: '0px',
      '&.MuiPaper-root': {
        borderRadius: 0,
        paddingBlock: '18px',
      },
      borderTop: '0.4px solid #D1D1D1',
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
    },
  },
  accordionSummary: {
    paddingTop: '6px',
    paddingBottom: '6px',
    paddingLeft: '24px',

    [theme.breakpoints.up('sm')]: {
      paddingLeft: '12px',
      '& .MuiAccordionSummary-content': {
        marginBlock: '0px !important',
      },
    },
  },
  filterTitle: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px !important',
    lineHeight: '20px',
    marginBlock: '0px !important',
    [theme.breakpoints.up('md')]: {
      fontWeight: 600,
    },
  },
  filterSubtitle: {
    cursor: 'pointer',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '20px',
    color: theme.palette.primary.moreBlue,
    [theme.breakpoints.up('sm')]: {
      marginTop: '7px',
      color: theme.palette.primary.almostBlack,
    },
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}))
