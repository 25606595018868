import { useStyles } from './CuentaCorriente.styles'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Typography, Button, Box, Grid, useMediaQuery, Skeleton, Select, MenuItem } from '@mui/material'
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import Comprobante from './Comprobante'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import TitleWithBackArrow from 'components/TitleWithBackArrow'
import RoundedSquareIconButton from 'components/RoundedSquareIconButton'
import { ReactComponent as DownIcon } from '../../assets/downIcon.svg'
import { downloadCuentaCorriente } from './services/downloadCuentaCorriente'
import { Helmet } from 'react-helmet'
import { updateUserInfo } from 'stores/userStore/actionsUser'
import { formatDateToInput, formatInputDateToDDMMYYYY, getDateFromDate } from './utils/date'
import { stateAccountHistory, stateAccountHistoryB64, stateAccountHistorySaldo } from './services/history'
import { debounce } from 'utils/debounce'

function numberToCurrency(n) {
  return `${Number(n).toLocaleString("es-ES")}`;
}

const today = formatDateToInput(getDateFromDate(0))

const dateFilterDefaultValues = {
  desde: formatDateToInput(getDateFromDate(-30)),
  hasta: today
}

function CuentaCorriente() {
  const classes = useStyles()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [ amount, setAmount ] = useState();
  const [expanded, setExpanded] = useState(-1)
  const { userInfo } = useSelector(({ userAuth }) => userAuth)
  const [downloading, setDownloading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [historyAccount, setHistoryAccount] = useState([]);
  const [historyAccountFiltered, setHistoryAccountFiltered] = useState([])
  const [historySaldo, setHistorySaldo] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams()
  const [datePickerValues, setDatePickerValues] = useState({
    desde: searchParams.get('desde') || dateFilterDefaultValues.desde,
    hasta: searchParams.get('hasta') || dateFilterDefaultValues.hasta,
  })

  const desktop = useMediaQuery((theme) => theme.breakpoints.up('sm'))
  const currentTab = useMemo(() => searchParams.get('tab'), [searchParams.toString()])
  const handleChange = (panel) => (_event, newExpanded) => {
    setExpanded(newExpanded ? panel : -1)
  }

  const saldo = userInfo?.cuentaCorriente?.length
    ? [...userInfo?.cuentaCorriente].pop().SaldoAcumulado
    : 0

  const saldoAlDate = useMemo(() => {
    const desdeDate = new Date(datePickerValues.desde)
    return formatInputDateToDDMMYYYY(formatDateToInput(desdeDate))
  }, [datePickerValues.desde])
  

  const handleClick = () => {
    navigate(`./pago?saldo=${amount}`)
  }

  useEffect(() => {
    if(userInfo.user)
      dispatch(updateUserInfo(userInfo.user))
  }, [userInfo.user])

  const createLinkAndDowload = (b64) => {
    const link = document.createElement('a')
    link.href = 'data:application/octet-stream;base64,' + b64
    link.download = `resumen-${userInfo.codigoUsuario}.pdf`
    link.target = '_blank'
    link.rel = 'noreferrer'
    link.click()
  }

  const donwloadResumen = async () => {
    try {
      setDownloading(true)
      let b64 = ''
      if(currentTab === 'Pendientes') {
        b64 = await downloadCuentaCorriente(userInfo.codigoUsuario)
      }
      if(currentTab === 'Historial') {
        let {desde, hasta} = datePickerValues;
        desde = formatInputDateToDDMMYYYY(desde)
        hasta = formatInputDateToDDMMYYYY(hasta)
        b64 = await stateAccountHistoryB64(userInfo.codigoUsuario, desde, hasta)
      }
      createLinkAndDowload(b64)
    } catch (error) {
      console.error(error)
    } finally {
      setDownloading(false)
    }
  }

  const handleChangeTab = (tab) => {
    searchParams.delete('desde')
    searchParams.delete('hasta')
    searchParams.set('tab', tab)
    setSearchParams(searchParams)
  }

  const handleFiltersDate = ({target: { value, name }}) => {
    setDatePickerValues({...datePickerValues, [name]: value})
  }

  const handleFilter = (e) => {
    e?.preventDefault();
    let desde = datePickerValues.desde
    let hasta = datePickerValues.hasta

    if(!desde)
      return alert('Fecha "Desde" es requerida');
    if(!hasta)
      return alert('Fecha "Hasta" es requerida');

    searchParams.set('hasta', hasta)
    searchParams.set('desde', desde)
    setSearchParams(searchParams)

    desde = formatInputDateToDDMMYYYY(desde)
    hasta = formatInputDateToDDMMYYYY(hasta)

    setLoading(true)
    Promise.all([
      stateAccountHistory(userInfo.codigoUsuario, desde, hasta),
      stateAccountHistorySaldo(userInfo.codigoUsuario, desde)
    ])
      .then(([history, saldo]) => {
        if(history.length === 1 || !history[0].Comprobante) {
          setHistorySaldo(0);
          setHistoryAccount([]);
          setHistoryAccountFiltered([]);
          return;
        }
        setHistorySaldo(numberToCurrency(saldo));
        const rows = history.map((row) => ({
          ...row,
          TipoComprobante: row.Tipo,
          FechaDocumento: row.Fecha,
          SaldoAcumulado: numberToCurrency(row.Acumulado),
          Importe: numberToCurrency(row.Importe),
        }))
        setHistoryAccount(rows);
        setHistoryAccountFiltered(rows);

      })
      .catch(alert)
      .finally(() => setLoading(false)) 
  }
  

  useEffect(() => {
    if(!searchParams.has('tab')) {
      searchParams.set('tab', 'Pendientes')
      setSearchParams(searchParams)
    }
    if(searchParams.get('tab') === 'Historial') {
      handleFilter()
    }
  }, [])

  const handleFilterHistory = (e) => {
    let {value, name} = e.target
    if(value === 'Ver todos'){
      value = ''
    }
    const result = historyAccount.filter((h) => value ? h[name].toLowerCase().includes(value.toLowerCase()) : true)
    setHistoryAccountFiltered(result)
  }

  return (
    <>
      <Helmet>
        <title>Resumen de cuenta corriente | Villanueva</title>
        <meta name='description' content='Resumen de cuenta corriente' />
        <meta
          name='keywords'
          content='resumen, cuenta, corriente, Villanueva'
        />
      </Helmet>
      <Grid className={classes.container}>
        <div className={classes.innerContainer}>
          <div>
            <Box className={classes.tabsAndFilters}>
              <TitleWithBackArrow to={'/resumen'}>Resumen de cuenta corriente</TitleWithBackArrow>
              {currentTab === 'Historial' && (
                <form className={classes.filtersContainer} onSubmit={handleFilter}>
                  <label className={classes.filtersLabel} htmlFor='historial_filtro_desde'>Mostrar desde:</label>
                  <input 
                    id='historial_filtro_desde' 
                    type="date" 
                    name="desde"
                    required
                    min='2010-01-01'
                    max={datePickerValues.to}
                    defaultValue={datePickerValues.desde}
                    className={classes.dateFilter} 
                    onChange={handleFiltersDate} 
                  />

                  <label className={classes.filtersLabel} htmlFor='historial_filtro_hasta'>Hasta:</label>
                  <input 
                    id='historial_filtro_hasta' 
                    type="date" 
                    name="hasta" 
                    required
                    min={datePickerValues.desde}
                    max={today}
                    defaultValue={datePickerValues.hasta}
                    className={classes.dateFilter} 
                    onChange={handleFiltersDate} 
                  />
                  <Box className={classes.filtersButtonSubmitContainer}>
                    <Button 
                      variant='primary' 
                      type='submit'
                      disabled={loading}
                      className={classes.filtersButtonSubmit}
                      sx={{
                        padding: 1,
                        fontSize: 16,
                        height: 34,
                        marginRight: 1,
                        width: '80px'
                      }}
                    >
                        Filtrar
                    </Button>
                  </Box>
                </form>
              )}
            </Box>
            
            <div className={classes.content}>
              <div className={classes.rowContainer}>
                <Box className={classes.tabsContainer}>
                  <Button 
                    className={classes.tab}
                    variant={currentTab === 'Pendientes' ? 'primary' : 'secondary'} 
                      onClick={() => handleChangeTab('Pendientes')}
                  >
                    Pendientes
                  </Button>
                  <Button 
                    className={classes.tab}
                    variant={currentTab === 'Historial' ? 'primary' : 'secondary'} 
                      onClick={() => handleChangeTab('Historial')}
                  >
                    Historial
                  </Button>
                </Box>
                {historyAccount.length > 0 && currentTab === 'Historial' && (
                  <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}>
                    <Box sx={{
                      display:'flex',
                      alignItems: 'center',
                      marginRight: '20px'
                    }}>
                      <Typography>Filtrar por: </Typography>
                    </Box>
                    <Box sx={{marginRight: '20px'}}>
                      <Typography paragraph className={classes.subtitle}>
                        Tipo de comprobante
                      </Typography>
                      <Box sx={{
                        display:'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end',
                        height: '39px',
                        marginBottom: '20px'
                      }}>
                        <Select
                          variant='standard'
                          sx={{
                            fontSize: '12px',
                            width: '120px',
                            height: '40px'
                          }}
                          name='TipoComprobante'
                          defaultValue='Ver todos'
                          onChange={handleFilterHistory}
                        >
                          <MenuItem value='Ver todos'>Ver todos</MenuItem>
                          <MenuItem value='FC'>FC</MenuItem>
                          <MenuItem value='NC'>NC</MenuItem>
                          <MenuItem value='ND'>ND</MenuItem>
                          <MenuItem value='RC'>RC</MenuItem>
                          <MenuItem value='AS'>AS</MenuItem>
                        </Select>
                      </Box>
                    </Box>
                    <Box>
                      <Typography paragraph className={classes.subtitle}>
                        Número de comprobante
                      </Typography>
                      <input 
                        name='Comprobante' 
                        style={{height: '5px', fontSize: 'initial'}} 
                        id='ComprobanteFiltro' 
                        onChange={debounce(handleFilterHistory, 500)}
                      />
                    </Box>
                  </Box>
                )}
                {((currentTab === 'Historial' && historyAccount.length > 0) ||
                  (currentTab === 'Pendientes' && userInfo.cuentaCorriente.length > 0)) && (
                  <Box className={classes.summaryCard}>
                    <Typography className={classes.description}>{currentTab === 'Pendientes' ? 'Saldo' : `Saldo al ${saldoAlDate}`} </Typography>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                      <Typography className={classes.balance}>
                        {currentTab === 'Pendientes' ? `$ ${saldo ?? 0.0}` : `$ ${historySaldo}`}
                      </Typography>
                        <RoundedSquareIconButton 
                          loading={downloading}
                          onClick={donwloadResumen} 
                          icon={<DownIcon stroke='#53A2FF' />} 
                        />
                    </Box>
                  </Box>
                  )}
              </div>
              {!desktop ? (
                <Grid container className={classes.tableHeaders}>
                  <Typography
                    className={classes.tableHeader}
                    sx={{ width: '23%' }}
                  >
                    Fecha
                  </Typography>
                  <Typography
                    className={classes.tableHeader}
                    sx={{ width: '48%' }}
                  >
                    Comprobante
                  </Typography>
                  {currentTab === 'Pendientes' && (
                    <Typography className={classes.tableHeader}>
                      Pendiente
                    </Typography>
                  )}
                  {currentTab === 'Historial' && (
                    <Typography className={classes.tableHeader}>
                      Importe
                    </Typography>
                  )}
                </Grid>
              ) : (
                <Grid container className={classes.tableHeaders}>
                  <Typography
                    className={classes.tableHeader}
                    sx={{ width: '70px' }}
                  >
                    Tipo
                  </Typography>
                  <Typography
                    className={classes.tableHeader}
                    sx={{ width: currentTab === 'Pendientes' ?  '157px' : '200px' }}
                  >
                    Comprobante
                  </Typography>
                  <Typography
                    className={classes.tableHeader}
                    sx={{ width: '90px' }}
                  >
                    Sucursal
                  </Typography>
                  <Typography
                    className={classes.tableHeader}
                    sx={{ width: currentTab === 'Pendientes' ? '100px' : '150px', textAlign:'center' }}
                  >
                    Fecha
                  </Typography>
                  {currentTab === 'Pendientes' && (
                    <Typography
                      className={classes.tableHeader}
                      sx={{ width: '130px' }}
                    >
                      Fecha de venc.
                    </Typography>
                  )}
                  <Typography
                    className={classes.tableHeader}
                    sx={{ width: '100px', textAlign: 'right' }}
                  >
                    Importe
                  </Typography>
                  {currentTab === 'Pendientes' && (
                    <Typography
                      className={classes.tableHeader}
                      sx={{ width: '100px', textAlign: 'right' }}
                    >
                      Pendiente
                    </Typography>
                  )}
                  <Typography
                    className={classes.tableHeader}
                    sx={{ width: currentTab === 'Pendientes' ?  '100px' : '200px', textAlign: 'right' }}
                  >
                    Saldo Acumulado
                  </Typography>
                  <Typography
                    className={classes.tableHeader}
                    sx={{ width: currentTab === 'Pendientes' ? '100px' : '150px', textAlign: 'center' }}
                  >
                    Descargar
                  </Typography>
                </Grid>
              )}

              <div className={classes.accordions}>
                {loading ? (
                  [...Array(7).keys()].map((index) => (
                    <Skeleton 
                      style={{marginTop: '15px', borderRadius: '5px'}} key={index} variant="rounded" width={'100%'} height={50}
                    />
                  ))
                  ): (
                    <>
                      {currentTab === 'Historial' && (
                        historyAccountFiltered.length === 0 
                        ? <Typography className={classes.noResultsText}>
                            No se encontraron comprobantes
                          </Typography>
                        : historyAccountFiltered?.map((comp, index) => (
                          <Comprobante
                            data={comp}
                            key={index}
                            onChange={handleChange(index)}
                            expanded={expanded === index}
                          />
                        ))
                      )}
                      {currentTab === 'Pendientes' && (
                        userInfo.cuentaCorriente?.length === 0 
                        ? <Typography>
                            No se encontraron comprobantes
                          </Typography>
                        : userInfo.cuentaCorriente.map((comp, index) => (
                          <Comprobante
                            data={comp}
                            key={index}
                            onChange={handleChange(index)}
                            expanded={expanded === index}
                          />
                        ))
                      )}
                 </> 
                )}
              </div>
            </div>
          </div><br></br>
          {currentTab === 'Pendientes' && (
            <Grid
              container
              sx={(theme) => ({
                [theme.breakpoints.up('sm')]: { justifyContent: 'flex-end' },
              })}
            >
              <CurrencyTextField
                label="Monto a pagar"
                variant="standard"
                value={amount}
                currencySymbol="$"
                style={{marginRight:'30px'}}
                decimalCharacter=","
                digitGroupSeparator="."
                outputFormat="string"
                onChange={(event, value) => setAmount(value)}
              />
              <Button
                variant='contained'
                fullWidth
                disabled={!amount ?? false}
                className={classes.button}
                onClick={() => handleClick()}
              >
                Abonar saldo
              </Button>
            </Grid>
          )}
        </div>
      </Grid>
    </>
  )
}

export default CuentaCorriente
