import { useEffect, useState } from 'react'
import Popover from '@mui/material/Popover'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Backdrop from '@mui/material/Backdrop'
import Box from '@mui/material/Box'
import { useDispatch, useSelector } from 'react-redux'
import locationIcon from '../../assets/location.svg'
import { usePopOverSucursalesStyles } from './PopOverSucursales.styles'
import PolygonComponent from './PolygonComponent'
import { selectCurrentBranch } from 'stores/userStore/actionsUser'
import { getCartItems} from 'stores/cartStore/actionsCart'
import { UPDATE_USER_OPENED_CART } from 'stores/userStore/constantsUser'

export default function PopoverComponent() {
  const classes = usePopOverSucursalesStyles()
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState(null)
  const { userInfo, currentBranch } = useSelector((state) => state.userAuth)
  const cart = useSelector((store) => store.cart)

  const handleClick = (event) => setAnchorEl(event.currentTarget)

  const handleClose = () => setAnchorEl(null)

  const handleBranchSwitch = (cod, name, slpCode, openedCart) => () => {
    dispatch(selectCurrentBranch({ cod, name, slpCode, openedCart }))
      dispatch(UPDATE_USER_OPENED_CART(openedCart||cart.idOrder))
      dispatch(getCartItems(userInfo.codigoUsuario, openedCart||cart.idOrder))
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  useEffect(() => {
    if (userInfo.sucursalAsignada)
      dispatch(selectCurrentBranch({ codigo: userInfo.sucursalAsignada }))
  }, [userInfo?.sucursales, userInfo?.sucursalAsignada, userInfo.sucursalPredeterminada])

  useEffect(() => {
    if(!currentBranch?.name && userInfo.sucursales?.length > 0) {
      let selectedBranch = userInfo.sucursales[0]
      if(userInfo.sucursalPredeterminada)
        selectedBranch = userInfo.sucursales.find((s) => s.cod === userInfo.sucursalPredeterminada) || userInfo.sucursales[0]
      dispatch(selectCurrentBranch(selectedBranch))
    }
  }, [currentBranch, userInfo.sucursales?.length])

  return (
    <Box>
      <Stack direction='row' spacing={1}>
        <img src={locationIcon} alt='Localización' />
        {currentBranch?.name && (
          <Typography component='span' className={classes.textStyles}>
            Estás viendo productos disponibles en
          </Typography>
        )}
        <Typography
          component='span'
          aria-describedby={id}
          role='button'
          onClick={handleClick}
          className={`${classes.textStyles} ${classes.branchOfficeText}`}
          title={currentBranch?.name || ''}
        >
          {currentBranch?.name || ''}
        </Typography>
        {userInfo?.sucursales?.length > 1 && <PolygonComponent size={7} />}
      </Stack>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          className={classes.popOverContainer}
        >
        {Array.isArray(userInfo?.sucursales) && userInfo?.sucursales?.map(
            ({ cod, name, slpCode, openedCart }, index) => (
              <Typography
                key={cod}
                className={classes.brancOfficesPopOver}
                role='button'
                onClick={handleBranchSwitch(cod, name, slpCode, openedCart)}
                sx={{
                  borderBottom:
                    index + 1 < userInfo?.sucursales?.length
                      ? '.3px solid #CDCDCD'
                      : null,
                }}
              >
                {name}
              </Typography>
            )
          )}
        </Popover>
      </Backdrop>
    </Box>
  )
}
