import { ApiConnection } from 'config'

import { getUserPromotionsAndOffers } from 'stores/userStore/actionsUser'
import {
  PRODUCT_DETAILS_FAIL,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_LIST_FAIL,
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  CONCAT_PRODUCTS,
} from './constantsProduct'
import { getProducts } from './services/getProducts'

export const listProducts = (
  page,
  items,
  concatProducts = true,
  finderParams,
  entity,
  cardCode
) => async (dispatch, getState) => {
  dispatch({
    type: PRODUCT_LIST_REQUEST,
  })
  try {
    const { codigoUsuario, user } = getState()?.userAuth?.userInfo
    const { response } = await getProducts({
      page,
      items,
      finderParams,
      cardCode: cardCode || codigoUsuario,
    })
    dispatch(getUserPromotionsAndOffers(user))

    if (concatProducts) {
      return dispatch({
        type: CONCAT_PRODUCTS,
        payload: { response, entity },
      })
    }
    dispatch({
      type: PRODUCT_LIST_SUCCESS,
      payload: { response, entity },
    })
  } catch (error) {
    dispatch({ type: PRODUCT_LIST_FAIL, payload: error.message })
  }
}

export const detailsProduct = (productSku, cardCode) => async (
  dispatch,
  getState
) => {
  dispatch({ type: PRODUCT_DETAILS_REQUEST, payload: productSku })
  const { codigoUsuario, user } = getState()?.userAuth?.userInfo
  try {
    const userId = localStorage.getItem('sellerId')
    const { data } = await ApiConnection.get(
      `product/finder`,
      {
        params: {
          sku: productSku,
          cardCode: cardCode || codigoUsuario,
          page: 1,
          items: 1,
          userId
        },
      }
    )

    const product = data.response?.products ? data.response?.products[0] : {}
    dispatch({ type: PRODUCT_DETAILS_SUCCESS, payload: product })
    dispatch(getUserPromotionsAndOffers(user))
  } catch (error) {
    dispatch({
      type: PRODUCT_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
