import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useAccordinElementStyles } from './AccordionElement.styles'

const AccordionElement = ({
  label,
  children,
  selectedValue,
  handleChange,
  accordionExpanded,
  accordionStyles,
}) => {
  const classes = useAccordinElementStyles()

  return (
    <Accordion
      disableGutters
      className={classes.accordionItem}
      expanded={accordionExpanded?.[label] || false}
      onChange={handleChange(label)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        className={classes.accordionSummary}
      >
        <Typography className={classes.filterTitle}>{label}</Typography>
        <Typography className={classes.filterSubtitle}>
          {selectedValue}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          paddingBottom: '7px',
          maxWidth: '100%',
        }}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  )
}
export default AccordionElement
