import React, { useState } from 'react'
import { useStyles } from './WireTransferPage.styles'
import { Box, Typography, Button, Grid, CircularProgress } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Title from 'components/Title'
import FileInput from 'components/DragDropFile'
import { FileUploader } from 'react-drag-drop-files'
import { useDispatch, useSelector } from 'react-redux'
import uploadFile from 'pages/CuentaCorrientePaymentPage/services/uploadFile'
import { setSnackbar } from 'stores/snackbarStore'

const fileTypes = ['JPEG', 'PNG', 'GIF', 'PDF', 'JPG']

const bankDetails = {
  bank: 'SANTANDER RIO',
  accountType: 'Cuenta corriente en pesos',
  CBU: '0720266920000001084690',
  alias: 'vnsariocc',
  accountNumber: '266-010846/9',
  holder: 'VILLA NUEVA SA',
  cuit: '30-55791346-3'
}

function WireTransferPage({ onClick, desktop }) {
  const classes = useStyles()
  const [receipt, setReceipt] = useState(null)
  const [uploading, setUploading] = useState(false)
  const userInfo = useSelector((store) => store.userAuth.userInfo)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const onSubmit = async (e) => {
    e.preventDefault()
    if (receipt) {
      const formData = new FormData()
      formData.append('toUpload', receipt)
      formData.append('tipo', 'comprobante')
      formData.append('cardCode', userInfo?.codigoUsuario)
      setUploading(true)
      try {
        const res = await uploadFile(formData)
        if (res?.data?.message?.includes('exito')) {
          navigate(`/cuenta-corriente/pago/enviado?status=true`)
        } else {
          navigate(`/cuenta-corriente/pago/enviado?status=false`)
        }
      } catch (error) {
        dispatch(setSnackbar(true, 'error', error.message))
      }
      setUploading(false)
    } else {
      dispatch(
        setSnackbar(
          true,
          'error',
          'Por favor, suba un comprobante antes de continuar.'
        )
      )
    }
  }

  return (
    <Box className={classes.container}>
      <form style={{ width: '100%' }} onSubmit={onSubmit}>
        <Box sx={{ marginBottom: '6px' }}>
          {!desktop && (
            <Grid container>
              <ArrowBackIcon onClick={onClick} style={{ color: '#444444' }} />
              <Title
                text={'Transferencia bancaria'}
                customStyles={{ margin: '0 0 30px 15px' }}
              />
            </Grid>
          )}
          <Box className={classes.content}>
            <Typography className={classes.datosTitle}>Datos:</Typography>
            <Box className={classes.datosContainer}>
              <Box className={classes.datosRow}>
                <Typography>Banco</Typography>
                <Typography sx={{ fontWeight: 600 }}>
                  {bankDetails?.bank}
                </Typography>
              </Box>
              <Box className={classes.datosRow}>
                <Typography>Tipo de cuenta</Typography>
                <Typography sx={{ fontWeight: 600 }}>
                  {bankDetails?.accountType}
                </Typography>
              </Box>
              <Box className={classes.datosRow}>
                <Typography>CBU</Typography>
                <Typography sx={{ fontWeight: 600 }}>
                  {bankDetails?.CBU}
                </Typography>
              </Box>
              <Box className={classes.datosRow}>
                <Typography>Alias</Typography>
                <Typography sx={{ fontWeight: 600 }}>
                  {bankDetails?.alias}
                </Typography>
              </Box>
              <Box className={classes.datosRow}>
                <Typography>N° de cuenta</Typography>
                <Typography sx={{ fontWeight: 600 }}>
                  {bankDetails?.accountNumber}
                </Typography>
              </Box>
              <Box className={classes.datosRow}>
                <Typography>Titular de la cuenta</Typography>
                <Typography sx={{ fontWeight: 600 }}>
                  {bankDetails?.holder}
                </Typography>
              </Box>
              <Box className={classes.datosRow}>
                <Typography>CUIT/CUIL</Typography>
                <Typography sx={{ fontWeight: 600 }}>
                  {bankDetails?.cuit}
                </Typography>
              </Box>
            </Box>
          </Box>

          <FileUploader
            handleChange={(file) => setReceipt(file)}
            multiple={false}
            name='receipt'
            types={fileTypes}
            maxSize={10}
            hoverTitle={'Suelta aqui'}
          >
            <FileInput fileName={receipt?.name} prompt='Subir comprobante' />
          </FileUploader>
        </Box>
        <Box>
          <Button
            variant='contained'
            type='submit'
            fullWidth
            className={classes.button}
            disabled={uploading}
          >
            {!uploading ? (
              'Enviar comprobante'
            ) : (
              <CircularProgress disableShrink />
            )}
          </Button>
        </Box>
      </form>
    </Box>
  )
}

export default WireTransferPage
