import React, { useState } from 'react'
import { useStyles } from './ChequePage.styles'
import { Box, Typography, Button, Grid, CircularProgress } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setSnackbar } from 'stores/snackbarStore'
import Title from 'components/Title'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import FileInput from 'components/DragDropFile'
import { FileUploader } from 'react-drag-drop-files'
import uploadFile from 'pages/CuentaCorrientePaymentPage/services/uploadFile'

const chequeDetails = {
  razonSocial: 'Villanueva S.A',
  CUIT: '24-33004563-2',
}

const fileTypes = ['JPEG', 'PNG', 'GIF', 'PDF', 'JPG']

function ChequePage({ onClick, desktop = false }) {
  const [fileFrente, setFileFrente] = useState(null)
  const [fileDorso, setFileDorso] = useState(null)
  const [uploading, setUploading] = useState(false)
  const userInfo = useSelector((store) => store.userAuth?.userInfo)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const classes = useStyles()

  const onSubmit = async (e) => {
    e.preventDefault()
    if (fileFrente && fileDorso) {
      const formData = new FormData()
      formData.append('toUpload', fileFrente)
      formData.append('tipo', 'cheque-frente')
      formData.append('cardCode', userInfo?.codigoUsuario)
      const formData2 = new FormData()
      formData2.append('toUpload', fileDorso)
      formData2.append('tipo', 'cheque-dorso')
      formData2.append('cardCode', userInfo?.codigoUsuario)
      setUploading(true)
      try {
        const res = await uploadFile(formData)
        const res2 = await uploadFile(formData2)
        if (
          res?.data?.message?.includes('exito') &&
          res2?.data?.message?.includes('exito')
        ) {
          navigate(`/cuenta-corriente/pago/enviado?status=true`)
        } else {
          navigate(`/cuenta-corriente/pago/enviado?status=false`)
        }
      } catch (error) {
        dispatch(setSnackbar(true, 'error', error.message))
      }
      setUploading(false)
    } else {
      dispatch(
        setSnackbar(
          true,
          'error',
          'Por favor, suba ambos archivos antes de continuar.'
        )
      )
    }
  }

  return (
    <Box className={classes.container}>
      <form style={{ width: '100%' }} onSubmit={onSubmit}>
        <Box sx={{ marginBottom: '6px' }}>
          {!desktop && (
            <Grid container>
              <ArrowBackIcon onClick={onClick} style={{ color: '#444444' }} />
              <Title
                text={'Cheque'}
                customStyles={{ margin: '0 0 30px 15px' }}
              />
            </Grid>
          )}
          <Box className={classes.content}>
            <Typography className={classes.datosTitle}>Datos:</Typography>
            <Box className={classes.datosContainer}>
              <Box className={classes.datosRow}>
                <Typography>Razón social</Typography>
                <Typography sx={{ fontWeight: 600 }}>
                  {chequeDetails?.razonSocial}
                </Typography>
              </Box>
              <Box className={classes.datosRow}>
                <Typography>CUIT</Typography>
                <Typography sx={{ fontWeight: 600 }}>
                  {chequeDetails?.CUIT}
                </Typography>
              </Box>
            </Box>
          </Box>

          <FileUploader
            handleChange={(file) => setFileFrente(file)}
            multiple={false}
            name='frente'
            types={fileTypes}
            maxSize={10}
            hoverTitle={'Suelta aqui'}
          >
            <FileInput
              fileName={fileFrente?.name}
              prompt='Subir foto del frente'
            />
          </FileUploader>

          <FileUploader
            handleChange={(file) => setFileDorso(file)}
            multiple={false}
            name='dorso'
            types={fileTypes}
            maxSize={10}
            hoverTitle={'Suelta aqui'}
          >
            <FileInput
              fileName={fileDorso?.name}
              prompt='Subir foto del dorso'
            />
          </FileUploader>
        </Box>
        <Box>
          <Button
            variant='contained'
            fullWidth
            type='submit'
            className={classes.button}
            disabled={uploading}
          >
            {!uploading ? 'Enviar cheque' : <CircularProgress disableShrink />}
          </Button>
        </Box>
      </form>
    </Box>
  )
}

export default ChequePage
