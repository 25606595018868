import { useStyles } from './SelectClient.styles'
import React, { useEffect, useState } from 'react'
import Title from 'components/Title'
import ClientSearchBox from './ClientSearchBox'
import {
  Typography,
  Button,
  Grid,
  Slide,
  Dialog,
  useMediaQuery,
  Backdrop,
  CircularProgress,
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import {
  openSelectClientModal,
  sellerClientLogin,
} from 'stores/userStore/actionsUser'
import CustomCloseIcon from 'components/CloseIcon'
import getAllClients from './services/getAllClients'
import { setSnackbar } from 'stores/snackbarStore'
import LoadingSpinner from 'components/LoadingSpinner'
import { useNavigate } from 'react-router-dom'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

function SelectClient() {
  const classes = useStyles()
  const desktop = useMediaQuery((theme) => theme.breakpoints.up('sm'))
  const dispatch = useDispatch()
  const openModal = useSelector((state) => state.userAuth.openSelectClientModal)
  const userInfo = useSelector((state) => state.userAuth.userInfo)
  const navigate = useNavigate()

  const [query, setQuery] = useState('')
  const [clients, setClients] = useState([])
  const [loading, setLoading] = useState(false)
  const [loggingIn, setLoggingIn] = useState(false)

  useEffect(async () => {
    if (openModal && clients.length === 0) {
      setLoading(true)
      const sellerId = localStorage.getItem('sellerId')
      try {
        const res = await getAllClients(
          userInfo?.id || sellerId,
          userInfo?.sucursalAsignada
        )
        setClients(res.response)
      } catch (error) {
        dispatch(setSnackbar(true, 'error', error.message))
      }
      setLoading(false)
    }
  }, [openModal])

  const handleClientCard = (client) => (e) => {
    try {
      setLoggingIn(true)
      if (!localStorage.getItem('sellerId') && userInfo?.id)
        localStorage.setItem('sellerId', userInfo.id)
      dispatch(sellerClientLogin(client.user, client.sucursal)).then((res) => {
        setLoggingIn(false)
        handleClose()
        navigate('/')
        setQuery('')
      })
    } catch (error) {
      dispatch(
        setSnackbar(true, 'error', 'Error al intentar logearse con el usuario')
      )
    }
  }

  const handleClose = () => {
    dispatch(openSelectClientModal(false))
  }

  return (
    <>
      <Dialog
        open={openModal}
        TransitionComponent={Transition}
        fullScreen={!desktop}
        onClose={handleClose}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Grid className={classes.innerContainer}>
          <CustomCloseIcon handleClose={handleClose} />
          <Title
            text='¿Con qué cliente querés operar?'
            customStyles={{ margin: 0 }}
          />
          <ClientSearchBox onChange={setQuery} />
          {loading && <LoadingSpinner />}
          {clients
            ?.filter(
              (val) =>
                query === '' ||
                val?.razonSocial.toLowerCase().includes(query.toLowerCase()) ||
                val?.user.toLowerCase().includes(query.toLowerCase())
            )
            .map((client, index) => (
              <Button
                key={index}
                className={classes.clientCard}
                onClick={handleClientCard(client)}
              >
                <Typography>
                  {client?.razonSocial} - {client?.user} - {client?.sucursal}
                </Typography>
              </Button>
            ))}
        </Grid>
      </Dialog>
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.modal + 1,
          backdropFilter: 'blur(4px)',
        }}
        open={loggingIn}
      >
        <CircularProgress color='inherit' />
        <Typography variant='h6' sx={{ marginLeft: 2 }}>
          Ingresando... Por favor espere...
        </Typography>
      </Backdrop>
    </>
  )
}

export default SelectClient
