import React from 'react'
import { Typography } from '@mui/material'
import uploadCloud from '../assets/uploadCloud.svg'

const FileInput = ({ fileName, prompt }) => {
  return (
    <div
      style={{
        border: '1px dashed #999999',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '14px 5px',
        boxSizing: 'border-box',
        borderRadius: '8px',
        backgroundColor: 'white',
        marginBottom: '16px',
        cursor: 'pointer',
      }}
    >
      <img src={uploadCloud} style={{ marginBottom: '13px' }} />
      <Typography
        sx={(theme) => ({
          textAlign: 'center',
          color: '#999999',
          fontSize: '12px',
          fontWeight: 500,
          marginBottom: '13px',
          maxWidth: '120px',
        })}
      >
        {!fileName ? prompt ?? 'Subir archivo' : `Archivo subido: ${fileName}`}
      </Typography>
      <Typography
        sx={(theme) => ({
          textAlign: 'center',
          color: '#999999',
          fontSize: '10px',
        })}
      >
        Limite de 10 MB
      </Typography>
    </div>
  )
}

export default FileInput
