import React, { forwardRef } from 'react'
import {
  Grid,
  Dialog,
  Box,
  useMediaQuery,
  Slide,
  IconButton
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import { setCompraRapidaModalIsClosed } from 'stores/appStore/actionsApp'
import { ProductDetailsSection } from 'pages/ProductPage/ProductDetailsSection'
import { useDispatch, useSelector } from 'react-redux';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

export default function CompraRapidaModal () {
  const desktop = useMediaQuery((theme) => theme.breakpoints.up('sm'), { noSsr: true })
  const {isOpen} = useSelector((state) => state.app.modales.compraRapida)
  const dispatch = useDispatch();

  const close = () => {
    dispatch(setCompraRapidaModalIsClosed())
  }

  return (
    <Dialog
      open={isOpen} 
      onClose={close}
      transition={Transition}
      maxWidth={'lg'}
    >
      <Grid style={{maxWidth: '1040px'}}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            position: 'relative',
            marginBottom: desktop ? 'auto' : '20px'
          }}
        >
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={close}
            sx={{
              marginTop: 1,
              marginRight: 1,
              position: 'absolute',
              zIndex: 1,
              right: 0
            }}
          >
            <CloseIcon/>
          </IconButton>
        </Box>
        <ProductDetailsSection compraRapida closeCompraRapidaModal={close} />
      </Grid>
    </Dialog>
  )
}