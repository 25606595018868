import React from 'react'

export default function HomeIcon({ active }) {
  return (
    <svg
      width='17'
      height='18'
      viewBox='0 0 17 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.94439 16.9039V14.1437C5.94438 13.4443 6.50981 12.876 7.21018 12.8716H9.78181C10.4853 12.8716 11.0556 13.4412 11.0556 14.1437V16.8959C11.0556 17.5026 11.5457 17.9956 12.1532 18H13.9076C14.727 18.0021 15.5136 17.6785 16.0938 17.1006C16.6739 16.5228 17 15.7381 17 14.9198V7.07926C17 6.41825 16.7066 5.79124 16.1989 5.36715L10.2385 0.606842C9.19656 -0.22579 7.70849 -0.198893 6.69745 0.670846L0.865221 5.36715C0.333505 5.77874 0.0157047 6.40761 0 7.07926V14.9118C0 16.6173 1.3845 18 3.09237 18H4.80679C5.09926 18.0021 5.38049 17.8876 5.58805 17.6818C5.79562 17.476 5.91235 17.196 5.91235 16.9039H5.94439Z'
        fill={active ? '#48854E' : '#9B9B9B'}
      />
    </svg>
  )
}
