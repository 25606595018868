import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '80vh',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
      minHeight: 0,
    },
  },
  content: {
    paddingLeft: '5px',
    [theme.breakpoints.up('sm')]: {
      backgroundColor: '#EEEEEE',
      borderRadius: '8px',
      padding: '20px',
      marginBottom: '21px',
    },
  },
  datosContainer: {
    marginBottom: '30px',
    [theme.breakpoints.up('sm')]: {
      marginBottom: 0,
    },
  },
  datosTitle: {
    fontSize: '16px',
    fontWeight: 600,
    marginBottom: '30px',
    color: theme.palette.primary.almostBlack,
    [theme.breakpoints.up('sm')]: {
      marginBottom: '20px',
    },
  },
  datosRow: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '4px',
    '& p': {
      fontSize: '14px',
      color: theme.palette.primary.almostBlack,
    },
  },
  button: {
    maxHeight: '56px',
    padding: '13px 0px',
    textTransform: 'capitalize',
    fontSize: '18px',
    borderRadius: '6px',
    [theme.breakpoints.up('sm')]: {
      float: 'right',
    },
  },
}))
