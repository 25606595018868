import React, { useEffect, useState } from 'react'
import {
  Typography,
  Button,
  TextField,
  Dialog,
  useMediaQuery,
  Slide,
  CircularProgress,
  Grid,
} from '@mui/material'
import { useForm } from 'react-hook-form'
import { useStyles } from './CaravanasOficiales.styles'
import { useNavigate } from 'react-router-dom'
import ClientDetails from './ClientDetails'
import getProducerList from './services/getProducerList'
import { setSnackbar } from 'stores/snackbarStore'
import { useDispatch } from 'react-redux'
import AddProducer from './forms/AddProducer'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

function CaravanasCombinadas({
  open,
  onClose,
  onSave,
  numberTo,
  showFromNumber,
  packQuantity
}) {
  const classes = useStyles()
  const desktop = useMediaQuery((theme) => theme.breakpoints.up('sm'))
  const minimumQueryLength = 3
  const navigate = useNavigate()
  const dispatch = useDispatch()

  // STATE
  const [loading, setLoading] = useState(false)
  const [selectedProducer, setSelectedProducer] = useState(null)
  const [producerOptions, setProducerOptions] = useState([])
  const [showAddProducer, setShowAddProducer] = useState(false)
  const [selectedProducerErrors, setSelectedProducerErrors] = useState({})

  const [query, setQuery] = useState('')

  useEffect(() => {
    if (query.trim().length === 0) {
      setProducerOptions([])
      setSelectedProducer(null)
    }
    if (query.length >= minimumQueryLength) {
      setLoading(true)
      getProducerList(query)
        .then((res) => {
          setProducerOptions(res.slice(0, 5))
          setSelectedProducer(res?.[0])
          setLoading(false)
        })
        .catch((error) => alert('Error al traer productores', error.message))
    }
  }, [query])

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      productor: '',
      CV: '',
      observaciones: '',
      textoTarjeta: '',
      numeracionDesde: 1,
    },
  })

  // EVENTS
  const flipShowAddProducer = (e) => {
    setShowAddProducer(!showAddProducer)
  }

  const handleProducerSelect = (producer) => (e) => {
    e.preventDefault()
    setSelectedProducer(producer)
    setProducerOptions([])
  }

  const addQuantityAndSubmit = (data) => {
    if (selectedProducer === null) {
      return alert('Por favor seleccione un productor')
    }
    if(!selectedProducer.digitoVerificador) {
      return setSelectedProducerErrors({
        ...selectedProducerErrors, 
        digitoVerificador: { message: 'CV es requerido' }
      })
    }

    if (data.numeracionDesde) {
      const numeroDesdeValido = (Number(data.numeracionDesde) - 1) % packQuantity === 0
      if (!numeroDesdeValido) {
        dispatch(
          setSnackbar(
            true,
            'error',
            `Ingrese un número desde válido. (Ej: 1, ${[1,2,3,4].map((x) => (packQuantity * x) + 1).join(', ')})`
          )
        )
        return
      }
    }
    const newData = {
      ...data,
      cantidad: numberTo,
      productor: selectedProducer?.id,
      numeracionHasta: parseInt(data.numeracionDesde) + numberTo - 1,
    }
    onSave(newData)
    navigate('/carrito')
    onClose()
  }

  const handleKeyDown = (e) => {
    // 65 to 90 letter keys
    // 32 spacebar
    // 8 backspace
    // 13 enter
    setQuery(e.target.value)
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      fullScreen={!desktop}
      onClose={onClose}
      maxWidth={'md'}
    >
      <div className={classes.container}>
        <div className={classes.titleAndFormContainer}>
          <Typography className={classes.title}>
            Pedido de caravanas combinadas
          </Typography>

          <form
            style={{ width: '100%', marginBottom: '30px' }}
            onSubmit={(e) => {
              e.preventDefault()
            }}
          >
            <Typography className={classes.tagName}>
              Titular / RENSPA / CUIG
            </Typography>
            <TextField
              InputProps={{
                onKeyDown: handleKeyDown,
              }}
              variant='outlined'
              fullWidth
              required
              id='titular'
              placeholder='Buscar'
              name='titular'
              className={classes.textField}
              // {...register('titular', {
              //   required: 'El titular es obligatorio',
              // })}
              helperText={errors?.titular?.message ?? ''}
            />

            {loading ? (
              <Grid sx={{ textAlign: 'center', padding: '10px' }}>
                <CircularProgress disableShrink size={30} />
              </Grid>
            ) : (
              <>
                {producerOptions.length > 1 && (
                  <>
                    <Typography variant='h3' sx={{ padding: '14px 0px' }}>
                      Seleccione una opción
                    </Typography>
                    {producerOptions.map((producer, index) => (
                      <Button
                        key={index}
                        variant='secondary'
                        sx={{
                          fontSize: '16px',
                          textTransform: 'capitalize',
                        }}
                        onClick={handleProducerSelect(producer)}
                      >
                        {producer.nombre} - {producer.cuig}
                      </Button>
                    ))}
                  </>
                )}
                {selectedProducer ? (
                  <ClientDetails 
                    selectedProducer={selectedProducer} 
                    setSelectedProducer={setSelectedProducer} 
                    errors={selectedProducerErrors}
                    setErrors={setSelectedProducerErrors}
                  />
                ) : showAddProducer ? (
                  <Typography
                    className={classes.agregar}
                    onClick={() => setShowAddProducer(false)}
                  >
                    Volver
                  </Typography>
                ) : (
                  <Typography
                    className={classes.agregar}
                    onClick={() => setShowAddProducer(true)}
                  >
                    Agregar nuevo productor
                  </Typography>
                )}
              </>
            )}

            {showAddProducer && producerOptions.length === 0 && (
              <AddProducer hideForm={flipShowAddProducer} />
            )}

            <Grid container sx={{ gap: '20px' }} wrap='nowrap'>
              <Grid sx={{ width: '50%' }}>
                <Typography className={classes.tagName}>CV</Typography>
                <TextField
                  onKeyDown={(e) =>
                    e?.code?.includes('Key') &&
                    !e?.ctrlKey &&
                    e.preventDefault()
                  }
                  onPaste={(e) => {
                    isNaN(e.clipboardData.getData('text')) && e.preventDefault()
                  }}
                  InputProps={{
                    inputProps: { min: '1' },
                  }}
                  className={classes.textField}
                  variant='outlined'
                  fullWidth
                  required
                  id='CV'
                  placeholder='Ej: 203'
                  name='CV'
                  type='number'
                  {...register('CV', { required: 'el CV es obligatorio' })}
                  helperText={errors?.CV?.message ?? ''}
                />
              </Grid>

              {showFromNumber && (
                <Grid sx={{ width: '50%' }}>
                  <Typography className={classes.tagName}>
                    Numero desde
                  </Typography>
                  <TextField
                    onKeyDown={(e) =>
                      e?.code?.includes('Key') &&
                      !e?.ctrlKey &&
                      e.preventDefault()
                    }
                    onPaste={(e) => {
                      isNaN(e.clipboardData.getData('text')) &&
                        e.preventDefault()
                    }}
                    InputProps={{
                      inputProps: { min: '1' },
                    }}
                    placeholder='Numeración desde'
                    fullWidth
                    {...register('numeracionDesde', {
                      required: 'la numeracion desde es obligatoria',
                    })}
                    type='number'
                    className={classes.textField}
                    helperText={errors?.numeracionDesde?.message ?? ''}
                  />
                </Grid>
              )}
            </Grid>

            <Typography className={classes.tagName}>
              Texto impreso en caravana de manejo
            </Typography>
            <TextField
              variant='outlined'
              fullWidth
              required
              id='textoTarjeta'
              placeholder='Buscar'
              name='textoTarjeta'
              className={classes.textField}
              {...register('textoTarjeta')}
              helperText={errors?.textoTarjeta?.message ?? ''}
            />

            <Typography className={classes.tagName}>Observaciones</Typography>
            <TextField
              variant='outlined'
              fullWidth
              multiline
              minRows={4}
              maxRows={8}
              id='observaciones'
              placeholder='Ej: Retira Manuel Garcia, de Transportix'
              name='observaciones'
              className={classes.textField}
              {...register('observaciones')}
              helperText={errors?.observaciones?.message ?? ''}
            />
          </form>
        </div>
        <div className={classes.buttons}>
          <Button
            onClick={handleSubmit(addQuantityAndSubmit)}
            variant='contained'
            fullWidth
            className={classes.buttonBuy}
          >
            Continuar
          </Button>

          <Button
            onClick={onClose}
            variant='text'
            fullWidth
            className={classes.buttonBack}
          >
            Volver
          </Button>
        </div>
      </div>
    </Dialog>
  )
}

export default CaravanasCombinadas
