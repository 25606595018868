import React from 'react'
import { IconButton } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

function RightButton({ disabled, sx, size, color, handleFunc }) {
  return (
    <IconButton
      onClick={handleFunc}
      disabled={disabled}
      sx={sx}
      size={size}
      color={color}
    >
      <ChevronRightIcon />
    </IconButton>
  )
}

export default RightButton
