import React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import imageSuccess from '../../../assets/imageSuccess.png'
import error from '../../../assets/error.png'
import { Box, Grid, Typography, Button } from '@mui/material'
import { useStyles } from './SuccessPage.styles'
import { Helmet } from 'react-helmet'

const SuccessPage = () => {
  const navigate = useNavigate()
  const classes = useStyles()
  const [searchParams] = useSearchParams()
  const status = JSON.parse(searchParams.get('status'))

  const goToHome = () => {
    navigate('/')
  }

  return (
    <>
      <Helmet>
        <title>
          {status
            ? 'Comprobante subido con éxito'
            : 'Error al subir comprobante'}{' '}
          - Cuenta corriente | Villanueva
        </title>
        <meta
          name='description'
          content={
            status
              ? 'Comprobante subido con éxito'
              : 'Error al subir comprobante'
          }
        />
        <meta name='keywords' content={'comprobante, subido, éxito, error'} />
      </Helmet>
      <Box
        sx={(theme) => ({
          height: '85vh',
          backgroundColor: { xs: 'white', sm: 'default' },
        })}
      >
        <Box className={classes.container}>
          {status ? (
            <Box className={classes.content}>
              <img
                src={imageSuccess}
                alt='Éxito'
                style={{ borderRadius: 50, marginBottom: '26px' }}
              />
              <Typography className={classes.title}>
                ¡El comprobante se ha <br />
                subido con éxito!
              </Typography>
              <Typography paragraph className={classes.subtitleInfo}>
                El impacto del saldo en la cuenta corriente se verá reflejado en
                las próximas horas.
              </Typography>
            </Box>
          ) : (
            <Box className={classes.content}>
              <Grid item style={{ marginBottom: '25px' }}>
                <img src={error} alt='Error' />
              </Grid>
              <Grid item>
                <Typography
                  className={classes.title}
                  sx={(theme) => ({ color: theme.palette.error.main })}
                >
                  Error
                </Typography>
                <Typography paragraph className={classes.subtitleInfo}>
                  Desafortunadamente tuvimos un problema para subir tu
                  comprobante. Vuelve a intentarlo más tarde.
                </Typography>
              </Grid>
            </Box>
          )}

          {status ? (
            <Box className={classes.buttons}>
              <Button
                onClick={goToHome}
                variant='contained'
                fullWidth
                className={classes.btnPrimary}
              >
                Volver al inicio
              </Button>
              <Button
                onClick={() => navigate('/cuenta-corriente')}
                variant='text'
                fullWidth
                className={classes.btnSecondary}
              >
                Ver cuenta corriente
              </Button>
            </Box>
          ) : (
            <Box className={classes.buttons}>
              <Button
                onClick={goToHome}
                variant='contained'
                fullWidth
                className={classes.btnPrimary}
              >
                Volver al inicio
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </>
  )
}

export default SuccessPage
