import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'

function CustomCloseIcon({ handleClose }) {
  return (
    <IconButton
      aria-label='close'
      onClick={handleClose}
      size='large'
      sx={{
        position: 'absolute',
        right: 8,
        top: 8,
        color: (theme) => theme.palette.primary.almostBlack,
      }}
    >
      <CloseIcon />
    </IconButton>
  )
}

export default CustomCloseIcon
