import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { changeLoginState, openSelectClientModal, } from '../../stores/userStore/actionsUser'
import { getCartItems } from '../../stores/cartStore/actionsCart'
import { useStyles } from './Header.styles'
import SidebarComponent from '../Sidebar/index'
import SinginPage from '../SigninModal/SigninModal'
import TopMenu from 'components/TopMenu/TopMenu'
import SearchBoxAlt from './SearchBoxAlt'
import { Box, Grid, Badge, useMediaQuery, Typography } from '@mui/material/'
import UserIcon from '@mui/icons-material/Person'
import GroupIcon from '@mui/icons-material/Group'
import CartIcon from '@mui/icons-material/ShoppingCart'
import Logo from '../../assets/logoCompleto.svg'
import sinAvatar from '../../assets/sinAvatar.svg'
import SelectClient from 'components/SelectClient/SelectClient'

const isAdmin = false

const HeaderComponent = () => {
  const dispatch = useDispatch()
  const { signedIn, showModalLogin, userInfo, currentBranch } = useSelector(
    ({ userAuth }) => userAuth
  )
  // eslint-disable-next-line camelcase
  const { product, product_manejo, product_official } = useSelector(
    ({ cart }) => cart
  )
  const cart = useSelector((store) => store.cart)
  useEffect(() => {
    if (JSON.parse(localStorage.getItem('signedIn'))) {
      const localUserInfo = JSON.parse(localStorage.getItem('userInfo'))
      Array.isArray(localUserInfo.sucursales) &&
      dispatch(
        getCartItems(
          userInfo?.codigoUsuario,
          cart.idOrder ??
            localStorage.getItem('idOrder') ??
            userInfo?.openedCart
        )
      )
    }
  }, [dispatch, signedIn])

  const desktop = useMediaQuery((theme) => theme.breakpoints.up('sm'))
  const classes = useStyles()
  const budgeNumber =
    (product?.length || 0) +
    (product_manejo?.length || 0) +
    (product_official?.length || 0)

  const isSeller = localStorage.getItem('permisos') === '2' || userInfo.permisos === '2'

  return (
    <>
      <Box component='header' className={classes.container}>
        <div className={classes.innerContainer}>
          <SinginPage />
          <SelectClient />
          {!desktop && <SidebarComponent />}
          <Link to='/' className={classes.logo}>
            <img src={Logo} alt='Logo Villanueva' />
          </Link>
          {desktop && <SearchBoxAlt />}
          <div>
            {!signedIn ? (
              <Grid
                title='Iniciar sesion'
                onClick={() => dispatch(changeLoginState(showModalLogin))}
                style={{ padding: '0px' }}
                className={classes.signIn}
              >
                {desktop && <Typography>Iniciar Sesión</Typography>}
                <UserIcon
                  size='20'
                  sx={(theme) => ({
                    color: theme.palette.primary.almostBlack,
                  })}
                />
              </Grid>
            ) : (
              <div className={classes.signedIn}>
                {desktop && (
                  <div className={classes.userCard}>
                    <Link to='/resumen' style={{ padding: 0 }}>
                      <div className={classes.userCardAvatar}>
                        <img
                          src={userInfo?.avatar ?? sinAvatar}
                          alt='avatar del usuario'
                          style={{
                            display: 'block',
                            borderRadius: '50%',
                            width: '100%',
                          }}
                        />
                      </div>
                    </Link>
                    <Link to='/resumen' style={{ padding: 0 }}>
                      <Typography className={classes.userCardName}>{`${
                        userInfo?.razonSocial !== 'false'
                          ? userInfo?.razonSocial &&
                            userInfo?.razonSocial.length > 20
                            ? `${userInfo?.razonSocial.slice(0, 10)} ...`
                            : userInfo?.razonSocial ?? 'User'
                          : userInfo?.user
                      }`}</Typography>
                      <Typography sx={{ fontSize: 12 }}>
                        Ver mi cuenta
                      </Typography>
                    </Link>
                  </div>
                )}
                {isAdmin && (
                  <Link to='/userlist' style={{ padding: '0px 15px 0px 0px' }}>
                    <GroupIcon size='20' />
                  </Link>
                )}
                {userInfo.permisos !== '2' && (
                  <Link to='/carrito' style={{ padding: '0px', marginRight: 10 }}>
                    <Badge badgeContent={budgeNumber} color='primary'>
                      <CartIcon size='20' />
                    </Badge>
                  </Link>
                )}
                {isSeller && (
                  <Grid
                    title='Mis clientes'
                    onClick={() => dispatch(openSelectClientModal(true))}
                    style={{ padding: '0px' }}
                    className={classes.signIn}
                  >
                    <Typography>Mis clientes</Typography>
                  </Grid>
                )}
              </div>
            )}
          </div>
        </div>
      </Box>
      {desktop && <TopMenu />}
    </>
  )
}

export default HeaderComponent
