import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '60px 10px',
    color: theme.palette.primary.almostBlack,
    [theme.breakpoints.up('sm')]: {
      padding: '60px 0px',
      maxWidth: '1040px',
      margin: 'auto',
    },
  },
  titleBox: {
    textAlign: 'center',
    marginBottom: '50px',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'left',
      marginBottom: 0,
      maxWidth: '330px',
      margin: 'auto',
    },
  },
  title: {
    marginBottom: '5px',
    fontWeight: 600,
    fontSize: '30px',
    lineHeight: '42px',
    [theme.breakpoints.up('sm')]: {},
  },

  disclaimer: {
    fontSize: '10px',
    lineHeight: '16px',
    color: theme.palette.primary.lightGrey,
  },
  toggleButtonGroup: {
    marginBottom: '18px',
    '& .Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  toggleButton: {
    textTransform: 'none',
  },
  formLabel: {
    fontSize: '12px',
    fontWeight: 600,
    marginBottom: '7px',
  },
  textField: {
    marginBottom: '18px',
    '& .MuiInputBase-root': {
      fontSize: '12px',
    },
    '& .MuiFormHelperText-root': {
      color: 'red',
    },
  },
  shorterField: {
    [theme.breakpoints.up('sm')]: {
      width: '274px',
    },
  },
  submitButton: {
    fontSize: '16px',
    marginTop: '34px',
    [theme.breakpoints.up('sm')]: {
      width: '274px',
    },
  },
}))

export default useStyles
