import React, { useState } from 'react'
import {
  detailsWidths,
  useStyles,
  getStatusColor,
  tableColumns,
  columnWidths,
} from './OrderListDesktop.styles'
import {
  Grid,
  Button,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import RoundedSquareIconButton from 'components/RoundedSquareIconButton'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import OrderDetailModalDesktop from './OrderDetailModalDesktop'
import {
  parseCodigo,
  parseDateString,
  parseMoney,
  toInt,
  trimString,
} from '../utils/parseFunctions'
import SearchBox from '../components/SearchBox'

function OrderListDesktop({
  orderList,
  handleSearchSubmit,
  resetOrders,
  showAllButton,
  loading,
  totalOrderLength = 0,
}) {
  const classes = useStyles()
  const [expanded, setExpanded] = useState(-1)
  const [openDetail, setOpenDetail] = useState(false)
  const [modalProduct, setModalProduct] = useState({})

  const handleChange = (panel) => (_event, newExpanded) => {
    setExpanded(newExpanded ? panel : -1)
  }

  const handleOpenModal = (e, producto) => {
    e.preventDefault()
    setModalProduct(producto)
    setOpenDetail(true)
  }

  return (
    <Grid className={classes.container}>
      <Grid container alignItems='center' sx={{ marginBottom: '0px' }}>
        <SearchBox
          placeholder='Buscar pedido por codigo'
          containerStyles={{
            width: '50%',
            margin: 0,
            display: 'inline-block',
            paddingTop: '4px',
          }}
          onSubmit={handleSearchSubmit}
        />
        {showAllButton && (
          <Button
            sx={{ marginLeft: '8px', color: '#444444' }}
            variant='text'
            onClick={resetOrders}
          >
            Ver todos
          </Button>
        )}
      </Grid>
      <Grid sx={{ margin: '16px 6px 10px' }}>
        <Typography variant='p' sx={{ color: '#444', fontWeight: 400 }}>
          Mostrando {orderList.length} de {totalOrderLength} pedidos.
        </Typography>
      </Grid>
      <Grid className={classes.table}>
        <Grid container className={classes.tableHeadersRow}>
          <Typography variant='h3' sx={{ width: tableColumns[0] }}>
            Fecha del pedido
          </Typography>
          <Typography variant='h3' sx={{ width: tableColumns[1] }}>
            Código de pedido
          </Typography>
          <Typography variant='h3' sx={{ width: tableColumns[2] }}>
            Total del pedido sin IVA
          </Typography>
          <Typography variant='h3' sx={{ width: tableColumns[3] }}>
            Estado general
          </Typography>
        </Grid>
        {loading && (
          <Grid
            container
            alignItems='center'
            justifyContent='center'
            sx={{ minHeight: '30vh' }}
            className={classes.borderTop}
          >
            <CircularProgress color='primary' disableShrink />
          </Grid>
        )}
        {orderList?.length === 0 && !loading && (
          <Grid
            container
            alignItems='center'
            justifyContent='center'
            sx={{ minHeight: '30vh' }}
            className={classes.borderTop}
          >
            <Typography variant='h3'>{'No se encontraron pedidos'}</Typography>
          </Grid>
        )}
        {orderList.map((order, index) => {
          let totalPrice = 0;
          for (const a of order.articulos) {
            totalPrice = totalPrice + ((parseFloat(a.Price).toFixed(2) * parseFloat(a.CantidadPedida).toFixed(2)) - (((parseFloat(a.Price).toFixed(2) * parseFloat(a.CantidadPedida).toFixed(2)) * parseFloat(a.Desc || a.DescLinea).toFixed(2)) / 100))
          }
          return (
            <Accordion
              disableGutters
              className={classes.accordionContainer}
              onChange={handleChange(index)}
              expanded={expanded === index}
              square
              key={index}
            >
              <AccordionSummary
                className={classes.summary}
                expandIcon={<ExpandMoreIcon />}
              >
                <Grid container alignItems='center'>
                  <Typography variant='p' sx={{ width: tableColumns[0] }}>
                    {parseDateString(order?.fecha)}
                  </Typography>
                  <Typography variant='p' sx={{ width: tableColumns[1] }}>
                    {parseCodigo(order?.codigo)}
                  </Typography>
                  <Typography variant='p' sx={{ width: tableColumns[2] }}>
                    $ {parseFloat(totalPrice).toFixed(2)}
                  </Typography>
                  <Typography
                    variant='p'
                    sx={{
                      width: tableColumns[3],
                      color: getStatusColor(order?.estado),
                      fontWeight: 500,
                    }}
                  >
                    {order?.estado}
                  </Typography>
                </Grid>
              </AccordionSummary>

              <AccordionDetails className={classes.details}>
                <Grid container className={classes.detailsHeadersRow}>
                  <Typography
                    variant='h3'
                    sx={{ fontWeight: 500, width: columnWidths.cantidad }}
                  >
                    Cant.
                  </Typography>
                  <Typography
                    variant='h3'
                    sx={{ fontWeight: 500, width: columnWidths.producto }}
                  >
                    Producto
                  </Typography>
                  <Typography
                    variant='h3'
                    sx={{ fontWeight: 500, width: columnWidths.precioDeLista }}
                  >
                    Precio de lista
                  </Typography>
                  <Typography
                    variant='h3'
                    sx={{ fontWeight: 500, width: columnWidths.descuento }}
                  >
                    Descuento
                  </Typography>
                  <Typography
                    variant='h3'
                    sx={{ fontWeight: 500, width: columnWidths.transporte }}
                  >
                    Transporte
                  </Typography>
                  <Typography
                    variant='h3'
                    sx={{ fontWeight: 500, width: columnWidths.precio }}
                  >
                    Total de Linea
                  </Typography>
                  <Typography
                    variant='h3'
                    sx={{ fontWeight: 500, width: columnWidths.despachado }}
                  >
                    Despachado
                  </Typography>

                  <Typography
                    variant='h3'
                    sx={{ fontWeight: 500, width: columnWidths.estado }}
                  >
                    Estado
                  </Typography>
                  <Typography
                    variant='h3'
                    sx={{ fontWeight: 500, width: columnWidths.detalle }}
                  >
                    Detalle
                  </Typography>
                </Grid>
                {order?.articulos?.map((producto, index) => (
                  <Grid className={classes.detailRow} key={index}>
                    <Typography variant='p' sx={{ width: columnWidths.cantidad }}>
                      {toInt(producto?.CantidadPedida)}
                    </Typography>
                    <Typography
                      variant='p'
                      sx={{ width: columnWidths.producto, paddingRight: '4px' }}
                    >
                      {trimString(producto?.Descripcion ?? '', 44)}
                    </Typography>

                    <Typography
                      variant='p'
                      sx={{ width: columnWidths.precioDeLista }}
                    >
                      ${parseMoney(producto.Price)}
                    </Typography>
                    <Typography
                      variant='p'
                      sx={{ width: columnWidths.descuento }}
                    >
                      {toInt(producto.Desc || producto.DescLinea)}%
                    </Typography>
                    <Typography
                      variant='p'
                      sx={{ width: columnWidths.transporte }}
                    >
                      {producto?.Transporte ?? 'No especificado'}
                    </Typography>
                    <Typography
                      variant='p'
                      sx={{ width: columnWidths.precio }}
                    >
                      ${parseMoney(String((parseFloat(parseFloat(producto?.Price).toFixed(2) - ((parseFloat(producto?.Price).toFixed(2)) * 
                      parseFloat(producto?.Desc || producto?.DescLinea).toFixed(2)) / 100).toFixed(2)) * toInt(producto?.CantidadPedida)))}
                    </Typography>
                    <Typography
                      variant='p'
                      sx={{
                        width: columnWidths.despachado,
                        fontWeight: 500,
                        color: getStatusColor(producto?.Estado),
                      }}
                    >
                      {toInt(producto?.CantidadDespachada) +
                        ' de ' +
                        toInt(producto?.CantidadPedida)}
                    </Typography>

                    <Typography
                      variant='p'
                      sx={{
                        width: columnWidths.estado,
                        color: getStatusColor(producto?.Estado),
                        fontWeight: 500,
                      }}
                    >
                      {producto?.Estado}
                    </Typography>
                    <Grid
                      sx={{ paddingLeft: '15px', width: columnWidths.detalle }}
                    >
                      {producto?.Productor && (
                        
                      <RoundedSquareIconButton
                        disabled={!producto?.Productor}
                        icon={
                          <RemoveRedEyeIcon
                            sx={{ width: '14px', height: '14px' }}
                          />
                        }
                        onClick={(e) => handleOpenModal(e, producto)}
                      />
                      )}
                    </Grid>
                  </Grid>
                ))}
              </AccordionDetails>
            </Accordion>
          )
        })}
      </Grid>

      <OrderDetailModalDesktop
        product={modalProduct}
        open={openDetail}
        onClose={() => setOpenDetail(false)}
      />
    </Grid>
  )
}

export default OrderListDesktop
