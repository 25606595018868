import React, { useState, useEffect } from 'react'
import { useStyles } from './Sidebar.styles'
import { Drawer, Box, Divider } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import UserInfoComponent from './UserInfoComponent'
import ListItemComponent from './ListItemComponent'
import HomeIcon from './icons/homeIcon'
import NewsIcon from './icons/newsIcon'
import burger from '../../assets/burgerIcon.png'
import AccordionComponent from './AccordionComponent'
import { useLocation } from 'react-router-dom'
import getCategories from 'components/Filter/services/getCategories'
import { formatCategoriesInTopMenu } from 'components/Filter/helpers/filterList'
import { useDispatch } from 'react-redux'
import { clearAllFilters, searchQuery } from 'stores/searchStore/actionsSearch'
import { buildBreadcumbs, getCurrentCategory } from 'utils/filters'

export default function SidebarComponent() {
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const [menuOpen, setMenuOpen] = useState(false)
  const classes = useStyles()
  const [accordionExpanded, setAccordionExpanded] = useState(false)
  const [categoryList, setCategoryList] = useState([])

  useEffect(async () => {
    const categories = await getCategories()
    const categoriesFormated = formatCategoriesInTopMenu(
      categories.sort((a, b) => Number(a.codigo) - Number(b.codigo))
    ).filter(({ isLeftMenu }) => isLeftMenu)
    
    setCategoryList(categoriesFormated)
  }, [])

  useEffect(() => {
    if(!menuOpen)
      setAccordionExpanded(false)
  }, [menuOpen])

  const handleChange = (panel) => (_event, isExpanded) => {
    setAccordionExpanded(isExpanded ? panel : false)
  }

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }

    setMenuOpen(open)
  }

  const handleSelectCategory = async (item) => {
    const current = item.value || item.label
    const breadcumbs = (await buildBreadcumbs(current))?.map((el) => ({
      ...el,
      type: 'categoria',
    }))
    localStorage.setItem('breadcumbs', JSON.stringify(breadcumbs))
    const currentCategory = getCurrentCategory()
    localStorage.setItem('currentLevel', currentCategory.level || 2)

    // dispatch(clearAllFilters())
    dispatch(
      searchQuery({
        query: {
          [item.type]: {
            label: item.value,
            value: item.codigo || item.code,
          },
        },
      })
    )
  }

  // the client needed put the category with code 110 at the last of categories list
  const setLastItem = (itemList, code) => {
    if(itemList?.length === 0) return []
    const index = itemList.findIndex((el) => el.codigo === code)
    const temp = [...itemList]
    temp.splice(index, 1)
    return [...temp, itemList[index]]
  }

  return (
    <>
      <img
        role='button'
        src={burger}
        onClick={toggleDrawer(true)}
        alt='Menú inferior'
      />
      <Drawer anchor='left' open={menuOpen} onClose={toggleDrawer(false)}>
        <Box className={classes.mainBox}>
          <Box className={classes.closeBox} onClick={toggleDrawer(false)}>
            <CloseIcon className={classes.closeIcon} />
          </Box>
          <UserInfoComponent closeDrawer={toggleDrawer(false)} />
          <Box sx={{ marginInline: '24px' }}>
            <ListItemComponent
              label='Inicio'
              active={pathname === '/'}
              closeDrawer={toggleDrawer(false)}
              linkTo='/'
            >
              <HomeIcon active={pathname === '/'} />
            </ListItemComponent>
            <Divider sx={{ marginTop: '3px' }} />
            {setLastItem(categoryList, '110').map((category, index) => {
              const { id, label, icon = null, childs } = category
              if (childs?.length > 0) {
                return (
                  <AccordionComponent
                    key={id}
                    label={label}
                    icon={icon}
                    index={index}
                    linkTo={'catalogue'}
                    childs={childs}
                    onPanelChange={handleChange}
                    accordionExpanded={accordionExpanded}
                    handleSelect={(item) => {
                      setMenuOpen(false)
                      // handleSelectCategory(item)
                    }}
                  />
                )
              } else {
                return (
                  <ListItemComponent
                    linkTo={'/catalogue'}
                    key={id ?? index}
                    label={category.label}
                    closeDrawer={toggleDrawer(false)}
                    onClick={() => handleSelectCategory(category)}
                  />
                )
              }
            })}
            <Divider sx={{ marginTop: '3px' }} />
            <ListItemComponent
              linkTo='/noticias'
              label='Noticias'
              active={pathname === `/noticias`}
              closeDrawer={toggleDrawer(false)}
            >
              <NewsIcon active={pathname === `/noticias`} />
            </ListItemComponent>
          </Box>
        </Box>
      </Drawer>
    </>
  )
}
