import React from 'react'
import { useStyles } from './AboutUsPage.style'
import { useMediaQuery, Grid, Skeleton, Box } from '@mui/material'
import CarouselComponent from 'components/Carousel/CarouselComponent'
import ProductLinesComponent from 'components/ProductLines/ProductLinesComponent'
import { usePageContent } from 'pages/PagePreview/usePageContent'

const AboutUsPage = () => {
  const classes = useStyles()
  const desktop = useMediaQuery((theme) => theme.breakpoints.up('md'), {
    noSsr: true,
  })

  const {
    content,
    loading,
    aboutBanners,
    productLinesBanners,
  } = usePageContent('nosotros')

  const bannersToShow = desktop ? aboutBanners?.desk : aboutBanners?.mobile
  const showCarousel = bannersToShow && bannersToShow.length > 0

  return (
    <Grid className={classes.container}>
      <Grid item xs={12} className={classes.mainGrid}>
        {content && (
          <>
            <div dangerouslySetInnerHTML={{ __html: content.beforeCarousel }} />
            {loading ? (
              <Skeleton
                variant='rectangular'
                width='100%'
                height={500}
                sx={{
                  borderRadius: '50px',
                  margin: 'auto',
                  width: '80%',
                }}
              />
            ) : (
              showCarousel && (
                <Box sx={{ width: '80%', margin: 'auto' }}>
                  <CarouselComponent
                    bannersToShow={bannersToShow}
                    desktop={desktop}
                  />
                </Box>
              )
            )}
            <div dangerouslySetInnerHTML={{ __html: content.afterCarousel }} />
            {loading ? (
              <Skeleton
                variant='rectangular'
                width='100%'
                height={500}
                sx={{
                  borderRadius: '50px',
                  margin: 'auto',
                  width: '80%',
                }}
              />
            ) : (
              Array.isArray(productLinesBanners) &&
              productLinesBanners.length > 0 && (
                <ProductLinesComponent
                  productLinesBanners={productLinesBanners}
                  desktop={desktop}
                />
              )
            )}
            <div
              dangerouslySetInnerHTML={{ __html: content.afterProductLines }}
            />
          </>
        )}
      </Grid>
    </Grid>
  )
}

export default AboutUsPage
