import React, { useEffect, useMemo, useState } from 'react'
import { Stack, Box, Typography } from '@mui/material'
import CategoryCard from './CategoryCard'
import { useStyles } from './Category.styles'
import { Link } from 'react-router-dom'
import bovinos from '../../assets/categories/bovinos.svg'
import saludAnimal from '../../assets/categories/saludAnimal.svg'
import insumosVet from '../../assets/categories/insumosVet.svg'
import porcinos from '../../assets/categories/porcinos.svg'
import ovinos from '../../assets/categories/ovinos.svg'
import rurales from '../../assets/categories/rurales.svg'
import equinos from '../../assets/categories/equinos.svg'
import mascotas from '../../assets/categories/mascotas.svg'
import caravanaIcon from '../../assets/caravanaIcon.svg'
import getCategories from 'components/Filter/services/getCategories'
import { useSelector } from 'react-redux'
import getCategoriesCards from './services/GetCardCategories'

const categoriesToRender = [
  {
    label: 'Caravanas oficiales electrónicas',
    redirectTo: '/catalogo/caravanas-oficiales-electronicas',
    icon: caravanaIcon,
  },
  { label: 'Nutrición', redirectTo: '/catalogo/nutricion', icon: saludAnimal },
  {
    label: 'Salud y reproducción',
    redirectTo: '/catalogo/salud-y-reproduccion',
    icon: insumosVet,
  },
  { label: 'Producción bovina', redirectTo: '/catalogo/produccion--bovina', icon: bovinos },
  {
    label: 'Producción porcina',
    redirectTo: '/catalogo/produccion--porcina',
    icon: porcinos,
  },
  { label: 'Producción ovina', redirectTo: '/catalogo/produccion--ovina	', icon: ovinos },
  { label: 'Cuidados equinos', redirectTo: '/catalogo/cuidados-equinos', icon: equinos },
  {
    label: 'Instalaciones rurales',
    redirectTo: '/catalogo/instalaciones-rurales',
    icon: rurales,
  },
  { label: 'Mascotas', redirectTo: '/catalogo/mascotas', icon: mascotas },
]

export default function CategoriesSection() {
  const classes = useStyles()
  const [cardCategories, setCardCategories] = useState(categoriesToRender)

  // useEffect(() => {
  //   getCategoriesCards()
  //     .then(setCardCategories)
  // }, [])

  return (
    <Box className={classes.mainGrid}>
      {cardCategories?.length > 0 && (
        <>
          <span className={classes.catMasPopulares}>
            <Typography className={classes.title}>
              Categorías más populares
            </Typography>
            {/* <Link to='/allcardCategories' className={classes.catMasPopularesLink}>
              Ver todas
            </Link> */}
          </span>
          <Stack direction='row' spacing='11px' sx={{width: '100%', justifyContent: 'space-between'}} className={classes.stackGrid}>
            {cardCategories?.map((category, index) => (
              <CategoryCard 
                key={category?.id ?? index} 
                name={category.label}
                redirectTo={category.redirectTo}  
                Icon={category.icon}
              />
            ))}
          </Stack>
        </>
      )}
    </Box>
  )
}
