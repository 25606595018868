import { ApiConnection } from 'config'

export const getCart = async (idUsuario, idOrder) => {
  try {
    const res = await ApiConnection.get(
      `cart/opened`,
      {
        params: {
          idUsuario,
          idOrder,
        },
      }
    )
    return res.data.response
  } catch (error) {
    console.error(error)
  }
}

export const getSavedCarts = async (idUsuario) => {
  try {
    const res = await ApiConnection.get(`cart/all-saved`, {
      params: {
        idUsuario,
      },
    })
    return res.data.response
  } catch (error) {
    console.error(error)
  }
}
