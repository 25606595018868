import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export default function useProductPrice(product) {
  const [priceToShow, setPriceToShow] = useState({priceToShow: product?.precioDeLista ?? 0, priceApplyPromotions: true})
  const { userInfo } = useSelector(({ userAuth }) => userAuth)

  useEffect(() => {
    if(!product?.sku || !userInfo?.promociones) return;

    if(product.precioDeLista && !userInfo.promociones) {
      setPriceToShow({priceToShow: product.precioDeLista, priceApplyPromotions: true})
      return;
    }

    let modified = false;
    
    userInfo?.promociones?.PR17?.forEach(pr => {
      if(pr?.skuObj?.includes(product.sku) && pr?.nuevoMonto){
        let nuevoMonto = pr.nuevoMonto;

        if(product.moneda === 'USD' && product.precioDolar) {
          nuevoMonto = pr.nuevoMonto * Number(product.precioDolar)
        }

        setPriceToShow({priceToShow: nuevoMonto, priceApplyPromotions: false})
        modified = true;
      }
    });

    if(!modified && product.precioDeLista) {
      setPriceToShow({priceToShow: product.precioDeLista, priceApplyPromotions: true})
    }

  }, [product?.sku, userInfo?.promociones, product?.precioDolar])

  return priceToShow
}