import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(() => ({
  newsCard: {
    display: 'block',
    backgroundColor: '#fff',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    borderRadius: '8px',
    overflow: 'hidden',
    textDecoration: 'none',
    color: '#000',
    position: 'relative',
    height: '100%',
  },
  imageContainer: {
    width: '100%',
    height: '350px',
    position: 'relative',
    overflow: 'hidden',
  },
  thumbnail: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    filter: 'brightness(50%)',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background:
      'linear-gradient(to bottom, rgba(0,0,0,0) 50%, rgba(0,0,0,0.9) 100%)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    padding: '16px',
    boxSizing: 'border-box',
  },
  category: {
    position: 'absolute',
    top: '16px',
    left: '16px',
    backgroundColor: '#4AA259',
    color: '#fff',
    padding: '4px 8px',
    borderRadius: '4px',
    fontSize: '0.75rem',
    fontWeight: 'bold',
  },
  date: {
    position: 'absolute',
    top: '16px',
    right: '16px',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    color: '#fff',
    padding: '4px 8px',
    borderRadius: '4px',
    fontSize: '0.75rem',
  },
  textOverlay: {
    color: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    height: '100%',
  },
  title: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    marginBottom: '25px',
  },
  description: {
    fontSize: '0.9rem',
    marginBottom: '25px',
  },
  readMore: {
    fontSize: '0.75rem',
    fontWeight: 'bold',
    color: '#1e90ff',
    textDecoration: 'underline',
    alignSelf: 'flex-end',
  },
}))
