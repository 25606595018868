import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import TitleWithBackArrow from 'components/TitleWithBackArrow'
import { useStyles } from './DownloadFiles.styles'
import { Helmet } from 'react-helmet'
import { DownloadButtonCard } from './DownloadButtonCard'
import getEcommerceDownloadFiles from './services/getEcommerceDownloadFiles'

const buttonCards = [
  {label: 'Lista de precios.pdf', ruta: 'pdf/lista-de-precios.pdf' },
  {label: 'Lista de precios (Excel)', ruta: 'pdf/lista-de-precios-excel.xlsx' },
  {label: 'Modificados.pdf', ruta: 'pdf/modificados.pdf' },
  {label: 'Modificados (Excel)', ruta: 'pdf/modificados-excel.xlsx' },
]

function DownloadFiles() {
  const defaultFiles = JSON.parse(localStorage.getItem('vn_ecommerce_files') || '[]')
  const classes = useStyles()
  const [files, setFiles] = useState(defaultFiles)

  const openFile = (fileName) => {
    const url = `${process.env.REACT_APP_URL_BACKEND_REMOTO}/files/${fileName}`
    window.open(url, '_blank')
  }

  useEffect(() => {
    getEcommerceDownloadFiles()
      .then(setFiles)
      .catch(console.log)
  }, [])

  return (
    <>
      <Helmet>
        <title>Resumen de cuenta corriente | Villanueva</title>
        <meta name='description' content='Resumen de cuenta corriente' />
        <meta
          name='keywords'
          content='resumen, cuenta, corriente, Villanueva'
        />
      </Helmet>
      <Grid className={classes.container}>
        <div className={classes.innerContainer}>
          <div>
            <TitleWithBackArrow>Archivos para descargar</TitleWithBackArrow>
            <div className={classes.content}>
              <Grid container spacing={2}>
                {(files.length > 0 ? files : buttonCards).map((buttonCard, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <DownloadButtonCard
                      fileName={buttonCard.label}
                      onClick={() => openFile(buttonCard.file.ruta)}
                    />
                  </Grid>
                ))}
              </Grid>
            </div>
          </div>
        </div>
      </Grid>
    </>
  )
}

export default DownloadFiles
