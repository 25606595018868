import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '95vh',
    backgroundColor: theme.palette.primary.white,
    padding: '50px 40px ',
    paddingTop: '100px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      minHeight: 0,
      padding: '100px 0px 400px',
    },
  },
  innerContainer: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: '400px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  title: {
    fontWeight: '700',
    fontSize: '22px',
    lineHeight: '32px',
    margin: '25px 0px',
    color: theme.palette.primary.almostBlack,
  },
  tagName: {
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '15px',
    color: theme.palette.primary.moreGrey,
    marginBottom: '9px',
    marginTop: '15px',
  },
  subtitleInfo: {
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '18px',
    color: theme.palette.primary.grey,
    marginBottom: '5px',
  },
  buttonBuy: {
    margin: '40px 0px',
    [theme.breakpoints.up('sm')]: {
      width: '325px',
    },
  },
  emailIcon: {
    color: theme.palette.primary.typographyGreen,
    fontSize: '46px',
  },
  iconContainer: {
    padding: '1em',
    borderRadius: '20px',
    backgroundColor: theme.palette.primary.lightGreen,
    width: '82px',
  },
}))
