import React from 'react'
import { useStyles } from './TopMenu.styles'
import { Link } from 'react-router-dom'
import { Typography, Grid } from '@mui/material'
import { formatTitle } from 'components/Filter/helpers/filterList';
import { buildUrl } from 'utils/filters'

function ActualChillds({ childs, handleClose }) {
  const classes = useStyles()
  
  return (
    <div className={classes.actualChildsContainer} >
      <Grid item xs={12}>
        <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 2, sm: 5, md: 12 }}>
          {childs.map((child, index) => (
            <Grid item xs={2} sm={4} md={4} key={index}>
              <Typography className={classes.dropDownSubItemText}>
                <Link
                  to={`/catalogo/${buildUrl(child.code)}`}
                  onClick={handleClose}
                  className={classes.menuItemBox}
                >
                  <Typography className={classes.dropDownSubItemText}>
                    <b>{formatTitle(child.label)}</b>
                  </Typography>
                </Link>

                {(Array.isArray(child.childs) && child.childs.length ) ? (
                  child.childs.map((subChild, subIndex) => (
                    <Link
                      to={`/catalogo/${buildUrl(subChild.code)}`}
                      key={index}
                      onClick={handleClose}
                      className={classes.menuSubItemBox}
                    >
                      <Typography key={subIndex} className={classes.quickAccessSubItem}  sx={{ height: 1, gap: 2 }}>
                        {formatTitle(subChild.title)}
                      </Typography>
                    </Link>
                  ))
                  // TODO REFACTOR THIS
                ) : (<div>   <Typography style={{ color: '#f9f9f9' }}>texto oculto para centrar las columnas
                </Typography></div>)}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </div>

  )
}

export default ActualChillds
