import React from 'react'
import { makeStyles } from '@mui/styles'

const DiscountLabel = ({ discountType, size=12}) => {
  const styles = useStyles()

  if (!discountType) return null

  const discountTypeLabel =
    discountType === 'promotion'
      ? 'PROMOCIÓN'
      : discountType === 'offer'
      ? 'OFERTA'
      : discountType === 'combo'
      ? 'COMBO'
      :  discountType === 'specialPrice'
      ? 'PRECIO ESPECIAL'
      : ''

  if(!discountTypeLabel) return null

  const colorStyle =
    discountType === 'offer' ? styles.offerBg : styles.promotionBg

  return <p className={`${styles.label} ${colorStyle}`} style={{fontSize: size}}>{discountTypeLabel}</p>
}

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: '12px',
    position: 'absolute',
    left: 0,
    top: 16,
    padding: '8px 12px',
    color: '#FFFFFF',
    margin: 0,
    borderRadius: '0 6px 6px 0',
    zIndex: 100,
  },
  offerBg: {
    backgroundColor: theme?.palette?.error?.main,
  },
  promotionBg: {
    backgroundColor: theme?.palette?.primary?.main,
  },
}))

export default DiscountLabel
