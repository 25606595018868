import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

function DownloadIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg
        width={props.width}
        height={props.height}
        viewBox='0 0 16 14'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M1 10.3333L1.4347 11.99C1.51041 12.2785 1.68518 12.5345 1.93123 12.7175C2.17729 12.9005 2.48051 13 2.7927 13H13.2073C13.5195 13 13.8227 12.9005 14.0688 12.7175C14.3148 12.5345 14.4896 12.2785 14.5653 11.99L15 10.3333M8 9V1V9ZM8 9L5.2 6.33333L8 9ZM8 9L10.8 6.33333L8 9Z'
          stroke={props.disabled ? 'rgba(0, 0, 0, 0.26)' : '#343030'}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </SvgIcon>
  )
}

export default DownloadIcon
