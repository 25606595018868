import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  clientCard: {
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.primary.borderGrey,
    display: 'block',
    textTransform: 'capitalize',
    width: '100%',
    color: theme.palette.primary.moreGrey,
    padding: '15px ',
    textAlign: 'left',
    fontFamily: 'Inter',
  },
  innerContainer: {
    borderRadius: '10px',
    position: 'relative',
    padding: '50px 32px 50px',
    [theme.breakpoints.up('sm')]: {
      minWidth: '400px',
      maxWidth: '1400px',
      margin: 'auto',
    },
  },
  tagName: {
    fontWeight: '200',
    fontSize: '12px',
    lineHeight: '15px',
    marginBottom: '9px',
    marginTop: '15px',
  },
  inputField: {
    [theme.breakpoints.up('sm')]: {
      '& input': {
        fontSize: '10px',
        color: theme.palette.primary.moreGrey,
      },
    },
  },
  forgot: {
    fontWeight: '300',
    fontSize: '12px',
    margiTop: '16px',
    marginBottom: '16px',
    textDecoration: 'underline',
  },
}))
