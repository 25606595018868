import React from 'react'
import noResults from '../../assets/noResults.png'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { useStyles } from './NoResults.styles'

function NoResults(props) {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      <Box className={classes.innerContainer}>
        <img
          src={noResults}
          alt='no hay resultados'
          style={{
            backgroundColor: 'white',
            borderRadius: '50%',
            padding: '32px 25px',
            width: '148.41px',
            height: '134.07px',
          }}
        />
        <Typography className={classes.title}>
          Oops... No encontramos resultados
        </Typography>
        <Typography className={classes.subTitle}>
          Probá realizando una nueva busqueda y cambiando las palabras claves
        </Typography>
      </Box>
    </Box>
  )
}

export default NoResults
