import { ApiConnection } from 'config'

export const getCategories = async () => {
  try {
    const { data } = await ApiConnection.get(`menu-category`)
    return data?.response
  } catch (error) {
    throw new Error(error.message)
  }
}

export const getAppInfo = async () => {
  try {
    const { data } = await ApiConnection.get(`app`)
    return data?.response
  } catch (error) {
    throw new Error(error.message)
  }
}

export const getAboutBanners = async () => {
  try {
    const { data } = await ApiConnection.get(`ec-files/section/about-banners`)
    return data?.response
  } catch (error) {
    throw new Error(error.message)
  }
}

export const getProductLinesBanners = async () => {
  try {
    const { data } = await ApiConnection.get(
      `ec-files/section/productLines-banners`
    )
    return data?.response
  } catch (error) {
    throw new Error(error.message)
  }
}

export const getHomeBanners = async () => {
  try {
    const { data } = await ApiConnection.get(`ec-files/section/home-banners`)
    return data?.response
  } catch (error) {
    throw new Error(error.message)
  }
}

export const getCartBanners = async () => {
  try {
    const { data } = await ApiConnection.get(`ec-files/section/cart-banners`)
    return data?.response
  } catch (error) {
    throw new Error(error.message)
  }
}

export const getCatalogoBannersFiles = async (category = '') => {
  const res = await ApiConnection.get(`ec-files/section/catalogo-banners`, {
    params: { category },
  })
  return res?.data?.response
}

export const getCatalogoCardsFiles = async (category = '') => {
  const res = await ApiConnection.get(`ec-files/section/catalogo-cards`, {
    params: { category },
  })
  return res?.data?.response
}
