import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
         container: {
           [theme.breakpoints.up('sm')]: {
             maxWidth: '1040px',
             margin: 'auto',
             paddingBottom: '70px',
           },
         },
         textGrid: {
           padding: '20px, 0px, 20px, 0px',
           borderBottom: '0.6px solid #bbbbbb',
         },
         text: {
           fontWeight: '400',
           fontSize: '13px',
           lineHeight: '16px',
           color: theme.palette.primary.almostBlack,
         },
         title: {
           fontWeight: '600',
           fontSize: '18px',
           lineHeight: '22px',
           color: theme.palette.primary.almostBlack,
         },
         subtitle: {
           paddingLeft: '15px',
           paddingRight: '15px',
           fontWeight: '500',
           fontSize: '12px',
           lineHeight: '15px',
           color: theme.palette.primary.almostBlack,
           marginBottom: '9px',
         },
         subtitleInfo: {
           fontWeight: '300',
           fontSize: '12px',
           lineHeight: '15px',
           color: theme.palette.primary.almostBlack,
           marginBottom: '5px',
         },
         grid: {
           margin: '20px',
         },
         imgContainer: {
           width: '100%',
           height: 180,
           borderRadius: 6,

           '& div': {
             width: '100%',
             height: '100%',
           },
         },
         card: {
           margin: '15px',
           boxShadow: '0px 2px 14px rgba(0, 0, 0, 0.1)',
           borderRadius: '8px',
          //  cursor: 'pointer',
           width: '100%',
          //  '&:active': {
          //    border: `1px solid ${theme.palette.primary.typographyGreen}`,
          //  },
           '&:hover': {
             boxShadow: '0px 2px 18px rgba(0,0,0,0.25)',
             transition: '0.5s',
           },
           [theme.breakpoints.up('sm')]: {
             padding: 0,
             margin: 0,
             width: '333px',
             marginBottom: '31px',
             boxSizing: 'border-box',
           },
         },
         sucursalImg: {
           [theme.breakpoints.up('sm')]: {
             display: 'block',
             objectFit: 'cover',
           },
         },
         cardInsideContainer: {
           justifyContent: 'center',
           alignItems: 'center',
           verticalAlign: 'center',
         },
         addressTitleActive: {
           fontWeight: '600',
           color: theme.palette.primary.almostBlack,
           marginBottom: '20px',
           lineHeight: '24px',
           fontSize: '20px',
           [theme.breakpoints.up('sm')]: {
             fontWeight: 700,
           },
         },
         addressDescriptionActive: {
           paddingTop: '20px',
           fontWeight: '400',
           fontSize: '12px',
           lineHeight: '15px',
           color: theme.palette.primary.almostBlack,
         },
         sucursalDescriptionTitle: {
          fontWeight: 'bold',
          fontSize: '12px',
          marginBottom: '5px'
         },
         sucursalDescription: {
           fontWeight: '400',
           fontSize: '12px',
          //  lineHeight: '15px',
           color: theme.palette.primary.almostBlack,
           marginBottom: '14px',
           [theme.breakpoints.up('sm')]: {
             color: theme.palette.primary.lightGrey,
           },
         },
         sucursalIcon: {
           fontSize: '18px',
           marginRight: '10px',
           color: theme.palette.primary.lightGrey,
         },
         sucursalInfoContainer: {
           padding: '20px',
           [theme.breakpoints.up('sm')]: {},
         },
         viewReach: {
           paddingTop: '20px',
           fontWeight: '700',
           fontSize: '12px',
           lineHeight: '15px',
           textAlign: 'center',
           color: theme.palette.primary.typographyGreen,
           [theme.breakpoints.up('sm')]: {
             textAlign: 'right',
           },
         },
         reachModal: {
           position: 'absolute',
           top: '50%',
           transform: 'translate(0%, -50%)',
           margin: '20px',
           width: '320px',
           backgroundColor: 'white',
           boxShadow: '0px 2px 14px rgba(0, 0, 0, 0.1)',
           padding: '40px 55px',
           borderRadius: '8px',
           maxWidth: '670px',
           [theme.breakpoints.up('sm')]: {
             width: '100%',
             margin: 0,
             left: '50%',
             transform: 'translate(-50%, -50%)',
           },
         },
         reachImage: {
           display: 'none',
           [theme.breakpoints.up('sm')]: {
             display: 'block',
           },
         },
         modalUl: {
           listStyle: 'disc',
           marginLeft: '20px',
         },
       }))
