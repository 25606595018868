import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  grid: {
    padding: '50px 20px ',
    minHeight: '95vh',
    backgroundColor: theme.palette.primary.white,
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      minHeight: 0,
      padding: '100px 0px 400px',
    },
  },
  innerContainer: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: '400px',
      alignItems: 'center',
    },
  },
  form: {
    width: '100%',
    margin: '30px 0px',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '350px',
    },
  },
  title: {
    fontWeight: '700',
    fontSize: '22px',
    lineHeight: '32px',
    marginBottom: '10px',
    color: theme.palette.primary.almostBlack,
    [theme.breakpoints.up('sm')]: {
      textAlign: 'center',
    },
  },
  tagName: {
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '15px',
    color: theme.palette.primary.moreGrey,
    marginBottom: '9px',
    marginTop: '15px',
  },
  subtitleInfo: {
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '18px',
    color: theme.palette.primary.grey,
    marginBottom: '5px',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'center',
    },
  },
  buttonBuy: {
    [theme.breakpoints.up('sm')]: {
      width: '325px',
    },
  },
}))
