import React from 'react'
import {
  Dialog,
  Typography,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  Grid,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import logoMP from '../../../src/assets/logoMP.png'
import logo from '../../../src/assets/logo.png'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const MetodosDePagoModal = (props) => {
  const { open, handleClose } = props

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      sx={{ borderRadius: '8px', margin: '30px' }}
      aria-describedby='alert-dialog-slide-description'
    >
      <DialogTitle>
        <Grid
          sx={{
            display: 'flex',
            flexDirection: 'row',
            // width: '380px',
            justifyContent: 'space-around',
          }}
        >
          <Typography
            sx={{
              paddingTop: '15px',
              fontWeight: '600',
              fontSize: '16px',
              lineHeight: '19px',
              color: (theme) => theme.palette.primary.almostBlack,
            }}
          >
            Medios de pago para este producto
          </Typography>
          <IconButton
            aria-label='close'
            onClick={handleClose}
            size='large'
            sx={{
              color: (theme) => theme.palette.primary.almostBlack,
            }}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid>
          <Grid
            sx={{
              display: 'flex',
              flexDirection: 'row',
              marginBottom: '20px',
            }}
          >
            <img
              src={logo}
              style={{
                marginRight: '15px',
                width: '30px',
                height: '30px',
              }}
            ></img>
            <Typography>Cuenta Corriente</Typography>
          </Grid>
          <Grid
            sx={{
              display: 'flex',
              flexDirection: 'row',
              marginBottom: '20px',
            }}
          >
            <img
              src={logoMP}
              style={{
                marginRight: '15px',
                width: '34px',
                height: '24px',
              }}
            ></img>
            <Typography>Tarjeta de Crédito</Typography>
          </Grid>
          <Grid
            sx={{
              display: 'flex',
              flexDirection: 'row',
              marginBottom: '20px',
            }}
          >
            <img
              src={logoMP}
              style={{
                marginRight: '15px',
                width: '34px',
                height: '24px',
              }}
            ></img>
            <Typography>Tarjeta de Debito</Typography>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default MetodosDePagoModal
