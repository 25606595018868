import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
         container: {
           width: '100%',
         },
         innerContainer: {
           maxWidth: '1040px',
           margin: 'auto',
         },
         carousel: {
           
         },
         image: {
           display: 'block',
           borderRadius: '8px',
           height: '200px',
           [theme.breakpoints.up('sm')]: {
             height: '75vh',
             borderRadius: 0,
             objectFit: 'fill',
           },
         },
         fullImg: {
           width: '100%',
           [theme.breakpoints.up('sm')]: {
             objectFit: 'cover',
           },
         },
         fullImgContainer: {
           [theme.breakpoints.up('sm')]: {
             maxHeight: '500px',
             overflow: 'hidden',
           },
         },
         mainGrid: {
           padding: '6px',
           [theme.breakpoints.up('sm')]: {
             padding: '0px',
             paddingBottom: '96.5px',
           },
         },
         searchGrid: {
           padding: '14px',
         },
       }))
