import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: '1040px',
      margin: 'auto',
    },
  },
  title: {
    fontSize: '24px',
    lineHeight: '32px',
    fontWeight: 700,
    color: theme.palette.primary.almostBlack,
  },
  subtitle: {
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: 300,
    color: theme.palette.primary.black,
    marginTop: '15px',
    marginBottom: '23px',
  },
  date: {
    fontSize: '11px',
    lineHeight: '13px',
    fontWeight: 400,
    color: theme.palette.primary.almostBlack,
    marginBottom: '20px',
    marginTop: '20px',
  },
  mainGrid: {
    padding: '15px',
  },
  tag: {
    backgroundColor: theme.palette.primary.softGreen,
    padding: '5px',
    fontSize: '13px',
    lineHeight: '12px',
    fontWeight: 700,
    width: '65px',
    textAlign: 'center',
    color: theme.palette.primary.typographyGreen,
  },
  img: {
    width: '100%',
  },
  imgAuth: {
    width: '40px',
    borderRadius: '50%',
    marginRight: '20px',
    marginBottom: '20px',
  },
  texto1: {
    fontSize: '14px',
    lineHeight: '22px',
    fontWeight: 400,
    color: theme.palette.primary.almostBlack,
    marginTop: '15px',
    marginBottom: '23px',
  },
  author: {
    fontSize: '12px',
    fontWeight: 400,
    fontStyle: 'italic',
    color: theme.palette.primary.almostBlack,
  },
}))
