import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  textInput: {
    borderRadius: '8px',
  },
  addressTitleActive: {
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '15px',
    color: theme.palette.primary.almostBlack,
  },
  title: {
    fontWeight: '700',
    fontSize: '22px',
    lineHeight: '32px',
    marginBottom: '10px',
    color: theme.palette.primary.almostBlack,
    [theme.breakpoints.up('sm')]: {
      fontSize: '32px',
      lineHeight: '42px',
    },
  },
  shorterTextField: {
    width: '100%',
    marginBottom: '5%'
  },
   textFieldInput: {
    fontSize: '12px',
    padding: '14px',
  },
  tagName: {
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '15px',
    color: theme.palette.primary.moreGrey,
    marginBottom: '9px',
    marginTop: '15px',
  },
  subtitleInfo: {
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '18px',
    color: theme.palette.primary.moreGrey,
    marginBottom: '5px',
  },
  grid: {
    padding: '28px 14px',
    backgroundColor: theme.palette.primary.white,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      justifyContent: 'center',
    },
    alignItems: 'space-around',
  },
  gridOfForm: {
    with:"100%",
    backgroundColor: theme.palette.primary.white,
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'center',
    },
  },
  innerContainerOfForm: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: '2000px',
    },
  },
  gridAligner: {},
  buttonsGrid: {
    marginTop: '55px',
  },
  forgot: {
    fontWeight: '300',
    fontSize: '12px',
    textDecoration: 'underline',
  },
  sucursal: {
    fontWeight: '400',
    fontSize: '12px',
    color: theme.palette.primary.black,
  },
  buttonBuy: {
    marginTop: '51px',
    marginBottom: '51px',
    width: "100%"
  },
}))
