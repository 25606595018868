import React from 'react'
import { Box, Typography } from '@mui/material'
import { useStyles } from './Category.styles'
import caravanaIcon from '../../assets/caravanaIcon.svg'
import { Link } from 'react-router-dom'
import { searchQuery } from 'stores/searchStore/actionsSearch'
import { useDispatch, useSelector } from 'react-redux'
import { buildBreadcumbs, buildUrl } from 'utils/filters'

const CategoryCard = ({
  Icon,
  name = 'Nombre categoria',
  redirectTo,
  disabled = false,
}) => {
  const classes = useStyles()

  return (
    <Box
      className={classes.card}
      sx={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
    >
      <Link
        to={redirectTo}
        style={{
          color: '#888888',
          cursor: disabled ? 'not-allowed' : 'pointer',
        }}
        title={disabled ? 'Deshabilitado' : name}
      >
        <Box className={classes.image}>
          {Icon?.ruta && typeof Icon?.ruta === 'string' ? (
            <img
              src={`${process.env.REACT_APP_URL_BACKEND_REMOTO}/files/${Icon.ruta}` ?? caravanaIcon}
              alt='caravana'
              className={classes.categoryIcon}
            />
          ) : (
            <img
              src={Icon}
              alt='caravana'
              className={classes.categoryIcon}
            />
          )}
        </Box>
        <Typography paragraph className={classes.categoryName}>
          {name}
        </Typography>
      </Link>
    </Box>
  )
}

export default CategoryCard
