const isOverdue = (dueDateString = '') => {
  const dueDateInt = parseInt(dueDateString)
  const today = new Date().toJSON().slice(0, 10).replace(/-/g, '')
  const todayInt = parseInt(today)
  if (isNaN(todayInt) || isNaN(dueDateInt)) return null
  if (todayInt > dueDateInt) return true
  return false
}

export default isOverdue
