import { ApiConnection } from 'config'


export const loginService = async (headers) => {
  try {
    const data = ApiConnection.get(
      `users/login`,
      {
        headers,
      }
    )

    return data
  } catch (e) {
    throw new Error('Login Error')
  }
}
