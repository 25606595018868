import React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  buttonCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[2],
    cursor: 'pointer',
  },
  fileName: {
    marginBottom: theme.spacing(1),
    cursor: 'pointer',
  },
  downloadButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

export function DownloadButtonCard({ fileName, onClick }) {
  const classes = useStyles();

  return (
    <div className={classes.buttonCard}>
      <Typography
        onClick={onClick}
        className={classes.fileName}
      >
        {fileName}
      </Typography>
      <Button
        variant="contained"
        className={classes.downloadButton}
        onClick={onClick}
      >
        Descargar
      </Button>
    </div>
  );
}