import { ApiConnection } from 'config'
;

const getNewsPage = async (id) => {
  const { data } = await ApiConnection.get(
    `news?id=${id}`
  )

  return data.response
};


export default getNewsPage;