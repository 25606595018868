import { ApiConnection } from 'config'


const getEcommerceDownloadFiles = async () => {
  const res = await ApiConnection.get(
    `ec-files/section/downloadable-files`
  )
  return res?.data?.response
}

export default getEcommerceDownloadFiles
