import React from 'react'
import { Grid, Slide, Modal, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { parseCodigo, parseDateString, toInt } from '../utils/parseFunctions'

const containerStyle = {
  position: 'absolute',
  top: '50vh',
  left: '50vw',
  width: 372,
  bgcolor: 'white',
  boxShadow: 24,
  padding: '32px',
  paddingBottom: '47px',
  borderRadius: '8px',
  transform: 'translate(-50%,-50%) !important',
}

const h2Style = { width: '50%' }
const pStyle = { width: '50%', margin: '8px 0px' }

function OrderDetailModalDesktop({ open, onClose, product }) {
  return (
    <Modal open={open} onClose={onClose} closeAfterTransition>
      <Slide in={open} direction='up'>
        <Grid sx={containerStyle}>
          <Grid sx={{ marginBottom: '30px' }}>
            <Typography
              variant='h1'
              sx={(theme) => ({ color: theme.palette.primary.typographyGreen })}
            >
              Pedido: {parseCodigo(product.NroEc || product?.NroPedido)}
            </Typography>
            <Typography variant='p'>
              {parseDateString(product?.Fecha)}
            </Typography>
          </Grid>
          <Grid container wrap='nowrap'>
            <Grid container flexDirection='column'>
              <Grid container alignItems='center'>
                <Typography sx={h2Style} variant='h2'>
                  Cant. Despachada
                </Typography>
                <Typography variant='p' sx={pStyle}>
                  {product?.CantidadDespachada}
                </Typography>
              </Grid>

              <Grid container alignItems='center'>
                <Typography sx={h2Style} variant='h2'>
                  Series
                </Typography>
                <Typography variant='p' sx={pStyle}>
                  {product?.SerieCE}
                </Typography>
              </Grid>
              <Grid container alignItems='center'>
                <Typography sx={h2Style} variant='h2'>
                  Productor
                </Typography>
                <Typography variant='p' sx={pStyle}>
                  {product?.Productor?.nombre}
                </Typography>
              </Grid>
              <Grid container alignItems='center'>
                <Typography sx={h2Style} variant='h2'>
                  Establecimiento
                </Typography>
                <Typography variant='p' sx={pStyle}>
                  {product?.Productor?.establecimiento}
                </Typography>
              </Grid>
              <Grid container alignItems='center'>
                <Typography sx={h2Style} variant='h2'>
                  Cuig
                </Typography>
                <Typography variant='p' sx={pStyle}>
                  {product?.Productor?.cuig}
                </Typography>
              </Grid>
              <Grid container alignItems='center'>
                <Typography sx={h2Style} variant='h2'>
                  Renspa
                </Typography>
                <Typography variant='p' sx={pStyle}>
                  {product?.Productor?.renspa}
                </Typography>
              </Grid>
              <Grid container alignItems='center'>
                <Typography sx={h2Style} variant='h2'>
                  Rango
                </Typography>
                <Typography variant='p' sx={pStyle}>
                  {product?.Rango}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <CloseIcon
            sx={{
              position: 'absolute',
              right: '25px',
              top: '25px',
              cursor: 'pointer',
            }}
            onClick={onClose}
          />
        </Grid>
      </Slide>
    </Modal>
  )
}

export default OrderDetailModalDesktop
