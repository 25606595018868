import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '95vh',
    backgroundColor: theme.palette.primary.white,
    padding: '30px 14px',
    [theme.breakpoints.up('sm')]: {
      padding: 0,
      minHeight: 0,
      backgroundColor: 'transparent',
    },
  },
  rowContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    marginBottom: '20px',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row'
    },
  },
  subtitle: {
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '15px',
    color: theme.palette.primary.almostBlack,
    marginBottom: '9px',
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '85vh',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '1040px',
      margin: 'auto',
      paddingTop: '32px',
      paddingBottom: '90px',
      backgroundColor: 'transparent',
      minHeight: 0,
    },
  },
  summaryCard: {
    padding: '15px 15px',
    margin: '0px 0px',
    borderRadius: '8px',
    // boxShadow: '0px 2px 14px rgba(0, 0, 0, 0.1)',
    color: theme.palette.primary.almostBlack,
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    backgroundColor: theme.palette.primary.white,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    [theme.breakpoints.up('sm')]: {
      maxWidth: '350px',
    },
  },
  description: {
    fontSize: '13px',
    fontWeight: 400,
    marginLeft: '10px',
    [theme.breakpoints.up('sm')]: {
      marginRight: 0,
    },
  },
  balance: {
    fontSize: '18px',
    fontWeight: 600,
    marginLeft: '10px',
    marginRight: '10px',
  },
  downloadLink: {
    '& svg': {
      width: '26px',
      height: '22px',
    },
  },
  content: {
    backgroundColor: theme.palette.primary.white,
    [theme.breakpoints.up('sm')]: {
      borderRadius: '8px',
      boxShadow: '0px 2px 14px rgba(0,0,0,0.1)',
      padding: '10px 20px',
    },
  },
  tableHeaders: {
    padding: '8px 5px',
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.primary.almostWhite,
  },
  tableHeader: {
    fontSize: '13px',
    fontWeight: 600,
    color: theme.palette.primary.almostBlack,
  },
  button: {
    padding: '12px',
    textTransform: 'capitalize',
    fontSize: '18px',
    marginLeft: '5px' ,
    borderRadius: '6px',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '216px',
      marginLeft: '5px' 
    },
  },
  inputValue: {
    textTransform: 'capitalize',
    fontSize: '18px',
    borderRadius: '6px',
    marginRight: '15px'
  },
  tabsAndFilters: {
    display: 'flex',
    backgroundColor: theme.palette.primary.white,
    borderRadius: '8px',
    marginBottom: '20px',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 10,
    boxShadow: '0px 2px 14px rgba(0,0,0,0.1)',
    height: 50,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      height: 'auto',

    },
  },
  filtersContainer: {
    display:'flex',
    alignItems:'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'start',
      marginTop: '10px',
      width: '250px'
    },
  },
  filtersLabel: {
    [theme.breakpoints.down('md')]: {
      marginBottom: '5px'
    },
  },
  filtersButtonSubmit: {
    [theme.breakpoints.down('md')]: {
      justifySelf: 'center',
      width: '100% !important'
    },
  },
  filtersButtonSubmitContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%'
    },
  },
  tabsContainer: {
    display:'flex',

  },
  tab: {
    margin: '0px 10px',
    cursor: 'pointer',
    padding: 1,
    fontSize: 16,
    height: 34,
    width: 112
  },
  dateFilter: {
    fontSize: '16px',
    padding: 10,
    margin: '0 20px',
    [theme.breakpoints.down('md')]: {
      width: '88%',
      margin: '0 0 5px 0'
    },
  },
  noResultsText: {
    textAlign: 'center',
    marginTop: 20,
    marginBottom: 20,
  }
}))
