import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '337px',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontSize: '18px',
    color: theme.palette.primary.typographyGrey,
    fontWeight: 600,
    margin: '20px 0px 5px 0px',
  },
  subTitle: {
    fontSize: '14px',
    color: theme.palette.primary.typographyGrey,
    fontWeight: 300,
  },
}))
