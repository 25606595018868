const currencyToNumber = (currencyString) => {
  // recorrer string y escribirlo en formato "123456.00"
  // pasarselo a un parseFloat()
  if(!currencyString) currencyString = '0,00'
  let newString = currencyString.replace(/\./g, '')
  newString = newString.replace(',', '.')
  const result = parseFloat(newString)
  if (isNaN(result)) return 0
  return result
}

export default currencyToNumber
