export const trimString = (str = '', cap = 0) => {
  if (str.length > cap) {
    return str.slice(0, cap) + '...'
  }
  return str
}

export const parseDateString = (dateString = '') => {
  if (dateString) {
    const day = dateString?.slice(6)
    const month = dateString?.slice(4, 6)
    const year = dateString?.slice(0, 4)
    return `${day}/${month}/${year}`
  }
  return ''
}

export const parseDateStringShort = (dateString = '') => {
  if (dateString) {
    const day = dateString?.slice(6)
    const month = dateString?.slice(4, 6)
    return `${day}/${month}`
  }
  return ''
}

export const parseCodigo = (codigo = '') => {
  if(codigo.startsWith('S-')) return codigo
  if(codigo.length > 8) {
    codigo = codigo.split('_')?.[0]?.slice(-8)
  }
  return `EC-${codigo}`
}

export const parseMoney = (moneyString = '') => {
  if (!moneyString) return null

  let integer = '0'
  let decimal = '0'
  if (/\./.test(moneyString)) {
    integer = moneyString.split('.')[0]
    decimal = moneyString.split('.')[1].slice(0, 2)
  } else {
    integer = moneyString
  }

  // Adds '.' 3 digit separator
  if (integer?.length > 3) {
    let newInt = ''
    let counter = 0
    for (let i = integer.length - 1; i >= 0; i--) {
      if (counter === 3) {
        newInt = '.' + newInt
        counter = 0
      }
      newInt = integer[i] + newInt
      counter++
    }
    integer = newInt
  }

  return `${integer},${decimal}`
}

export const toInt = (number = '') => {
  if (number) return number.split('.')[0]
}
