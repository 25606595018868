import React, { useEffect, useState } from 'react';
import { Grid, Skeleton } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useStyles } from './PagePreview.style';
import getPageByName from './getPageByName';
import AboutUsPage from 'pages/AboutUs/AboutUsPage';

export default function PagePreview() {
  const classes = useStyles();
  const { pageName } = useParams();
  const [loading, setLoading] = useState(true);
  const [pageContent, setPageContent] = useState('');

  useEffect(() => {
    if (!pageName) return;
    setLoading(true);

    const fetchPage = async () => {
      try {
        const res = await getPageByName(pageName);
        setPageContent(res.content);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchPage();
  }, [pageName]);

  // Dynamic component rendering based on pageName
  const renderPage = () => {
    if (loading) return <Skeleton variant="rectangular" width="100%" height={500} />;
    if (!pageContent) return <div>Page not found</div>;

    switch (pageName) {
      case 'nosotros':
        return <AboutUsPage content={pageContent} />;
      default:
        return <div dangerouslySetInnerHTML={{ __html: pageContent }} />;
    }
  };

  return (
    <Grid className={classes.container}>
      <Grid item xs={12} className={classes.mainGrid}>
        {renderPage()}
      </Grid>
    </Grid>
  );
}
