import { ApiConnection } from 'config'


export const sendEmailForResetPassword = async (cuit) => {
    try {
        const { data } = await ApiConnection.post(
            `users/recover-password`, { cuit }
        );
        return data;
    } catch (error) {
        console.error(error)
    }
}
