import { ApiConnection } from 'config'

export const userDetailsService = async (user) => {
  try {
    const data = ApiConnection.get(
      `users/updated-info`,
      { headers: { user } }
    )
    return data
  } catch (e) {
    throw new Error('Error fetching user details')
  }
}
