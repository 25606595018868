import React, { useState } from 'react'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import RemoveCircleOutlineSharpIcon from '@mui/icons-material/RemoveCircleOutlineSharp'
import AddCircleOutlineSharpIcon from '@mui/icons-material/AddCircleOutlineSharp'
import Typography from '@mui/material/Typography'
import { questions } from './questions'

const accordionStyles = {
  padding: '8px 0px',
}

export default function FAQSection() {
  const [expanded, setExpanded] = useState({})

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded({ ...expanded, [panel]: isExpanded })
  }

  return (
    <div
      style={{
        backgroundColor: 'white',
      }}
    >
      <Grid
        container
        sx={(theme) => ({
          backgroundColor: 'white',
          padding: '12px',
          '& div:last-child hr': {
            display: 'none',
          },
          [theme.breakpoints.up('sm')]: {
            maxWidth: '1040px',
            margin: 'auto',
            padding: 0,
            paddingBottom: '72px',
          },
        })}
      >
        <Typography
          sx={(theme) => ({
            marginTop: '36px',
            marginLeft: '2px',
            marginBottom: '10px',
            fontFamily: 'Inter',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: '600',
            lineHeight: '23px',
            letterSpacing: '0em',
            textAlign: 'center',
            color: theme.palette.primary.almostBlack,
            [theme.breakpoints.up('sm')]: {
              marginTop: '55px',
              marginBottom: '42px',
            },
          })}
        >
          Preguntas Frecuentes
        </Typography>
        {questions.map(({ question, answer }, index) => (
          <Grid item key={question} xs={12}>
            <Accordion
              sx={accordionStyles}
              disableGutters
              elevation={0}
              square
              expanded={expanded?.[question] ?? false}
              onChange={handleChange(question)}
            >
              <AccordionSummary
                sx={{ padding: '0px', margin: 0 }}
                expandIcon={
                  expanded?.[question] ?? false ? (
                    <RemoveCircleOutlineSharpIcon />
                  ) : (
                    <AddCircleOutlineSharpIcon />
                  )
                }
                aria-controls={`${question}-content`}
                id={`${question}-header`}
              >
                <Typography
                  sx={(theme) => ({
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    lineHeight: '18px',
                    color: theme.palette.primary.almostBlack,
                  })}
                >
                  {question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant='p'>
                  {answer.includes('\n') ? (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: answer.replace(/\n/g, '<br/>'),
                      }}
                    />
                  ) : (
                    answer
                  )}
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Divider />
          </Grid>
        ))}
      </Grid>
    </div>
  )
}
