import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import logoMP from '../../assets/logoMP.png'
import { useStyles } from './PaymentMethodPage.styles'
import {
  Box,
  Grid,
  TextField,
  Typography,
  Radio,
  Card,
  useMediaQuery,
  Select,
  MenuItem,
  Autocomplete
} from '@mui/material'
import BottomActions from '../../components/BottomActions'
import Title from '../../components/Title'
import { createOrder } from './services/postHeaderCart'
import { formatDate } from 'utils/formatDate'
import CheckoutSummary from 'components/CheckoutSummary/CheckoutSummary'
import { editHeaderCart } from 'pages/ShippingPage/services/editHeaderCart'
import { clearCart } from 'stores/cartStore/actionsCart'
import { subtractBalance, updateUserInfo } from 'stores/userStore/actionsUser'
import payWithMP from './services/payWithMP'
import { setSnackbar } from 'stores/snackbarStore'
import { fixAmount } from 'pages/ProductPage/utils/helpers'
import { Helmet } from 'react-helmet'
import getPerceptionsMP from './services/getPerceptions'
import { parseMoney } from 'pages/OrderListPage/utils/parseFunctions'
import { formatNumber } from '../../utils/formatNumber'
import { getCartSummary } from 'components/CheckoutSummary/helpers/helpers'
import { getAvailableBalance }
  from '../ResumePage/services/getAvailableBalance'
import getTransports from 'pages/ShippingPage/services/getTransports'
import { createHeaderCart } from 'pages/ShippingPage/services/createHeaderCart'

const currentDate = formatDate(true)

const PaymentMethodPage = () => {
  const navigate = useNavigate()
  const classes = useStyles()
  const cart = useSelector((state) => state.cart)
  const desktop = useMediaQuery((theme) => theme.breakpoints.up('sm'))
  const { userInfo, currentBranch } = useSelector(({ userAuth }) => userAuth)
  const dispatch = useDispatch()

  // STATE
  const [comments, setComments] = useState('')
  const [selectedValue, setSelectedValue] = useState('cc')
  const [processingPayment, setProcessingPayment] = useState(false)
  const [address, setAddress] = useState(userInfo.direccion?.length === 1 ? userInfo.direccion[0].codigo : null)
  const [addressName, setAddressName] = useState(userInfo.direccion?.length === 1 ? userInfo.direccion[0].direccion : null)
  const [aliquot, setAliquot] = useState({}) // usar solamente en pagos con Mercado Pago;
  const [errors, setErrors] = useState({addressError: false})
  const [availableBalance, setAvailableBalance] = useState(null)
  const [shipmentSelected, setShipmentSelected] = useState(null)
  const [transportsList, setTransportsList] = useState({ isLoading: true, values: []})
  const [summaryValues, setSummaryValues] = useState({})
  
  useEffect(async () => {
    const balance = await getAvailableBalance(userInfo?.codigoUsuario || 0)
    setAvailableBalance(balance.disponible)
  }, [availableBalance])



  const shouldApplyTax = selectedValue === 'mp'

  useEffect(() => {
    getCartSummary(cart, shouldApplyTax, aliquot, userInfo)
      .then(setSummaryValues)
      .catch(console.error)
  }, [cart, shouldApplyTax, aliquot])

  const goToPayWithMP = async () => {
    try {
      const res = await payWithMP(
        summaryValues.total,
        userInfo.codigoUsuario,
        cart.idOrder
      )
      if (res) {
        dispatch(clearCart())
        window.location.assign(res)
      } else {
        dispatch(
          setSnackbar(true, 'error', 'Error de conexión. Intente nuevamente.')
        )
      }
    } catch (error) {
      dispatch(
        setSnackbar(true, 'error', 'Error de conexión. Intente nuevamente.')
      )
    }
    setProcessingPayment(false)
  }

  const handleGetAliquot = async () => {
    try {
      const res = await getPerceptionsMP(userInfo.codigoUsuario)

      if (res) {
        const minValue = fixAmount(res?.BaseMin || 0)
        const percentage = fixAmount(res?.alicuota || 0)

        return { minValue, percentage }
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(async () => {
    if (userInfo.codigoUsuario) {
      const value = await handleGetAliquot(userInfo.codigoUsuario)
      setAliquot(value || {})
    }
    dispatch(updateUserInfo(userInfo.user))
  }, [userInfo.codigoUsuario])

  // EVENTS
  const submitHandler = async (e) => {
    e.preventDefault()
    if(!address) {
      setErrors({...errors, addressError: true})
      return dispatch(setSnackbar(true, 'warning', 'Por favor, seleccione una dirección antes de continuar.'))
    }
    if(!shipmentSelected?.codigo) {
      setErrors({...errors, shipmentError: true})
      return dispatch(setSnackbar(true, 'warning', 'Por favor, elija un transporte antes de continuar.'))
    }
    
    setProcessingPayment(true)
    const isSellerUser = localStorage.getItem('permisos') === '2'
    try {
      await createHeaderCart({
        totalAmount: fixAmount(summaryValues?.total || 0),
        finalUserAmount: fixAmount(summaryValues?.total || 0),
        cardCode: userInfo.codigoUsuario,
        idUsuario: userInfo.codigoUsuario,
        uNroCompEsp: `${userInfo.codigoUsuario}${cart.idOrder}`,
        slpCode: currentBranch?.slpCode,
        idOrder: cart.idOrder,
        uBitSucursal: currentBranch?.cod,
        uBitTransporteAsignado: shipmentSelected.codigo,
        // docDate: currentDate,
        address: address,
        addressName: addressName,
        // docDueDate: currentDate,
        comments: comments,
        discPrcnt: summaryValues?.cupon?.percentage || '0',
        discCode: summaryValues?.cupon?.code || '',
        uBitOrigenPedido: isSellerUser ? '11' : '8',
        groupNum: userInfo.codPago
      })
    } catch (error) {
      return dispatch(
        setSnackbar(true, 'error', 'Error de conexión. Intente nuevamente.')
      )
    }
    // proceso a seguir en mercado pago
    if (selectedValue === 'mp') goToPayWithMP()
    if (selectedValue === 'cc') {
      try {
        const { message } = await createOrder({
          idUsuario: userInfo?.codigoUsuario,
          idOrder: cart.idOrder,
          cupon: cart?.cuponSelected || undefined
        })
        if (message.includes('exito')) {
          dispatch(subtractBalance(summaryValues?.total))
          navigate(`/pedido-realizado?status=true&idOrder=${cart.idOrder}`)
        }
        dispatch(clearCart())
      } catch (error) {
        dispatch(
          setSnackbar(true, 'error', 'Error de conexión. Intente nuevamente.')
        )
      }
    }
    setProcessingPayment(false)
  }

  const handleChange = (event) => {
    setSelectedValue(event.target.value)
  }
  const handleChangeCard = (event) => {
    setSelectedValue(event)
  }

  const handleSelect = (e) => {
    setAddress(e.target.value)
    setAddressName(userInfo.direccion.filter((d) => d.codigo === e.target.value)[0].direccion)
  }

  const diferenceAmountWithMP = fixAmount(
    summaryValues.totalWithTaxAndAliquot -
    summaryValues.totalWithouTaxAndAliquot || 0
  )

  useEffect(() => {
    getTransports(currentBranch.cod)
      .then((values) => {
        values.unshift({
          codigo: 'Retiro en sucursal'
        })
        setTransportsList({ isLoading: false, values })
      })
      .catch(() => {
        setTransportsList({ isLoading: false, values: [] })
        dispatch(setSnackbar(true, 'error', 'Error al cargar transportes'))
      })
  }, [currentBranch.cod])

  const setDefaultShipment = () => {
    if(!addressName) return;
    const userDireccion = userInfo?.direccion?.find(({direccion}) => direccion === addressName)
    if(!userDireccion?.transportePredeterminado) return;
    const transportForAddress = transportsList?.values?.
      find((shipment) => shipment.codigo === userDireccion.transportePredeterminado)
    setShipmentSelected(transportForAddress || null)
  }

  useEffect(
    setDefaultShipment,
    [addressName, userInfo?.direccion?.length, transportsList?.values?.length]
  )

  return (
    <>
      <Helmet>
        <title>Datos de Facturación | Villanueva</title>
        <meta name='description' content='Elige tu método de pago' />
        <meta
          name='keywords'
          content='facturación, pago, mercado pago, tarjeta de crédito, tarjeta de débito, efectivo, compra, villanueva, finalizar compra, datos'
        />
      </Helmet>
      <Box className={classes.container}>
        <Title
          text='Datos de Facturación'
          customStyles={desktop ? { margin: '30px 0px' } : {}}
        />
        <Grid container>
          <Grid className={classes.firstColumn}>
            <Card className={classes.userInfo}>
              <Grid container spacing={2}>
                <Grid item xs={7}>
                  <Typography paragraph className={classes.subtitle}>
                    Razon Social
                  </Typography>
                  <Typography paragraph className={classes.subtitleInfo}>
                    {userInfo?.razonSocial ?? ''}
                  </Typography>
                  <Typography paragraph className={classes.subtitle}>
                    Correo electrónico
                  </Typography>
                  <Typography paragraph className={classes.subtitleInfo}>
                    {userInfo.contacto.correoElectronico ?? ''}
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography paragraph className={classes.subtitle}>
                    CUIT
                  </Typography>
                  <Typography paragraph className={classes.subtitleInfo}>
                    {userInfo.user}
                  </Typography>
                  <Typography paragraph className={classes.subtitle}>
                    Teléfono
                  </Typography>
                  <Typography paragraph className={classes.subtitleInfo}>
                    {userInfo.contacto.telefonoFijo}
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography paragraph className={classes.subtitle}>
                    Dirección
                  </Typography>
                  <Typography paragraph className={classes.subtitleInfo}>
                    {addressName}
                  </Typography>
                </Grid>
                <Box sx={{ display: desktop ? 'flex' : 'block' }} width={'100%'} paddingLeft={2}>
                  <Box width={ desktop ? '50%' : '100%' }>
                    <Typography paragraph className={classes.subtitle}>
                      Seleccionar dirección de entrega
                    </Typography>
                    
                    <Select
                      inputProps={{
                        className: classes.textFieldInput,
                      }}
                      className={`${classes.textField}`}
                      value={address}
                      style={{width: desktop ? '90%' : '100%', height: '55px', marginBottom: desktop ? 0 : 10}}
                      onChange={handleSelect}
                      error={errors.addressError && !address}
                    >
                      {userInfo.direccion.map((d, index) => {
                        return (
                          <MenuItem value={d.codigo} key={index}>
                            {d?.direccion}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </Box>
                  <Box width={ desktop ? '50%' : '100%' }>
                    <Typography paragraph className={classes.subtitle}>
                      Seleccionar transporte
                    </Typography>
                    <Autocomplete
                      id="controlled-demo"
                      value={shipmentSelected}
                      options={transportsList.values}
                      getOptionLabel={(option) => `${option.codigo} ${option.nombre ? `(${option.nombre})` : ''}`}
                      isOptionEqualToValue={(option) => option.id}
                      disabled={!!transportsList.isLoading}
                      onChange={(event, newValue) => {
                        setShipmentSelected(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField 
                          {...params} 
                          style={{ width: desktop ? '90%' : '100%' }}
                          className={`${classes.textField}`}
                          error={errors.shipmentError && !shipmentSelected}
                          id='standard-basic'
                          variant='outlined'
                          placeholder={transportsList.isLoading ? 'Cargando transportes...' : 'Escribe para buscar...'}
                          fullWidth
                        />
                      )}
                    />
                  </Box>
                </Box>
                <Grid item xs={12}>
                  <Typography paragraph className={classes.subtitle}>
                    Aclaración{' '}
                    <span className={classes.options}>(Opcional)</span>
                  </Typography>
                  <TextField
                    id='standard-basic'
                    label='Ej: El Telefono correcto es 351-2...'
                    variant='outlined'
                    value={comments}
                    fullWidth
                    className={classes.textInput}
                    onChange={(e) => {
                      const str = e.target.value;
                      setComments(str.replace(/[^a-zA-Z0-9 .,!]/g, ""))
                    }}
                  />
                </Grid>
              </Grid>
            </Card>
            <Title
              text='Medio de pago'
              customStyles={desktop ? { margin: '30px 0px' } : {}}
            />
            {!['4', '6', '7'].includes(userInfo.codPago) ? (
              <Card
                className={classes.card}
                onClick={() => handleChangeCard('cc')}
              >
                <Grid
                  container
                  spacing={2}
                  className={classes.cardInsideContainer}
                >
                  <Grid item xs={2}>
                    <Radio
                      checked={selectedValue === 'cc'}
                      onChange={handleChange}
                      value='cc'
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className={classes.addressTitleActive}>
                      Cuenta Corriente
                    </Typography>
                    <Typography className={classes.addressDescriptionActive}>
                      Saldo disponible{' '}
                      {availableBalance ? parseMoney(availableBalance) :
                        '0'}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}></Grid>
                </Grid>
              </Card>
            ) :
              (
                localStorage.getItem('permisos') !== '2' && (
                  <Card
                    className={classes.card}
                    onClick={() => handleChangeCard('mp')}
                  >
                    <Grid
                      container
                      spacing={2}
                      className={classes.cardInsideContainer}
                    >
                      <Grid item xs={2}>
                        <Radio
                          checked={selectedValue === 'mp'}
                          onChange={handleChange}
                          value='mp'
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Typography className={classes.addressTitleActive}>
                          Tarjeta de crédito o debito
                        </Typography>
                        {diferenceAmountWithMP ? (
                          <Typography className={classes.addressDescriptionActive}>
                            Se sumará {formatNumber(diferenceAmountWithMP.toString())}{' '}
                            correspondiente al IVA
                          </Typography>
                        ) : null}
                      </Grid>
                      <Grid item xs={4}>
                        <img src={logoMP} alt='Logo Mercadopago' />
                      </Grid>
                    </Grid>
                  </Card>
                )
              )}
          </Grid>
          {desktop ? (
            <CheckoutSummary
              firstAction={submitHandler}
              firstActionDisabled={processingPayment}
              secondAction={() => navigate('/')}
              firstText='Finalizar pedido'
              secondText='Seguir comprando'
              shouldApplyTax={selectedValue === 'mp'}
              aliquot={aliquot}
            />
          ) : (
            <Grid className={classes.grid}>
              <BottomActions
                firstAction={submitHandler}
                firstActionDisabled={processingPayment}
                firstText={'Finalizar pedido'}
                cart={cart}
              />
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  )
}

export default PaymentMethodPage
