import {
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_LIST_FAIL,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_DETAILS_FAIL,
  CONCAT_PRODUCTS,
} from './constantsProduct'

const initialState = {
  loading: false,
  error: false,
  product: [],
  caravanasOficiales: [],
  recomendados: [],
}

export const productListReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case PRODUCT_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      }
    case PRODUCT_LIST_SUCCESS: {
      const { response, entity } = payload
      return {
        ...state,
        loading: false,
        error: false,
        [entity]: [...state?.[entity], ...response.products],
      }
    }
    case CONCAT_PRODUCTS: {
      const { response, entity } = payload
      return {
        ...state,
        loading: false,
        error: false,
        [entity]: [...state?.[entity], ...response.products],
      }
    }
    case PRODUCT_LIST_FAIL:
      return { ...state, loading: false, error: payload }
    default:
      return state
  }
}

export const productDetailsReducer = (state = { loading: true }, action) => {
  const { type, payload } = action
  switch (type) {
    case PRODUCT_DETAILS_REQUEST:
      return { loading: true }
    case PRODUCT_DETAILS_SUCCESS:
      return { loading: false, product: payload }
    case PRODUCT_DETAILS_FAIL:
      return { loading: false, error: payload }
    default:
      return state
  }
}
