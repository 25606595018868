import { ApiConnection } from 'config'

export const addItem = async (item, order) => {
  try {
    const sellerId = localStorage.getItem('sellerId')

    const payload = {
      ...order,
      ...item,
      sellerId: sellerId || null,
    }

    const { data } = await ApiConnection.post(`cart`, payload)
    return { ...data }
  } catch (error) {
    console.error(error)
  }
}
