import React from 'react'
import { Grid, Slide, Modal, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import {
  parseCodigo,
  parseDateString,
  parseMoney,
  toInt,
} from './utils/parseFunctions'
import { getStatusColor } from './OrderListPage.styles'
import RoundedSquareIconButton from 'components/RoundedSquareIconButton'
import DownloadIcon from '../../components/DownloadIcon'

const containerStyle = {
  position: 'absolute',
  top: '50vh',
  left: '50vw',
  width: 330,
  bgcolor: 'white',
  boxShadow: 24,
  padding: '32px 16px 26px',
  paddingBottom: '47px',
  borderRadius: '8px',
  transform: 'translate(-50%,-50%) !important',
}

const h2Style = { width: '50%' }
const pStyle = { width: '50%', margin: '8px 0px' }
const greyBackground = {
  backgroundColor: 'rgba(217, 217, 217, 0.2)',
  marginLeft: '-16px',
  width: 330,
  paddingLeft: '16px',
}

function OrderDetailModal({ open, onClose, product }) {
  return (
    <Modal open={open} onClose={onClose} closeAfterTransition>
      <Slide in={open} direction='up'>
        <Grid sx={containerStyle}>
          <Grid sx={{ marginBottom: '30px' }}>
            <Typography
              variant='h1'
              sx={(theme) => ({ color: theme.palette.primary.typographyGreen })}
            >
              Pedido: {parseCodigo(product?.NroEc || product?.NroPedido)}
            </Typography>
            <Typography variant='p'>
              {parseDateString(product?.Fecha)}
            </Typography>
          </Grid>
          <Grid container wrap='nowrap' flexDirection='column'>
            <Grid container flexDirection='column'>
              <Grid container alignItems='center' sx={greyBackground}>
                <Typography sx={h2Style} variant='h2'>
                  Total del pedido
                </Typography>
                <Typography variant='p' sx={pStyle}>
                  {`$ ${parseMoney(product?.TotalPedido)}`}
                </Typography>
              </Grid>

              <Grid container alignItems='center'>
                <Typography sx={h2Style} variant='h2'>
                  Producto
                </Typography>
                <Typography variant='p' sx={pStyle}>
                  {product?.Descripcion}
                </Typography>
              </Grid>

              <Grid container alignItems='center' sx={greyBackground}>
                <Typography sx={h2Style} variant='h2'>
                  Transporte
                </Typography>
                <Typography variant='p' sx={pStyle}>
                  {product?.Transporte}
                </Typography>
              </Grid>

              <Grid container alignItems='center'>
                <Typography sx={h2Style} variant='h2'>
                  Despachado
                </Typography>
                <Typography
                  variant='p'
                  sx={{
                    ...pStyle,
                    fontWeight: 500,
                    color: getStatusColor(product?.Estado),
                  }}
                >
                  {`${toInt(product?.CantidadDespachada)} de ${toInt(
                    product?.CantidadPedida
                  )}`}
                </Typography>
              </Grid>

              <Grid container alignItems='center' sx={greyBackground}>
                <Typography sx={h2Style} variant='h2'>
                  Fecha despacho
                </Typography>
                <Typography variant='p' sx={pStyle}>
                  {product?.FechaDespacho}
                </Typography>
              </Grid>

              <Grid container alignItems='center'>
                <Typography sx={h2Style} variant='h2'>
                  Estado
                </Typography>
                <Typography
                  variant='p'
                  sx={{
                    ...pStyle,
                    fontWeight: 500,
                    color: getStatusColor(product?.Estado),
                  }}
                >
                  {product?.Estado}
                </Typography>
              </Grid>

              <Grid container alignItems='center' sx={greyBackground}>
                <Typography sx={h2Style} variant='h2'>
                  Series
                </Typography>
                <Typography variant='p' sx={pStyle}>
                  {product?.SerieCE}
                </Typography>
              </Grid>

              <Grid container alignItems='center'>
                <Typography sx={h2Style} variant='h2'>
                  Productor
                </Typography>
                <Typography variant='p' sx={pStyle}>
                  {product?.Productor}
                </Typography>
              </Grid>

              <Grid container alignItems='center' sx={greyBackground}>
                <Typography sx={h2Style} variant='h2'>
                  Establecimiento
                </Typography>
                <Typography variant='p' sx={pStyle}>
                  {product?.Establecimiento}
                </Typography>
              </Grid>

              <Grid container alignItems='center'>
                <Typography sx={h2Style} variant='h2'>
                  Cuig
                </Typography>
                <Typography variant='p' sx={pStyle}>
                  {product?.Cuig}
                </Typography>
              </Grid>

              <Grid container alignItems='center' sx={greyBackground}>
                <Typography sx={h2Style} variant='h2'>
                  Renspa
                </Typography>
                <Typography variant='p' sx={pStyle}>
                  {product?.Renspa}
                </Typography>
              </Grid>

              <Grid container alignItems='center'>
                <Typography sx={h2Style} variant='h2'>
                  Rango
                </Typography>
                <Typography variant='p' sx={pStyle}>
                  {product?.Rango}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              justifyContent='center'
              alignItems='center'
              sx={{
                marginTop: '30px',
              }}
            >
              <Typography
                variant='h3'
                sx={{
                  marginRight: '18px',
                }}
              >
                Descargar
              </Typography>
              <RoundedSquareIconButton
                icon={
                  <DownloadIcon
                    width='14px'
                    height='14px'
                    style={{ marginTop: '2px' }}
                  />
                }
              />
            </Grid>
          </Grid>

          <CloseIcon
            sx={{
              position: 'absolute',
              right: '25px',
              top: '25px',
              cursor: 'pointer',
            }}
            onClick={onClose}
          />
        </Grid>
      </Slide>
    </Modal>
  )
}

export default OrderDetailModal
