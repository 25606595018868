import React from 'react'
import { useStyles } from './ProductLinesComponent.styles'
import {
  useTheme,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  IconButton,
} from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

const ProductLinesComponent = ({ productLinesBanners, desktop }) => {
  const classes = useStyles()
  const theme = useTheme()

  return (
    <Grid container spacing={2} justifyContent='center'>
      {productLinesBanners.map((banner) => (
        <Grid item xs={12} sm={desktop ? 4 : 6} key={banner.id}>
          <Card
            className={classes.card}
            onClick={() =>
              banner.redirectTo && (window.location.href = banner.redirectTo)
            }
          >
            <CardMedia
              image={`${process.env.REACT_APP_URL_BACKEND_REMOTO}/files/${banner.file.ruta}`}
              title={banner.label}
              className={classes.cardMedia}
            />
            <CardContent className={classes.cardContent}>
              <Typography
                variant='h5'
                component='h2'
                gutterBottom
                className={classes.typography}
              >
                {banner.label}
              </Typography>
              {banner.redirectTo && (
                <IconButton
                  aria-label='go to banner'
                  className={classes.iconButton}
                  onClick={() =>
                    banner.redirectTo &&
                    (window.location.href = banner.redirectTo)
                  }
                >
                  <ArrowForwardIcon />
                </IconButton>
              )}
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  )
}

export default ProductLinesComponent
