import { ApiConnection } from 'config'


const getPerceptionsMP = async (cardCode) => {
  try {
    const res = await ApiConnection.get(
      `users/perceptions`,
      {
        params: { cardCode },
      }
    )
    return res?.data?.response
  } catch (error) {
    throw new Error(error.message)
  }
}

export default getPerceptionsMP
