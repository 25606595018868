import { buildBreadcumbs, buildUrl } from 'utils/filters'
import { normalizeUrlString } from 'utils/formatString'
import store from 'store'

export const findCurrentCategoryWithNormalizedTree = (tree) => {
  const { allCategories } = store.getState().app
  const treeParts = tree.split('/')

  const possibleCategories = allCategories.filter((c) => normalizeUrlString(c.nombre) === [...treeParts].pop())

  if(possibleCategories.length === 1) {
    return possibleCategories[0]
  }

  for (const currentCat of possibleCategories) {
    if(buildUrl(currentCat.codigo) === tree)
      return currentCat
  }
}

export const buildCategoriesURLTree = (categoriesUrl) => {
  const fullTree = categoriesUrl
  let currentCategory = null
  let treeWithoutCurrent = []
  const currentCategoryURL = fullTree.split('/').pop()
  let treeWithoutCurrentCategoryURL = [...fullTree.split('/')]
  treeWithoutCurrentCategoryURL.pop()
  treeWithoutCurrentCategoryURL = treeWithoutCurrentCategoryURL.join('/')


  const categoryCode = findCurrentCategoryWithNormalizedTree(categoriesUrl)

  const breadcumbsThree = buildBreadcumbs(categoryCode.codigo)
  currentCategory = breadcumbsThree[breadcumbsThree.length - 1]
  treeWithoutCurrent = breadcumbsThree.filter((cat) => cat.id !== currentCategory.id)

  return {
    fullTree,
    treeWithoutCurrentCategoryURL,
    currentCategoryURL,
    values: {
      currentCategory,
      treeWithoutCurrent
    }
  }
}