import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  grid: {
    padding: '62px 29px 0px 29px',
    backgroundColor: theme.palette.primary.white,
    alignItems: 'space-around',
    [theme.breakpoints.up('sm')]: {
      padding: '38px 50px 30px',
      boxSizing: 'border-box',
      maxWidth: '641px',
    },
  },
  container: {},
  textInput: {
    borderRadius: '8px',
  },
  addressTitleActive: {
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '15px',
    color: theme.palette.primary.almostBlack,
  },
  title: {
    fontWeight: '700',
    fontSize: '22px',
    lineHeight: '32px',
    marginBottom: '10px',
    color: theme.palette.primary.almostBlack,
    [theme.breakpoints.up('sm')]: {
      textAlign: 'center',
      marginBottom: '18px',
    },
  },
  tagName: {
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '15px',
    color: theme.palette.primary.moreGrey,
    marginBottom: '9px',
    marginTop: '15px',
  },
  inputField: {
    [theme.breakpoints.up('sm')]: {
      '& input': {
        fontSize: '12px',
        color: theme.palette.primary.moreGrey,
      },
    },
  },
  subtitleInfo: {
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '18px',
    color: theme.palette.primary.typographyGrey,
    marginBottom: '5px',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'center',
      maxWidth: '300px',
      margin: 'auto',
      marginBottom: '34px',
    },
  },

  gridAligner: {},
  buttonsGrid: {
    marginTop: '55px',
  },
  forgot: {
    fontWeight: '300',
    fontSize: '12px',
    textDecoration: 'underline',
  },
  buttonBuy: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '6px',
    height: '56px',
    fontWeight: '500',
    fontSize: '18px',
    textTransform: 'unset',
    marginTop: '51px',
    lineHeight: '22px',
  },
  buttonKeepBuying: {
    borderRadius: '6px',
    color: theme.palette.primary.typographyGreen,
    fontWeight: '400',
    fontSize: '18px',
    textTransform: 'unset',
    lineHeight: '22px',
    height: '56px',
    marginBottom: '20px',
    marginTop: '10px',
  },
  bottomInfo: {
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    marginTop: '30px',
  },
  question: {
    fontWeight: '300',
    fontSize: '14px',
    lineHeight: '17px',
    [theme.breakpoints.up('sm')]: {
      marginBottom: '5px',
    },
  },
  alta: {
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '17px',
    color: theme.palette.primary.typographyGreen,
    [theme.breakpoints.up('sm')]: {
      marginBottom: '22px',
    },
  },
  disclaimer: {
    marginTop: '30px',
    fontWeight: '200',
    fontSize: '8px',
    lineHeight: '17px',
    textAlign: 'left',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'center',
      maxWidth: '300px',
      margin: 'auto',
    },
  },
}))
