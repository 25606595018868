import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  mainBox: {
    backgroundColor: theme.palette.backgroundColor,
    minHeight: '100vh',
    paddingLeft: '14px',
    paddingRight: '14px',
    [theme.breakpoints.up('md')]: {
      backgroundColor: 'white',
      padding: 0,
      marginTop: '0px',
      boxSize: 'border-box',
    },
  },
  closeBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: '14px',
    cursor: 'pointer',
  },
  filterLabel: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    marginBottom: '12px',
    paddingLeft: '38px',
    cursor: 'pointer !important',
    [theme.breakpoints.up('md')]: {
      marginBottom: '7px',
      paddingLeft: '0px',
    },
  },
  slider: {
    color: '#6398FF',
    '& .MuiSlider-valueLabel': {
      fontSize: 12,
      fontWeight: 'normal',
      top: -6,
      backgroundColor: 'unset',
      color: '#000',
      '&:before': {
        display: 'none',
      },
      '& *': {
        background: 'transparent',
        color: '#000',
      },
    },
  },
  desdeHasta: {
    width: '85%',
    fontSize: '12px !important',
    padding: 0,
  },
}))
