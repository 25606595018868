class Queue {
  constructor(){
    this.queue = []
    this.executing = false
    this.onEndExcecution = function(){}
  }

  addToQueue(promiseCallback, onEndExcecution) {
    this.queue.push(promiseCallback);
    if(onEndExcecution)
      this.onEndExcecution = onEndExcecution
    if(this.executing === false)
      this.sendNextPromise()
  }

  sendNextPromise() {
    if (this.queue.length > 0) {
      const promiseCallback = this.queue.shift();
      promiseCallback().finally(() => this.sendNextPromise());
      this.executing = true
    } else {
      this.executing = false
      this.onEndExcecution()
      this.onEndExcecution = () => {}
    }
  }
}

export const requestQueue = new Queue()