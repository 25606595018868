import React from 'react'
import { Link } from 'react-router-dom'

export default function ListItemComponent({
  label,
  icon,
  children,
  active,
  linkTo,
  onClick,
}) {
  return (
    <Link
      to={linkTo || '/'}
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
      onClick={onClick}
    >
      {icon && <img style={{ marginLeft: '25px' }} src={icon} alt={label} />}
      {children && children}
      <p
        style={{
          marginLeft: icon ? '25px' : '5px',
          fontFamily: 'Inter',
          fontStyle: 'normal',
          fontWeight: active ? '600' : '400',
          fontSize: '14px',
          color: active ? '#48854E' : '#444444',
        }}
      >
        {label}
      </p>
    </Link>
  )
}
