import { ApiConnection } from 'config'


const getTransports = async (sucursal) => {
    try {
        const {data} = await ApiConnection.post(
             `transport/bysucursal`,
            {
                sucursal
            }
        
    )
        return data.response
    } catch (error) {
        throw new Error(error.message)
    }
}

export default getTransports