import { makeStyles } from '@mui/styles'
import { createStyles } from '@mui/material/styles'

export const useStylesBreadCrumb = makeStyles(
  createStyles({
    li: {
      marginTop: '0px',
    },
    separator: {
      margin: 0,
      paddingTop: '1px',
    },
    labelBreadCrumb: {
      fontFamily: 'Yantramanav',
      fontWeight: '400',
      fontSize: '13px',
      lineHeight: '15px',
      marginBottom: '0px',
      cursor: 'pointer',
    },
  })
)
