export const formatNumber = (number, currency = 'ARS') => {
  if (typeof number === 'string') {
    const numberFormatted = new Intl.NumberFormat('es-AR', {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    })
    return numberFormatted.format(number)
  }
  
  if (currency === 'USD') {
    const exchangeRate = 0.0 // ejemplo de tasa de cambio (1 USD = 20.46 ARS)
    const numberConverted = number * exchangeRate
    const numberFormatted = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    return numberFormatted.format(numberConverted)
  }

  return number
}

export function numberWithDots(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}
