import { ApiConnection } from 'config'


const payWithMP = async (
  importe,
  cardCode,
  idOrder,
  accountPayment = false
) => {
  try {
    const res = await ApiConnection.post(
      `mercado-pago/checkout`,
      {
        unit_price: importe,
        cardCode,
        idOrder,
        accountPayment,
      }
    )
    return res?.data?.response?.redireccion
  } catch (error) {
    throw new Error(error.message)
  }
}

export default payWithMP
