import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    primary: {
      main: '#4AA259',
      white: '#ffffff',
      almostBlack: '#444444',
      moreGrey: '#4A4A57',
      moreBlack: '#333333',
      almostWhite: '#F4F4F4',
      grey: '#AEACAC',
      typographyGrey: '#A1A1A1',
      disabled: '#C9C9C9',
      typographyGreen: '#00984A',
      typographyRed: '#E45151',
      darkGreen: '#4A8850',
      lightGreen: '#E6F3E8',
      lightBlue: '#74A3FF',
      moreBlue: '#53A2FF',
      filterBlue: '#6398FF',
      almostBlue: '#75C2EE',
      yellow: '#F8B900',
      productName: '#B6B6B6',
      softGreen: 'rgba(0, 152, 74, 0.1)',
      chipBackground: '#F7F7F7',
      lightGrey: '#999999',
      borderGrey: '#e3e3e3',
    },
    error: {
      main: '#d32f2f',
      dark: 'a41919',
    },
    backgroundColor: '#F4F4F4',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1366,
      xl: 1600,
    },
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'primary' },
          style: {
            background: '#4AA259',
            borderRadius: '6px',
            textTransform: 'none',
            padding: '17px 20px',
            width: '100%',
            fontSize: '18px',
            lineHeight: '20px',
            fontWeight: 500,
            color: '#FFFFFF',
            '&:hover': {
              background: '#3c8248',
            },
          },
        },
        {
          props: { variant: 'secondary' },
          style: {
            background: 'rgba(74, 162, 89, 0.2)',
            borderRadius: '6px',
            textTransform: 'none',
            padding: '17px 20px',
            width: '100%',
            fontSize: '18px',
            lineHeight: '20px',
            fontWeight: 500,
            color: '#4AA259',
            '&:hover': {
              background: 'rgba(74, 162, 89, 0.3)',
            },
          },
        },
        {
          props: { variant: 'lastOpportunityPrimary' },
          style: {
            background: '#b71c1c',
            borderRadius: '6px',
            textTransform: 'none',
            padding: '17px 20px',
            width: '100%',
            fontSize: '18px',
            lineHeight: '20px',
            fontWeight: 500,
            color: '#FFFFFF',
            '&:hover': {
              background: '#9a0007',
            },
          },
        },
        {
          props: { variant: 'lastOpportunitySecondary' },
          style: {
            background: 'rgba(183, 28, 28, 0.2)',
            borderRadius: '6px',
            textTransform: 'none',
            padding: '17px 20px',
            width: '100%',
            fontSize: '18px',
            lineHeight: '20px',
            fontWeight: 500,
            color: '#b71c1c',
            '&:hover': {
              background: 'rgba(183, 28, 28, 0.3)',
            },
          },
        },
      ],
    },
  },
  typography: {
    fontFamily: 'Inter',
    h1: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '120%',
      fontSize: '18px',
      color: '#444444',
    },
    h2: {
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '19px',
      color: '#444444',
      padding: '8px 0px',
    },
    h3: {
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '19px',
      color: '#444444',
    },
    a: { textDecoration: 'none' },
    p: {
      fontSize: '14px',
      lineHeight: '16px',
      fontWeight: 400,
      color: '#444444',
    },
    subtitle: {
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: 400,
      color: '#444444',
    },
  },
})
export default theme
