import { CircularProgress, Grid } from '@mui/material'

import React from 'react'

function LoadingSpinner() {
  return (
    <Grid container justifyContent='center' sx={{ padding: '12px 0px' }}>
      <CircularProgress disableShrink color='inherit' />
    </Grid>
  )
}

export default LoadingSpinner
