import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: '1040px',
      margin: 'auto',
    },
  },
  mainGrid: {
    padding: '15px',
  }
}))
