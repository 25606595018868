import React from 'react'
import InputBase from '@mui/material/InputBase'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'

function ClientSearchBox({ onChange, placeholder, containerStyles, onSubmit }) {
  return (
    <Paper
      sx={{
        display: 'flex',
        alignItems: 'center',
        borderRadius: '8px',
        height: '50px',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.08)',
        margin: '20px 0px',
        ...containerStyles,
      }}
    >
      <form style={{ display: 'flex' }} onSubmit={(e) => onSubmit(e)}>
        <IconButton type='submit' sx={{ p: '10px' }} aria-label='search'>
          <SearchIcon />
        </IconButton>
        <InputBase
          sx={{
            ml: 1,
            width: '100%',
            '& .MuiInputBase-input': {
              fontSize: '14px',
            },
          }}
          placeholder={placeholder ?? 'Buscar clientes'}
          inputProps={{
            'aria-label': 'Buscar clientes',
            style: { border: '0px' },
          }}
          name='q'
          id='q'
          onChange={(e) => onChange(e.target.value)}
        />
      </form>
    </Paper>
  )
}

export default ClientSearchBox
