import React from 'react'
import { Typography, Button, Grid } from '@mui/material'
import { useStyles } from '../ResetPassword/ResetPasswordSent.styles'
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead'
import { Helmet } from 'react-helmet'
import { useNavigate} from 'react-router-dom'

function ChangesAndReturnsSent() {
  const navigate = useNavigate()
  const classes = useStyles()

  return (
    <>
      <Helmet>
        <title>Cambios o devoluciones | Villanueva</title>
      </Helmet>
      <div className={classes.container}>
        <Grid className={classes.innerContainer}>
          <Grid className={classes.iconContainer}>
            <MarkEmailReadIcon className={classes.emailIcon} />
          </Grid>
          <Typography className={classes.title}>
            Cambios o devoluciones.
          </Typography>
          <Typography paragraph className={classes.subtitleInfo}>
            Hemos recibido tu solicitud, un asesor se comunicara contigo en la brevedad.
          </Typography>
          <Grid sx={{ width: { sm: '325px' } }}>
          <Grid sx={{ width: { sm: '325px' } }}>
            <Button
              variant='primary'
              fullWidth
              onClick={() =>navigate(`/`)}
              className={classes.buttonBuy}
            >
              Regresar
            </Button>
          </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default ChangesAndReturnsSent;
