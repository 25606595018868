import React from 'react'
import { Carousel } from 'react-responsive-carousel'
import CarrouselControl from 'components/CarrouselControl'
import { useNavigate } from 'react-router-dom'

const CarouselComponent = ({ bannersToShow, desktop }) => {
  const navigate = useNavigate()

  const handleImageClick = (redirectTo) => {
    if (redirectTo) {
      navigate(redirectTo)
    }
  }

  return (
    <Carousel
      showArrows
      autoPlay
      infiniteLoop
      interval={5000}
      showThumbs={false}
      onClickItem={(itemIndex) =>
        handleImageClick(bannersToShow[itemIndex].redirectTo)
      }
      renderArrowNext={(onClick, nextItem) =>
        desktop ? (
          <CarrouselControl
            onClick={onClick}
            nextItem={nextItem}
            direction='right'
          />
        ) : (
          <></>
        )
      }
      renderArrowPrev={(onClick, prevItem) =>
        desktop ? (
          <CarrouselControl
            onClick={onClick}
            previousItem={prevItem}
            direction='left'
          />
        ) : (
          <></>
        )
      }
    >
      {bannersToShow.map((banner, index) => (
        <div
          key={index}
          style={{
            cursor: banner.redirectTo ? 'pointer' : 'default',
            display: 'flex',
            justifyContent: 'center',
          }}
          onClick={() => handleImageClick(banner.redirectTo)}
        >
          <img
            src={`${process.env.REACT_APP_URL_BACKEND_REMOTO}/files/${banner.file.ruta}`}
            alt={banner.label}
          />
        </div>
      ))}
    </Carousel>
  )
}

export default CarouselComponent
