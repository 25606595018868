export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST'
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS'
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL'

export const USER_SIGNIN_REQUEST = 'USER_SIGNIN_REQUEST'
export const USER_SIGNIN_SUCCESS = 'USER_SIGNIN_SUCCESS'
export const USER_SIGNIN_FAIL = 'USER_SIGNIN_FAIL'

export const USER_SIGNOUT = 'USER_SIGNOUT'

export const USER_DETAILS_REQUEST = 'USER_DETAILS_REQUEST'
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS'
export const USER_PROMOTIONS_SUCCESS = 'USER_PROMOTIONS_SUCCESS'
export const USER_DETAILS_FAIL = 'USER_DETAILS_FAIL'
export const USER_DETAILS_RESET = 'USER_DETAILS_RESET'

export const USER_LIST_REQUEST = 'USER_LIST_REQUEST'
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS'
export const USER_LIST_FAIL = 'USER_LIST_FAIL'

export const USER_UPDATE_PROFILE_REQUEST = 'USER_UPDATE_PROFILE_REQUEST'
export const USER_UPDATE_PROFILE_SUCCESS = 'USER_UPDATE_PROFILE_SUCCESS'
export const USER_UPDATE_PROFILE_FAIL = 'USER_UPDATE_PROFILE_FAIL'
export const USER_UPDATE_PROFILE_RESET = 'USER_UPDATE_PROFILE_RESET'
export const USER_MODAL_LOGIN_STATE = 'USER_MODAL_LOGIN_STATE'
export const USER_RESET_PASSWORD_MODAL_STATE = 'USER_RESET_PASSWORD_MODAL_STATE'

export const USER_CURRENT_BRANCH = 'USER_CURRENT_BRANCH'
export const UPDATE_BRANCH_LIST = 'UPDATE_BRANCH_LIST'
export const UPDATE_BALANCE = 'UPDATE_BALANCE'

export const UPDATE_USER_OPENED_CART = 'UPDATE_USER_OPENED_CART';

