import { ApiConnection } from 'config'

export const createOrder = async (order) => {
  const sellerId = localStorage.getItem('sellerId') || undefined
  const { data } = await ApiConnection.post(
    `purchase`,
    null,
    {
      params: { ...order, sellerId },
    }
  )
  return { ...data }
}
