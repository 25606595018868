import React from 'react'
import Box from '@mui/material/Box'
import Footer from './Footer'
import HeaderComponent from './Header/index'
import Snackbar from './SnackBar'
import CloseAlert from './CloseAlert'

const Layout = ({ children }) => {
  return (
    <Box sx={{ backgroundColor: '#F4F4F4' }}>
      <CloseAlert />
      <HeaderComponent />
      <main>{children}</main>
      <Footer />
      <Snackbar />
    </Box>
  )
}

export default Layout
