import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  IconButton,
  Grid,
  Typography,
  useMediaQuery,
  CircularProgress,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useNavigate } from 'react-router-dom'
import { detailsWidths, tableColumns, useStyles } from './OrderListPage.styles'
import OrderListDesktop from './OrderListDesktop/OrderListDesktop'
import { getAllOrders } from './services/getAllOrders'
import groupOrders from './utils/groupOrders'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  parseCodigo,
  parseDateStringShort,
  toInt,
  trimString,
} from './utils/parseFunctions'
import { getStatusColor } from './OrderListDesktop/OrderListDesktop.styles'
import StatusIcon from './components/StatusIcon'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import RoundedSquareIconButton from 'components/RoundedSquareIconButton'
import OrderDetailModal from './OrderDetailModal'
import { Helmet } from 'react-helmet'

const OrderListPage = () => {
  const userSignin = useSelector((state) => state.userAuth)
  const { userInfo } = userSignin

  const classes = useStyles()
  const navigate = useNavigate()
  const desktop = useMediaQuery((theme) => theme.breakpoints.up('sm'))

  const [orders, setOrders] = useState([])
  const [ordersToRender, setOrdersToRender] = useState([])
  const [loadingOrders, setLoadingOrders] = useState(false)
  const [expanded, setExpanded] = useState(-1)
  const [openDetailModal, setOpenDetailModal] = useState(false)
  const [modalProduct, setModalProduct] = useState({})

  useEffect(() => {
    setLoadingOrders(true)
    getAllOrders(userInfo?.codigoUsuario)
      .then((orders) => {
        const groupedOrders = groupOrders(orders)
        setOrders(groupedOrders)
        setOrdersToRender(groupedOrders.slice(0, 20))
      })
      .finally(() => setLoadingOrders(false))
  }, [])

  const handleSearchSubmit = (e, query) => {
    e.preventDefault()
    if (query?.trim() !== '') {
      const newOrders = orders.filter((order) => {
        if (order?.codigo?.toLowerCase().includes(query?.toLowerCase())) {
          return order
        }
      })
      setOrdersToRender(newOrders)
    } else {
      resetOrders()
    }
  }

  const resetOrders = (e) => {
    setOrdersToRender(orders)
  }

  const handleOpenModal = (e, producto) => {
    e.preventDefault()
    setModalProduct(producto)
    setOpenDetailModal(true)
  }

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : -1)
  }

  const handleModalClose = (e) => {
    setOpenDetailModal(false)
  }

  return (
    <>
      <Helmet>
        <title>Historial de pedidos | Villanueva</title>
        <meta name='description' content='Historial de pedidos en Villanueva' />
        <meta name='keywords' content='historial, pedidos, Villanueva' />
      </Helmet>
      <div className={classes.container}>
        <Grid container className={classes.title}>
          <IconButton aria-label='delete' onClick={() => navigate(-1)}>
            <ArrowBackIcon fontSize='medium' />
          </IconButton>
          <h1>Historial de pedidos</h1>
        </Grid>

        {desktop ? (
          <OrderListDesktop
            loading={loadingOrders}
            orderList={ordersToRender}
            handleSearchSubmit={handleSearchSubmit}
            resetOrders={resetOrders}
            showAllButton={orders !== ordersToRender}
            totalOrderLength={orders.length}
          />
        ) : (
          <Grid className={classes.orderList}>
            <Grid container className={classes.tableHeadersRow}>
              <Typography variant='h3' sx={{ width: tableColumns[0] }}>
                Fecha
              </Typography>
              <Typography variant='h3' sx={{ width: tableColumns[1] }}>
                Cod. pedido
              </Typography>
              <Typography variant='h3' sx={{ width: tableColumns[2] }}>
                Estado general
              </Typography>
            </Grid>

            {loadingOrders && (
              <Grid
                container
                alignItems='center'
                justifyContent='center'
                sx={{ minHeight: '30vh' }}
                className={classes.borderTop}
              >
                <CircularProgress color='primary' disableShrink />
              </Grid>
            )}

            {ordersToRender.map((order, index) => (
              <Accordion
                disableGutters
                className={classes.accordionContainer}
                onChange={handleChange(index)}
                expanded={expanded === index}
                square
                key={index}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Grid container alignItems='center'>
                    <Typography variant='p' sx={{ width: tableColumns[0] }}>
                      {parseDateStringShort(order?.fecha)}
                    </Typography>
                    <Typography variant='p' sx={{ width: tableColumns[1] }}>
                      {parseCodigo(order?.codigo).startsWith('S')
                        ? parseCodigo(order?.codigo) 
                        : `EC-${parseCodigo(order?.codigo)}`
                      }
                    </Typography>
                    <Grid
                      container
                      sx={{
                        justifyContent: 'center',
                        width: tableColumns[2],
                      }}
                    >
                      <StatusIcon statusString={order?.estado} />
                    </Grid>
                  </Grid>
                </AccordionSummary>

                <AccordionDetails
                  sx={{
                    padding: '20px 12px 30px !important',
                  }}
                >
                  <Grid container className={classes.detailsHeadersRow}>
                    <Typography
                      className={classes.detailHeader}
                      sx={{ width: detailsWidths[0] }}
                    >
                      Cant
                    </Typography>
                    <Typography
                      className={classes.detailHeader}
                      sx={{ width: detailsWidths[1] }}
                    >
                      Producto
                    </Typography>
                    <Typography
                      className={classes.detailHeader}
                      sx={{ width: detailsWidths[2] }}
                    >
                      Estado
                    </Typography>
                    <Typography
                      className={classes.detailHeader}
                      sx={{ width: detailsWidths[3] }}
                    >
                      Detalle
                    </Typography>
                  </Grid>

                  {order?.articulos?.map((producto, index) => (
                    <Grid className={classes.detailRow} key={index}>
                      <Typography
                        className={classes.detailText}
                        sx={{
                          width: detailsWidths[0],
                          textAlign: 'right',
                          paddingRight: '18px',
                        }}
                      >
                        {toInt(producto?.CantidadPedida)}
                      </Typography>
                      <Typography
                        className={classes.detailText}
                        sx={{ width: detailsWidths[1], paddingRight: '4px' }}
                      >
                        {trimString(producto?.Descripcion, 44)}
                      </Typography>
                      <Typography
                        className={classes.detailText}
                        sx={{
                          width: detailsWidths[2],
                          color: getStatusColor(producto?.Estado),
                          fontWeight: 500,
                        }}
                      >
                        {producto?.Estado}
                      </Typography>
                        
                      <Grid
                        sx={{ paddingLeft: '15px', width: detailsWidths[3] }}
                        >
                        {producto?.Productor && (
                        <RoundedSquareIconButton
                          icon={
                            <RemoveRedEyeIcon
                              sx={{ width: '14px', height: '14px' }}
                            />
                          }
                          onClick={(e) => handleOpenModal(e, producto)}
                        />
                        )}
                      </Grid>
                    </Grid>
                  ))}
                </AccordionDetails>
              </Accordion>
            ))}
          </Grid>
        )}
        <OrderDetailModal
          product={modalProduct}
          open={openDetailModal}
          onClose={handleModalClose}
        />
      </div>
    </>
  )
}

export default OrderListPage
