import { ApiConnection } from "config/index"


export const updateActiveOrder = async (userId, orderId, branchIdentifier) => {
    try{
        const response = await ApiConnection.post(
            "activeOrders",
            {
                userId,
                orderId,
                branchIdentifier
            }
        )
        return response.data
    } catch (error) {
        console.error("Failed to update active order",error)
    }
}

export const deleteOrder = async (orderId, userId) => {
    try{
        const response = await ApiConnection.delete(
            `activeOrders/${orderId}`,{
                params: { userId },
            }
        )
        return response.data
    } catch (error) {
        console.error("Failed to delete order",error)
    }
}