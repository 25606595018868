import { LOAD_INITIAL_DATA_FINISHED, SET_COMPRA_RAPIDA_MODAL, SET_BANNERS, SET_LOADING } from './constantsApp'

const initialState = {
  allCategories: [],
  appInfo: {},
  initFinished: false,
  loaders: {
    cart: {isLoading: false}
  },
  modales: {
    compraRapida: false
  },
  banners: {
    home: {
      desk: [],
      mobile: []
    },
    cart: {
      desk: [],
      mobile: []
    }
  }
}

export const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_INITIAL_DATA_FINISHED: 
      return {
        ...state, 
        ...action.payload,
        initFinished: true
      }
    case SET_COMPRA_RAPIDA_MODAL: 
      return {
        ...state, 
        modales: {
          ...state.modales,
          compraRapida: action.payload
        }
      }
    case SET_BANNERS: 
      return {
        ...state, 
          banners: {
          ...state.banners,
          ...action.payload
        }
      }
    case SET_LOADING: 
      return {
        ...state, 
          loaders: {
          ...state.loaders,
          [action.payload[0]]: { 
            isLoading: action.payload[1]
          }
        }
      }
    default:
      return state
  }
}