import React from 'react'
import { Box } from '@mui/material'

const YouTubeVideo = () => {
  return (
    <Box
      sx={{
        position: 'relative',
        width: '60%',
        height: 'auto',
        margin: '0 auto',
        marginY: 4,
        backgroundColor: 'transparent',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          paddingTop: '56.25%',
          overflow: 'hidden',
          backgroundColor: 'transparent',
          marginBottom: '-2px',
        }}
      >
        <iframe
          src='https://www.youtube.com/embed/sTiVzSqPFGQ?autoplay=1&mute=1&loop=1&playlist=sTiVzSqPFGQ'
          title='YouTube video'
          allow='autoplay; encrypted-media'
          allowFullScreen
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            border: 'none',
            backgroundColor: 'transparent',
          }}
        />
      </Box>
    </Box>
  )
}

export default YouTubeVideo
