import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  container: {
    height: '100vh',
    width: '100vw',
    position: 'absolute',
    top: 0,
    padding: '136px 14px 50px',
    boxSizing: 'border-box',
    backgroundColor: theme.palette.primary.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      position: 'relative',
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      padding: 0,
      backgroundColor: 'transparent',
    },
  },
  content: {
    maxWidth: '293px',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '332px',
    },
  },
  text: {
    fontWeight: '400',
    fontSize: '13px',
    lineHeight: '16px',
    color: theme.palette.primary.almostBlack,
  },
  title: {
    fontWeight: '600',
    fontSize: '24px',
    lineHeight: '32px',
    marginBottom: '15px',
    color: theme.palette.primary.typographyGreen,
  },
  subtitle: {
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '15px',
    color: theme.palette.primary.almostBlack,
    marginBottom: '9px',
  },
  subtitleInfo: {
    fontWeight: '300',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#999999',
    marginBottom: '5px',
  },
  buttons: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginTop: '50px',
      maxWidth: '332px',
    },
  },
  btnPrimary: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '6px',
    height: '56px',
    fontFamily: 'Inter',
    fontWeight: '500',
    fontSize: '18px',
    textTransform: 'unset',
    lineHeight: '22px',
    padding: '17px 5px',
    marginBottom: '10px',
  },
  btnSecondary: {
    color: theme.palette.primary.main,
    fontFamily: 'Inter',
    fontWeight: '500',
    fontSize: '18px',
    textTransform: 'unset',
    lineHeight: '22px',
    padding: '17px 5px',
    height: '56px',
  },
}))
