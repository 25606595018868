import { useRef, useEffect } from 'react'
import InputBase from '@mui/material/InputBase'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import { useNavigate, useLocation } from 'react-router-dom'

const SearchBox = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const searchInput = useRef(null)

  const submitHandler = (e) => {
    e.preventDefault()
    if(!searchInput.current.value) return
    
    navigate(`/catalogo?nameOrSku=${searchInput.current.value}`)
  }

  useEffect(() => {
    if (!pathname.match(/catalogue/)) searchInput.current.value = ''
  }, [pathname])

  return (
    <Paper
      component='form'
      onSubmit={submitHandler}
      sx={{
        display: { xs: 'flex', md: 'none' },
        width: '100%',
        marginInline: { xs: '0px', sm: '14px' },
        alignItems: 'center',
        borderRadius: '8px',
        height: '50px',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.08)',
      }}
    >
      <IconButton type='submit' sx={{ p: '10px' }} aria-label='search'>
        <SearchIcon />
      </IconButton>
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder='Buscar productos'
        inputProps={{
          'aria-label': 'Buscar productos',
          style: { border: '0px' },
          ref: searchInput,
        }}
      />
    </Paper>
  )
}

export default SearchBox
