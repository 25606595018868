import { ApiConnection } from 'config'


const checkStock = async (params) => {
  try {
    const { data } = await ApiConnection.get(
      `product/stock`,
      {
        params,
      }
    )
    return data
  } catch (error) {
    throw new Error(error.message)
  }
}

export default checkStock
