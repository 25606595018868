import React from 'react'
import { Typography, Button, Grid } from '@mui/material'
import { useStyles } from './NotFoundPage.styles'
import vacaOvni from '../../assets/ovni-vaca.svg'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'

function NotFoundPage() {
  const classes = useStyles()
  const navigate = useNavigate()

  return (
    <>
      <Helmet>
        <title>404 - Página no encontrada | Villanueva</title>
        <meta name='description' content='Página no encontrada' />
        <meta name='keywords' content='404, página, no encontrada' />
      </Helmet>
      <Grid className={classes.container}>
        <div className={classes.notFoundContainer}>
          <img src={vacaOvni} alt='Vaca siendo abducida' />
          <Typography className={classes.fourOfour}>404 </Typography>
          <Typography className={classes.caption}>
            Página no encontrada{' '}
          </Typography>
        </div>
        <Button
          onClick={() => navigate('/')}
          className={classes.button}
          variant='primary'
          fullWidth
        >
          Volver al inicio
        </Button>
      </Grid>
    </>
  )
}

export default NotFoundPage
