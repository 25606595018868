import React from 'react'
import { Typography, Button } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Link } from 'react-router-dom'

function PaymentMethodButton({
  children,
  icon,
  iconAlt,
  route,
  onClick,
  selected,
}) {
  const classes = useStyles()

  return (
    <>
      {route !== './mercadopago' ? (
        <Button
          onClick={onClick}
          className={
            selected
              ? `${classes.container} ${classes.selected}`
              : classes.container
          }
        >
          <Typography>{children}</Typography>
          <img src={icon} alt={iconAlt} />
        </Button>
      ) : (
        <Link to={'./mercadopago'} className={classes.container}>
          <Typography>{children}</Typography>
          <img src={icon} alt={iconAlt} />
        </Link>
      )}
    </>
  )
}

export default PaymentMethodButton

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    textTransform: 'none',
    backgroundColor: 'white',
    marginBottom: '12px',
    padding: '31px',
    maxHeight: '80px',
    boxSizing: 'border-box',
    boxShadow: '0px 2px 14px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& p': {
      fontWeight: 600,
      fontSize: '14px',
      color: theme.palette.primary.almostBlack,
    },
    [theme.breakpoints.up('sm')]: {
      marginBottom: '16px',
    },
  },
  selected: {
    border: '2px solid #00984A',
  },
}))
