import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { signOut, openResetPasswordModal } from '../../stores/userStore/actionsUser'
import { Box, Grid, Typography, Avatar } from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { useStyles } from './ResumePage.styles'
import { parseMoney } from 'pages/OrderListPage/utils/parseFunctions'
import { Helmet } from 'react-helmet'
import { getAvailableBalance }
  from './services/getAvailableBalance';
import ResetPassword from './ResetPassword'

const ResumenPage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { userAuth } = useSelector((state) => state)
  const [availableBalance, setAvailableBalance] = useState(null);
  const [password, setPassowrd] = useState(null);


  useEffect(async () => {
    const balance = await getAvailableBalance(userAuth.userInfo?.codigoUsuario || 0)
    setAvailableBalance(balance.disponible)
  }, [availableBalance])

  useEffect(async () => {
    let word = "password";
    word = word.replace(/./g, "*");
    setPassowrd(word)
  }, [])

  const classes = useStyles()
  const signoutHandler = () => {
    dispatch(signOut())
  }
  const goToListadoPedidos = () => {
    navigate('/mis-pedidos')
  }

  const appVersion = useMemo(() => (
    localStorage.getItem('vn_ecommerce_version')
  ), [])

  return (
    <>
      <Helmet>
        <title>Mi cuenta | Villanueva</title>
        <meta name='description' content='Datos de mi cuenta' />
        <meta name='keywords' content='mi cuenta, datos, cuenta' />
      </Helmet>
      <ResetPassword/>
      <Box className={classes.boxMain}>
        <Grid container columns={12}>
          <Grid item xs={12} sm={4}>
            <Grid
              item
              container
              justifyContent='center'
              alignItems='center'
              flexDirection='column'
              className={classes.avatarBox}
            >
              <Avatar
                className={classes.avatar}
                src={userAuth.userInfo?.avatar}
              />

              <Typography className={classes.text}>
                {userAuth.userInfo?.razonSocial !== 'false' ? userAuth.userInfo?.razonSocial : ''}
              </Typography>

              <Typography className={classes.textEmail}>
                {userAuth.userInfo?.user ?? ''}
              </Typography>
              <Typography style={{marginTop: '10px'}} className={classes.textDescription}>
                version: {appVersion}
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={8}>
            

            <Grid className={classes.boxPrecio}>
              <Grid
                className={classes.borderMargin}
                container
                justifyContent='space-between'
              >
                <Typography paragraph className={classes.textMid}>
                  Crédito disponible para comprar
                </Typography>
                <Typography paragraph className={classes.textColor}>
                  ${' '}
                  {availableBalance ? parseMoney(availableBalance) :
                    '0'}
                </Typography>
              </Grid>
              <Grid
                className={classes.borderMargin}
                container
                justifyContent='space-between'
              >
                <Typography paragraph className={classes.textMid}>
                  Sucursal asignada
                </Typography>
                <Typography paragraph className={classes.textMid}>
                  {userAuth.userInfo?.sucursales?.[0]?.name ?? 'No asignada'}
                </Typography>
              </Grid>
              <Grid
                container
                justifyContent='space-between'
                alignItems='center'
                className={classes.border}
                sx={{
                  cursor: 'pointer',
                  userSelect: 'none',
                }}
                onClick={goToListadoPedidos}
              >
                <Typography paragraph className={classes.borderText}>
                  Historial de pedidos
                </Typography>

                <ArrowForwardIosIcon
                  fontSize='small'
                  sx={{ color: '#B1B1B1', marginRight: '8px' }}
                />
              </Grid>
              <Grid
                container
                justifyContent='space-between'
                alignItems='center'
                className={classes.border}
                sx={(theme) => ({
                  cursor: 'pointer',
                  userSelect: 'none',
                  [theme.breakpoints.down('sm')]: {
                    borderBottom: '0.6px solid #BBBBBB',
                  },
                })}
                onClick={() => {
                  navigate('/cuenta-corriente')
                }}
              >
                <Typography paragraph className={classes.borderText}>
                  Resumen de cuenta corriente
                </Typography>

                <ArrowForwardIosIcon
                  fontSize='small'
                  sx={{ color: '#B1B1B1', marginRight: '8px' }}
                />
              </Grid>
              <Grid
                container
                justifyContent='space-between'
                alignItems='center'
                sx={(theme) => ({
                  userSelect: 'none',
                  cursor: 'pointer',
                  [theme.breakpoints.down('sm')]: {
                    borderBottom: '0.6px solid #BBBBBB',
                  },
                })}
                onClick={() => {
                  navigate('/archivos')
                }}
              >
                <Typography paragraph className={classes.borderText}>
                  Archivos para descargar
                </Typography>

                <ArrowForwardIosIcon
                  fontSize='small'
                  sx={{ color: '#B1B1B1', marginRight: '8px' }}
                />
              </Grid>
            </Grid>
            <Grid className={classes.userInfoBox}>
              <Grid
                container
                justifyContent='space-between'
                className={`${classes.border} ${classes.userInfoItem}`}
              >
                <Typography className={classes.userInfoBold}>
                  Razón social
                </Typography>
                <Typography className={classes.userInfoNormal}>
                  {userAuth.userInfo?.razonSocial !== 'false' ? userAuth.userInfo?.razonSocial : '-'}
                </Typography>
              </Grid>

              <Grid
                container
                justifyContent='space-between'
                className={`${classes.border} ${classes.userInfoItem}`}
              >
                <Typography className={classes.userInfoBold}>
                  Código de cliente
                </Typography>
                <Typography className={classes.userInfoNormal}>
                  {userAuth.userInfo?.codigoUsuario ?? '-'}
                </Typography>
              </Grid>

              <Grid
                container
                justifyContent='space-between'
                className={`${classes.border} ${classes.userInfoItem}`}
              >
                <Typography className={classes.userInfoBold}>CUIT</Typography>
                <Typography className={classes.userInfoNormal}>
                  {userAuth.userInfo?.razonSocial !== 'false' ? userAuth.userInfo?.user : '-'}
                </Typography>
              </Grid>

              <Grid
                container
                justifyContent='space-between'
                className={`${classes.border} ${classes.userInfoItem}`}
                sx={(theme) => ({
                  cursor: 'pointer',
                  userSelect: 'none',
                  [theme.breakpoints.down('sm')]: {
                    borderBottom: '0.6px solid #BBBBBB',
                  },
                })}
                onClick={() => dispatch(openResetPasswordModal(true))}
              >
                <Typography className={classes.userInfoBold}>Contraseña</Typography>
                <Typography className={classes.userInfoNormal}>
                  {password}
                  <ArrowForwardIosIcon
                    fontSize='small'
                    sx={{ color: '#B1B1B1', marginLeft: '15px' }}
                  />
                </Typography>
              </Grid>

              {userAuth.userInfo.direccion && userAuth.userInfo.direccion.map((d, index) => (
                <div key={d.id}>
                  <Grid
                    container
                    justifyContent='space-between'
                    className={`${classes.border} ${classes.userInfoItem}`}
                  >
                    <Typography className={classes.userInfoBold}>
                      Dirección {index + 1}
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        textAlign: 'right',
                      }}
                    >
                      <Typography
                        sx={{ marginBottom: '3px' }}
                        className={classes.userInfoNormal}
                      >
                        {userAuth.userInfo?.direccion?.calle ?? ''}
                      </Typography>
                      <Typography className={classes.userInfoNormal}>
                        {d.direccion}
                      </Typography>
                    </Box>
                  </Grid>

                </div>

              )

              )}
              <Grid
                container
                justifyContent='space-between'
                className={`${classes.border} ${classes.userInfoItem}`}
              >
                <Typography className={classes.userInfoBold}>Email</Typography>
                <Typography className={classes.userInfoNormal}>
                  {userAuth.userInfo?.razonSocial !== 'false' ? userAuth.userInfo?.contacto?.correoElectronico : userAuth.userInfo?.user}
                </Typography>
              </Grid>
              <Grid
                container
                justifyContent='space-between'
                className={`${classes.border} ${classes.userInfoItem}`}
              >
                <Typography className={classes.userInfoBold}>
                  Teléfono celular
                </Typography>
                <Typography className={classes.userInfoNormal}>
                  {userAuth.userInfo?.contacto?.telefonoMovil ?? '-'}
                </Typography>
              </Grid>

              <Grid
                container
                justifyContent='space-between'
                className={`${classes.userInfoItem}`}
              >
                <Typography className={classes.userInfoBold}>
                  Teléfono fijo
                </Typography>
                <Typography className={classes.userInfoNormal}>
                  {userAuth?.userInfo?.contacto?.telefonoFijo ?? '-'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Box className={classes.signOutBox}>
          <Typography
            onClick={signoutHandler}
            className={classes.textCloseSession}
          >
            Cerrar sesión
          </Typography>
        </Box>
      </Box>
    </>
  )
}

export default ResumenPage
