import React from 'react'
import { Button } from '@mui/material'

function RoundedSquareIconButton({
  disabled = false,
  iconColor = '#343030',
  icon,
  onClick,
  loading
}) {
  return (
    <Button
      disabled={disabled || loading}
      variant='text'
      loading={loading}
      sx={{
        display: 'block',
        padding: '8px',
        paddingTop: '6px',
        backgroundColor: '#F5F5F5',
        color: iconColor,
        width: '30px',
        minWidth: 0,
        boxSizing: 'border-box',
        height: '30px',
        borderRadius: '4px',
        cursor: 'pointer',
        '&:hover': {
          transition: '0.25s',
          backgroundColor: 'rgb(210,210,210)',
        },
      }}
      onClick={onClick}
    >
     {loading ? '...' : icon} 
    </Button>
  )
}

export default RoundedSquareIconButton
