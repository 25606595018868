import React from 'react'
import { Grid, Typography, IconButton, Select, MenuItem } from '@mui/material'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import SkipNextIcon from '@mui/icons-material/SkipNext'
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious'
import { makeStyles } from '@mui/styles'

function Pagination({
  itemsShown = 0,
  totalItems = 0,
  currentPage = 0,
  totalPages = 0,
  nextPage,
  prevPage,
  skipPrev,
  skipNext,
  handleChange,
  showSelect,
  disableNext,
  values = [20, 30, 50],
}) {
  const styles = useStyles()
  return (
    <Grid
      container
      alignItems='flex-end'
      className={styles.paginationContainer}
    >
      {/* <Typography className={styles.paginationText}>
        Mostrando {itemsShown} de {totalItems}
      </Typography> */}

      <Typography className={styles.paginationText}>
        Página {currentPage} de {totalPages}
      </Typography>

      {/* <Grid
        container
        justifyContent="flex-end"
        alignItems="center"
        style={{ marginTop: "12px", display: showSelect ? "flex" : "none" }}
      >
        <Typography className={styles.paginationText} style={{ marginRight: "12px" }}>
          Items por página
        </Typography>
        <Select
          id="select-itemsToShow"
          value={itemsShown}
          onChange={handleChange}
          style={{ fontSize: "12px" }}
        >
          {values.map((value, index) => (
            <MenuItem value={value} key={index}>
              {value}
            </MenuItem>
          ))}
        </Select>
      </Grid> */}

      <Grid
        container
        justifyContent='flex-end'
        alignItems='center'
        style={{ marginTop: '15px' }}
      >
        {/* <IconButton
          disabled={currentPage < 11}
          onClick={skipPrev}
          className={styles.paginationButton}
        >
          <SkipPreviousIcon />
        </IconButton> */}
        <IconButton
          disabled={currentPage <= 1}
          onClick={prevPage}
          className={styles.paginationButton}
        >
          <ArrowLeftIcon />
        </IconButton>
        <Typography
          className={styles.paginationText}
          style={{ fontSize: '14px', margin: '0px 10px' }}
        >
          {currentPage}
        </Typography>
        <IconButton
          disabled={totalPages - currentPage <= 0}
          onClick={nextPage}
          className={styles.paginationButton}
        >
          <ArrowRightIcon />
        </IconButton>
        {/* <IconButton
          disabled={totalPages - currentPage < 10}
          onClick={skipNext}
          className={styles.paginationButton}
        >
          <SkipNextIcon />
        </IconButton> */}
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles((theme) => ({
  paginationContainer: {
    padding: '20px 0px',
    flexDirection: 'column',
  },
  paginationText: {
    fontSize: '12px',
    color: '#737373',
    marginRight: '12px'
  },
  paginationButton: {
    padding: '6px',
  },
}))

export default Pagination
