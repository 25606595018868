const PolygonComponent = ({ size }) => {
  return (
    <div style={{ verticalAlign: 'middle' }}>
      <svg
        width={`${size}`}
        height={`${size}`}
        viewBox={`0 0 ${size} ${size - 1}`}
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path d='M2.5 4L0.334936 0.25L4.66506 0.25L2.5 4Z' fill='#23FF8E' />
      </svg>
    </div>
  )
}
export default PolygonComponent
