import { useState } from 'react'
import Alert from '@mui/material/Alert'
import { makeStyles } from '@mui/styles'
import CancelIcon from '../assets/closeIcon.svg'
import { useSelector } from 'react-redux'
import PopoverComponent from './PopOverSucursales/PopOverSucursales'

const CloseAlert = () => {
  const classes = useStyles()
  const [open, setOpen] = useState(true)
  const { signedIn } = useSelector((state) => state.userAuth)

  return (
    <>
      {open && (
        <Alert
          icon={false}
          action={
            !signedIn ? (
              <img
                src={CancelIcon}
                className={classes.icon}
                alt='cerrar'
                onClick={() => setOpen(false)}
              />
            ) : (
              false
            )
          }
          className={classes.alert}
          sx={{
            backgroundColor: !signedIn ? '#00984A' : '#444444',
          }}
          classes={{ message: classes.message, action: classes.action }}
        >
          {signedIn ? (
            <PopoverComponent />
          ) : (
            'Si ya sos cliente iniciá sesión para visualizar los precios'
          )}
        </Alert>
      )}
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  alert: {
    padding: '10px 8px 10px 21px',
    borderRadius: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  message: {
    padding: '0px !important',
    fontSize: '11px',
    fontWeight: 500,
    color: 'white',
    [theme.breakpoints.up('sm')]: {
      fontSize: '14px',
    },
  },
  action: {
    padding: '0px !important',
    marginLeft: '10px',
    marginRight: '10px',
  },
  icon: {
    color: 'white',
    width: '17px',
    height: '17px',
    // marginTop: '-2px',
  },
}))

export default CloseAlert
