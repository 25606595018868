import { ApiConnection } from 'config'

export const getProducts = async ({ page, items, finderParams, cardCode }) => {
  const userId = localStorage.getItem('sellerId')

  const { data } = await ApiConnection.get(
    `product/finder`,
    {
      params: {
        cardCode,
        page,
        items,
        userId,
        ...finderParams,
      },
    }
  )
  return { ...data }
}
