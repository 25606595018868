import { useState } from 'react'
import { useMediaQuery } from '@mui/material'
import FilterMobileComponent from './FilterMobileComponent'
import FilterDesktopComponent from './FilterDesktopComponent'

export default function FilterComponent() {
  const desktop = useMediaQuery((theme) => theme.breakpoints.up('md'), { noSsr: true })
  const [accordionExpanded, setAccordionExpanded] = useState({})

  const handleChange = (panel) => (_event, isExpanded) => {
    setAccordionExpanded({
      ...accordionExpanded,
      [panel]: isExpanded,
    })
  }

  return (
    <>
      {!desktop ? (
        <FilterMobileComponent
          accordionExpanded={accordionExpanded}
          handleChange={handleChange}
        />
      ) : (
        <FilterDesktopComponent
          accordionExpanded={accordionExpanded}
          handleChange={handleChange}
        />
      )}
    </>
  )
}
