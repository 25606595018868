import React, { useEffect, useMemo, useRef, useState } from 'react'
import { makeStyles } from '@mui/styles'
import {
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  useMediaQuery,
  Grid,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { ReactComponent as DownloadIcon } from '../../assets/download.svg'
import { Link, useSearchParams } from 'react-router-dom'
import { parseDateString } from 'pages/OrderListPage/utils/parseFunctions'
import isOverdue from './utils/isOverdue'
import { getDetail } from './services/getDetail'

const objTypeValues = {
  "NC":14,
  "FC": 13,
  "ND":13,
  "RC": 24
}

function Comprobante({ data, onChange, expanded }) {
  const classes = useStyles()
  const [downloading, setDownloading] = useState(false)
  const linkRef = useRef(null)
  const desktop = useMediaQuery((theme) => theme.breakpoints.up('sm'))
  const [searchParams] = useSearchParams()
  const overdue = isOverdue(data?.FechaVto)

  const handleDownload = async (e) => {
    e.preventDefault()
    if(downloading) return;
    try {
      setDownloading(true)
      const base64Data = await getDetail(data.DocEntry, objTypeValues[data?.TipoComprobante] || 13)
      linkRef.current.href = 'data:application/octet-stream;base64,' + base64Data.response
      linkRef.current.download = `${data?.Comprobante ?? 'resumen'}.pdf`
      linkRef.current.click()
    } catch(e) {
      console.error(e)
      // alert('El comprobante solicitado no se pudo descargar')
    } finally {
      setDownloading(false)
    }
  }

  const currentTab = useMemo(() => searchParams.get('tab'), [searchParams.toString()])

  return (
    <Accordion
      disableGutters
      className={classes.accordionItem}
      onChange={desktop ? null : onChange}
      expanded={desktop ? false : expanded}
      sx={{
        boxShadow: '0px 1px rgba(0, 0, 0, 0.24)',
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
      }}
      square
    >
      <AccordionSummary
        className={classes.summary}
        expandIcon={desktop ? '' : <ExpandMoreIcon />}
      >
        {!desktop ? (
          <Grid container>
            <Typography className={classes.summaryFecha}>
              {parseDateString(data?.FechaDocumento)}
            </Typography>
            <Typography className={classes.summaryComprobante}>
              {data?.Comprobante}
            </Typography>
            {currentTab === 'Pendientes' && (
              <Typography className={classes.summarySaldo}>
                $ {data?.Pendiente}
              </Typography>
            )}
            {currentTab === 'Historial' && (
              <Typography sx={{ width: '100px', textAlign: 'right' }}>
                {data.Importe.includes('$') ? '' : '$ '}
                {data?.Importe}
              </Typography>
            )}
          </Grid>
        ) : (
          <Grid container>
            <Typography sx={{ width: '70px' }}>
              {data?.TipoComprobante}
            </Typography>
            <Typography sx={{ width: currentTab === 'Pendientes' ?  '157px' : '200px' }}>
              {data?.Comprobante}
            </Typography>
            <Typography sx={{ width: '90px' }}>
              {data?.Sucursal ?? '-'}
            </Typography>
            <Typography sx={{ width: currentTab === 'Pendientes' ? '100px' : '150px', textAlign:'center' }}>
              {parseDateString(data?.FechaDocumento)}
            </Typography>
            {currentTab === 'Pendientes' && (
              <Typography
                sx={(theme) => ({
                  width: '130px',
                  color: overdue ? theme.palette.primary.typographyRed : '#444',
                  fontWeight: overdue ? 500 : 400,
                })}
              >
                {parseDateString(data?.FechaVto)}
              </Typography>
            )}
            <Typography sx={{ width: '100px', textAlign: 'right' }}>
              {data.Importe.includes('$') ? '' : '$ '}
              {data?.Importe}
            </Typography>
            {currentTab === 'Pendientes' && (
              <Typography sx={{ width: '100px', textAlign: 'right' }}>
                $ {data?.Pendiente}
              </Typography>
            )}
            <Typography sx={{ width: currentTab === 'Pendientes' ? '100px' : '200px', textAlign: 'right' }}>
              {data.SaldoAcumulado.includes('$') ? '' : '$ '}
              {data?.SaldoAcumulado}
            </Typography>
            {Object.keys(objTypeValues).includes(data.TipoComprobante) && (
              <Grid sx={{ width: currentTab === 'Pendientes' ? '100px' : '150px', textAlign: 'center' }}>
                <Link
                  to={data?.comprobanteURL ?? '#'}
                  onClick={handleDownload}
                >
                  {downloading
                   ? <Typography>...</Typography> 
                   : <DownloadIcon
                      stroke='#53A2FF'
                      style={{ width: '14px', height: '12px' }}
                    />
                  }
                </Link>
              </Grid>
            )}
          </Grid>
        )}
      </AccordionSummary>
      <Link ref={linkRef} hidden to='#' target='_blank' download='detail.pdf' />
      <AccordionDetails className={classes.details}>
        <div className={classes.detailRow}>
          <Typography sx={{ fontWeight: 600 }} className={classes.halfBlock}>
            Fecha de vencimiento
          </Typography>
          <Typography className={classes.halfBlock}>
            {data?.FechaVto}
          </Typography>
        </div>

        <div className={classes.detailRow}>
          <Typography sx={{ fontWeight: 600 }} className={classes.halfBlock}>
            Importe
          </Typography>
          <Typography className={classes.halfBlock}>
            $ {data?.Importe}
          </Typography>
        </div>

        <div className={classes.detailRow}>
          <Typography sx={{ fontWeight: 600 }} className={classes.halfBlock}>
            Sucursal
          </Typography>
          <Typography className={classes.halfBlock}>
            {data?.Sucursal}
          </Typography>
        </div>

        <div className={classes.detailRow}>
          <Typography sx={{ fontWeight: 600 }} className={classes.halfBlock}>
            Tipo de comprobante
          </Typography>
          <Typography className={classes.halfBlock}>
            {data?.TipoComprobante}
          </Typography>
        </div>
        {Object.keys(objTypeValues).includes(data.TipoComprobante) && (
          <div className={classes.downloadReceipt}>
            <Link
              to={'#'}
              onClick={handleDownload}
              className={classes.linkContainer}
            >
              <DownloadIcon
                stroke='#53A2FF'
                style={{ width: '14px', height: '12px', marginRight: '12px' }}
              />
              {downloading
                ? <Typography 
                    sx={(theme) => ({
                      color: theme.palette.primary.moreBlue,
                    })}
                  >
                    Descargando...
                  </Typography> 
                : <Typography
                  sx={(theme) => ({
                    fontSize: '12px',
                    fontWeight: 600,
                    color: theme.palette.primary.moreBlue,
                    lineHeight: '24px',
                  })}
                >
                  Descargar comprobante
                </Typography>
              }
            </Link>
          </div>
        )}
      </AccordionDetails>
    </Accordion>
  )
}

export default Comprobante

const useStyles = makeStyles((theme) => ({
  accordionItem: {
    '& > div': {
      '& > div': {
        margin: 0,
        padding: '20px 0px',
      },
      padding: '0px 6px',
    },
  },
  summary: {
    '& div': {
      cursor: 'auto',
      userSelect: 'text',
    },
    '& p': {
      fontSize: '13px',
    },
  },
  summaryFecha: {
    width: '23%',
  },
  summaryComprobante: {
    width: '48%',
    [theme.breakpoints.up('sm')]: {
      width: '50%',
    },
  },
  details: {
    padding: 0,
    paddingBottom: ' 20px ',
  },
  detailRow: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '5px 0px',
    paddingRight: '22px',
    '& p': {
      fontSize: '14px',
    },
  },
  downloadReceipt: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '26px',
  },
  linkContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}))
