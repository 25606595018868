import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: '0px 18px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.08)',
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
    [theme.breakpoints.up('sm')]: {
      padding: 0,
    },
  },
  innerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      boxSizing: 'border-box',
      maxWidth: '1040px',
      maxHeight: '71px',
      margin: 'auto',
      padding: '12px 0px',
      paddingRight: '8px',
    },
  },
  logo: {
    [theme.breakpoints.up('sm')]: {
      padding: '0px',
      '& img': {
        display: 'block',
        height: '30px',
      },
    },
  },
  signIn: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    userSelect: 'none',
    '& p': {
      color: theme.palette.primary.almostBlack,
      fontSize: '14px',
      marginRight: '5px',
      fontWeight: 500,
    },
  },
  signedIn: {
    display: 'flex',
    alignItems: 'center',
  },
  userCard: {
    display: 'flex',
    alignItems: 'center',
  },
  userCardAvatar: {
    boxSizing: 'border-box',
    border: '2px solid',
    borderColor: theme.palette.primary.darkGreen,
    borderRadius: '50%',
    marginRight: '9px',
    padding: '2px',

    width: '40px',
  },
  userCardName: {
    fontSize: '14px',
    fontWeight: 600,
    color: theme.palette.primary.almostBlack,
    marginRight: '20px',
    maxWidth: '150px',
    minWidth: '100px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))
