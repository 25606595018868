import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '95vh',
    padding: '30px 15px',
    boxSizing: 'border-box',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '1040px',
      margin: 'auto',
      padding: '30px 0px',
    },
  },
  paymentMethodsContainer: {
    boxSizing: 'border-box',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '680px',
      paddingRight: '18px',
    },
  },
  paymentDetails: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '362px',
    },
  },
}))
