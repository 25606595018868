import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: '20px'
  },
  title: {
    color: '#4AA259',
    fontSize: '18px',
    fontWeight: 'bold'
  },
  infoContainer: {
    padding: '20px',
  },
  buttonContinue: {
    backgroundColor: '#4AA259',
    color: 'white',
    borderRadius: '6px',
    height: '56px',
    fontFamily: 'Inter',
    fontWeight: '500',
    fontSize: '18px',
    textTransform: 'unset',
    lineHeight: '22px',
  },
  buttonKeepBuying: {
    borderRadius: '6px',
    color: '#4AA259',
    fontFamily: 'Inter',
    fontWeight: '400',
    fontSize: '18px',
    textTransform: 'unset',
    lineHeight: '22px',
    height: '56px',
    marginTop: '10px',
  }
}))