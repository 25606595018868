import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  mainGrid: {
    padding: '14px',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '1040px',
      margin: 'auto',
      padding: '0px',
    },
  },
  filterGrid: {
    marginTop: '27px',
    justifyContent: 'space-between',
    width: '100%',
    paddingInline: '15px',
    [theme.breakpoints.up('md')]: {
      marginTop: '2px',
      maxWidth: 'calc(270px - 62px)',
      backgroundColor: theme.palette.primary.white,
      borderRadius: '8px',
      padding: '16px',
      marginRight: '20px',
    },
  },
  subtitle: {
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '14px',
    color: theme.palette.primary.almostBlack,
    marginBottom: '4px',
  },
  title: {
    fontWeight: '600',
    marginBottom: '6px',
    fontSize: '16px',
    textOverflow: 'ellipsis',
    lineHeight: '22px',
    color: theme.palette.primary.almostBlack,
  },
  disclaimerSection: {
    marginTop: '45px',
  },
  disclaimerTitle: {
    fontWeight: '700',
    paddingBottom: '20px',
    fontSize: '10px',
    lineHeight: '13px',
    color: theme.palette.primary.almostBlack,
  },
  disclaimerDescription: {
    fontWeight: '400',
    paddingBottom: '47px',
    fontSize: '10px',
    lineHeight: '13px',
    color: theme.palette.primary.almostBlack,
  },
  checkBoxLabel: {
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    color: theme.palette.primary.almostBlack,
  },
  checkBox: {
    '&.Mui-checked': {
      color: theme.palette.primary.moreBlue,
    },
  },
}))
