import { ApiConnection } from "config"

export const stateAccountHistory = async (cardCode, desde, hasta) => {
  const res = await ApiConnection.get(
    `users/state-account/history`,
    {
      params: { cardCode, desde, hasta },
    }
  )
  return res.data.response
}

export const stateAccountHistoryB64 = async (cardCode, desde, hasta) => {
  const res = await ApiConnection.get(
    `users/state-account/history/b64`,
    {
      params: { cardCode, desde, hasta },
    }
  )
  return res.data.response
}

export const stateAccountHistorySaldo = async (cardCode, hasta) => {
  const res = await ApiConnection.get(
    `users/state-account/history/saldo`,
    {
      params: { cardCode, hasta },
    }
  )
  return res.data.response
}