import React from 'react'
import { Grid, Typography, Button, TextField } from '@mui/material'
import { useStyles } from './ResetPassword.styles'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { sendEmailForResetPassword } from './services/sendEmailForResetPassword'
import { useDispatch } from 'react-redux'
import { setSnackbar } from 'stores/snackbarStore'

function ResetPassword() {
  const classes = useStyles()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      cuit: '',
    },
  })
  const onSubmit = async ({ cuit }) => {
    try {
      const { response } = await sendEmailForResetPassword(cuit);
      if (!response) dispatch(
        setSnackbar(true, 'error', 'Ha ocurrido un error, revise si el cuit es valido.')
      )
      navigate('./enviado', {
        state: {
          email: response.email,
        }
      })
    }
    catch {
      dispatch(
        setSnackbar(true, 'error', 'Ha ocurrido un error, revise si el cuit es valido.')
      )
    }

  }

  return (
    <>
      <Helmet>
        <title>¿Has olvidado tu contraseña? | Villanueva</title>
        <meta
          name='description'
          content='Introduce tu cuit para enviarte un email de recuperación de contraseña'
        />
        <meta
          name='keywords'
          content='contraseña, has olvidado, e-mail, nuevo, regeneración'
        />
      </Helmet>
      <Grid container className={classes.grid}>
        <Grid
          container
          flexDirection='column'
          className={classes.innerContainer}
        >
          <Typography className={classes.title}>
            Recuperar contraseña
          </Typography>
          <Typography paragraph className={classes.subtitleInfo}>
            Compartinos tu cuit para enviarte un email de recuperación de contraseña.
          </Typography>
          <form className={classes.form}>
            <Typography className={classes.tagName}>CUIT (solo números)</Typography>
            <TextField
              variant='outlined'
              fullWidth
              required
              id='cuit'
              name='cuit'
              type='number'
              {...register('cuit', { required: true, minLength: 10 })}
              error={!!errors.cuit}
            />
            <Typography variant='inherit' color='red' sx={{ paddingTop: 1 }}>
              {errors.cuit?.type === 'required' && 'El cuit es obligatorio'}
            </Typography>
          </form>

          <Button
            onClick={handleSubmit(onSubmit)}
            variant='primary'
            fullWidth
            className={classes.buttonBuy}
          >
            Enviar Código
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

export default ResetPassword
