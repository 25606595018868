import React from 'react'
import { makeStyles } from '@mui/styles'
import { Link } from 'react-router-dom'
import { Typography } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

function TitleWithBackArrow({
  customStylesContainer,
  customStylesArrowBack,
  customStylesTitle,
  children,
  to
}) {
  const classes = useStyles()
  return (
    <div className={classes.container} style={customStylesContainer}>
      <Link to={to || -1} style={{ height: '24px' }}>
        <ArrowBackIcon
          className={classes.arrowBack}
          style={customStylesArrowBack}
        />
      </Link>
      <Typography className={classes.title} style={customStylesTitle}>
        {children}
      </Typography>
    </div>
  )
}

export default TitleWithBackArrow

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  arrowBack: {
    color: theme.palette.primary.almostBlack,
  },
  title: {
    fontWeight: '600',
    fontSize: '18px',
    marginLeft: '12px',
    color: theme.palette.primary.almostBlack,
    cursor: 'default',
  },
}))
