import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getAboutBannersAction,
  getProductLinesBannersAction,
} from 'stores/appStore/actionsApp'
import getPageByName from 'pages/PagePreview/getPageByName'

export const usePageContent = (pageName) => {
  const [content, setContent] = useState({
    beforeCarousel: '',
    afterCarousel: '',
    beforeProductLines: '',
    afterProductLines: '',
  })
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()
  const aboutBanners = useSelector(({ app }) => app.banners?.about)
  const productLinesBanners = useSelector(
    ({ app }) => app.banners?.productLines?.desk
  )

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const res = await getPageByName(pageName)
        if (pageName === 'terms') {
          setContent({ beforeCarousel: res.content })
        } else {
          const contentWithPlaceholder = res.content
            .replace('{about_banner}', '<div id="carousel-placeholder"></div>')
            .replace(
              '{productLines_banner}',
              '<div id="productLines-banners-placeholder"></div>'
            )

          const [
            beforeCarousel,
            afterCarouselAndProductLines,
          ] = contentWithPlaceholder.split(
            '<div id="carousel-placeholder"></div>'
          )

          const [
            afterCarousel,
            afterProductLines,
          ] = afterCarouselAndProductLines.split(
            '<div id="productLines-banners-placeholder"></div>'
          )

          setContent({ beforeCarousel, afterCarousel, afterProductLines })
        }
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    }

    fetchContent()
  }, [pageName, dispatch])

  useEffect(() => {
    dispatch(getAboutBannersAction()).then(() =>
      dispatch(getProductLinesBannersAction())
    )
  }, [dispatch])

  return { content, loading, aboutBanners, productLinesBanners }
}
