import { ApiConnection } from 'config'

export const minusItem = async (item, order) => {
  try {
    const { data } = await ApiConnection.post(
      `cart`,
      {
        ...order,
        [item.tipo ?? 'product']: { ...item, cantidad: item.cantidad - 1 },
      }
    )
    return { ...data }
  } catch (error) {
    console.error(error)
  }
}
