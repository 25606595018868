export const quickAccess = [
  // {
  //   title: 'Noticias',
  //   url: '/news',
  // },
  {
    title: 'Sucursales',
    url: '/sucursales',
  },
  {
    title: 'Cambios y devoluciones',
    url: '/devoluciones',
  },
  {
    title: 'Trabajá con nosotros',
    url: '/trabaja-con-nosotros',
  },
]
