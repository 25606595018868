import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Grid, Typography } from '@mui/material';
import { useStyles } from './NewsPage.styles';
import getNewsPage from './getNewsPage';
import { useParams } from 'react-router-dom';

function formatDate(dateString) {
  const months = [
    'enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio',
    'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'
  ];

  const date = new Date(dateString);
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  return `${day} de ${months[monthIndex]} del ${year}`;
}

export default function NewsPage() {
  const classes = useStyles();
  const { id } = useParams();
  const [noticia, setNoticia] = useState(null);

  useEffect(() => {
    const fetchNoticia = async () => {
      try {
        const noticiaData = await getNewsPage(id);
        setNoticia(noticiaData);
      } catch (error) {
        console.log(error);
      }
    };

    fetchNoticia();
  }, [id]);

  if (!noticia) {
    return <div>Loading...</div>;
  }

  const noticiaEncontrada = noticia.find(item => item.id === id);

  if (!noticiaEncontrada) {
    return <div>News not found.</div>;
  }

  const { imagen, texto, titulo, tiempo, descripcion, url, imgAut, createdAt, categoria } = noticiaEncontrada;
  const formattedCreatedAt = formatDate(createdAt);

  return (
    <>
      <Helmet>
        <title>{`${noticiaEncontrada.titulo || ''}`} | Villanueva</title>
        <meta name='description' content={descripcion || ''} />
      </Helmet>
      <Grid className={classes.container}>
        <Grid item xs={12} className={classes.mainGrid}>
          <Typography className={classes.tag}>{noticiaEncontrada.categoria}</Typography>
          <Typography className={classes.date}>{formattedCreatedAt}</Typography>
          {imagen && (
            <img style={{objectFit: 'cover', width: '100%', marginBottom: '20px'}} src={imagen}/>
          )}
          <Typography className={classes.title}>{noticiaEncontrada.titulo}</Typography>
          <Typography className={classes.subtitle}>
            {noticiaEncontrada.descripcion}
          </Typography>
          <Grid container alignItems='center' sx={{ marginTop: '40px' }}>
          </Grid>
            <Typography
              className={classes.texto1}>
              <div dangerouslySetInnerHTML={{__html: texto}}></div>
            </Typography>
        </Grid>
      </Grid>
    </>
  );
}
