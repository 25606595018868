import currencyToNumber from 'utils/currencyToNumber'
import {
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_SIGNIN_FAIL,
  USER_SIGNIN_REQUEST,
  USER_SIGNIN_SUCCESS,
  USER_SIGNOUT,
  USER_MODAL_LOGIN_STATE,
  USER_CURRENT_BRANCH,
  UPDATE_BRANCH_LIST,
  USER_PROMOTIONS_SUCCESS,
  UPDATE_BALANCE,
} from './constantsUser'
import { loginService } from './services/login'
import { registerService } from './services/register'
import { userDetailsService } from './services/userDetails'
import store from '../../store'
import { userPromotionsService } from './services/userPromotions'
import { clearCart, getCartItems, setIdOrder } from 'stores/cartStore/actionsCart'
import { LOAD_INITIAL_DATA_FINISHED } from 'stores/appStore/constantsApp'

export const register = (user, pass) => async (dispatch) => {
  dispatch({ type: USER_REGISTER_REQUEST, payload: { user, pass } })
  try {
    const res = await registerService(user, pass)
    const { response } = res.data
    dispatch({ type: USER_REGISTER_SUCCESS, payload: response })
  } catch (e) {
    dispatch({ type: USER_REGISTER_FAIL, payload: e.message })
    console.error(e.message)
  }
}

export const login = (user, pass) => async (dispatch) => {
  dispatch({ type: USER_SIGNIN_REQUEST })
  try {
    const res = await loginService(
      { 
        user: user.toLowerCase(), 
        pass, 
        isFromBO: 'false' 
      }
    )
    const result = res.data.response
    
    if (result) {
      localStorage.setItem('vn_ecommerce_version', result.appInfo?.version)

      const response = typeof result.user === 'object' ? result.user : result
      if (response) {
        const newResponse = {
          ...response,
          saldoDisponible: currencyToNumber(response.saldoDisponible),
          deuda: currencyToNumber(response.deuda)
        }

        dispatch({ type: USER_SIGNIN_SUCCESS, payload: newResponse })
        localStorage.setItem('userInfo', JSON.stringify(newResponse))
        localStorage.setItem('signedIn', JSON.stringify(true))

        if (response.openedCart) {
          dispatch(setIdOrder(response.openedCart))
          dispatch(getCartItems(response.codigoUsuario, response.openedCart))
        } else {
          dispatch(clearCart())
        }
      } else {
        dispatch({ type: USER_SIGNIN_FAIL, payload: res.message })
      }
    }
  } catch (e) {
    dispatch({ type: USER_SIGNIN_FAIL, payload: e.message })
    console.error(e.message)
  }
}

export const sellerClientLogin = (user, sucursal) => async (dispatch) => {
  dispatch({ type: USER_SIGNIN_REQUEST })
  try {
    const res = await loginService({ user, permisos: 2, isFromBO: 'false' })
    const { response } = res.data

    if (response) {
      response.sucursales = response.sucursales.filter(
        (s) => s.cod === sucursal
      )
      localStorage.setItem('vn_ecommerce_version', response.appInfo?.version)

      dispatch({ type: USER_SIGNIN_SUCCESS, payload: response })
      localStorage.setItem('userInfo', JSON.stringify(response))
      localStorage.setItem('signedIn', JSON.stringify(true))
      localStorage.setItem('permisos', 2)

      if (response.openedCart) {
        dispatch(setIdOrder(response.openedCart))
        dispatch(getCartItems(response.codigoUsuario, response.openedCart))
      } else {
        dispatch(clearCart())
      }

      const currentBranch = response.sucursales[0]
      dispatch(selectCurrentBranch(currentBranch))
    } else {
      dispatch({ type: USER_SIGNIN_FAIL })
    }
  } catch (error) {
    dispatch({ type: USER_SIGNIN_FAIL })
    console.error(error.message)
  }
}

export const signOut = () => (dispatch) => {
  localStorage.clear()
  document.location.href = '/'
  dispatch({ type: USER_SIGNOUT })
}

export const updateUserInfo = (user) => async (dispatch) => {
  dispatch({ type: USER_DETAILS_REQUEST })
  try {
    const res = await userDetailsService(user)
    const { response } = res.data
    dispatch({ type: USER_DETAILS_SUCCESS, payload: response })
    if(response.appInfo){
      dispatch({ type: LOAD_INITIAL_DATA_FINISHED, payload: { appInfo: response.appInfo }})
    }
    if(response.habilitado === false) {
      alert('Su usuario se encuentra desactivado, vamos a cerrar la sesión')
      dispatch(signOut())
      setTimeout(() => window.location.reload(), 200)
    }
    const appVersion = localStorage.getItem('vn_ecommerce_version')

    if(response.appInfo?.version && appVersion !== response.appInfo?.version) {
      localStorage.setItem('vn_ecommerce_version', response.appInfo?.version)
      setTimeout(() => window.location.reload(), 200)
      if(response.appInfo?.version?.includes('logout'))
        dispatch(signOut())
    }
  } catch (e) {
    dispatch({ type: USER_DETAILS_FAIL, payload: e.message })
    console.error(e.message)
  }
}

export const getUserPromotionsAndOffers = (user) => (dispatch) => {
  userPromotionsService(user)
    .then((response) =>
      dispatch({ type: USER_PROMOTIONS_SUCCESS, payload: response })
    )
    .catch(console.error)
}

export const changeLoginState = (state) => (dispatch) => {
  dispatch({ type: USER_MODAL_LOGIN_STATE, payload: !state })
}

export const openSelectClientModal = (open = false) => (dispatch) => {
  dispatch({ type: 'USER_SELECT_CLIENT_MODAL', payload: open })
}

export const openResetPasswordModal = (open = false) => (dispatch) => {
  dispatch({ type: 'USER_RESET_PASSWORD_MODAL', payload: open })
}

export const selectCurrentBranch = (branch, codigoUsuario) => (dispatch) => {
  localStorage.setItem('currentBranch', JSON.stringify(branch))
  dispatch({ type: USER_CURRENT_BRANCH, payload: branch })
}

export const updateBranchList = (branchList) => (dispatch) => {
  dispatch({ type: UPDATE_BRANCH_LIST, payload: branchList })
}

export const updateBalance = (balance) => (dispatch) => {
  dispatch({ type: UPDATE_BALANCE, payload: balance })
} 

export const subtractBalance = (subtract = 0) => (dispatch) => {
  if (subtract) {
    dispatch({ type: 'USER_SUBTRACT_BALANCE', payload: subtract })
    localStorage.setItem(
      'userInfo',
      JSON.stringify(store.getState().userAuth.userInfo)
    )
  }
}
