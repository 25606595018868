import { getAllProductDiscount } from './getProductDiscounts'
import { fixAmount } from './helpers'

const formatProductToAdd = (product, quantity, discountInfo) => {
  return {
    ...product,
    cantidad: quantity,
    precio: discountInfo.priceWithDiscount,
    descuentos: discountInfo.discount ? fixAmount(discountInfo.discount) : 0,
    codigoOferta: discountInfo.code || null,
  }
}

export const getProductsToAdd = (
  product,
  cart,
  promotions,
  offers,
  sellerDiscount,
  branchOffice
) => {
  const isProduct = product.tipo === 'product'
  const currentProductsInCart = isProduct
    ? cart.product?.filter((prod) => prod?.sku === product?.sku)
    : [product]

  const itemInCartWithPromotionOrOffer = currentProductsInCart.filter(
    ({ codigoOferta }) => codigoOferta
  )

  let totalQuantity = product.cantidad
  if (isProduct) {
    currentProductsInCart.forEach((item) => (totalQuantity += item.cantidad))
  }

  const productToAdd = {
    ...product,
    cantidad: totalQuantity,
    codigoOferta: null,
  }

  const newDiscounts = getAllProductDiscount(
    productToAdd,
    cart,
    offers,
    promotions,
    sellerDiscount,
    branchOffice
  )

  let itemsToAdd = []

  const sortProductsInCart = currentProductsInCart.sort(
    (a, b) => b.descuentos - a.descuentos
  )

  if (
    newDiscounts.mainDiscount.discount >=
      (sortProductsInCart[0]?.descuentos || 0) &&
    newDiscounts.mainDiscount.code
  ) {
    const newItemWithDiscount = formatProductToAdd(
      productToAdd,
      newDiscounts.mainDiscount.affectedQuantity,
      newDiscounts.mainDiscount
    )

    const newItemWithBasicDiscount = newDiscounts.secondaryDiscount
      ? formatProductToAdd(
          productToAdd,
          newDiscounts.secondaryDiscount.affectedQuantity,
          newDiscounts.secondaryDiscount
        )
      : null


    itemsToAdd = [newItemWithDiscount, newItemWithBasicDiscount]
  } else {
    let noAffectedProductsQuantity = totalQuantity
    itemInCartWithPromotionOrOffer.forEach(
      (item) => (noAffectedProductsQuantity -= item.cantidad)
    )

    const newBasicDiscounts = getAllProductDiscount(
      { ...productToAdd, cantidad: noAffectedProductsQuantity },
      cart,
      {},
      {},
      sellerDiscount,
      branchOffice
    )

    const newItemWithBasicDiscount = newBasicDiscounts?.mainDiscount
      ? formatProductToAdd(
          productToAdd,
          newBasicDiscounts.mainDiscount.affectedQuantity,
          newBasicDiscounts.mainDiscount
        )
      : null

    // itemsToAdd = [newItemWithBasicDiscount, ...itemInCartWithPromotionOrOffer]
    itemsToAdd = [newItemWithBasicDiscount]
  }

  const itemsToDelete = currentProductsInCart.map((product) => {
    const isInNewItems = itemsToAdd
      .filter(Boolean)
      .some(
        ({ idProducto, codigoOferta }) =>
          product.idProducto === idProducto &&
          Boolean(product.codigoOferta) === Boolean(codigoOferta)
      )

    if (!isInNewItems) {
      return {
        ...product,
        cantidad: 0,
      }
    } else {
      return null
    }
  })

  const filterItemsToAdd = !isProduct
    ? itemsToAdd
        .filter(Boolean)
        .filter(({ id, codigoOferta, descuentos, cantidad }) => {
          return !(
            id === product.id &&
            codigoOferta === product.codigoOferta &&
            descuentos === product.descuentos &&
            cantidad === product.cantidad
          )
        })
    : itemsToAdd

  return [...filterItemsToAdd, ...itemsToDelete].filter(Boolean)
}
