import { ApiConnection } from 'config'


export const resetPasswordService = async ({ cardCode, newPassword, oldPassword }) => {
    try {
        const { data } = await ApiConnection.patch(
            `users/password?cardCode=${cardCode}&newPassword=${newPassword}&oldPassword=${oldPassword}`
        );
        return data;
    } catch (error) {
        console.error(error)
    }
}
