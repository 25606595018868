import { ApiConnection } from 'config'

export const getAllOrders = async (cardCode) => {
  try {
    const res = await ApiConnection.get(
      `state-sales-order?cardCode=${cardCode}`
    )
    return res?.data?.response
  } catch (e) {
    console.error(e.message)
  }
}
