import { fixAmount, isProductCategoryInCart, isProductInCart } from './helpers'

export const isProductPromotionObjective = (product, promotion) =>
  (promotion?.skuObj &&
    product?.sku &&
    promotion?.skuObj?.includes(product?.sku)) ||
  (promotion?.skuObj &&
    product?.sku) ||
  (promotion?.tipoCategoria &&
    product?.subLinea &&
    promotion?.tipoCategoria === product?.subLinea) ||
  (promotion?.tipoCategoria &&
    product?.linea &&
    promotion?.tipoCategoria === product?.linea) ||
  (promotion?.tipoCategoria &&
    product?.rubro &&
    promotion?.tipoCategoria === product?.rubro) ||
  false

export const isPromotionRelatedWithProduct = (product, promotion) =>
  isProductPromotionObjective(product, promotion) ||
  (promotion?.sku && product?.sku && promotion?.sku === product?.sku) ||
  (promotion?.categoria &&
    product?.subLinea &&
    promotion?.categoria === product?.subLinea) ||
  (promotion?.categoria &&
    product?.linea &&
    promotion?.categoria === product?.linea) ||
  (promotion?.categoria &&
    product?.rubro &&
    promotion?.categoria === product?.rubro) ||
  false

export const isProductInSomePromotion = (product, promotions) =>
  getFlatPromotions(promotions)?.some((promotion) =>
    isPromotionRelatedWithProduct(product, promotion)
  )

export const getHighPromotionDiscount = (list) => {
  const listSort = list.sort((a, b) => {
    const descuentoA = a?.cantidadAfectada ? a?.descuento : 0
    const descuentoB = b?.cantidadAfectada ? b?.descuento : 0

    if (descuentoA === descuentoB) {
      return (
        (descuentoB || 0) * (b?.cantidadAfectada || 0) -
        (descuentoA || 0) * (a?.cantidadAfectada || 0)
      )
    }
    return (descuentoB || 0) - (descuentoA || 0)
  })
  return listSort[0]?.descuento ? listSort[0] : { descuento: 0, codigo: null }
}

export const getFlatPromotions = (promotions) => {
  let flatPromotions = []
  if (promotions) {
    Object.keys(promotions).map(
      (key) => (flatPromotions = [...flatPromotions, ...promotions[key]])
    )
  }
  return flatPromotions
}

const isPromotionValid = (product, promotion, cart) => {
  const { skuObj, sku, cantidad, codigo, categoria, tipoCategoria } = promotion

  const isInSkuObj = Boolean(skuObj?.includes(product.sku))
  const isSkuInCart = Boolean(isProductInCart(cart, sku, cantidad))
  const isCategoryInCart = Boolean(
    isProductCategoryInCart(cart, categoria, cantidad)
  )
  const isInCategory =
    tipoCategoria === product.subLinea ||
    tipoCategoria === product.linea ||
    tipoCategoria === product.rubro

  const isPromotionCode = (code) => codigo.startsWith(`${code}-`)

  if (isPromotionCode('PR1')) return isInSkuObj
  if (isPromotionCode('PR2')) return isInSkuObj
  if (isPromotionCode('PR3')) return isInSkuObj // verificar cantidad
  if (isPromotionCode('PR4')) return isInSkuObj && isSkuInCart
  if (isPromotionCode('PR5')) return isInSkuObj && isSkuInCart
  if (isPromotionCode('PR6')) return isInSkuObj && isSkuInCart
  if (isPromotionCode('PR7')) return isInSkuObj
  if (isPromotionCode('PR8')) return isCategoryInCart && isInSkuObj
  if (isPromotionCode('PR9')) return isInCategory && isCategoryInCart
  if (isPromotionCode('PR10')) return isSkuInCart
  if (isPromotionCode('PR15')) return false
  if (isPromotionCode('PR16')) return false

  return false
}

const calcTotalGift = (cantidad, porCada, regalo) => {
  return  Number(porCada) > Number(cantidad) ? 0 : parseInt(((cantidad) / porCada) * regalo)
}

const getAffectedQuantity = (
  cantidad = 0,
  promotion,
  cantidadSkuInCart = 0,
  cantidadCategoriaInCart = 0
) => {
  let affectedQuantity = 0
  const isPromotionCode = (code) => promotion.codigo.startsWith(`${code}-`)

  if (isPromotionCode('PR1')) affectedQuantity = cantidad
  if (isPromotionCode('PR2')) affectedQuantity = cantidad
  if (isPromotionCode('PR3'))
    affectedQuantity =
      Math.floor(cantidad / promotion.cantidad) * promotion.cantidad
  if (isPromotionCode('PR4')) affectedQuantity = cantidad
  if (isPromotionCode('PR5')) affectedQuantity = cantidad
  if (isPromotionCode('PR6')) affectedQuantity = cantidad
  if (isPromotionCode('PR7'))
    affectedQuantity = calcTotalGift(
      cantidad,
      promotion.porCada,
      promotion.regalo
    )
  if (isPromotionCode('PR8'))
    affectedQuantity =
      Math.floor(cantidadCategoriaInCart / promotion.cantidad) *
      promotion.regalo
  if (isPromotionCode('PR9')) affectedQuantity = cantidad
  if (isPromotionCode('PR10'))
    affectedQuantity = Math.floor(cantidadSkuInCart / promotion.cantidad)
  if (isPromotionCode('PR15')) affectedQuantity = 0
  if (isPromotionCode('PR16')) affectedQuantity = 0

  return affectedQuantity > cantidad ? cantidad : affectedQuantity
}

const promotionsParseToDiscount = (
  promotions,
  precioDeLista,
  productDiscount,
  cantidad,
  cart
) => {
  const formatedPromotions = promotions.map((promotion) => {
    const isAmountFix = promotion?.codigo?.startsWith('PR5-')
    const extraDiscount = promotion?.codigo?.startsWith('PR3-')
      ? parseFloat(productDiscount || 0)
      : 0

    const percentageDiscount =
      promotion.porcentaje ||
      (promotion.monto
        ? isAmountFix
          ? ((precioDeLista - promotion.monto) * 100) / precioDeLista
          : (promotion.monto * 100) / precioDeLista
        : 0)

    const descuento =
      promotion.regalo && !promotion?.codigo?.startsWith('PR8-')
        ? 100
        : percentageDiscount + extraDiscount

    const cartItemWithSku = isProductInCart(cart, promotion.sku)
    const cartItemWithCategory = isProductCategoryInCart(
      cart,
      promotion.categoria,
      0
    )

    let skuQuantity = 0
    let categoryQuantity = 0

    if (cartItemWithSku)
      cartItemWithSku.forEach((item) => (skuQuantity += item.cantidad))

    if (cartItemWithCategory)
      cartItemWithCategory.forEach(
        (item) => (categoryQuantity += item.cantidad)
      )

    const cantidadAfectada = getAffectedQuantity(
      cantidad,
      promotion,
      skuQuantity,
      categoryQuantity
    )

    return {
      codigo: promotion.codigo,
      descuento:
        descuento < 0 || descuento > 100 ? 0 : Math.ceil(fixAmount(descuento)),
      fixAmount: isAmountFix
        ? fixAmount(promotion?.monto || 0) > fixAmount(precioDeLista || 0)
          ? fixAmount(precioDeLista || 0)
          : fixAmount(promotion?.monto || 0)
        : null,
      cantidadAfectada,
      cantidadNoAfectada: promotion.codigo.includes('PR7')
        ? cantidad
        : cantidad - cantidadAfectada,
    }
  })

  return formatedPromotions
}

export const getFormatPromotions = (
  promotions,
  product,
  cart,
  branchOffice
) => {
  const {
    sku,
    cantidad,
    subLinea,
    linea,
    rubro,
    precioDeLista,
    descuento,
  } = product
  const flatPromotions = getFlatPromotions(promotions)

  const filterPromotions = flatPromotions.filter(
    (promotion) =>
      isPromotionValid({ sku, subLinea, linea, rubro }, promotion, cart) &&
        (promotion.sucursal?.includes(branchOffice) || promotion.sucursal === '*')
    )
    
  const promotionsFormated = promotionsParseToDiscount(
    filterPromotions,
    precioDeLista,
    descuento,
    cantidad,
    cart
  )
  return promotionsFormated
}
