import React, { useEffect, useMemo, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Card from '@mui/material/Card'
import { Grid, useMediaQuery, Box, Button } from '@mui/material'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { useStylesProducts } from './styles'
import { formatNumber } from '../../utils/formatNumber'
import { useDispatch, useSelector } from 'react-redux'
import DiscountLabel from 'components/DiscountLabel/index'
import { getAllProductDiscount } from 'pages/ProductPage/utils/getProductDiscounts'
import { fixAmount } from 'pages/ProductPage/utils/helpers'
import { useStyles } from '../../pages/ProductPage/ProductPage.styles'
import { setCompraRapidaModalIsOpened } from 'stores/appStore/actionsApp'
import ProductsColorTabs from './ProductsColorTabs'
import useProductPrice from 'customHooks/useProductPrice'

export default function ProductCard({
  sinImagen,
  imagen,
  showPrice,
  precioDeLista,
  nombre,
  nombreEC,
  descuento,
  tipo,
  sku,
  combos,
  linea,
  subLinea,
  rubro,
  moneda,
  catalogo,
  variantes,
  isLastOpportunity,
}) {
  const styles = useStyles()
  const desktop = useMediaQuery((theme) => theme.breakpoints.up('sm'), {
    noSsr: true,
  })
  const { userInfo, signedIn, currentBranch } = useSelector(
    ({ userAuth }) => userAuth
  )
  const classes = useStylesProducts({ signedIn })
  const [discountInfo, setDiscountInfo] = useState({})
  const cart = useSelector((store) => store.cart)
  const navigate = useNavigate()
  const isCatalogue =
    useLocation().pathname.includes('/catalogue') || !!catalogo
  const [varianteSelectedIndex, setVarianteSelectedIndex] = useState(null)
  const dispatch = useDispatch()

  const {priceToShow, priceApplyPromotions} = useProductPrice({
    sku: variantes?.length > 0 ? variantes[varianteSelectedIndex || 0].sku : sku,
    precioDeLista: variantes?.length > 0 ? variantes[varianteSelectedIndex || 0].precioDeLista : precioDeLista,
  })

  const img =
    varianteSelectedIndex === null
      ? imagen?.[0]
      : variantes[varianteSelectedIndex || 0].imagen?.[0]

  useEffect(() => {
    if (signedIn) {
      if(!priceApplyPromotions){
        return setDiscountInfo({})
      }
      let product = {
        descuento,
        precioDeLista: priceToShow,
        cantidad: 1,
        sku,
        linea,
        subLinea,
        rubro,
        tipo,
      }

      if (variantes?.length > 0) {
        product = variantes[varianteSelectedIndex || 0]
      }

      const allProductDiscounts = getAllProductDiscount(
        product,
        cart,
        userInfo?.ofertas,
        userInfo?.promociones,
        null,
        currentBranch?.cod
      )

      const discount = allProductDiscounts?.secondaryDiscount
        ? allProductDiscounts?.secondaryDiscount
        : allProductDiscounts?.mainDiscount

      setDiscountInfo(discount)
    }
  }, [
    userInfo?.ofertas,
    userInfo?.promociones,
    signedIn,
    descuento,
    priceToShow,
    sku,
    cart,
    varianteSelectedIndex,
    priceApplyPromotions
  ])

  if (nombreEC?.trim() === '') nombreEC = null

  const price = useMemo(() => {
    return String(
      formatNumber(
        discountInfo?.discount
          ? discountInfo?.priceWithDiscount.toString()
          : String(priceToShow),
        moneda || 'ARS'
      ) ?? 0
    )
  }, [
    discountInfo?.discount,
    discountInfo?.priceWithDiscount,
    priceToShow,
    precioDeLista,
    moneda,
  ])

  function findVariantByColorAndShowImg(color) {
    const varianteIndex = variantes.findIndex(
      ({ caracteristicas }) =>
        caracteristicas.find(({ nombre }) => nombre === 'Color')?.values[0]
          ?.nombre === color
    )
    setVarianteSelectedIndex(varianteIndex)
  }

  const variantColors = useMemo(() => {
    return [
      ...new Set(
        variantes
          ?.map(
            ({ caracteristicas }) =>
              caracteristicas.find(({ nombre }) => nombre === 'Color')
                ?.values[0]?.nombre
          )
          .filter(Boolean)
      ),
    ]
  }, [variantes])

  const hasSpecialPrice = Number(priceToShow ?? 0) < Number(precioDeLista ?? 0)

  return (
    <Grid
      item
      sx={{
        '&.MuiGrid-root': {
          width:
            desktop && isCatalogue
              ? '242px !important'
              : isCatalogue
              ? '47%'
              : '90%',
          marginBottom: '30px',
          paddingBottom: '-30px',
        },
      }}
    >
      <Card
        classes={{
          root: classes?.card,
        }}
        sx={{
          position: 'relative',
          '&:hover': {
            boxShadow: '0px 5px 13px rgba(0, 0, 0, 0.2)',
          },
        }}
      >
        <DiscountLabel
          discountType={
            hasSpecialPrice 
            ? 'specialPrice'
            : combos 
              ? 'combo' 
              : discountInfo?.discountType
          }
        />
        {isLastOpportunity && (
          <div className={classes.promotionFlag}>
            <Typography className={classes.promotionText}>
              ULTIMAS OPORTUNIDADES
            </Typography>
          </div>
        )}

        <img
          src={img || sinImagen}
          height='132px'
          alt='Producto'
          className={classes.productImage}
          style={{ cursor: 'pointer' }}
          onClick={() => navigate(`/producto/${sku}`)}
        />
        <CardContent style={{ padding: '0px' }}>
          <Typography paragraph classes={{ paragraph: classes?.productName }}>
            {nombreEC || nombre}
          </Typography>
          {/* {!variantes && ( */}
          <Typography paragraph className={styles.sku}>
            SKU {sku}
          </Typography>
          {/* )} */}
          {showPrice && (
            <Box className={classes?.row} style={{ position: 'relative' }}>
              <Box>
                {(discountInfo?.discount || hasSpecialPrice) && (
                  <Typography
                    paragraph
                    component='strike'
                    gutterBottom={false}
                    classes={{ paragraph: classes?.productOffert }}
                  >
                    {formatNumber(
                      String(hasSpecialPrice ? precioDeLista : priceToShow), 
                      moneda || 'ARS'
                    ) ?? 0}
                  </Typography>
                )}
                <Typography
                  paragraph
                  gutterBottom={false}
                  classes={{ paragraph: classes?.productPrice }}
                >
                  {price}
                </Typography>
              </Box>
              <Box sx={{ marginLeft: '10px' }}>
                {discountInfo?.discount && (
                  <Typography
                    paragraph
                    gutterBottom={false}
                    classes={{
                      paragraph: isLastOpportunity
                        ? classes.offertBoxLastOpportunity
                        : classes.offertBox,
                    }}
                    style={
                      price.length > 8
                        ? {
                            position: 'absolute',
                            right: 0,
                            top: 0,
                          }
                        : {}
                    }
                  >
                    {`${fixAmount(discountInfo?.discount)}%`} OFF
                  </Typography>
                )}
                <Typography className={classes?.ivaText}>+IVA</Typography>
              </Box>
            </Box>
          )}
          <Box
            className={classes?.row}
            sx={{
              justifyContent: 'space-between',
              marginTop: 2,
              flexDirection: desktop ? 'row' : 'column',
            }}
          >
            {showPrice && (
              <Box style={!desktop ? { width: '100%' } : {}}>
                <Button
                  variant={
                    isLastOpportunity ? 'lastOpportunityPrimary' : 'primary'
                  }
                  onClick={() => dispatch(setCompraRapidaModalIsOpened(sku))}
                  sx={{
                    padding: 1,
                    fontSize: 14,
                    height: 34,
                    width: desktop ? '100px' : '100%',
                  }}
                >
                  Comprar
                </Button>
              </Box>
            )}
            <Box style={!desktop ? { width: '100%', marginTop: 10 } : {}}>
              <Link to={`/producto/${sku}`}>
                <Button
                  variant={
                    isLastOpportunity ? 'lastOpportunitySecondary' : 'secondary'
                  }
                  sx={{
                    padding: 1,
                    fontSize: 14,
                    height: 34,
                    width: desktop ? '100px' : '100%',
                  }}
                >
                  + Info
                </Button>
              </Link>
            </Box>
          </Box>
          {/* {variantes?.length > 0 &&
            variantes.some(({ caracteristicas }) =>
              caracteristicas?.some(({ nombre }) => nombre === 'Color')
            ) && (
              <Box sx={{ marginTop: '20px' }}>
                <ProductsColorTabs
                  onClick={findVariantByColorAndShowImg}
                  colors={variantColors}
                />
              </Box>
            )} */}
        </CardContent>
      </Card>
    </Grid>
  )
}
