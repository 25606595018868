import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  mainBox: {
    width: '87vw',
    maxWidth: '350px',
    backgroundColor: 'white',
  },
  closeBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: '14px',
    cursor: 'pointer',
  },
  closeIcon: {
    padding: '10px 27px 0px 0px',
  },
}))
