import {
  LOAD_INITIAL_DATA_FINISHED,
  SET_BANNERS,
  SET_COMPRA_RAPIDA_MODAL,
  SET_LOADING,
} from './constantsApp'
import {
  getAboutBanners,
  getAppInfo,
  getCartBanners,
  getCatalogoBannersFiles,
  getCatalogoCardsFiles,
  getCategories,
  getHomeBanners,
  getProductLinesBanners,
} from './services/getters'

export const getInitialData = () => (dispatch) => {
  Promise.all([getCategories(), getAppInfo(), getHomeBanners()]).then(
    ([allCategories, appInfo, homeBanners]) => {
      const deskBanners = homeBanners.filter((row) => !row.showOnMobile)
      const mobileBanners = homeBanners.filter((row) => row.showOnMobile)

      dispatch({
        type: SET_BANNERS,
        payload: {
          home: { desk: deskBanners, mobile: mobileBanners },
        },
      })
      dispatch({
        type: LOAD_INITIAL_DATA_FINISHED,
        payload: { allCategories, appInfo },
      })
    }
  )
}

export const getAboutBannersAction = () => async (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true })
  try {
    const aboutBanners = await getAboutBanners()
    const deskBanners = aboutBanners.filter((row) => !row.showOnMobile)
    const mobileBanners = aboutBanners.filter((row) => row.showOnMobile)
    dispatch({
      type: SET_BANNERS,
      payload: { about: { desk: deskBanners, mobile: mobileBanners } },
    })
  } catch (error) {
    console.error(error)
  } finally {
    dispatch({ type: SET_LOADING, payload: false })
  }
}

export const getProductLinesBannersAction = () => async (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true })
  try {
    const productLinesBanners = await getProductLinesBanners()
    const deskBanners = productLinesBanners.filter((row) => !row.showOnMobile)
    const mobileBanners = productLinesBanners.filter((row) => row.showOnMobile)
    dispatch({
      type: SET_BANNERS,
      payload: { productLines: { desk: deskBanners, mobile: mobileBanners } },
    })
  } catch (error) {
    console.error(error)
  } finally {
    dispatch({ type: SET_LOADING, payload: false })
  }
}

export const getCartBannersAction = () => (dispatch) => {
  getCartBanners().then((cartBanners) => {
    const deskBanners = cartBanners.filter((row) => !row.showOnMobile)
    const mobileBanners = cartBanners.filter((row) => row.showOnMobile)
    dispatch({
      type: SET_BANNERS,
      payload: { cart: { desk: deskBanners, mobile: mobileBanners } },
    })
  })
}

export const getCatalogoBannersAction = (category) => (dispatch) => {
  Promise.all([
    getCatalogoBannersFiles(category),
    getCatalogoCardsFiles(category),
  ]).then(([topBanners, cardBanners]) => {
    const deskTopBanners = topBanners.filter((row) => !row.showOnMobile)
    const mobileTopBanners = topBanners.filter((row) => row.showOnMobile)

    dispatch({
      type: SET_BANNERS,
      payload: {
        catalogo_top: { desk: deskTopBanners, mobile: mobileTopBanners },
        catalogo_cards: { desk: cardBanners, mobile: cardBanners },
      },
    })
  })
}

export const setCompraRapidaModalIsOpened = (sku) => (dispatch) => {
  dispatch({
    type: SET_COMPRA_RAPIDA_MODAL,
    payload: { isOpen: true, sku },
  })
}

export const setCompraRapidaModalIsClosed = () => (dispatch) => {
  dispatch({
    type: SET_COMPRA_RAPIDA_MODAL,
    payload: { isOpen: false },
  })
}

export const setSectionIsLoading = ([section = '', value = true]) => (
  dispatch
) => {
  dispatch({
    type: SET_LOADING,
    payload: [section, value],
  })
}
