export const constantsCart = {
  CART_INIT: 'cart-init',
  CART_LOAD: 'cart-load',
  CART_EMPTY: 'cart-empty',
  CART_ADD_ITEM: 'cart-add-item',
  CART_EDIT_ITEM: 'cart-edit-item',
  CART_PLUS_ITEM: 'cart-plus-item',
  CART_MINUS_ITEM: 'cart-minus-item',
  CART_REMOVE_ITEM: 'cart-remove-item',
  CART_ADD_CUPON: 'cart-add-cupon',
  CART_ADD_CUPON_NEW: 'cart-add-cupon-new',
  CART_GET_SAVED_ORDERS_REQUEST: 'cart-get-saved-orders-request',
  CART_GET_SAVED_ORDERS_SUCCESS: 'cart-get-saved-orders-success',
  CART_GET_SAVED_ORDERS_FAIL: 'cart-get-saved-orders-fail',
  CART_DELETE_SAVED_ORDER_REQUEST: 'cart-delete-saved-order-request',
  CART_DELETE_SAVED_ORDER_SUCCESS: 'cart-delete-saved-order-success',
  CART_DELETE_SAVED_ORDER_FAIL: 'cart-delete-saved-order-fail',
}
