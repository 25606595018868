const groupOrders = (articleList = []) => {
  const orderList = []
  const orderExists = (code) => {
    return orderList.find((order) => order.codigo === code)
  }
  
  articleList.map((article) => {
    const orderId = article?.NroEc?.split('_')?.[0]?.slice(-8) || article.NroPedido
    const order = orderExists(orderId)
    if (order) {
      order.articulos.push(article)
    } else {
      orderList.push({
        fecha: article?.Fecha,
        codigo: orderId || '-',
        total: article?.TotalPedido,
        articulos: [article],
      })
    }
  })
  return asignarEstados(orderList)
}

export const asignarEstados = (orders = []) => {
  const ordersWithStatus = orders.map((order) => {
    let pendiente = false
    let cancelado = true
    order?.articulos?.map((articulo) => {
      if (articulo?.Estado === 'Pendiente') pendiente = true
      if (articulo?.Estado !== 'Cancelado') cancelado = false
    })
    const despachadoCancelado = cancelado ? 'Cancelado' : 'Despachado'
    order.estado = pendiente ? 'Pendiente' : despachadoCancelado
    return order
  })

  return ordersWithStatus
}

export default groupOrders
