import getProducer from 'stores/cartStore/services/getProducer'
import { formatDate } from 'utils/formatDate'
import { constantsCart } from './constantsCart'
import { addItem } from './services/addItem'
import { getCart, getSavedCarts } from './services/getCart'
import { minusItem } from './services/minusItem'
import { plusItem } from './services/plusItem'
import { removeItem } from './services/removeItem'
import { requestQueue } from 'utils/requestsQueue'
import { setSectionIsLoading } from 'stores/appStore/actionsApp'
import { deleteOrder, updateActiveOrder } from './services/updateActiveOrder'
import { UPDATE_USER_OPENED_CART } from 'stores/userStore/constantsUser'

const {
  CART_INIT,
  CART_LOAD,
  CART_ADD_ITEM,
  CART_EDIT_ITEM,
  CART_PLUS_ITEM,
  CART_MINUS_ITEM,
  CART_REMOVE_ITEM,
  CART_ADD_CUPON,
  CART_ADD_CUPON_NEW,
  CART_GET_SAVED_ORDERS_REQUEST,
  CART_GET_SAVED_ORDERS_SUCCESS,
  CART_GET_SAVED_ORDERS_FAIL,
  CART_DELETE_SAVED_ORDER_REQUEST,
  CART_DELETE_SAVED_ORDER_SUCCESS,
  CART_DELETE_SAVED_ORDER_FAIL,
} = constantsCart

export const getCartItems = (idUsuario, idOrder) => async (dispatch) => {
  requestQueue.addToQueue(async () => {
    try {
      const actionType = idOrder ? CART_LOAD : CART_GET_SAVED_ORDERS_SUCCESS
      const res = await getCart(idUsuario, idOrder)

      if (!res) {
        const newIdOrder = formatDate()
        dispatch({ type: CART_INIT, payload: newIdOrder })
        localStorage.setItem('idOrder', newIdOrder)
        const currentBranch = JSON.parse(localStorage.getItem('currentBranch'))
        const branchIdentifier = currentBranch?.cod
        const user = JSON.parse(localStorage.getItem('userInfo'))
        updateActiveOrder(user.user, newIdOrder, branchIdentifier)
        const updatedUserInfo = { ...user, openedCart: newIdOrder }
        dispatch({ type: UPDATE_USER_OPENED_CART, payload: newIdOrder })
        localStorage.setItem('userInfo', JSON.stringify(updatedUserInfo))
        return
      } else {
        dispatch({ type: actionType, payload: res })
      }
      localStorage.setItem('idOrder', res?.idOrder)
    } catch (error) {
      console.error(error)
    }
  })
}

export const plusCartItem = (product, order, quantity = 1) => (dispatch) => {
  requestQueue.addToQueue(() => plusItem(product, order))
  dispatch({
    type: CART_PLUS_ITEM,
    payload: {
      [product.tipo]: {
        ...product,
        cantidad: product.cantidad + quantity,
      },
    },
  })
}

export const minusCartItem = (product, order, quantity = 1) => (dispatch) => {
  requestQueue.addToQueue(() => minusItem(product, order))
  dispatch({
    type: CART_MINUS_ITEM,
    payload: {
      [product.tipo]: {
        ...product,
        cantidad: product.cantidad - quantity,
      },
    },
  })
}

export const addItemToCart = (product, order, setLoading, parent) => (
  dispatch
) => {
  requestQueue.addToQueue(async () => {
    try {
      setLoading && setLoading(true)

      const { response } = await addItem(product, order)

      const productData = { ...response }
      if (
        productData?.productor &&
        typeof productData?.productor === 'string'
      ) {
        const productor = await getProducer(productData?.productor)
        productData.productor = productor
      }

      if (parent) productData.parent = parent

      if (productData) {
        productData.descuento = product.descuento
        dispatch({
          type: CART_ADD_ITEM,
          payload: { [productData.tipo]: productData },
        })
        setLoading && setTimeout(() => setLoading(false), 200)
      }
    } catch (error) {
      setLoading && setTimeout(() => setLoading(false), 200)
      console.log({ error })
    }
  })
}

export const editItemFromCart = (product, order) => (dispatch) => {
  requestQueue.addToQueue(
    async () => {
      await addItem(
        {
          [product.tipo]: {
            ...product,
          },
          updateCartItem: true,
        },
        order
      )
    },
    () => dispatch(setSectionIsLoading(['cart', false]))
  )

  dispatch({
    type: CART_EDIT_ITEM,
    payload: {
      [product.tipo]: {
        ...product,
      },
    },
  })
}

export const removeItemFromCart = (product) => (dispatch) => {
  let productType
  if (product?.product) productType = 'product'
  if (product?.product_manejo) productType = 'product_manejo'
  if (product?.product_official) productType = 'product_official'

  requestQueue.addToQueue(async () => {
    await removeItem(product[productType]?.id)
    dispatch({
      type: CART_REMOVE_ITEM,
      payload: product,
    })
  })
}

export const clearCart = () => async (dispatch) => {
  localStorage.removeItem('cuponSelected')
  const newIdOrder = formatDate()
  const currentBranch = JSON.parse(localStorage.getItem('currentBranch'))
  const branchIdentifier = currentBranch?.cod
  const user = JSON.parse(localStorage.getItem('userInfo'))
  try {
    await updateActiveOrder(user.user, newIdOrder, branchIdentifier)
    dispatch({ type: CART_INIT, payload: newIdOrder })
    localStorage.setItem('idOrder', newIdOrder)
    const updatedUserInfo = { ...user, openedCart: newIdOrder }
    localStorage.setItem('userInfo', JSON.stringify(updatedUserInfo))
    dispatch({ type: UPDATE_USER_OPENED_CART, payload: newIdOrder })
  } catch (error) {
    console.error('Error al guardar el carrito', error)
  }
}

export const setIdOrder = (newIdOrder) => (dispatch) => {
  if (newIdOrder) {
    localStorage.setItem('idOrder', newIdOrder)
    dispatch({ type: 'CART_SET_ID_ORDER', payload: newIdOrder })
  }
}

export const setCuponDiscount = (cupon) => (dispatch) => {
  if (cupon) {
    dispatch({ type: CART_ADD_CUPON, payload: cupon })
  } else {
    dispatch({ type: CART_ADD_CUPON, payload: null })
  }
}

export const setNewCuponDiscount = (cupon) => (dispatch) => {
  if (cupon) {
    localStorage.setItem('cuponSelected', cupon)
    dispatch({ type: CART_ADD_CUPON_NEW, payload: cupon })
  } else {
    localStorage.removeItem('cuponSelected')
    dispatch({ type: CART_ADD_CUPON_NEW, payload: '' })
  }
}

export const fetchSavedCarts = (userId) => async (dispatch) => {
  dispatch({ type: CART_GET_SAVED_ORDERS_REQUEST })
  try {
    const savedCarts = await getSavedCarts(userId)
    dispatch({
      type: CART_GET_SAVED_ORDERS_SUCCESS,
      payload: savedCarts,
    })
  } catch (error) {
    dispatch({
      type: CART_GET_SAVED_ORDERS_FAIL,
      payload: error.message,
    })
  }
}

export const changeActiveOrderAndFetchDetails = (orderId) => async (
  dispatch,
  getState
) => {
  const { userInfo } = getState().userAuth
  const currentBranch = JSON.parse(localStorage.getItem('currentBranch'))
  const branchIdentifier = currentBranch?.cod

  try {
    await updateActiveOrder(userInfo.user, orderId, branchIdentifier)
    const newActiveCart = await getCart(userInfo.codigoUsuario, orderId)
    dispatch({ type: CART_LOAD, payload: newActiveCart })
    localStorage.setItem('idOrder', orderId)
    const updatedUserInfo = { ...userInfo, openedCart: orderId }
    localStorage.setItem('userInfo', JSON.stringify(updatedUserInfo))
    dispatch({ type: UPDATE_USER_OPENED_CART, payload: orderId })
  } catch (error) {
    console.error('Error al obtener el carrito actual', error)
  }
}

export const deleteSavedCart = (orderId) => async (dispatch, getState) => {
  const { userInfo } = getState().userAuth
  dispatch({ type: CART_DELETE_SAVED_ORDER_REQUEST })
  try {
    await deleteOrder(orderId, userInfo?.codigoUsuario)
    dispatch({ type: CART_DELETE_SAVED_ORDER_SUCCESS, payload: orderId })
    dispatch(fetchSavedCarts(userInfo?.codigoUsuario))
  } catch (error) {
    dispatch({
      type: CART_DELETE_SAVED_ORDER_FAIL,
      payload: error.message,
    })
  }
}
