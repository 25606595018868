import React, { useContext, useMemo } from 'react'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import { useStylesBreadCrumb } from './styles'
import { useNavigate } from 'react-router-dom'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { formatTitle } from 'components/Filter/helpers/filterList'
import { buildUrl } from 'utils/filters'
import { CatalogueContext } from 'pages/CatalogPage - refactor/CatalogPage'
import { useSelector } from 'react-redux'

export default function BreadcrumbComponent({ productName, middleBreadCrumb }) {
  const classes = useStylesBreadCrumb()
  const navigate = useNavigate()
  const { categoriesURL: { values } } = useContext(CatalogueContext)
  const { initFinished } = useSelector(({app}) => app)

  const breadcumbs = useMemo(() => {
    if(middleBreadCrumb) {
      return Array.isArray(middleBreadCrumb) ? middleBreadCrumb.sort((a, b) => a.nivel - b.nivel) : []
    } else {
      return [
        ...(values?.treeWithoutCurrent || []),
        values?.currentCategory
      ]
      .filter(Boolean)
      .sort((a, b) => a.nivel - b.nivel)

    }
  }, [values, middleBreadCrumb])

  return (
    <Stack
      sx={(theme) => ({
        margin: '24px 0px 15px 14px',
        [theme.breakpoints.up('sm')]: {
          margin: '7px',
          marginTop: '16px',
        },
      })}
    >
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize='small' />}
        aria-label='breadcrumb'
        classes={{
          li: classes.li,
          separator: classes.separator,
        }}
      >
        <Typography
          paragraph
          classes={{ paragraph: classes.labelBreadCrumb }}
          onClick={() => navigate(`/`)}
        >
          Home
        </Typography>
        {Array.isArray(breadcumbs) && breadcumbs.filter((bf) => !bf.disabled).map((b, index) => {
          if(initFinished)
            return (
              <Typography
                key={index}
                paragraph
                classes={{ paragraph: classes.labelBreadCrumb }}
                onClick={() => {
                  navigate(`/catalogo/${buildUrl(b.codigo)}`)
                }}
              >
                {b.title ? formatTitle(b.title) : formatTitle(b.nombre)}
              </Typography>
            )
        })}
        {productName && (
          <Typography
            paragraph
            classes={{ paragraph: classes.labelBreadCrumb }}
            style={{ fontWeight: '700' }}
          >
            {productName}
          </Typography>
        )}
      </Breadcrumbs>
    </Stack>
  )
}
