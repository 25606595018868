export const getDateFromDate = (days = 0, relativeDate = new Date()) => {
  const targetDate = new Date(relativeDate);
  targetDate.setDate(relativeDate.getDate() + days);
  return targetDate;
}

export const formatDateToInput = (date = new Date()) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export const formatInputDateToDDMMYYYY = (val = formatDateToInput(), separator = '/') => {
  val = val.split('-')
  return `${val[2]}${separator}${val[1]}${separator}${val[0]}`
}